import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Table } from "reactstrap";
import { translateConditionEnumValue } from "helpers/translateHardcodedData";
import {conditions, installation_types} from "helpers/listsHardcodedData";
import i18next from "i18next";

const TableCondition = (props) => {
  const [state, setState] = useState({ all_conditions: [] });

  const handleDataChange = (event) => {
    const value = event.target.value;

    setState((state) => ({
      ...state,
      [event.target.name]: value,
    }));

    props.data[event.target.name] = value;
  };

  const handleEdit = () => {
    setState((state) => ({ ...state, mode: "edit" }));
  };

  const handleSave = () => {
    setState((state) => ({ ...state, mode: "view" }));
  };

  useEffect(() => {
    setState((state) => ({ ...state, ...props.data }));
  }, []);

  const radioStyle = {
    listStyle: "none",
    paddingLeft: "0",
  };
  const labelStyle = {
    paddingLeft: "20px",
  };

  const tableStyle = {
    tableLayout: "fixed",
  };

  const { buHeaterId, bureHeatCostAllocatorId, bureWaterMeterId } = props.data;

  const conditions_list = conditions();

  if (bureHeatCostAllocatorId !== null) {
    state.all_conditions = conditions_list.filter((item) =>
      item.categories.includes("heat-cost-allocator")
    );
  } else if (buHeaterId !== null) {
    state.all_conditions = conditions_list.filter((item) =>
      item.categories.includes("heat-meter")
    );
  } else if (bureWaterMeterId !== null) {
    state.all_conditions = conditions_list.filter((item) =>
      item.categories.includes("water-meter")
    );
  }

  const { nomHCATypes } = props.data;
  const { id, fromDate, toDate, condition } = props.data;

  const fromDateLocale = fromDate
    ? new Date(fromDate).toISOString().slice(0, 10)
    : "";
  const toDateLocale = toDate
    ? new Date(toDate).toISOString().slice(0, 10)
    : "";

  const conditionValue = translateConditionEnumValue(condition);

  return (
    <>
      <div className="table-responsive">
        <Table className="table-nowrap mb-0" style={tableStyle}>
          <tbody>
            <tr>
              <th>{i18next.t("date-from")}</th>

              <td>
                {props.mode === "edit" ? (
                  <input
                    type="date"
                    name="fromDate"
                    id="heat_cost_allocator_condition_from_date"
                    onChange={(e) => handleDataChange(e)}
                    value={fromDateLocale}
                    className="w-30 input-styled"
                  />
                ) : (
                  fromDateLocale
                )}
              </td>
            </tr>

            <tr>
              <th>{i18next.t("date-to")}</th>

              <td>
                {props.mode === "edit" ? (
                  <input
                    type="date"
                    name="toDate"
                    id="heat_cost_allocator_condition_to_date"
                    onChange={(e) => handleDataChange(e)}
                    value={toDateLocale}
                    className="w-30 input-styled"
                  />
                ) : (
                  toDateLocale
                )}
              </td>
            </tr>

            <tr>
              <th>{i18next.t("condition")}</th>

              <td>
                {props.mode === "edit" ? (
                  <select
                    name="heaterCondition"
                    id="condition_heater_condition"
                    onChange={(e) => handleDataChange(e)}
                    value={heaterCondition}
                    className="w-100 input-styled"
                  >
                    <option
                      value=""
                      selected={heaterCondition === "" ? true : false}
                    >
                      {i18next.t("select_condition")}
                    </option>

                    {state.all_conditions &&
                      state.all_conditions.length > 0 &&
                      state.all_conditions.map((condition) => (
                        <option
                          value={condition.key}
                          selected={
                            state.heaterCondition === condition.key
                              ? true
                              : false
                          }
                        >
                          {condition.value}
                        </option>
                      ))}
                  </select>
                ) : (
                  conditionValue
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
};

TableCondition.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TableCondition));

import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Card, CardBody, Container } from "reactstrap";
import { Modal } from "reactstrap";
import i18next from "i18next";

const ModalWarnings = (props) => {
  const closeModal = () => {
    props.closeModal();
  };

  const warning_arr = [];
  let globalWarnings = [];

  if (props.warnings && props.warnings.warnings) {
    if (
      props.warnings.warnings._globalWarning &&
      props.warnings.warnings._globalWarning.length
    ) {
      for (const globalWarningElement of props.warnings.warnings
        ._globalWarning) {
        let globalWarning = {
          name: globalWarningElement,
        };

        if (
          props.warnings.warningArguments["_globalWarning"] &&
          props.warnings.warningArguments["_globalWarning"][
            globalWarningElement
          ].length
        ) {
          globalWarning.warning_argument = {};

          if (globalWarningElement === "WALKBY_MANАGE_ADD_MANUAL_REPORT") {
            globalWarning.warning_argument.value =
              props.warnings.warningArguments["_globalWarning"][
                globalWarningElement
              ].map(
                (a) =>
                  `${i18next.t("url_add_report_estate").replaceAll("{id}", a)}`
              );
          } else {
            globalWarning.warning_argument.value =
              props.warnings.warningArguments["_globalWarning"][
                globalWarningElement
              ];
          }
        } else {
        }

        globalWarnings.push(globalWarning);
      }
    } else {
      const warnings = Object.keys(props.warnings.warnings);
      warnings.map((warning) => {
        warning_arr.push({
          name: warning,
          warning_labels: props.warnings.warnings[warning],
          warning_argument: {
            label:
              props.warnings.warningArguments[warning][
                `${props.warnings.warnings[warning]}`
              ][0],
            value:
              props.warnings.warningArguments[warning][
                `${props.warnings.warnings[warning]}`
              ][1],
          },
        });
      });
    }
  }

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Modal isOpen={props.isModalOpen} scrollable={true}>
            <div className="modal-header">
              <h5 className="modal-title mt-0">
                {i18next.t("modal_warning_title")}
              </h5>

              <button
                type="button"
                onClick={() => closeModal()}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <Card className="mb-0">
                <CardBody>
                  {globalWarnings.map((globalWarning) => {
                    if (globalWarning && globalWarning !== undefined) {
                      let msg =
                        globalWarning.warning_argument &&
                        globalWarning.warning_argument.value.length
                          ? i18next
                              .t(globalWarning.name)
                              .replace(
                                "{...}",
                                globalWarning.warning_argument.value.toString()
                              )
                          : i18next.t(globalWarning.name);

                      if (
                        globalWarning.warning_argument &&
                        globalWarning.warning_argument.value.length
                      ) {
                        globalWarning.warning_argument.value.forEach(
                          (element, index) => {
                            msg = msg.replace(`{${index}}`, element);
                            console.log(msg);
                          }
                        );
                      }

                      return (
                        <p>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: msg,
                            }}
                          />
                        </p>
                      );
                    }
                  })}

                  {warning_arr &&
                    warning_arr.length > 0 &&
                    warning_arr.map((warning) => (
                      <p>
                        {i18next
                          .t(warning.warning_labels[0])
                          .replace(
                            "{label}",
                            i18next.t(warning.warning_argument.label)
                          )
                          .replace("{value}", warning.warning_argument.value)
                          .replace("{...}", "Масив от грешките".toString())}
                      </p>
                    ))}
                </CardBody>
              </Card>
            </div>
          </Modal>
        </Container>
      </div>
    </>
  );
};

ModalWarnings.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
  closeModal: PropTypes.func,
  openModal: PropTypes.func,
};

const mapStateToProps = ({ modal }) => ({
  // modal
});

const mapDispatchToProps = (dispatch) => ({
  //...bindActionCreators({ closeModal, openModal }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ModalWarnings));

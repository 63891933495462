import PropTypes from "prop-types";
import { useRef, useEffect } from "react";
import SimpleBar from "simplebar-react";
import MetisMenu from "metismenujs";
import { withRouter, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

const SidebarContent = (props) => {
  const refDiv = useRef(null);
  const location = useLocation();

  useEffect(() => {
    initMenu();
  }, []);

  /*   componentDidUpdate(prevProps, prevState, ss) {
    if (this.props.type !== prevProps.type) {
      this.initMenu();
    }
  } */

  const initMenu = () => {
    new MetisMenu("#side-menu");

    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    for (let i = 0; i < items.length; ++i) {
      if (location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }

    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  };

  const scrollElement = (item) => {
    setTimeout(() => {
      if (refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop;
          if (currentPosition > window.innerHeight) {
            if (refDiv.current)
              refDiv.current.getScrollElement().scrollTop =
                currentPosition - 300;
          }
        }
      }
    }, 300);
  };

  const activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;

    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }

      scrollElement(item);
      return false;
    }

    scrollElement(item);
    return false;
  };

  return (
    <>
      <SimpleBar style={{ maxHeight: "100%" }} ref={refDiv}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="bx bxs-user-detail" />
                <span>{props.t("menu-users")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/user-list">{props.t("user-list")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/nomenclature" className=" waves-effect">
                <i className="bx bx-file" />
                <span>{props.t("menu-nomenclature")}</span>
              </Link>
            </li>

            <li>
              <Link to="/estate" className=" waves-effect">
                <i className="bx bxs-user-detail" />
                <span>{props.t("menu-estates")}</span>
              </Link>
            </li>

            <li>
              <Link to="/buildings" className=" waves-effect">
                <i className="bx bx-building-house" />
                <span>{props.t("menu-buildings")}</span>
              </Link>
            </li>

            <li>
              <Link to="/reports" className=" waves-effect">
                <i className="bx bx-file" />
                <span>{props.t("menu-reports")}</span>
              </Link>
            </li>

            <li>
              <Link to="/annual-calculation-bill" className=" waves-effect">
                <i className="bx bx-file" />
                <span>{props.t("menu-annual-calculation-bill")}</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="bx bx-file" />
                <span>{props.t("menu-upload-file")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/history-uploaded-files" className=" waves-effect">
                    <i className="bx bx-file" />
                    <span>{props.t("menu-history-uploaded-files")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/file-upload-f" className=" waves-effect">
                    <i className="bx bx-file" />
                    <span>{props.t("f-file")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/file-upload-fper" className=" waves-effect">
                    <i className="bx bx-file" />
                    <span>{props.t("fper-file")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/walk-by" className=" waves-effect">
                    <i className="bx bx-file" />
                    <span>{props.t("walk-by")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/file-upload-v" className=" waves-effect">
                    <i className="bx bx-file" />
                    <span>{props.t("v-file")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/file-upload-ftop" className=" waves-effect">
                    <i className="bx bx-file" />
                    <span>{props.t("ftop-file")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/file-upload-frd" className=" waves-effect">
                    <i className="bx bx-file" />
                    <span>{props.t("frd-file")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/file-upload-tec" className=" waves-effect">
                    <i className="bx bx-file" />
                    <span>{props.t("toplofication-file")}</span>
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="bx bx-file" />
                <span>{props.t("menu-export-file")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/export-file-month" className=" waves-effect">
                    <i className="bx bx-file" />
                    <span>{props.t("monthly")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/export-file-year" className=" waves-effect">
                    <i className="bx bx-file" />
                    <span>{props.t("yearly")}</span>
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="bx bx-file" />
                <span>{props.t("menu-bill-management")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/bill-management-month" className=" waves-effect">
                    <i className="bx bx-file" />
                    <span>{props.t("monthly")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/bill-management-year" className=" waves-effect">
                    <i className="bx bx-file" />
                    <span>{props.t("yearly")}</span>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
};

export default withRouter(withTranslation()(SidebarContent));

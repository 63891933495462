import { useState } from "react";
import PropTypes from "prop-types";

const PaginationSelect = (props) => {
  const [options, setOptions] = useState([10, 20, 50, 100, 200]);

  const handleDataChange = (event) => {
    props.onPageSizeUpdate(event.target.value);
  };

  const index = props.index !== undefined ? props.index : 1;
  const pageSize = props.pageSize !== undefined ? props.pageSize : 50;

  return (
    <>
      <div className="form_controls">
        <select
          type="select"
          name="pageSize"
          id={`page-size${index}`}
          onChange={(e) => handleDataChange(e)}
          value={pageSize}
          className="w-100 input-styled"
        >
          {options.map((option, i) => (
            <option key={`option-${index}${i}`} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

PaginationSelect.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
};

export default PaginationSelect;

import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Card, CardBody, Container } from "reactstrap";
import { Modal } from "reactstrap";
import i18next from "i18next";

const ModalErrors = (props) => {
  const closeModal = () => {
    props.closeModal();
  };

  const error_arr = [];
  let globalErrors = [];

  if (props.errors && props.errors.errors) {
    if (
      props.errors.errors._globalError &&
      props.errors.errors._globalError.length
    ) {
      for (const globalErrorElement of props.errors.errors._globalError) {
        let globalError = {
          name: globalErrorElement,
        };

        if (
          props.errors.errorArguments["_globalError"] &&
          props.errors.errorArguments["_globalError"][globalErrorElement].length
        ) {
          globalError.error_argument = {};

          if (globalErrorElement === "WALKBY_MANАGE_ADD_MANUAL_REPORT") {
            globalError.error_argument.value = props.errors.errorArguments[
              "_globalError"
            ][globalErrorElement].map(
              (a) =>
                `${i18next.t("url_add_report_estate").replaceAll("{id}", a)}`
            );
          } else {
            globalError.error_argument.value =
              props.errors.errorArguments["_globalError"][globalErrorElement];

            //console.log(globalError.error_argument);

            /*  error_arr.push({
              name: globalErrorElement,
              error_labels: Object.keys(globalError.error_argument.value),
              error_values: Object.values(globalError.error_argument.value),
              //error_arguments: globalError.error_argument.value,
            });*/
          }

          //console.log(globalError.error_argument);
        } else {
        }

        globalErrors.push(globalError);
      }
    } else {
      const errors = Object.keys(props.errors.errors);
      console.log(errors);
      errors.map((error) => {
        error_arr.push({
          name: error,
          error_labels: props.errors.errors[error],
          error_argument: {
            label:
              props.errors.errorArguments[error][
                `${props.errors.errors[error]}`
              ][0],
            value:
              props.errors.errorArguments[error][
                `${props.errors.errors[error]}`
              ][1],
          },
        });
      });
    }
  }

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Modal isOpen={props.isModalOpen} scrollable={true}>
            <div className="modal-header">
              <h5 className="modal-title mt-0">
                {i18next.t("modal_error_title")}
              </h5>

              <button
                type="button"
                onClick={() => closeModal()}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <Card className="mb-0">
                <CardBody>
                  {globalErrors.map((globalError) => {
                    // console.log(globalError);
                    if (globalError && globalError !== undefined) {
                      let msg =
                        globalError.error_argument &&
                        globalError.error_argument.value.length
                          ? i18next
                              .t(globalError.name)
                              .replace(
                                "{...}",
                                globalError.error_argument.value.toString()
                              )
                          : i18next.t(globalError.name);

                      if (
                        globalError.error_argument &&
                        globalError.error_argument.value.length
                      ) {
                        globalError.error_argument.value.forEach(
                          (element, index) => {
                            msg = msg.replace(`{${index}}`, element);
                            console.log(msg);
                          }
                        );
                      }

                      return (
                        <p>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: msg,
                            }}
                          />
                        </p>
                      );

                      /*return (
                        <p>
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                globalError.error_argument &&
                                globalError.error_argument.value.length
                                  ? i18next
                                      .t(globalError.name)
                                      .replace(
                                        "{...}",
                                        globalError.error_argument.value.toString()
                                      )
                                  : i18next.t(globalError.name),
                            }}
                          />
                        </p>
                      );*/
                    }
                  })}

                  {error_arr &&
                    error_arr.length > 0 &&
                    error_arr.map((error) => (
                      <p>
                        {i18next
                          .t(error.error_labels[0])
                          .replace(
                            "{label}",
                            i18next.t(error.error_argument.label)
                          )
                          .replace("{value}", error.error_argument.value)
                          .replace("{...}", "Масив от грешките".toString())}
                      </p>
                    ))}
                </CardBody>
              </Card>
            </div>
          </Modal>
        </Container>
      </div>
    </>
  );
};

ModalErrors.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
  closeModal: PropTypes.func,
  openModal: PropTypes.func,
};

const mapStateToProps = ({ modal }) => ({
  // modal
});

const mapDispatchToProps = (dispatch) => ({
  //...bindActionCreators({ closeModal, openModal }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ModalErrors));

import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Table } from "reactstrap";
import { str2bool } from "../../helpers/utils.js";
import i18next from "i18next";

const TableReportPeriodSW = (props) => {
  const [state, setState] = useState({});

  const handleDataChange = (event) => {
    const value = event.target.value;

    setState((state) => ({
      ...state,
      [event.target.name]: value,
    }));

    props.data[event.target.name] = value;
  };

  const handleEdit = () => {
    setState((state) => ({ ...state, mode: "edit" }));
  };

  const handleSave = () => {
    setState((state) => ({ ...state, mode: "view" }));
  };

  const toggleActive = (e) => {
    setState((state) => ({ ...state, isActive: str2bool(e.target.value) }));

    props.data.isActive = str2bool(e.target.value);
  };

  useEffect(() => {
    setState((state) => ({ ...state, ...props.data }));
  }, []);

  const radioStyle = {
    listStyle: "none",
    paddingLeft: "0",
  };
  const labelStyle = {
    paddingLeft: "20px",
  };

  const tableStyle = {
    tableLayout: "fixed",
  };

  const { id, name, fromDay, toDay } = props.data;

  return (
    <>
      <div className="table-responsive">
        <Table className="table-nowrap mb-0" style={tableStyle}>
          <tbody>
            <tr>
              <th>{i18next.t("name")}</th>

              <td>
                {props.mode === "edit" ? (
                  <input
                    name="name"
                    placeholder={i18next.t("placeholder_enter_name")}
                    id="report_period_sw_name"
                    onChange={(e) => handleDataChange(e)}
                    defaultValue={name}
                    className="w-100 input-styled"
                  />
                ) : (
                  name
                )}
              </td>
            </tr>

            <tr>
              <th>{i18next.t("fromDay")}</th>

              <td>
                {props.mode === "edit" ? (
                  <input
                    type="number"
                    min={1}
                    max={31}
                    name="fromDay"
                    placeholder={i18next.t("placeholder_enter_day")}
                    id="report_period_sw_from_year"
                    onChange={(e) => handleDataChange(e)}
                    defaultValue={fromDay}
                    className="w-100 input-styled"
                  />
                ) : (
                  fromDay
                )}
              </td>
            </tr>

            <tr>
              <th>{i18next.t("toDay")}</th>

              <td>
                {props.mode === "edit" ? (
                  <input
                    type="number"
                    min={1}
                    max={31}
                    name="toDay"
                    placeholder={i18next.t("placeholder_enter_day")}
                    id="report_period_sw_to_year"
                    onChange={(e) => handleDataChange(e)}
                    defaultValue={toDay}
                    className="w-100 input-styled"
                  />
                ) : (
                  toDay
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
};

TableReportPeriodSW.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TableReportPeriodSW));

import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import BarPageTitle from "../../components/Common/BarPageTitle";
import TableComponent from "../../components/Common/TableComponent";
import { TextField, Tooltip } from "@material-ui/core";
import { throttle } from "lodash";
import Pagination from "components/Common/Pagination";
import PaginationSelect from "components/Common/PaginationSelect";
import FilterBar from "components/Common/FilteringBar";
import * as endpoints from "constants/endpoints";
import { GetData } from "../../helpers/CRUD.js";
import i18next from "i18next";

const Estate = (props) => {
  const [state, setState] = useState({
    pageTitle: i18next.t("menu-estates"),
    isModalOpen: false,
    data: [],
    estates: [],
    isLoading: false,
    isLoaded: false,
    page: 1,
    totalCount: null,
    pageSize: 100,
    sorterLabel: "",
    sorterDirection: "",
    isActive: true,
  });

  const openModal = () => {
    setState((state) => ({ ...state, isModalOpen: !state.isModalOpen }));

    removeBodyCss();
  };

  const closeModal = () => {
    setState((state) => ({ ...state, isModalOpen: false }));
    removeBodyCss();
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };

  const filterEstates = (value) => {
    let endpointQueryParam = "";
    let endpointSortAdd = "";

    if (value) {
      endpointQueryParam = `filterText=${value}`;
    }

    if (state.sorterLabel !== "") {
      endpointSortAdd = `&orderBy=${state.sorterLabel}_${state.sorterDirection}`;
    }

    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/Estate/GetAll?${endpointQueryParam}&page=${state.page}&size=${state.pageSize}${endpointSortAdd}&isActive=${state.isActive}`;

    GetData(
      url,
      (data) => {
        let tableBodyData;

        if (data) {
          tableBodyData = data.items.map((item) => {
            return {
              id: item.id,
              data: {
                owner: item.currentOwner ? item.currentOwner.name : "",
                address: item.address
                  ? `${item.address.streetName} ${item.address.streetCode} ${
                      item.address.buildingNumber
                    } ${item.address.buildingSubNumber}, вх.: ${
                      item.entrance
                    }, ${item.type ? item.type.toLowerCase() : ""}: ${
                      item.apartmentNumber
                    }`
                  : "",
                tec: item.externalId ? item.externalId : "",
                sw:
                  item.sofiaWaterData &&
                  (item.sofiaWaterData.subscriberNumber === "empty"
                    ? "-"
                    : item.sofiaWaterData.subscriberNumber),
                number: item.number,
                contractNumber: item.externalContractNumber,
                isActive: item.isActive,
              },
            };
          });
        }

        setState((state) => ({
          ...state,
          estates: tableBodyData,
          isLoaded: true,
          totalCount: data.totalCount,
        }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const filterEstatesThrottled = throttle(filterEstates, 800);

  const updatePage = (page) => {
    setState((state) => ({ ...state, page }));
  };

  const onPageSizeUpdate = (pageSize) => {
    setState((state) => ({ ...state, pageSize, page: 1 }));
  };

  const onSorterClick = (label, direction) => {
    setState((state) => ({
      ...state,
      page: 1,
      sorterLabel: label,
      sorterDirection: direction,
    }));
  };

  useEffect(() => {
    filterEstates();
  }, [state.page, state.pageSize, state.isActive]);

  useEffect(() => {
    onPageSizeUpdate(state.pageSize);
  }, [state.isActive]);

  useEffect(() => {
    setState((state) => ({ ...state, isLoading: true }));
  }, []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <BarPageTitle pageTitle={state.pageTitle} />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row className="mb-4">
                    <Col>
                      <Tooltip
                        title="Търсете по Собственик, Адрес или Абонатен номер Топлофикация"
                        placement="right-start"
                      >
                        <TextField
                          InputLabelProps={{
                            style: {
                              pointerEvents: "auto",
                            },
                          }}
                          placeholder={i18next.t("placeholder_search")}
                          variant="outlined"
                          type="text"
                          size="small"
                          onChange={(e) =>
                            filterEstatesThrottled(e.target.value)
                          }
                        />
                      </Tooltip>
                    </Col>

                    <Col md="2">
                      <PaginationSelect
                        pageSize={state.pageSize}
                        index={1}
                        onPageSizeUpdate={onPageSizeUpdate}
                      />
                    </Col>
                  </Row>

                  <FilterBar
                    tableData={state.estates}
                    isActive
                    state={state}
                    setState={setState}
                  ></FilterBar>

                  <TableComponent
                    dataHead={[
                      i18next.t("owner"),
                      i18next.t("address"),
                      i18next.t("toplofication-number"),
                      i18next.t("water-supply-number"),
                      i18next.t("inner-client-number"),
                      i18next.t("Договорна сметка"),
                      i18next.t("actions"),
                    ]}
                    dataBody={state.estates}
                    sorterData={[
                      "currentOwner.name",
                      "address.streetName",
                      "externalId",
                      "sofiaWaterData.subscriberNumber",
                      "number",
                      "externalContractNumber",
                    ]}
                    onSorterClick={onSorterClick}
                    url_path={"estate"}
                    category={"estates"}
                    enableLinkView={true}
                    isLoading={state.isLoading}
                    isLoaded={state.isLoaded}
                  />

                  <Pagination
                    updatePage={updatePage}
                    page={state.page}
                    totalCount={state.totalCount}
                    pageSize={state.pageSize}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

Estate.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUsers: () => dispatch(getUsers()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Estate));

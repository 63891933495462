import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";
import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css";

const PaginationNav = (props) => {
  const handlePageClick = (page) => {
    props.updatePage(page);
  };

  const { page, totalCount, pageSize } = props;

  return (
    <>
      <Row>
        <Col lg="12" className="d-flex justify-content-center">
          <Pagination
            currentPage={page}
            totalSize={totalCount}
            sizePerPage={pageSize}
            changeCurrentPage={handlePageClick}
            theme="bootstrap"
          />
        </Col>
      </Row>
    </>
  );
};

PaginationNav.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
};

export default PaginationNav;

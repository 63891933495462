import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import BarPageTitle from "../../components/Common/BarPageTitle";
import BackButton from "../../components/Common/BackButton.js";
import * as endpoints from "constants/endpoints";
import { GetData } from "../../helpers/CRUD.js";
import i18next from "i18next";

const MonthlyHistory = (props) => {
  const { id } = useParams();

  const [state, setState] = useState({
    buildingExternalId: "",
    address: "",
    tableData: [],
  });

  const getTableData = () => {
    const url = `${endpoints.CALCULATIONS}/GetLast13Months/${id}`;

    GetData(
      url,
      (data) => {
        setState((state) => ({
          ...state,
          tableData: data,
          buildingExternalId: data[0]?.buildingExternalId,
          address: data[0]?.address,
        }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    getTableData();
  }, []);

  const tableHead = {
    backgroundColor: "#F0F0F0",
    borderColor: "#C8C8C8",
    verticalAlign: "middle",
  };

  const tableBody = {
    borderColor: "#C8C8C8",
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md="3">
              <BackButton />
            </Col>
          </Row>
          <BarPageTitle
            pageTitle={
              state.buildingExternalId &&
              state.address &&
              `${state.buildingExternalId} - ${state.address}`
            }
          />
          <Row>
            <Table
              className="table scrollable table-bordered"
              style={{ tableLayout: "fixed" }}
            >
              <Thead>
                <Tr>
                  <Th style={tableHead}>{i18next.t("building")}</Th>
                  <Th style={tableHead}>{i18next.t("address")}</Th>
                  <Th style={tableHead}>{i18next.t("percentageBuilding")}</Th>
                  <Th style={tableHead}>{i18next.t("SRShotWater")}</Th>
                  <Th style={tableHead}>{i18next.t("unit")}</Th>
                  <Th style={tableHead}>
                    {i18next.t("reportCommonWaterMeter")}
                  </Th>
                  <Th style={tableHead}>
                    {i18next.t("individualWatermetersSum")}
                  </Th>
                  <Th style={tableHead}>
                    {i18next.t("coefficientCommonWaterMeter")}
                  </Th>
                  <Th style={tableHead}>{i18next.t("monthYear")}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {state.tableData && state.tableData.length === 0 ? (
                  <Tr>
                    <Td
                      colSpan={9}
                      className="align-items-center text-center"
                      style={tableBody}
                    >
                      {i18next.t("table_missing_data")}
                    </Td>
                  </Tr>
                ) : (
                  state.tableData.map((row, i) => (
                    <Tr key={`period-${row.id}`}>
                      <Td style={tableBody}>{row.buildingExternalId}</Td>
                      <Td style={tableBody}>{row.address}</Td>
                      <Td style={tableBody}>
                        {row.buildingInstallationPercent.toFixed(6)}
                      </Td>
                      <Td style={tableBody}>{row.quantityHotWater.toFixed(6)}</Td>
                      <Td style={tableBody}>{row.heatingEnergyPerUnit}</Td>
                      <Td style={tableBody}>
                        {row.globalWaterReadingDifference}
                      </Td>
                      <Td style={tableBody}>{row.sumWaterMetersConsumption}</Td>
                      <Td style={tableBody}>{row.correctionCoefficient.toFixed(6)}</Td>
                      <Td style={tableBody}>
                        {row.month && row.year && `${row.month}/${row.year}`}
                      </Td>
                    </Tr>
                  ))
                )}
              </Tbody>
            </Table>
          </Row>
        </Container>
      </div>
    </>
  );
};

MonthlyHistory.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MonthlyHistory));

import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { cloneDeep } from "lodash";
import { Table } from "reactstrap";
import { manufacturer_product_options } from "../../helpers/listsHardcodedData";
import { str2bool } from "../../helpers/utils.js";
import i18next from "i18next";

const TableEquipmentManufacturer = (props) => {
  const [state, setState] = useState({
    productOptions: manufacturer_product_options(),
  });

  const handleDataChange = (event) => {
    const value = event.target.value;
    props.data[event.target.name] = value;
    setState((state) => ({
      ...state,
      [event.target.name]: value,
    }));
  };

  const handleCheck = (event) => {
    state.productOptions.forEach((option) => {
      if (option.value === event.target.value)
        option.isChecked = event.target.checked;
    });

    var type = "";

    const filteredItem = state.productOptions.filter(
      (option) => option.isChecked
    );

    for (var i = 0; i < filteredItem.length; i++) {
      if (i === 0) {
        type = `${filteredItem[i].name}`;
      } else if (i !== 0 && i < filteredItem.length) {
        type = `${type}; ${filteredItem[i].name}`;
      } else {
      }
    }

    setState((state) => ({ ...state, type }));

    props.data.type = type;
  };

  const initialCheck = () => {
    const types = state.type.split(";").map((item) => item.trim());
    const items = cloneDeep(state.productOptions);

    console.log('types', types);
    console.log('items', items);


    items.map((item) => {
      if (types.includes(item.name)) {
        item.isChecked = true;
      }
    });

    setState((state) => ({ ...state, productOptions: items }));
  };

  const handleEdit = () => {
    setState((state) => ({ ...state, mode: "edit" }));
  };

  const handleSave = () => {
    setState((state) => ({ ...state, mode: "view" }));
  };

  const toggleActive = (e) => {
    setState((state) => ({ ...state, isActive: str2bool(e.target.value) }));

    props.data.isActive = str2bool(e.target.value);
  };

  useEffect(() => {
    setState((state) => ({ ...state, ...props.data }));
  }, []);

  useEffect(() => {
    if (props.data.type !== state.type) {
      setState((state) => ({ ...state, ...props.data }));
    }
  });

  useEffect(() => {
    state.type && initialCheck();
  }, [state.type]);

  const radioStyle = {
    listStyle: "none",
    paddingLeft: "0",
  };
  const labelStyle = {
    paddingLeft: "20px",
  };

  const tableStyle = {
    tableLayout: "fixed",
  };

  const { id, name, type } = props.data;

  const typesIncluded =
    type && type !== "" && type.split(";").map((item) => item.trim());

  return (
    <>
      <div className="table-responsive">
        <Table className="table-nowrap mb-0" style={tableStyle}>
          <tbody>
            <tr>
              <th>{i18next.t("name")}</th>

              <td>
                {props.mode === "edit" ? (
                  <input
                    name="name"
                    placeholder={i18next.t("placeholder_enter_name")}
                    id="equipment_manufacturer_name"
                    onChange={(e) => handleDataChange(e)}
                    defaultValue={name}
                    className="w-100 input-styled"
                  />
                ) : (
                  name
                )}
              </td>
            </tr>

            <tr>
              <th>{i18next.t("devices")}</th>

              <td>
                {props.mode === "edit" &&
                  state.productOptions &&
                  state.productOptions.length > 0 && (
                    <ul className="list-checkboxes" style={radioStyle}>
                      {state.productOptions.map((option) => (
                        <li key={option.value}>
                          <input
                            id={option.value}
                            name="option.value"
                            onChange={(e) => handleCheck(e)}
                            type="checkbox"
                            checked={
                              typesIncluded &&
                              typesIncluded.includes(option.value.trim()) ===
                                true
                            }
                            value={option.value}
                          />

                          <label htmlFor={option.value} style={labelStyle}>
                            {option.name}
                          </label>
                        </li>
                      ))}
                    </ul>
                  )}

                {props.mode === "view" &&
                  type &&
                  type !== "" &&
                  type
                    .split(";")
                    .map((item, index) => <p key={index}>{item}</p>)}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
};

TableEquipmentManufacturer.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TableEquipmentManufacturer));

import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Button,
} from "reactstrap";
import BarPageTitle from "../../components/Common/BarPageTitle";
import TableComponent from "components/Common/TableComponent";
import { TextField, Tooltip } from "@material-ui/core";
import IconCheck from "components/Common/IconCheck";
import IconX from "components/Common/IconX";
import { throttle } from "lodash";
import { GetData } from "../../helpers/CRUD.js";
import i18next from "i18next";
import Pagination from "components/Common/Pagination";
import PaginationSelect from "components/Common/PaginationSelect";
import * as endpoints from "constants/endpoints";
import * as jsonpatch from "fast-json-patch";

const HistoryUploadedFiles = (props) => {
  const [state, setState] = useState({
    pageTitle: i18next.t("menu-history-uploaded-files"),
    isModalOpen: false,
    files: [],
    isLoading: false,
    isLoaded: false,
    page: 1,
    totalCount: null,
    pageSize: 100,
    sorterLabel: "",
    sorterDirection: "",
  });

  const emitErrors = (error) => {
    props.onErrorsShow(error.body);
  };

  const date = new Date();

  const filterFiles = (value) => {
    let endpointQueryParam = "";
    let endpointSortAdd = "";

    if (value) {
      endpointQueryParam = `filterText=${value}`;
    }

    if (state.sorterLabel !== "") {
      endpointSortAdd = `&orderBy=${state.sorterLabel}_${state.sorterDirection}`;
    }

    const url = `${endpoints.HISTORY_UPLOADS}/GetAll?${endpointQueryParam}&page=${state.page}&size=${state.pageSize}${endpointSortAdd}`;

    GetData(
      url,
      (data) => {
        let tableBodyData;

        console.log(data);
        if (data) {
          tableBodyData = data.items.map((item) => {
            var obj = JSON.parse(item.comment);

            let errorArguments_list = "";
            let warningArguments_list = "";
            let uploadMessage = "";
            let diffMessage = "";
            let mergeDiffId = 0;

            console.log(obj);
            if (obj !== null) {
              const errors = obj.errors;
              const errorArguments = obj.errorArguments;

              const warnings = obj.warnings;
              const warningArguments = obj.warningArguments;

              uploadMessage = obj.uploadMessage;
              diffMessage = obj.diffMessage !== null ? obj.diffMessage : '';
              mergeDiffId = obj.mergeDiffId !== null ? obj.mergeDiffId : 0;

              /*
                console.log("errors", errors);
                console.log("errorArguments", errorArguments);
                console.log("warnings", warnings);
                console.log("warningArguments", warningArguments);
              */
              //warningArguments
              let globalWarningArguments = [];
              if (
                warningArguments &&
                warningArguments._globalWarning !== null
              ) {
                for (const globalWarningElement in warningArguments._globalWarning) {
                  if (
                    warningArguments._globalWarning[globalWarningElement] &&
                    warningArguments._globalWarning[globalWarningElement]
                      .length > 0
                  ) {
                    let globalWarning = {
                      name: globalWarningElement,
                      warning_arguments: [],
                    };

                    warningArguments._globalWarning[
                      globalWarningElement
                    ].forEach((item) => {
                      globalWarning.warning_arguments.push(item);
                    });
                    globalWarningArguments.push(globalWarning);
                  }
                }
              }
              warningArguments_list = globalWarningArguments.map(
                (globalWarning) => {
                  if (globalWarning && globalWarning !== null) {
                    let msg =
                      globalWarning.warning_arguments.length > 0
                        ? i18next
                            .t(globalWarning.name)
                            .replace(
                              "{...}",
                              globalWarning.warning_arguments.join(",")
                            )
                        : i18next.t(globalWarning.name);

                    return msg;
                  }
                }
              );

              //errorArguments
              let globalErrorArguments = [];
              if (errorArguments && errorArguments._globalError !== null) {
                for (const globalErrorElement in errorArguments._globalError) {
                  if (
                    errorArguments._globalError[globalErrorElement] &&
                    errorArguments._globalError[globalErrorElement].length > 0
                  ) {
                    let globalError = {
                      name: globalErrorElement,
                      error_arguments: [],
                    };

                    errorArguments._globalError[globalErrorElement].forEach(
                      (item) => {
                        globalError.error_arguments.push(item);
                      }
                    );
                    globalErrorArguments.push(globalError);
                  }
                }
              }
              errorArguments_list = globalErrorArguments.map((globalError) => {
                if (globalError && globalError !== null) {
                  let msg =
                    globalError.error_arguments.length > 0
                      ? i18next
                          .t(globalError.name)
                          .replace(
                            "{...}",
                            globalError.error_arguments.join(",")
                          )
                      : i18next.t(globalError.name);

                  return msg;
                }
              });

              if (warningArguments_list == "") {
                //warnings
                let globalWarnings = [];
                if (warnings && warnings._globalWarning !== undefined) {
                  for (const globalWarningElement of warnings._globalWarning) {
                    let globalWarning = {
                      name: globalWarningElement,
                    };

                    globalWarnings.push(globalWarning);
                  }
                }
                warningArguments_list = globalWarnings.map((globalWarning) => {
                  if (globalWarning && globalWarning !== null) {
                    return i18next.t(globalWarning.name);
                  }
                });
              }

              if (errorArguments_list == "") {
                //errors
                let globalErrors = [];
                if (errors && errors._globalError !== undefined) {
                  for (const globalErrorElement of errors._globalError) {
                    let globalError = {
                      name: globalErrorElement,
                    };

                    globalErrors.push(globalError);
                  }
                }
                errorArguments_list = globalErrors.map((globalError) => {
                  if (globalError && globalError !== null) {
                    return i18next.t(globalError.name);
                  }
                });
              }
            }

            const diffMessageWithUrl = diffMessage !== '' ? `${i18next.t(diffMessage)}: ${i18next.t("url_merge_diff_page").replaceAll("{mergeDiffId}", mergeDiffId)}` : '';

            const comment = `${errorArguments_list} ${warningArguments_list} ${i18next.t(uploadMessage)}`;
            const commentAndShowMore =
              comment.length > 200 ? (
                <>
                  <div>
                    {`${comment.slice(0, 200)}...`}
                    <Button
                      color="link"
                      onClick={(e) => {
                        e.target.parentElement.style.display = "none";
                        e.target.parentElement.nextSibling.style.display =
                          "block";
                      }}
                    >
                      {i18next.t("show_more")}
                    </Button>
                  </div>
                  <div style={{ display: "none" }}>{comment}</div>
                </>
              ) : (
                comment
              );

            return {
              id: item.id,
              data: {
                fileName: item.fileName ? item.fileName : "",
                fileType: item.fileType ? item.fileType : "",
                username: item.user ? item.user.name : "",
                dateCreated: item.dateCreated ? item.dateCreated : "",
                isSuccessUpload: item.isSuccessUpload ? (
                  <IconCheck />
                ) : (
                  <IconX />
                ),
                comment: commentAndShowMore,
                isActive: true,
              },
            };
          });
        }

        setState((state) => ({
          ...state,
          files: tableBodyData,
          isLoaded: true,
          totalCount: data.totalCount,
        }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  // useEffect(() => {
  //   var observer = jsonpatch.observe(state.document);

  const filterFilesThrottled = throttle(filterFiles, 800);

  const updatePage = (page) => {
    setState((state) => ({ ...state, page }));
    filterFiles();
  };

  const onPageSizeUpdate = (pageSize) => {
    setState((state) => ({ ...state, pageSize, page: 1 }));
    filterFiles();
  };

  const onSorterClick = (label, direction) => {
    setState((state) => ({
      ...state,
      page: 1,
      sorterLabel: label,
      sorterDirection: direction,
    }));
    filterFiles();
  };

  useEffect(() => {
    filterFiles();
    setState((state) => ({ ...state, isLoading: true }));
  }, []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <BarPageTitle pageTitle={state.pageTitle} />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col>
                      <Tooltip
                        title="Търсете по име на файл"
                        placement="right-start"
                      >
                        <TextField
                          InputLabelProps={{
                            style: {
                              pointerEvents: "auto",
                            },
                          }}
                          placeholder={i18next.t("placeholder_search")}
                          variant="outlined"
                          type="text"
                          size="small"
                          onChange={(e) => filterFilesThrottled(e.target.value)}
                        />
                      </Tooltip>
                    </Col>

                    <Col md="2" className="ml-auto mr-0">
                      <PaginationSelect
                        pageSize={state.pageSize}
                        index={1}
                        onPageSizeUpdate={onPageSizeUpdate}
                      />
                    </Col>
                  </Row>

                  <TableComponent
                    dataHead={[
                      i18next.t("file_upload_fileName"),
                      i18next.t("file_upload_fileType"),
                      i18next.t("file_upload_uploadedBy"),
                      i18next.t("file_upload_dateCreated"),
                      i18next.t("file_upload_isSuccessUpload"),
                      i18next.t("file_upload_comment"),
                    ]}
                    dataBody={state.files}
                    sorterData={["fileName", "fileType"]}
                    onSorterClick={onSorterClick}
                    url_path={"files"}
                    category={"files"}
                    // enableLinkView={false}
                    disableLink={true}
                    isLoading={state.isLoading}
                    isLoaded={state.isLoaded}
                  />

                  <Pagination
                    updatePage={updatePage}
                    page={state.page}
                    totalCount={state.totalCount}
                    pageSize={state.pageSize}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

HistoryUploadedFiles.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUsers: () => dispatch(getUsers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HistoryUploadedFiles));

import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardBody, Col, Row, FormGroup, Label } from "reactstrap";
import { Modal, Button } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { getUsers } from "store/contacts/actions";
import { cloneDeep } from "lodash";
import * as endpoints from "constants/endpoints";
import * as jsonpatch from "fast-json-patch";
import { CreateItem } from "../../helpers/CRUD.js";
import i18next from "i18next";

const ModalZone = (props) => {
  const [state, setState] = useState({
    document: {
      buildingId: "",
      entrance: "",
      volume: "",
      subscriberStation: {
        number: "",
      },
      comment: "",
    },
    initialState: {},
  });

  const handleSubmit = (event, errors, values) => {
    setState((state) => ({ ...state, errors, values }));
  };

  const handleValidSubmit = (event, values) => {
    let patch = jsonpatch.compare(state.initialState, state.document);

    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/Zone/Add`;

    CreateItem(
      url,
      patch,
      (data) => {
        update();

        const currentState = cloneDeep(state.initialState);
        setState((state) => ({ ...state, document: currentState }));

        props.emitSuccess(i18next.t("entry_added_successfully"));
      },
      (error) => {
        props.emitErrors(error);
      }
    );
  };

  const update = () => {
    props.update();
  };

  useEffect(() => {
    const initialState = cloneDeep(state.document);
    const buildingId = props.buildingId;

    setState((state) => ({
      ...state,
      initialState,
      document: { ...state.document, buildingId: buildingId },
    }));
  }, []);

  return (
    <>
      <Modal isOpen={props.isModalOpen} scrollable={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            {i18next.t("modal_zone_title")} {props.building_top}
          </h5>

          <button
            type="button"
            onClick={() => props.closeModal()}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Card className="mb-0">
            <CardBody>
              <AvForm
                onSubmit={handleSubmit}
                onValidSubmit={handleValidSubmit}
                className="needs-validation"
              >
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="zone_entrance">
                        {i18next.t("label-entrance")}
                      </Label>
                      <AvField
                        name="document.entrance"
                        placeholder={i18next.t("placeholder_enter_entrance")}
                        type="number"
                        max={32767}
                        className="form-control"
                        id="zone_entrance"
                        validate={{
                          required: {
                            value: true,
                          },
                          maxLength: { value: 30 },
                        }}
                        onChange={(e) =>
                          setState((state) => ({
                            ...state,
                            document: {
                              ...state.document,
                              entrance: e.target.value,
                            },
                          }))
                        }
                        value={state.document.entrance}
                      />
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="zone_volume">
                        {i18next.t("zone_volume")}
                      </Label>
                      <AvField
                        name="document.volume"
                        placeholder={i18next.t("placeholder_enter_zone_volume")}
                        type="number"
                        min={0}
                        errorMessage={i18next.t("wrong-number")}
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                          },
                          maxLength: { value: 15 },
                        }}
                        onChange={(e) =>
                          setState((state) => ({
                            ...state,
                            document: {
                              ...state.document,
                              volume: e.target.value,
                            },
                          }))
                        }
                        value={state.document.volume}
                        id="zone_volume"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="zone_subscriber_station_number">
                        {i18next.t("subscriber_station")}
                      </Label>
                      <AvField
                        name="document.subscriberStation.number"
                        placeholder={i18next.t(
                          "placeholder_enter_subscriber_station_number"
                        )}
                        type="number"
                        errorMessage={i18next.t("wrong-number")}
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                          },
                          maxLength: { value: 15 },
                        }}
                        onChange={(e) =>
                          setState((state) => ({
                            ...state,
                            document: {
                              ...state.document,
                              subscriberStation: {
                                ...state.document.subscriberStation,
                                number: e.target.value,
                              },
                            },
                          }))
                        }
                        value={state.document.subscriberStation.number}
                        id="zone_subscriber_station_number"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md="12">
                    <FormGroup>
                      <Label htmlFor="zone_comment">
                        {i18next.t("field_comment")}
                      </Label>
                      <AvField
                        type="textarea"
                        name="document.comment"
                        placeholder={i18next.t("placeholder_enter_comment")}
                        errorMessage={i18next.t("modal_error_title")}
                        className="form-control"
                        id="zone_comment"
                        maxLength="200"
                        onChange={(e) =>
                          setState((state) => ({
                            ...state,
                            document: {
                              ...state.document,
                              comment: e.target.value,
                            },
                          }))
                        }
                        value={state.document.comment}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => props.closeModal()}
                  >
                    <i className="fa fa-times mr-1" />
                    {i18next.t("button_cancel")}
                  </button>

                  <Button color="primary" type="submit">
                    <i className="fa fa-plus-circle mr-1" />
                    {i18next.t("button_add")}
                  </Button>
                </div>
              </AvForm>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </>
  );
};

ModalZone.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUsers: () => dispatch(getUsers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ModalZone));

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardBody, Col, Row, FormGroup, Label } from "reactstrap";
import { Modal, Button } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { getUsers } from "store/contacts/actions";
import { cloneDeep } from "lodash";
import * as endpoints from "constants/endpoints";
import * as jsonpatch from "fast-json-patch";
import { GetData, CreateItem } from "../../helpers/CRUD.js";
import i18next from "i18next";

const ModalEstate = (props) => {
  const [state, setState] = useState({
    apartment_type_options: [],
    zoneIdOptions: [],
    document: {
      buildingId: "",
      zoneId: "",
      addressId: "",
      externalId: "",
      externalCode: "",
      entrance: "",
      floor: "",
      subscriberNumberInApartment: "",
      apartmentNumber: "",
      apartmentTypeId: "",
      externalContractNumber: "",
    },
    initialState: {},
  });

  const handleSubmit = (event, errors, values) => {
    setState((state) => ({ ...state, errors, values }));
  };

  const handleValidSubmit = (event, values) => {
    let patch = jsonpatch.compare(state.initialState, state.document);

    if (props.buildingId) {
      patch.push({
        op: "replace",
        path: "/buildingId",
        value: props.buildingId,
      });
    }

    if (props.estateId) {
      patch.push({
        op: "replace",
        path: "/estateId",
        value: props.estateId,
      });
    }

    if (props.zoneId) {
      patch.push({
        op: "replace",
        path: "/zoneId",
        value: props.zoneId,
      });
    }

    if (props.addressId) {
      patch.push({
        op: "replace",
        path: "/addressId",
        value: props.addressId,
      });
    }

    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/Estate/Add`;

    CreateItem(
      url,
      patch,
      (data) => {
        update();

        const currentState = cloneDeep(state.initialState);
        setState((state) => ({ ...state, document: currentState }));

        props.emitSuccess(i18next.t("entry_added_successfully"));
      },
      (error) => {
        props.emitErrors(error);
      }
    );
  };

  const update = () => {
    props.update();
  };

  const getAllApartmentTypes = () => {
    const url = `${endpoints.NOMENCLATURE_ENDPOINT}/ApartmentType/GetAll`;

    GetData(
      url,
      (data) => {
        setState((state) => ({
          ...state,
          apartment_type_options: data.items,
          apartmentTypesLoaded: true,
        }));
        getAllZones();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getAllZones = () => {
    const url = `${endpoints.ZONE}/GetAllByBuildingId?id=${props.buildingId}`;

    GetData(
      url,
      (data) => {
        setState((state) => ({
          ...state,
          zoneIdOptions: data,
          zonesLoaded: true,
        }));

        if (data.length === 1) {
          const document = state.document;
          document.zoneId = data[0].id;

          setState((state) => ({ ...state, document }));
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    getAllApartmentTypes();

    const initialState = cloneDeep(state.document);
    setState((state) => ({ ...state, initialState }));

    if (props.zoneId) {
      const document = state.document;
      document.zoneId = props.zoneId;

      setState((state) => ({ ...state, document }));
    }

    if (props.addressId) {
      const document = state.document;
      document.addressId = props.addressId;

      setState((state) => ({ ...state, document }));
    }

    if (props.buildingId) {
      const document = state.document;
      document.buildingId = props.buildingId;

      setState((state) => ({ ...state, document }));
    }
  }, []);

  return (
    <>
      <Modal isOpen={props.isModalOpen} scrollable={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            {i18next.t("modal_estate_title")} {props.building_top}
          </h5>

          <button
            type="button"
            onClick={() => props.closeModal()}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <Card className="mb-0">
            <CardBody>
              <AvForm
                onSubmit={handleSubmit}
                onValidSubmit={handleValidSubmit}
                className="needs-validation"
              >
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="validationEntrance">
                        {i18next.t("label-entrance")}
                      </Label>
                      <AvField
                        name="document.entrance"
                        placeholder={i18next.t("placeholder_enter_entrance")}
                        type="number"
                        min={1}
                        max={32767}
                        errorMessage="Max 10 digits"
                        className="form-control"
                        value={state.document.entrance}
                        validate={{
                          required: {
                            value: true,
                          },
                          maxLength: {
                            value: 10,
                          },
                        }}
                        onChange={(e) =>
                          setState((state) => ({
                            ...state,
                            document: {
                              ...state.document,
                              entrance: e.target.value,
                            },
                          }))
                        }
                        id="validationEntrance"
                      />
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="validationFloor">
                        {i18next.t("label-floor")}
                      </Label>
                      <AvField
                        name="document.floor"
                        placeholder={i18next.t("placeholder_enter_floor")}
                        type="number"
                        min={1}
                        max={32767}
                        errorMessage="Max 10 digits"
                        className="form-control"
                        value={state.document.floor}
                        validate={{
                          required: {
                            value: true,
                          },
                          maxLength: {
                            value: 10,
                          },
                        }}
                        onChange={(e) =>
                          setState((state) => ({
                            ...state,
                            document: {
                              ...state.document,
                              floor: e.target.value,
                            },
                          }))
                        }
                        id="validationFloor"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="validationName">
                        {i18next.t("subscriber-number-in-apartment")}
                      </Label>
                      <AvField
                        name="document.subscriberNumberInApartment"
                        placeholder={i18next.t("placeholder_enter_number")}
                        type="number"
                        min={1}
                        max={32767}
                        errorMessage="Max 10 digits"
                        className="form-control"
                        value={state.document.subscriberNumberInApartment}
                        validate={{
                          required: {
                            value: true,
                          },
                          maxLength: {
                            value: 20,
                          },
                        }}
                        onChange={(e) =>
                          setState((state) => ({
                            ...state,
                            document: {
                              ...state.document,
                              subscriberNumberInApartment: e.target.value,
                            },
                          }))
                        }
                        id="validationName"
                      />
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="validationNumber">
                        {i18next.t("label-number")}
                      </Label>
                      <AvField
                        name="document.apartmentNumber"
                        placeholder={i18next.t(
                          "placeholder_enter_apartment_number"
                        )}
                        type="number"
                        min={1}
                        max={32767}
                        errorMessage="Max 10 digits"
                        className="form-control"
                        value={state.document.apartmentNumber}
                        validate={{
                          required: {
                            value: true,
                          },
                          maxLength: {
                            value: 10,
                          },
                        }}
                        onChange={(e) =>
                          setState((state) => ({
                            ...state,
                            document: {
                              ...state.document,
                              apartmentNumber: e.target.value,
                            },
                          }))
                        }
                        id="validationNumber"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col></Col>

                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="validationApartmentType">
                        {i18next.t("label-apartment-type")}
                      </Label>

                      <AvField
                        type="select"
                        name="document.apartmentTypeId"
                        id="validationApartmentType"
                        className="form-control"
                        errorMessage={i18next.t("choose-apartment-type")}
                        value={state.document.apartmentTypeId}
                        validate={{
                          required: {
                            value: true,
                          },
                        }}
                        onChange={(e) =>
                          setState((state) => ({
                            ...state,
                            document: {
                              ...state.document,
                              apartmentTypeId: e.target.value,
                            },
                          }))
                        }
                      >
                        <option
                          value=""
                          selected={
                            state.document.apartmentTypeId === "" ? true : false
                          }
                        >
                          -- {i18next.t("choose-apartment-type")} --
                        </option>

                        {state.apartment_type_options &&
                          state.apartment_type_options.length > 0 &&
                          state.apartment_type_options.map((estateType) => (
                            <option
                              key={`estate-type-${estateType.id}`}
                              value={estateType.id}
                              selected={
                                state.document.apartmentTypeId === estateType.id
                                  ? true
                                  : false
                              }
                            >
                              {estateType.name}
                            </option>
                          ))}
                      </AvField>
                    </FormGroup>
                  </Col>
                </Row>

                {state.zonesLoaded && (
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label htmlFor="validationZoneId">
                          {i18next.t("zone")}
                        </Label>

                        <AvField
                          type="select"
                          name="document.zoneId"
                          id="validationZoneId"
                          className="form-control"
                          /* errorMessage="Изберете тип на имот"*/
                          value={state.document.zoneId}
                          /*validate={{
                            required: {
                              value: true,
                            },
                          }}*/
                          onChange={(e) =>
                            setState((state) => ({
                              ...state,
                              document: {
                                ...state.document,
                                zoneId: e.target.value,
                              },
                            }))
                          }
                        >
                          <option
                            value=""
                            selected={
                              state.document.zoneId === "" ? true : false
                            }
                          >
                            {i18next.t("select_zone")}
                          </option>

                          {state.zoneIdOptions &&
                            state.zoneIdOptions.length > 0 &&
                            state.zoneIdOptions.map((zone, index) => (
                              <option
                                key={`zone-${zone.id}`}
                                value={zone.id}
                                selected={
                                  state.document.zoneId === zone.id
                                    ? true
                                    : false
                                }
                              >
                                {zone.name}
                              </option>
                            ))}
                        </AvField>
                      </FormGroup>
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="validationexternalId">
                        {i18next.t("subscriber-number")}
                      </Label>
                      <AvField
                        name="document.externalId"
                        placeholder={i18next.t("placeholder_enter_code")}
                        type="text"
                        errorMessage="Max 10 digits"
                        className="form-control"
                        value={state.document.externalId}
                        validate={{
                          required: {
                            value: true,
                          },
                          maxLength: {
                            value: 10,
                          },
                        }}
                        onChange={(e) =>
                          setState((state) => ({
                            ...state,
                            document: {
                              ...state.document,
                              externalId: e.target.value,
                            },
                          }))
                        }
                        id="validationexternalId"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="validationexternalCode">
                        {i18next.t("installation-number")}
                      </Label>
                      <AvField
                        name="document.externalCode"
                        placeholder={i18next.t("placeholder_enter_code")}
                        type="text"
                        errorMessage="Max 10 digits"
                        className="form-control"
                        value={state.document.externalCode}
                        validate={{
                          required: {
                            value: true,
                          },
                          maxLength: {
                            value: 10,
                          },
                        }}
                        onChange={(e) =>
                          setState((state) => ({
                            ...state,
                            document: {
                              ...state.document,
                              externalCode: e.target.value,
                            },
                          }))
                        }
                        id="validationexternalCode"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="validationexternalContractNumber">
                        {i18next.t("contract-number")}
                      </Label>
                      <AvField
                        name="document.externalContractNumber"
                        placeholder={i18next.t("placeholder_enter_code")}
                        type="text"
                        errorMessage="Max 10 digits"
                        className="form-control"
                        value={state.document.externalContractNumber}
                        validate={{
                          required: {
                            value: true,
                          },
                          maxLength: {
                            value: 10,
                          },
                        }}
                        onChange={(e) =>
                          setState((state) => ({
                            ...state,
                            document: {
                              ...state.document,
                              externalContractNumber: e.target.value,
                            },
                          }))
                        }
                        id="validationexternalContractNumber"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => props.closeModal()}
                  >
                    <i className="fa fa-times mr-1" />
                    {i18next.t("button_cancel")}
                  </button>

                  <Button color="primary" type="submit">
                    <i className="fa fa-plus-circle mr-1" />
                    {i18next.t("button_add")}
                  </Button>
                </div>
              </AvForm>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </>
  );
};

ModalEstate.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUsers: () => dispatch(getUsers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ModalEstate));

import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Table } from "reactstrap";
import { str2bool } from "../../helpers/utils.js";
import i18next from "i18next";

const TableSubscriberStation = (props) => {
  const [state, setState] = useState({});

  const handleDataChange = (event) => {
    const value = event.target.value;

    setState((state) => ({
      ...state,
      [event.target.name]: value,
    }));
  };

  const handleEdit = () => {
    setState((state) => ({ ...state, mode: "edit" }));
  };

  const handleSave = () => {
    setState((state) => ({ ...state, mode: "view" }));
  };

  const toggleActive = (e) => {
    setState((state) => ({ ...state, isActive: str2bool(e.target.value) }));
  };

  useEffect(() => {
    setState((state) => ({ ...state, ...props.data }));
  }, []);

  const radioStyle = {
    listStyle: "none",
    paddingLeft: "0",
  };
  const labelStyle = {
    paddingLeft: "20px",
  };

  const tableStyle = {
    tableLayout: "fixed",
  };

  return (
    <>
      <div className="table-responsive">
        <Table className="mb-0" style={tableStyle}>
          <tbody>
            <tr>
              <th>{i18next.t("name")}</th>

              <td>{state.name}</td>
            </tr>

            <tr>
              <th>{i18next.t("label-number")}</th>

              <td>
                {props.mode === "edit" ? (
                  <input
                    name="number"
                    placeholder={i18next.t("placeholder_enter_number")}
                    id="field-number"
                    type="text"
                    onChange={(e) => handleDataChange(e)}
                    defaultValue={state.number}
                    className="w-100 input-styled"
                  />
                ) : (
                  state.number
                )}
              </td>
            </tr>

            <tr>
              <th>{i18next.t("active")}</th>

              <td>
                {props.mode === "edit" ? (
                  <div>
                    <div className="radio">
                      <label>
                        <input
                          type="radio"
                          value="true"
                          checked={state.isActive === true}
                          onChange={(e) => toggleActive(e)}
                        />
                        {i18next.t("active")}
                      </label>
                    </div>

                    <div className="radio">
                      <label>
                        <input
                          type="radio"
                          value="false"
                          checked={state.isActive === false}
                          onChange={(e) => toggleActive(e)}
                        />
                        {i18next.t("inactive")}
                      </label>
                    </div>
                  </div>
                ) : (
                  [
                    state.isActive === true ? (
                      <span key={`${state.id}-0`}>{i18next.t("yes")}</span>
                    ) : (
                      <span key={`${state.id}-1`}>{i18next.t("no")}</span>
                    ),
                  ]
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
};

TableSubscriberStation.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TableSubscriberStation));

import { useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter, useLocation } from "react-router-dom";

const NonAuthLayout = (props) => {
  const capitalizeFirstLetter = (string) => {
    return string.charAt(1).toUpperCase() + string.slice(2);
  };

  const location = useLocation();

  useEffect(() => {
    let currentage = capitalizeFirstLetter(location.pathname);

    document.title =
      currentage + " | Heat Hub";
  }, []);

  return <>{props.children}</>;
};

NonAuthLayout.propTypes = {
  children: PropTypes.object,
  location: PropTypes.object,
};

export default withRouter(NonAuthLayout);

import { authHeader } from "../helpers/jwt-token-access/auth-token-header.js";

export const sendRequest = ({
  url,
  method,
  body,
  headers = {},
  queryParams = {},
}) => {
  const headerData = authHeader();

  const options = {
    method: method,
    headers: new Headers({
      "content-type": "application/json",
      //'content-type': 'application/octet-stream',
      Authorization: headerData,
    }), // by default setting the content-type to be json type
    body: body ? body : null,
  };

  return fetch(url, options).then((res) => {
    if (res.ok) {
      return res;
    } else {
      return res.json().then(function (json) {
        return Promise.reject({
          status: res.status,
          ok: false,
          message: json.message,
          body: json,
        });
      });
    }
  });
};

export const sendRequestFile = ({
  url,
  method,
  body,
  headers = {},
  queryParams = {},
}) => {
  const headerData = authHeader();

  const options = {
    method: method,
    headers: new Headers({
      Authorization: headerData,
    }), // by default setting the content-type to be json type
    body: body ? body : null,
  };

  return fetch(url, options).then((res) => {
    if (res.ok) {
      return res;
    } else {
      return res.json().then(function (json) {
        // to be able to access error status when you catch the error
        return Promise.reject({
          status: res.status,
          ok: false,
          message: json.message,
          body: json,
        });
      });
    }
  });
};

export const sendRequestFileDownload = ({
  url,
  method,
  body,
  headers = {},
  queryParams = {},
}) => {
const headerData = authHeader();

const options = {
method: method,
headers: new Headers({
'content-type': 'application/octet-stream',
Authorization: headerData,
}), // by default setting the content-type to be json type
body: body ? body : null,
};
return fetch(url, options).then((res) => {
if (res.ok) {
  console.log(res);
return res;
} else {
return res.json().then(function (json) {
// to be able to access error status when you catch the error
return Promise.reject({
status: res.status,
ok: false,
message: json.message,
body: json,
});
});
}
});
};

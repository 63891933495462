import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { Modal, Button } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import * as jsonpatch from "fast-json-patch";
import * as endpoints from "constants/endpoints";
import { sendRequest } from "../../helpers/fetch_helper.js";
import { CreateItem } from "../../helpers/CRUD.js";
import i18next from "i18next";

const ModalRadioModuleTypes = (props) => {
  const old_state = {
    nomEquipmentManufacturers: [],
  };

  const [state, setState] = useState({
    ...old_state,
    document: {
      name: "",
      equipmentManufacturerId: null,
      equipmentManufacturerRadioModuleId: null,
      model: "",
    },
  });

  const getInitialState = (old_state) => ({
    ...old_state,
    document: {
      name: "",
      equipmentManufacturerId: null,
      equipmentManufacturerRadioModuleId: null,
      model: "",
    },
  });

  const resetData = () => {
    setState(getInitialState(state));
  };

  const handleValidSubmit = (event, values) => {
    let patch = jsonpatch.compare(getInitialState().document, state.document);

    const url = `${endpoints.NOMENCLATURE_ENDPOINT}/RadioModuleType/Add`;

    CreateItem(
      url,
      patch,
      (data) => {
        update();
        props.emitSuccess(i18next.t("entry_added_successfully"));
      },
      (error) => {
        props.emitErrors(error);
      }
    );
  };

  const closeModal = () => {
    props.closeModal();
    resetData();
  };

  const update = () => {
    props.update();
    resetData();
  };

  const getNomEquipmentManufacturers = () => {
    const url = `${endpoints.NOMENCLATURE_ENDPOINT}/EquipmentManufacturer/GetAll`;

    sendRequest({
      url,
      method: "GET",
    })
      .then((resp) => resp.json())
      .then((data) => {
        setState((state) => ({
          ...state,
          nomEquipmentManufacturers: data.items,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getNomEquipmentManufacturers();
  }, []);

  const nomEquipmentManufacturersRadioModules =
    state.nomEquipmentManufacturers.filter((item) =>
      item.type.includes(i18next.t("radio-module"))
    );

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Modal isOpen={props.isModalOpen} scrollable={true}>
            <div className="modal-header">
              <h5 className="modal-title mt-0">
                {i18next.t("modal_radio_module_type_title")}
              </h5>

              <button
                type="button"
                onClick={() => closeModal()}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <Card className="mb-0">
                <CardBody>
                  <AvForm
                    onValidSubmit={handleValidSubmit}
                    className="needs-validation"
                  >
                    <Row className="mb-4">
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="radio_module_type_name">
                            {i18next.t("name")}
                          </Label>

                          <AvField
                            name="document.name"
                            placeholder={i18next.t("placeholder_enter_name")}
                            id="radio_module_type_name"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  name: e.target.value,
                                },
                              }))
                            }
                            defaultValue={state.document.name}
                            className="w-100"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="heater_type_еquipment_manufacturer">
                            {i18next.t("manufacture")}
                          </Label>

                          <AvField
                            type="select"
                            name="document.equipmentManufacturerId"
                            id="heater_type_еquipment_manufacturer"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  equipmentManufacturerId: e.target.value,
                                },
                              }))
                            }
                            value={state.document.equipmentManufacturerId}
                            className="w-100"
                          >
                            <option
                              value=""
                              selected={
                                state.document.equipmentManufacturerId === ""
                                  ? true
                                  : false
                              }
                            >
                              {i18next.t("select_equipment_manufacturer")}
                            </option>
                            {state.nomEquipmentManufacturers &&
                              state.nomEquipmentManufacturers.length > 0 &&
                              state.nomEquipmentManufacturers.map(
                                (еquipmentManufacturer) => (
                                  <option
                                    key={`option-${еquipmentManufacturer.id}`}
                                    value={еquipmentManufacturer.id}
                                    selected={
                                      state.document.equipmentManufacturerId ===
                                      еquipmentManufacturer.id
                                        ? true
                                        : false
                                    }
                                  >
                                    {еquipmentManufacturer.name}
                                  </option>
                                )
                              )}
                          </AvField>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="radio_module_type_еquipment_manufacturer_radio_module">
                            {i18next.t("deviceManufacturerRadioModule")}
                          </Label>

                          <AvField
                            type="select"
                            name="document.equipmentManufacturerRadioModuleId"
                            id="radio_module_type_еquipment_manufacturer_radio_module"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  equipmentManufacturerRadioModuleId:
                                    e.target.value,
                                },
                              }))
                            }
                            value={
                              state.document.equipmentManufacturerRadioModuleId
                            }
                            className="w-100"
                          >
                            <option
                              value=""
                              selected={
                                state.document
                                  .equipmentManufacturerRadioModuleId === ""
                                  ? true
                                  : false
                              }
                            >
                              {i18next.t(
                                "select_equipment_manufacturer_radio_module"
                              )}
                            </option>
                            {nomEquipmentManufacturersRadioModules.length > 0 &&
                              nomEquipmentManufacturersRadioModules.map(
                                (еquipmentManufacturer) => (
                                  <option
                                    key={`option-${еquipmentManufacturer.id}`}
                                    value={еquipmentManufacturer.id}
                                    selected={
                                      state.document
                                        .equipmentManufacturerRadioModuleId ===
                                      еquipmentManufacturer.id
                                        ? true
                                        : false
                                    }
                                  >
                                    {еquipmentManufacturer.name}
                                  </option>
                                )
                              )}
                          </AvField>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="radio_module_type_model">
                            {i18next.t("model")}
                          </Label>

                          <AvField
                            name="document.model"
                            placeholder={i18next.t("placeholder_enter_model")}
                            id="radio_module_type_model"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  model: e.target.value,
                                },
                              }))
                            }
                            defaultValue={state.document.model}
                            className="w-100"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => closeModal()}
                      >
                        <i className="fa fa-times mr-1" />
                        {i18next.t("button_cancel")}
                      </button>

                      <Button color="primary" type="submit">
                        <i className="fa fa-plus-circle mr-1" />
                        {i18next.t("button_add")}
                      </Button>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </div>
          </Modal>
        </Container>
      </div>
    </>
  );
};

ModalRadioModuleTypes.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUsers: () => dispatch(getUsers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ModalRadioModuleTypes));

import { useState } from "react";
import PropTypes from "prop-types";
import { Col, Row, Nav, NavItem, Container } from "reactstrap";
import { NavLink } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import i18next from "i18next";

const Navigation = (props) => {
  const [state, setState] = useState({
    links: [
      {
        id: "link-heating-areas",
        to: "/heating-areas",
        text: i18next.t("link-heating-areas"),
      },
      {
        id: "link-heater-type",
        to: "/heater-type",
        text: i18next.t("link-heater-type"),
        tooltip: i18next.t("link-heater-type-tooltip"),
      },
      {
        id: "link-radio-module-types",
        to: "/radio-module-types",
        text: i18next.t("link-radio-module-types"),
        tooltip: i18next.t("link-radio-module-types-tooltip"),
      },
      {
        id: "link-equipment-manufacturer",
        to: "/equipment-manufacturer",
        text: i18next.t("link-equipment-manufacturer"),
      },
      {
        id: "link-apartment-types",
        to: "/apartment-types",
        text: i18next.t("link-apartment-types"),
        tooltip: i18next.t("link-apartment-types-tooltip"),
      },
      {
        id: "link-room-types",
        to: "/room-types",
        text: i18next.t("link-room-types"),
        tooltip: i18next.t("link-room-types-tooltip"),
      },
      {
        id: "link-professional-house-manager-companies",
        to: "/professional-house-manager-companies",
        text: i18next.t("link-professional-house-manager-companies"),
        tooltip: i18next.t("link-professional-house-manager-companies-tooltip"),
      },
      {
        id: "link-heat-meter-types",
        to: "/heat-meter-types",
        text: i18next.t("link-heat-meter-types"),
        tooltip: i18next.t("link-heat-meter-types-tooltip"),
      },
      {
        id: "link-report-period-sw",
        to: "/report-period-sw",
        text: i18next.t("link-report-period-sw"),
        tooltip: i18next.t("link-report-period-sw-tooltip"),
      },
      {
        id: "link-heating-season",
        to: "/heating-seasons",
        text: i18next.t("link-heating-season"),
      },
      {
        id: "link-hca-types",
        to: "/hca-types",
        text: i18next.t("link-hca-types"),
        tooltip: i18next.t("link-hca-types-tooltip"),
      },
      {
        id: "link-service-price",
        to: "/service-price",
        text: i18next.t("link-service-price"),
      },
      {
        id: "link-calculation-configuration",
        to: "/calculation-configuration",
        text: i18next.t("link-calculation-configuration"),
      },
    ],
  });

  return (
    <>
      <div className="navigation">
        <Container fluid>
          <Row>
            <Col xs="12">
              <div className="d-flex align-items-center justify-content-between">
                <Nav pills>
                  {state.links.map((item) => (
                    <NavItem key={item.id}>
                      <NavLink
                        id={item.id}
                        to={item.to}
                        activeClassName="active"
                      >
                        {item.text}
                        {item.tooltip && (
                          <UncontrolledTooltip
                            placement="right"
                            target={item.id}
                          >
                            {item.tooltip}
                          </UncontrolledTooltip>
                        )}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

Navigation.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
};

export default Navigation;

import { Row, Col } from "reactstrap";
import * as endpoints from "constants/endpoints";
import { GetData } from "../../helpers/CRUD";
import { useEffect, useState } from "react";
import {
  REACT_APP_BUILD_COMMIT_HASH,
  REACT_APP_BUILD_TIMESTAMP,
} from "../../constants/constants";
import logoNordPng from "../../assets/images/Logotipi.png";

const Footer = () => {
  const [state, setState] = useState({
    version_be: "",
  });
  useEffect(() => {
    getCurrentVersion();
  }, []);

  const getCurrentVersion = () => {
    const url = `${endpoints.VERSION}/GetCurrentVersion`;

    GetData(
      url,
      (data) => {
        setState((state) => ({ ...state, version_be: data.version }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const date = new Date(REACT_APP_BUILD_TIMESTAMP);
  const versionDate = `${date.getFullYear()}.${
    date.getMonth() + 1
  }.${date.getDate()}.${date.getHours()}.${date.getMinutes()}`;
  const versionHash = REACT_APP_BUILD_COMMIT_HASH.substring(0, 7);

  return (
    <>
      <footer className="footer">
        <div className="container-fluid">
          <Row>
            <Col sm={6}>{new Date().getFullYear()} © Heat Hub. 
            <img style={{ marginLeft: '50px' }} width="120" src={logoNordPng}></img>
            </Col>
            <Col sm={6}>
              <div className="text-sm-right d-none d-sm-block">
                Design & Develop by &nbsp;
                <a href="https://techplana.com/" target="blank">
                  Plan A
                </a>
              </div>
              <div className="text-sm-right d-none d-sm-block">
                <span>BE:</span>
                <span>{state.version_be}</span>/<span>FE:</span>
                <span>{`v.${versionDate}.${versionHash}`}</span>
              </div>
            </Col>
          </Row>
        </div>
      </footer>
    </>
  );
};

export default Footer;

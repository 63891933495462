import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useHistory, withRouter } from "react-router-dom";
import i18next from "i18next";

const BackButton = (props) => {
  const history = useHistory();

  const handleRouterBack = (e) => {
    e.preventDefault();
    history.goBack();
  };

  return (
    <>
      <a
        onClick={(e) => handleRouterBack(e)}
        className="btn btn-primary waves-effect waves-light btn-sm mb-4"
      >
        <i className="mdi mdi-arrow-left mr-1" />

        {i18next.t("btn-back")}
      </a>
    </>
  );
};

BackButton.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
};

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUsers: () => dispatch(getUsers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BackButton));

import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { Modal, Button } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import * as jsonpatch from "fast-json-patch";
import * as endpoints from "constants/endpoints";
import { hca_type_types } from "helpers/listsHardcodedData";
import { CreateItem, GetData } from "../../helpers/CRUD.js";
import { str2bool } from "../../helpers/utils.js";
import i18next from "i18next";

const ModalHCATypes = (props) => {
  const old_state = {
    nomEquipmentManufacturers: [],
  };

  const [state, setState] = useState({
    ...old_state,
    document: {
      name: "",
      equipmentManufacturerId: null,
      model: "",
      type: "",
      hasRadioModule: false,
    },
  });

  const getInitialState = (old_state) => ({
    ...old_state,
    document: {
      name: "",
      equipmentManufacturerId: null,
      model: "",
      type: "",
      hasRadioModule: false,
    },
  });

  const resetData = () => {
    setState(getInitialState(state));
  };

  const handleValidSubmit = (event, values) => {
    let patch = jsonpatch.compare(getInitialState().document, state.document);

    const url = `${endpoints.NOMENCLATURE_ENDPOINT}/HeatCostAllocatorType/Add`;

    CreateItem(
      url,
      patch,
      (data) => {
        update();
        props.emitSuccess(i18next.t("entry_added_successfully"));
      },
      (error) => {
        props.emitErrors(error);
      }
    );
  };

  const toggleRadioModule = (e) => {
    const document = state.document;
    document.hasRadioModule = str2bool(e.target.value);

    setState((state) => ({ ...state, document }));
  };

  const closeModal = () => {
    props.closeModal();
    resetData();
  };

  const update = () => {
    props.update();
    resetData();
  };

  const getNomEquipmentManufacturers = () => {
    const url = `${endpoints.NOMENCLATURE_ENDPOINT}/EquipmentManufacturer/GetAllByDeviceType?deviceType=Разпределители`;

    GetData(
      url,
      (data) => {
        const filtered = data.filter((item) =>
          item.type.includes(i18next.t("allocators"))
        );

        setState((state) => ({
          ...state,
          nomEquipmentManufacturers: filtered,
        }));
      },
      (error) => console.log(error)
    );
  };

  useEffect(() => {
    getNomEquipmentManufacturers();
  }, []);

  const hca_type_types_list = hca_type_types();

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Modal isOpen={props.isModalOpen} scrollable={true}>
            <div className="modal-header">
              <h5 className="modal-title mt-0">
                {i18next.t("modal_hca_type_title")}
              </h5>

              <button
                type="button"
                onClick={() => closeModal()}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <Card className="mb-0">
                <CardBody>
                  <AvForm
                    onValidSubmit={handleValidSubmit}
                    className="needs-validation"
                  >
                    <Row className="mb-4">
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="name">{i18next.t("name")}</Label>

                          <AvField
                            name="document.name"
                            placeholder={i18next.t("placeholder_enter_name")}
                            id="name"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  name: e.target.value,
                                },
                              }))
                            }
                            defaultValue={state.document.name}
                            className="w-100"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="equipmentManufacturerId">
                            {i18next.t("manufacture")}
                          </Label>

                          <AvField
                            type="select"
                            name="document.equipmentManufacturerId"
                            id="equipmentManufacturerId"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  equipmentManufacturerId: e.target.value,
                                },
                              }))
                            }
                            value={state.document.equipmentManufacturerId}
                            className="w-100"
                          >
                            <option
                              value=""
                              selected={
                                state.document.equipmentManufacturerId === ""
                                  ? true
                                  : false
                              }
                            >
                              {i18next.t("select_equipment_manufacturer")}
                            </option>
                            {state.nomEquipmentManufacturers &&
                              state.nomEquipmentManufacturers.length > 0 &&
                              state.nomEquipmentManufacturers.map(
                                (еquipmentManufacturer) => (
                                  <option
                                    key={`option-${еquipmentManufacturer.id}`}
                                    value={еquipmentManufacturer.id}
                                    selected={
                                      state.document.equipmentManufacturerId ===
                                      location.id
                                        ? true
                                        : false
                                    }
                                  >
                                    {еquipmentManufacturer.name}
                                  </option>
                                )
                              )}
                          </AvField>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="model">{i18next.t("model")}</Label>

                          <AvField
                            name="document.model"
                            placeholder={i18next.t("placeholder_enter_model")}
                            id="model"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  model: e.target.value,
                                },
                              }))
                            }
                            defaultValue={state.document.model}
                            className="w-100"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="hca_type_type">
                            {i18next.t("type")}
                          </Label>

                          <AvField
                            type="select"
                            name="document.type"
                            id="hca_type_type"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  type: e.target.value,
                                },
                              }))
                            }
                            value={state.document.type}
                            className="w-100"
                          >
                            <option
                              value=""
                              selected={
                                state.document.type === "" ? true : false
                              }
                            >
                              {i18next.t("select_type")}
                            </option>
                            {hca_type_types_list &&
                              hca_type_types_list.length > 0 &&
                              hca_type_types_list.map((type) => (
                                <option
                                  key={type.key}
                                  value={type.key}
                                  selected={
                                    state.document.type === type.id
                                      ? true
                                      : false
                                  }
                                >
                                  {type.value}
                                </option>
                              ))}
                          </AvField>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col md="12">
                        <div className="form_controls">
                          <label>{i18next.t("radioModuleNumber")}</label>

                          <div>
                            <div className="radio">
                              <label>
                                <input
                                  type="radio"
                                  value="true"
                                  checked={
                                    state.document.hasRadioModule === true
                                  }
                                  onChange={(e) => toggleRadioModule(e)}
                                />
                                {i18next.t("yes")}
                              </label>
                            </div>

                            <div className="radio">
                              <label>
                                <input
                                  type="radio"
                                  value="false"
                                  checked={
                                    state.document.hasRadioModule === false
                                  }
                                  onChange={(e) => toggleRadioModule(e)}
                                />
                                {i18next.t("no")}
                              </label>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => closeModal()}
                      >
                        <i className="fa fa-times mr-1" />
                        {i18next.t("button_cancel")}
                      </button>

                      <Button color="primary" type="submit">
                        <i className="fa fa-plus-circle mr-1" />
                        {i18next.t("button_add")}
                      </Button>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </div>
          </Modal>
        </Container>
      </div>
    </>
  );
};

ModalHCATypes.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUsers: () => dispatch(getUsers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ModalHCATypes));

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import LinkView from "components/Common/LinkView";
import IconCheck from "components/Common/IconCheck";
import IconX from "components/Common/IconX";
import Loader from "../../components/Common/Loader.js";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import i18next from "i18next";

const TableListComponent = (props) => {
  const tableStyle = {
    tableLayout: "fixed",
  };

  const tableHead = {
    backgroundColor: "#f8f9fa",
    borderColor: "#eff2f7",
  };

  const tableData = {
    color: "black",
  };

  const tableDataRed = {
    color: "red",
  };

  const tableDataBlue = {
    color: "blue",
  };

  const randomNumb = Math.floor(Math.random() * 10);
  const id = `td-with-tooltip${Date.now()}-${randomNumb}`;

  const collspanAdder = !props.disableLink ? 1 : 0;

  return (
    <Table className="table scrollable table-bordered" style={tableStyle}>
      <Thead>
        <Tr>
          {props.tableColumns &&
            props.tableColumns.map((column) => (
              <Th key={`${column}-${Date.now()}`} style={tableHead}>
                {column}
              </Th>
            ))}

          {!props.disableLink && (
            <Th data-priority="1" style={tableHead}>
              {i18next.t("actions")}
            </Th>
          )}
        </Tr>
      </Thead>

      <Tbody>
        {props.isLoading && !props.isLoaded ? (
          <Tr>
            <Td
              colSpan={collspanAdder + props.tableColumns.length}
              className="align-items-center text-center"
            >
              <Loader showLoader={true} />
            </Td>
          </Tr>
        ) : null}

        {props.tableData &&
          props.isLoaded &&
          (props.tableData.length === 0 ? (
            <Tr>
              <Td
                colSpan={collspanAdder + props.tableColumns.length}
                className="align-items-center text-center"
              >
                {i18next.t("table_missing_data")}
              </Td>
            </Tr>
          ) : (
            props.tableData.map((data) => (
              <Tr key={data.id}>
                {props.tableColumns &&
                  props.tableColumns.includes(i18next.t("name")) && (
                    <Td
                      style={
                        (props.category === "heat-meter-types" &&
                          data.installation ===
                            i18next.t("installation_giving")) ||
                        (props.category === "heaters" &&
                          data.installation === i18next.t("installation_hot"))
                          ? tableDataRed
                          : (props.category === "heat-meter-types" &&
                              data.installation ===
                                i18next.t("installation_returning")) ||
                            (props.category === "heaters" &&
                              data.installation ===
                                i18next.t("installation_cold"))
                          ? tableDataBlue
                          : tableData
                      }
                    >
                      {data.name}
                    </Td>
                  )}

                {/* Nomenclatures */}
                {/* heating-areas name only */}

                {/* heater-type */}
                {props.category === "heater-type" &&
                  props.tableColumns &&
                  props.tableColumns.includes(i18next.t("type")) && (
                    <Td>{data.type}</Td>
                  )}
                {props.category === "heater-type" &&
                  props.tableColumns &&
                  props.tableColumns.includes(i18next.t("manufacture")) && (
                    <Td>
                      {data.equipmentManufacturer
                        ? data.equipmentManufacturer.name
                        : ""}
                    </Td>
                  )}
                {props.category === "heater-type" &&
                  props.tableColumns &&
                  props.tableColumns.includes(i18next.t("power")) && (
                    <Td>{data.pow}</Td>
                  )}
                {props.category === "heater-type" &&
                  props.tableColumns &&
                  props.tableColumns.includes(i18next.t("KcHF / Kc")) && (
                    <Td>{data.kSK1}</Td>
                  )}
                {props.category === "heater-type" &&
                  props.tableColumns &&
                  props.tableColumns.includes(i18next.t("KcHF1 / Kc1")) && (
                    <Td>{data.kSK2}</Td>
                  )}
                {props.category === "heater-type" &&
                  props.tableColumns &&
                  props.tableColumns.includes(i18next.t("KcHF2 / Kc2")) && (
                    <Td>{data.kSK3}</Td>
                  )}
                {props.category === "heater-type" &&
                  props.tableColumns &&
                  props.tableColumns.includes(i18next.t("KcHF3 / Kc3")) && (
                    <Td>{data.kSK4}</Td>
                  )}

                {/* radio-module-types */}
                {props.category === "radio-module-types" &&
                  props.tableColumns &&
                  props.tableColumns.includes(i18next.t("model")) && (
                    <Td>{data.model}</Td>
                  )}
                {props.category === "radio-module-types" &&
                  props.tableColumns &&
                  props.tableColumns.includes(i18next.t("manufacture")) && (
                    <Td>
                      {data.equipmentManufacturer
                        ? data.equipmentManufacturer.name
                        : ""}
                    </Td>
                  )}
                {props.category === "radio-module-types" &&
                  props.tableColumns &&
                  props.tableColumns.includes(i18next.t("compare-with")) && (
                    <Td>
                      {data.equipmentManufacturerRadioModule
                        ? data.equipmentManufacturerRadioModule.name
                        : ""}
                    </Td>
                  )}

                {/* equipment-manufacturer */}
                {props.category === "equipment-manufacturer" &&
                  props.tableColumns &&
                  props.tableColumns.includes(i18next.t("watermeter")) && (
                    <Td>
                      {data.type.includes(i18next.t("watermeter")) ? (
                        <IconCheck />
                      ) : (
                        <IconX />
                      )}
                    </Td>
                  )}
                {props.category === "equipment-manufacturer" &&
                  props.tableColumns &&
                  props.tableColumns.includes(i18next.t("heatmeter")) && (
                    <Td>
                      {data.type.includes(i18next.t("heatmeter")) ? (
                        <IconCheck />
                      ) : (
                        <IconX />
                      )}
                    </Td>
                  )}
                {props.category === "equipment-manufacturer" &&
                  props.tableColumns &&
                  props.tableColumns.includes(i18next.t("hca")) && (
                    <Td>
                      {data.type.includes(i18next.t("allocators")) ? (
                        <IconCheck />
                      ) : (
                        <IconX />
                      )}
                    </Td>
                  )}
                {props.category === "equipment-manufacturer" &&
                  props.tableColumns &&
                  props.tableColumns.includes(i18next.t("radio-module")) && (
                    <Td>
                      {data.type.includes(i18next.t("radio-module")) ? (
                        <IconCheck />
                      ) : (
                        <IconX />
                      )}
                    </Td>
                  )}
                {props.category === "equipment-manufacturer" &&
                  props.tableColumns &&
                  props.tableColumns.includes(i18next.t("heater")) && (
                    <Td>
                      {data.type.includes(i18next.t("heater")) ? (
                        <IconCheck />
                      ) : (
                        <IconX />
                      )}
                    </Td>
                  )}

                {/* apartment-types */}
                {props.category === "apartment-types" &&
                  props.tableColumns &&
                  props.tableColumns.includes(i18next.t("estate")) && (
                    <Td>{data.name}</Td>
                  )}

                {/* room-types */}
                {props.category === "room-types" &&
                  props.tableColumns &&
                  props.tableColumns.includes(i18next.t("room")) && (
                    <Td>{data.name}</Td>
                  )}

                {/* professional-house-manager-companies */}
                {props.category === "professional-house-manager-companies" &&
                  props.tableColumns &&
                  props.tableColumns.includes(i18next.t("contact-person")) && (
                    <Td>{data.contactName}</Td>
                  )}
                {props.category === "professional-house-manager-companies" &&
                  props.tableColumns &&
                  props.tableColumns.includes(i18next.t("phone")) && (
                    <Td>{data.contactPhone}</Td>
                  )}
                {props.category === "professional-house-manager-companies" &&
                  props.tableColumns &&
                  props.tableColumns.includes(i18next.t("email")) && (
                    <Td>{data.email}</Td>
                  )}

                {/* heat-meter-types */}
                {props.category === "heat-meter-types" &&
                  props.tableColumns &&
                  props.tableColumns.includes(i18next.t("manufacture")) && (
                    <Td>
                      {data.equipmentManufacturer
                        ? data.equipmentManufacturer.name
                        : ""}
                    </Td>
                  )}
                {props.category === "heat-meter-types" &&
                  props.tableColumns &&
                  props.tableColumns.includes(i18next.t("model")) && (
                    <Td>{data.model}</Td>
                  )}
                {props.category === "heat-meter-types" &&
                  props.tableColumns &&
                  props.tableColumns.includes(i18next.t("range")) && (
                    <Td>{data.range}</Td>
                  )}

                {/* report-period-sw name only */}

                {/* heating-seasons */}
                {props.category === "heating-seasons" &&
                  props.tableColumns &&
                  props.tableColumns.includes(i18next.t("locked")) && (
                    <Td>{data.isLocked ? <IconCheck /> : <IconX />}</Td>
                  )}
                {props.category === "heating-seasons" &&
                  props.tableColumns &&
                  props.tableColumns.includes(i18next.t("active")) && (
                    <Td>{data.isActive ? <IconCheck /> : <IconX />}</Td>
                  )}

                {/* hca-types */}
                {props.category === "hca-types" &&
                  props.tableColumns &&
                  props.tableColumns.includes(i18next.t("radio")) && (
                    <Td>{data.radioModule ? <IconCheck /> : <IconX />}</Td>
                  )}
                {/* Периоди на изравняване */}
                {props.category === "service-price" &&
                  props.tableColumns &&
                  props.tableColumns.includes(i18next.t("start-period")) && (
                    <Td>{data.startDate}</Td>
                  )}
                {props.category === "service-price" &&
                  props.tableColumns &&
                  props.tableColumns.includes(i18next.t("end-period")) && (
                    <Td>{data.endDate}</Td>
                  )}
                {props.category === "service-price" &&
                  props.tableColumns &&
                  props.tableColumns.includes(i18next.t("price-hca")) && (
                    <Td>{data.pricePerHeatCostAllocator}</Td>
                  )}
                {props.category === "service-price" &&
                  props.tableColumns &&
                  props.tableColumns.includes(
                    i18next.t("price-watermeter")
                  ) && <Td>{data.pricePerWaterMeter}</Td>}
                {props.category === "service-price" &&
                  props.tableColumns &&
                  props.tableColumns.includes(
                    i18next.t("price-apartment-meter")
                  ) && <Td>{data.pricePerHeatMeter}</Td>}
                {props.category === "service-price" &&
                  props.tableColumns &&
                  props.tableColumns.includes(i18next.t("price-service")) && (
                    <Td>{data.pricePerEstate}</Td>
                  )}

                {/* End of nomenclature */}

                {!props.disableLink && (
                  <Td>
                    <div className="actions">
                      <LinkView
                        link={`/${props.category}/${data.id}`}
                        id={"link-to" + data.id}
                      ></LinkView>
                    </div>
                  </Td>
                )}
              </Tr>
            ))
          ))}
      </Tbody>
    </Table>
  );
};

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TableListComponent));

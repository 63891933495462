import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Collapse } from "reactstrap";
import { Table } from "react-super-responsive-table";
import i18next from "i18next";

const TableWithAccordeon = (props) => {
  const handleToggle = (category, obj) => {
    props.toggleAcordion(category, obj);
  };

  const handleSelect = (category, parentId, objIndex) => {
    props.selectReport(category, parentId, objIndex);
  };

  const handleRowSelect = (e, category, obj) => {
    e.stopPropagation();
    console.log("e is", e.target.checked);
    console.log(e);
    props.selectRow(category, obj, e.target.checked);
  };

  const sendSingleRow = (id) => {
    props.sendSingleRowData(id, props.category);
  };

  const accordionStyle = {
    border: "1px solid #2a3042",
  };

  const accordionHeadStyle = {
    padding: "10px 10px 5px 10px",
  };

  const arrowStyle = {
    fontSize: "15px",
  };

  let checkedRowLength = false;

  const cellStyle = {
    backgroundColor: "#eff2f7",
  };

  const cellStyleNormal = {
    backgroundColor: "none",
  };

  return (
    <div className="table-list">
      {props.data.map((item) => (
        <div className="accordion " key={item.id} style={accordionStyle}>
          <div
            className="accordion_head d-flex align-items-center"
            style={accordionHeadStyle}
            aria-expanded={item.isCollapsed}
          >
            <label
              className="flex-shrink-1 mb-0 mr-3"
              onClick={(e) => e.stopPropagation()}
            >
              <input
                id={`select-${item.id}`}
                type="checkbox"
                onChange={(e) => handleRowSelect(e, props.category, item)}
              />

            </label>

            {props.category === "estates" && (
              <h5 className="flex-grow-1">
                {`${i18next.t("estate-kodpl")} ${item.data[0].kodpl}`}
              </h5>
            )}

            {props.category === "buildings" && (
              <h5 className="flex-grow-1">
                {`${i18next.t("bulding-externalId")} ${item.data[0].top}`}
              </h5>
            )}

            <div className="actions">
              {!item.isCollapsed &&
                item.selectedRow.some((item) => item.isSelected) && (
                  <button
                    className="btn btn-primary waves-effect waves-light"
                    onClick={() => {
                      sendSingleRow(item.id);
                    }}
                  >
                    {i18next.t("upload_files_save_changes")}
                  </button>
                )}

              <button
                className="btn btn-primary waves-effect waves-light ml-2"
                onClick={() => {
                  handleToggle(props.category, item);
                }}
              >
                <i className="bx bx-chevron-down" style={arrowStyle} />
              </button>
            </div>
          </div>

          <Collapse isOpen={!item.isCollapsed}>
            <div className="accordion_body" id="example-collapse-text">
              <div className="table mb-0" style={{ overflowX: "scroll" }}>
                <Table>
                  <tbody>
                    <tr>
                      <th>{i18next.t("column_select")}</th>

                      {Object.keys(item.data[0]).map((label, i) => {
                        return <th key={`${label}-label-${i}`}>{i18next.t(`column_${label}`)}</th>;
                      })}
                    </tr>

                    {item.data.map((report, i) => {
                      return (
                        <tr key={`${item.id}-${i}`}>
                          <td>
                            <div className="form-check mb-0 mr-2 d-flex align-items-center">
                              <input
                                className="form-check-input"
                                type="radio"
                                name={`isSelectedRowin${item.id}`}
                                id={`isSelected-${item.id}-${i}`}
                                checked={item.selectedRow[i].isSelected}
                                onChange={(e) =>
                                  handleSelect(props.category, item.id, i)
                                }
                              />

                              <label
                                htmlFor={`isSelected-${report.id}`}
                                className="mb-0"
                              >
                                {i === 0 && (
                                  <span>{i18next.t("from-current-file")}</span>
                                )}
                                {i === 1 && (
                                  <span>{i18next.t("from-previous-file")}</span>
                                )}
                                {i === 2 && (
                                  <span>
                                    {i18next.t("domain-object-value")}
                                  </span>
                                )}
                                {i === 3 && (
                                  <span>
                                    {i18next.t("domain-object-current-value")}
                                  </span>
                                )}
                              </label>
                            </div>
                          </td>

                          {Object.keys(item.data[0]).map((label, y) => {
                            return (
                              <td
                                style={
                                  item.category_diff.some(
                                    (item) => item == label
                                  )
                                    ? cellStyle
                                    : cellStyleNormal
                                }
                                key={`${item.id}-${i}-${y}`}
                              >
                                {report[label]}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </Collapse>
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TableWithAccordeon));

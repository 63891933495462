import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import i18next from "i18next";

const IconX = (props) => {
  const handleModalOpen = () => {
    props.onBtnClick();
  };

  const iconSize = {
    fontSize: "18px",
    color: "red",
  };

  const randomNumb = Math.floor(Math.random() * 10);
  const id = `icon-x-with-tooltip${Date.now()}-${randomNumb}`;

  return (
    <>
      <i className="bx bx-x" style={iconSize} id={id} />

      <UncontrolledTooltip placement="top" target={id}>
        {i18next.t("no")}
      </UncontrolledTooltip>
    </>
  );
};

IconX.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUsers: () => dispatch(getUsers()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(IconX));

import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  withRouter,
  useParams,
  useHistory,
  useLocation,
} from "react-router-dom";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { FormGroup, Label } from "reactstrap";
import { cloneDeep } from "lodash";
import * as jsonpatch from "fast-json-patch";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import classnames from "classnames";
import ModalWaterMeter from "../../components/Modals/ModalWaterMeter";
import ModalHeatMeter from "../../components/Modals/ModalHeatMeter";
import ModalHeater from "../../components/Modals/ModalHeater";
import ModalEstateContact from "../../components/Modals/ModalEstateContact";
import ModalEstateComment from "../../components/Modals/ModalEstateComment";
import ModalEstimatedToploficationData from "../../components/Modals/ModalEstimatedToploficationData";
import BackButton from "../../components/Common/BackButton.js";
import TableComponent from "../../components/Common/TableComponent";
import TableReports from "../../components/Common/TableReports";
import ConfirmBox from "../../components/Common/ConfirmBox.js";
import ListActions from "../../components/Common/ListActions.js";
import IconCheck from "components/Common/IconCheck";
import IconX from "components/Common/IconX";
import * as endpoints from "constants/endpoints";
import {
  translateConditionEnumValue,
  translateTypeEnumValue,
  translateDeviceLocationEnumValue,
  translateStatusEnumValue,
} from "helpers/translateHardcodedData";
import { formatDateLocale } from "../../helpers/utils";
import ModalHeatCostAllocator from "../../components/Modals/ModalHeatCostAllocator";
import { Tooltip } from "@material-ui/core";
import {
  GetData,
  SaveItem,
  DeleteItem,
  ToggleItem,
  CreateItem,
} from "../../helpers/CRUD.js";
import i18next from "i18next";

const EstateSingle = (props) => {
  const old_state = {
    pageTitle: "Имот",
    mode: "view",
    apartment_type_options: [],
    heating_season_options: [],
    heating_season: null,
    year: new Date().getFullYear(),
    toploficationData: [],
    watermeters: [],
    heatmeters: [],
    heaters: [],
    estateInvoices: [],
    documents: [],
    files_new: [],
    contacts: [],
    comments: [],
    owners_history: [],
    activeTab: "1",
    tabsNav: [
      {
        index: "1",
        id: "tab-nav-1",
        label: i18next.t("tab-expected-data"),
      },
      {
        index: "2",
        id: "tab-nav-2",
        label: i18next.t("tab-watermeters"),
      },
      {
        index: "3",
        id: "tab-nav-3",
        label: i18next.t("tab-heatmeters"),
      },
      {
        index: "4",
        id: "tab-nav-4",
        label: i18next.t("tab-heaters"),
      },
      {
        index: "5",
        id: "tab-nav-5",
        label: i18next.t("tab-invoices"),
      },
      {
        index: "6",
        id: "tab-nav-6",
        label: i18next.t("tab-reconciliation-bills"),
      },
      {
        index: "7",
        id: "tab-nav-7",
        label: i18next.t("tab-contacts"),
      },
      {
        index: "8",
        id: "tab-nav-8",
        label: i18next.t("tab-comments"),
      },
      {
        index: "9",
        id: "tab-nav-9",
        label: i18next.t("tab-documents"),
      },
      {
        index: "10",
        id: "tab-nav-10",
        label: i18next.t("tab-old-owners"),
      },
      { index: "11", id: "tab-nav-11", label: i18next.t("tab-reports") },
    ],
    isLoading: false,
    isLoaded: false,
    confirm_delete: false,
    confirm_toggle: false,
    confirm_save: false,
    success_dlg: false,
    error_dlg: false,
    actionType: "",
  };
  const date = new Date();
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();

  const [state, setState] = useState({
    ...old_state,
    document: {
      apartment_type: "",
      address: {
        city: "",
        streetName: "",
        streetCode: "",
        buildingNumber: "",
        buildingSubNumber: "",
      },
      entrance: "",
      floor: "",
      apartmentNumber: "",
      externalId: "",
      externalCode: "",
      externalContractNumber: "",
      currentOwner: {
        estateId: null,
        name: "",
        phone: "",
        email: "",
        hasZeroingLot: false,
        hasBillByEmail: false,
        hasEqualMonthlyInstallments: false,
        payerExternalId: "",
        payerExternalCode: "",
      },
      sofiaWaterData: {
        id: "",
        installation: "",
        subscriberNumber: "",
        contractAccount: "",
      },
      /* toploficationData: {
        heatingKWHByDay: 0.0,
        hotWaterKWHByMonth: 0.0,
        estateVolume: 0,
        dateUpdated: "",
      },*/
      apartmentType: {
        name: "",
      },
      apartmentTypeId: null,
      number: "",
      buAddElectricityId: "",
      isActive: false,
    },
  });

  const emitErrors = (error) => {
    props.onErrorsShow(error.body);
  };

  const emitSuccess = (success) => {
    props.onSuccessShow(success);
  };

  const onEstateCommentDelete = (row) => {
    const id = row.id;

    const url = `${endpoints.BUILDING_UNITS_ADDITIONAL_ENDPOINT}/EstateComment/Delete?id=${id}`;

    DeleteItem(
      url,
      () => {
        props.onSuccessShow(i18next.t("entry_deleted_successfully"));
        getAllComments();
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  const onFileChange = (event) => {
    const files = event.target.files;
    setState((state) => ({ ...state, files_new: files }));
  };

  const uploadFiles = () => {
    const files = state.files_new;
    const filesArray = Array.from(files);

    for (let i = 0; i < filesArray.length; i++) {
      uploadFile(filesArray[i]);
    }
  };

  const uploadFile = (file) => {
    let patch = [];

    if (id) {
      patch.push({
        op: "add",
        path: "/estateId",
        value: id,
      });
    }

    if (file) {
      patch.push(
        {
          op: "add",
          path: "/path",
          value: "path",
        },
        {
          op: "add",
          path: "/name",
          value: file.name,
        },
        {
          op: "add",
          path: "/buildingId",
          value: null,
        }
      );
    }

    const url = `${endpoints.BUILDING_UNITS_ADDITIONAL_ENDPOINT}/Document/Add`;

    CreateItem(
      url,
      patch,
      (data) => {
        getAllDocuments();
        emitSuccess(i18next.t("file_uploaded_successfully"));
      },
      (error) => {
        emitErrors(error);
      }
    );
  };

  const onModalDeviceReportDelete = (row) => {
    const url = `${endpoints.REPORT_DEVICE_MAP}/Delete?reportId=${row.reportId}&deviceId=${row.deviceId}`;

    DeleteItem(
      url,
      () => {
        props.onSuccessShow(i18next.t("entry_deleted_successfully"));
        getReportsByHeatingSeason();
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  const openModal = (id) => {
    setState((state) => ({ ...state, showModal: id }));
  };

  const onModalHCAOpen = (id) => {
    setState((state) => ({ ...state, showModal: "hca", heaterId: id }));
  };

  const showLinkAddHCA = (id) => {
    //setState((state) => ({ ...state, showModal: "hca", heaterId: id }));
    return true;
  };

  const closeModal = () => {
    setState((state) => ({
      ...state,
      showModal: null,
    }));

    removeBodyCss();
  };

  const closeAndUpdate = (modalCallback) => {
    setState((state) => ({ ...state, showModal: null }));
    modalCallback();
    removeBodyCss();
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };

  const handleEdit = () => {
    setState((state) => ({ ...state, mode: "edit" }));
  };

  const handleSave = () => {
    if (state.document.id) {
      const document = state.document;
      if (document.currentOwner) {
        document.currentOwner.estateId = state.document.id;
      }
      setState((state) => ({ ...state, document }));
    }

    let patch = jsonpatch.compare(state.initialDocument, state.document);

    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/Estate/Update?id=${id}`;

    SaveItem(
      url,
      patch,
      (data) => {
        props.onSuccessShow(i18next.t("entry_updated_successfully"));
        setState((state) => ({ ...state, mode: "view" }));
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  const handleDelete = () => {
    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/Estate/Delete?id=${id}`;

    DeleteItem(url, history.goBack, (error) => {
      props.onErrorsShow(error.body);
    });
  };

  const toggleActive = () => {
    let old_state = state.document;
    old_state.isActive = !state.document.isActive;

    setState((state) => ({ ...state, document: old_state }));
    handleToggleStatusPost();
  };

  const toggleTabs = (tab) => {
    if (state.activeTab !== tab) {
      setState((state) => ({ ...state, activeTab: tab }));
    }
  };

  const handleToggleStatusPost = () => {
    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/Estate/ToggleActive?id=${id}&isActive=${state.document.isActive}`;

    ToggleItem(
      url,
      () => {
        state.document.isActive
          ? props.onSuccessShow(i18next.t("entry_activated_successfully"))
          : props.onSuccessShow(i18next.t("entry_deactivated_successfully"));
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  const cancelConfirmBox = () => {
    setState((state) => ({
      ...state,
      confirm_delete: false,
      confirm_toggle: false,
      confirm_save: false,
    }));
  };

  const confirmDelete = () => {
    setState((state) => ({
      ...state,
      confirm_delete: false,
      success_dlg: true,
    }));
  };

  const confirmToggle = () => {
    setState((state) => ({
      ...state,
      confirm_toggle: false,
      success_dlg: true,
    }));
  };

  const confirmSave = () => {
    setState((state) => ({
      ...state,
      confirm_save: false,
      success_dlg: true,
      mode: "view",
    }));
  };

  const closeAfterConfirmDelete = () => {
    setState((state) => ({ ...state, success_dlg: false }));
    handleDelete();
  };

  const closeAfterConfirmToggle = () => {
    setState((state) => ({ ...state, success_dlg: false }));
    toggleActive();
  };

  const closeAfterConfirmSave = () => {
    setState((state) => ({ ...state, success_dlg: false }));
    handleSave();
  };

  const getEstateById = (id) => {
    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/Estate/GetById?id=${id}`;

    GetData(
      url,
      (data) => {
        setState((state) => ({
          ...state,
          document: { ...data },
          initialDocument: cloneDeep(data),
          estateDataLoaded: true,
          isLoaded: true,
        }));
        fetchCallback();
      },
      (error) => console.log(error)
    );
  };

  const fetchCallback = (id) => {
    getAllApartmentTypes();
    getAllHeatingSeasons();
    getWorkingHeatingSeason();
    getAllOwners();
    getAllWaterMeters();
    getAllHeatMeters();
    getAllHeaters();
    getAllInvoices();
    getAllDocuments();
    getAllComments();
    getAllContacts();
    getReportsByHeatingSeason();
    getEstimatedToploficationData();
  };

  const getAllApartmentTypes = () => {
    const url = `${endpoints.NOMENCLATURE_ENDPOINT}/ApartmentType/GetAll`;

    GetData(
      url,
      (data) => {
        setState((state) => ({ ...state, apartment_type_options: data.items }));
      },
      (error) => console.log(error)
    );
  };

  const getAllHeatingSeasons = () => {
    const url = `${endpoints.NOMENCLATURE_ENDPOINT}/HeatingSeason/GetAll`;

    GetData(
      url,
      (data) => {
        setState((state) => ({ ...state, heating_season_options: data.items }));
      },
      (error) => console.log(error)
    );
  };

  const getWorkingHeatingSeason = () => {
    const url = `${endpoints.NOMENCLATURE_ENDPOINT}/HeatingSeason/GetWorkingHeatingSeason`;

    GetData(
      url,
      (data) => {
        setState((state) => ({ ...state, heating_season: data }));
      },
      (error) => console.log(error)
    );
  };

  const getAllOwners = () => {
    const url = `${endpoints.BUILDING_UNITS_ADDITIONAL_ENDPOINT}/Owner/GetAllByEstateId?id=${id}`;
    GetData(
      url,
      (data) => {
        if (data.length > 0) {
          setState((state) => ({
            ...state,
            owners_history: data,
            ownersLoaded: true,
          }));
        }
      },
      (error) => console.log(error)
    );
  };

  const getEstimatedToploficationData = () => {
    const url = `${endpoints.BUILDING_UNITS_ADDITIONAL_ENDPOINT}/ToploficationData/GetAllByEstateId?id=${id}`;
    GetData(
      url,
      (data) => {
        setState((state) => ({
          ...state,
          toploficationData: data,
          toploficationDataLoaded: true,
        }));
      },
      (error) => console.log(error)
    );
  };

  const getAllWaterMeters = () => {
    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/reportEntities/WaterMeter/GetAllByEstateId?id=${id}`;
    GetData(
      url,
      (data) => {
        setState((state) => ({
          ...state,
          watermeters: data,
          watermeterLoaded: true,
        }));
      },
      (error) => console.log(error)
    );
  };

  const getAllWaterMetersInactive = () => {
    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/reportEntities/WaterMeter/GetAllByEstateId?id=${id}&isActive=false`;
    GetData(
      url,
      (data) => {
        let old_data = state.watermeters;
        old_data.push(...data);
        setState((state) => ({ ...state, watermeters: old_data }));
      },
      (error) => console.log(error)
    );
  };

  useEffect(() => {
    state.watermeterLoaded && getAllWaterMetersInactive();
  }, [state.watermeterLoaded]);

  const getAllHeatMeters = () => {
    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/reportEntities/HeatMeter/GetAllByEstateId?id=${id}`;
    GetData(
      url,
      (data) => {
        setState((state) => ({
          ...state,
          heatmeters: data,
          heatmetersLoaded: true,
        }));
      },
      (error) => console.log(error)
    );
  };

  const getAllHeatMetersInactive = () => {
    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/reportEntities/HeatMeter/GetAllByEstateId?id=${id}&isActive=false`;
    GetData(
      url,
      (data) => {
        let old_data = state.heatmeters;
        old_data.push(...data);
        setState((state) => ({ ...state, heatmeters: old_data }));
      },
      (error) => console.log(error)
    );
  };

  useEffect(() => {
    state.heatmetersLoaded && getAllHeatMetersInactive();
  }, [state.heatmetersLoaded]);

  const getAllHeaters = () => {
    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/Heater/GetAllByEstateId?id=${id}`;
    GetData(
      url,
      (data) => {
        setState((state) => ({ ...state, heaters: data, heatersLoaded: true }));
      },
      (error) => console.log(error)
    );
  };

  const getAllHeatersInactive = () => {
    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/Heater/GetAllByEstateId?id=${id}&isActive=false`;
    GetData(
      url,
      (data) => {
        let old_data = state.heaters;
        old_data.push(...data);
        setState((state) => ({ ...state, heaters: old_data }));
      },
      (error) => console.log(error)
    );
  };

  useEffect(() => {
    state.heatersLoaded && getAllHeatersInactive();
  }, [state.heatersLoaded]);

  const getAllInvoices = () => {
    if (state.heating_season !== null) {
      const heatingSeasonId = state.heating_season && state.heating_season.id;

      const url = `${endpoints.API}/EstateInvoice/GetAllByEstateId?id=${id}&heatingSeasonId=${heatingSeasonId}`;
      GetData(
        url,
        (data) => {
          setState((state) => ({
            ...state,
            estateInvoices: data,
            estateInvoicesLoaded: true,
          }));
        },
        (error) => console.log(error)
      );
    }
  };

  const getAllDocuments = () => {
    const url = `${endpoints.BUILDING_UNITS_ADDITIONAL_ENDPOINT}/Document/GetAllByEstateId?id=${id}`;
    GetData(
      url,
      (data) => {
        setState((state) => ({
          ...state,
          documents: data,
          documentsLoaded: true,
        }));
      },
      (error) => console.log(error)
    );
  };

  const getAllDocumentsInactive = () => {
    const url = `${endpoints.BUILDING_UNITS_ADDITIONAL_ENDPOINT}/Document/GetAllByEstateId?id=${id}&isActive=false`;
    GetData(
      url,
      (data) => {
        let old_data = state.documents;
        old_data.push(...data);
        setState((state) => ({ ...state, documents: old_data }));
      },
      (error) => console.log(error)
    );
  };

  useEffect(() => {
    state.documentsLoaded && getAllDocumentsInactive();
  }, [state.documentsLoaded]);

  const getAllComments = () => {
    const url = `${endpoints.BUILDING_UNITS_ADDITIONAL_ENDPOINT}/EstateComment/GetAllByEstateId?id=${id}`;
    GetData(
      url,
      (data) => {
        setState((state) => ({
          ...state,
          comments: data,
          commentsLoaded: true,
        }));
      },
      (error) => console.log(error)
    );
  };

  const getAllCommentsInactive = () => {
    const url = `${endpoints.BUILDING_UNITS_ADDITIONAL_ENDPOINT}/EstateComment/GetAllByEstateId?id=${id}&isActive=false`;
    GetData(
      url,
      (data) => {
        let old_data = state.comments;
        old_data.push(...data);
        setState((state) => ({ ...state, comments: old_data }));
      },
      (error) => console.log(error)
    );
  };

  useEffect(() => {
    state.commentsLoaded && getAllCommentsInactive();
  }, [state.commentsLoaded]);

  const getAllContacts = () => {
    const url = `${endpoints.BUILDING_UNITS_ADDITIONAL_ENDPOINT}/EstateContact/GetAllByEstateId?id=${id}`;
    GetData(
      url,
      (data) => {
        setState((state) => ({
          ...state,
          contacts: data,
          contactsLoaded: true,
        }));
      },
      (error) => console.log(error)
    );
  };

  const getAllContactsInactive = () => {
    const url = `${endpoints.BUILDING_UNITS_ADDITIONAL_ENDPOINT}/EstateContact/GetAllByEstateId?id=${id}&isActive=false`;
    GetData(
      url,
      (data) => {
        let old_data = state.contacts;
        old_data.push(...data);
        setState((state) => ({ ...state, contacts: old_data }));
      },
      (error) => console.log(error)
    );
  };

  useEffect(() => {
    state.contactsLoaded && getAllContactsInactive();
  }, [state.contactsLoaded]);

  const getReportsByHeatingSeason = () => {
    if (state.heating_season !== null) {
      const heatingSeasonId = state.heating_season && state.heating_season.id;
      const url = `${endpoints.REPORTS_ENDPOINT}/ReportDeviceMap/GetAllByEstateId?id=${id}&heatingSeasonId=${heatingSeasonId}`;
      GetData(
        url,
        (data) => {
          setState((state) => ({
            ...state,
            reports: data,
            reportsLoaded: true,
          }));
        },
        (error) => console.log(error)
      );
    }
  };

  const incrementHeatingSeason = () => {
    setState((state) => ({
      ...state,
      heating_season: state.heating_season_options.find(
        (item) => item.toYear === state.heating_season.toYear + 1
      ),
    }));
  };

  const decrementHeatingSeason = () => {
    setState((state) => ({
      ...state,
      heating_season: state.heating_season_options.find(
        (item) => item.toYear === state.heating_season.toYear - 1
      ),
    }));
  };

  useEffect(() => {
    setState((state) => ({ ...state, isLoading: true }));
    getEstateById(id);
  }, []);

  useEffect(() => {
    getReportsByHeatingSeason();
    getAllInvoices();
  }, [state.heating_season]);

  const emitRowSave = (row, deviceType) => {
    const url = `${endpoints.REPORTS_ENDPOINT}/ReportDeviceMap/UpdateReading?deviceId=${row.deviceId}&reportId=${row.reportId}`;

    let patch = [];
    if (deviceType === 3 || deviceType === 2) {
      patch = [
        { op: "replace", path: "/currentHeat", value: `${row.data.value}` },
      ];
    } else if (deviceType === 4) {
      patch = [
        { op: "replace", path: "/waterMeterValue", value: `${row.data.value}` },
      ];
    }

    SaveItem(
      url,
      patch,
      (data) => {
        props.onSuccessShow(i18next.t("entry_updated_successfully"));
        getReportsByHeatingSeason();
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  const radioStyle = {
    listStyle: "none",
    paddingLeft: "0",
  };

  const labelStyle = {
    paddingLeft: "20px",
  };

  const tableStyle = {
    tableLayout: "fixed",
  };

  let currentLocation = location.pathname;

  if (currentLocation.charAt(0) === "/") {
    currentLocation = currentLocation.substring(1);
  }

  let tableBodyDataExpected = [];
  if (state.estateDataLoaded) {
    tableBodyDataExpected = state.toploficationData?.map((item) => {
      return {
        id: item.id,
        data: {
          people: item.personCount,
          heatingKWHByDay: item.estimatedHeatingPerDay
            ? item.estimatedHeatingPerDay
            : 0.0,
          hotWaterKWHByMonth: item.estimatedHotWaterPerMonth
            ? item.estimatedHotWaterPerMonth
            : 0.0,
          estateVolume: item.estateVolume,
          month: item.month,
          year: item.year,
          isManualEstimation:
            item.isManualEstimation === true ? <IconCheck /> : <IconX />,
          dateUpdated: item.dateUpdated ? item.dateUpdated : null,
          isActive: true,
        },
      };
    });
  }

  let tableBodyDataWaterMeter = [];
  if (state.watermeterLoaded) {
    tableBodyDataWaterMeter = state.watermeters.map((item) => {
      return {
        id: item.id,
        rowColor: item.device?.deviceSubType === "WARM" ? "red" : "blue",
        data: {
          room:
            item.device && item.device.deviceLocation
              ? translateDeviceLocationEnumValue(item.device.deviceLocation)
              : "",
          number: item.device ? item.device.serialNumber : "",
          radio_number: item.radioModuleNumber,
          type:
            item.device && item.device.deviceSubType
              ? translateTypeEnumValue(item.device.deviceSubType)
              : "",
          condition:
            item.device &&
            item.device.deviceStatuses &&
            item.device.deviceStatuses.length > 0
              ? translateConditionEnumValue(
                  item.device.deviceStatuses[0].status
                )
              : "",
          comment:
            item.device && item.device.comment ? item.device.comment : "",
          isActive: item.isActive,
        },
      };
    });
  }

  let tableBodyDataHeatMeters = [];
  if (state.heatmetersLoaded) {
    tableBodyDataHeatMeters = state.heatmeters.map((item) => {
      return {
        id: item.id,
        rowColor:
          item.heatMeterType?.installation == "FILLING" ? "red" : "blue",
        data: {
          room:
            item.device && item.device.deviceLocation
              ? translateDeviceLocationEnumValue(item.device.deviceLocation)
              : "",
          number: item.device ? item.device.serialNumber : "",
          radio_number: item.radioModuleNumber,
          type: item.heatMeterType ? item.heatMeterType.name : "",
          condition:
            item.device &&
            item.device.deviceStatuses &&
            item.device.deviceStatuses.length > 0
              ? translateConditionEnumValue(
                  item.device.deviceStatuses[0].status
                )
              : "",
          comment: item.device.comment ? item.device.comment : "",
          isActive: item.isActive,
        },
      };
    });
  }

  let tableBodyDataInvoices = [];
  if (state.estateInvoicesLoaded) {
    tableBodyDataInvoices = state.estateInvoices.map((item) => {
      return {
        id: item.id,
        data: {
          date: item.date,
          heatingEnergyQuantity: item.heatingEnergyQuantity,
          heatingEnergyAmount: item.heatingEnergyAmountWithVAT,
          buildingInstallationQuantity: item.buildingInstallationQuantity,
          buildingInstallationAmount: item.buildingInstallationAmountWithVAT,
          consumption: item.consumption,
          hotWaterEnergyQuantity: item.hotWaterEnergyQuantity,
          hotWaterEnergyAmount: item.hotWaterEnergyAmountWithVAT,
          invoiceAmount: item.invoiceAmountWithVAT,
          isActive: true,
        },
      };
    });
  }

  let tableBodyDataReconciliationBills = [];

  let tableBodyDataHeaters = [];
  if (state.heatersLoaded) {
    tableBodyDataHeaters = state.heaters.map((item) => {
      return {
        id: item.id,
        data: {
          room: item.roomType ? item.roomType.name : "",
          number:
            item.heatCostAllocators &&
            item.heatCostAllocators.length &&
            item.heatCostAllocators[0].heatCostAllocatorType !== true
              ? item.heatCostAllocators[0].device.serialNumber
              : "-",
          radio_module:
            item.heatCostAllocators &&
            item.heatCostAllocators.length &&
            item.heatCostAllocators[0].heatCostAllocatorType !== true
              ? "Да"
              : "Не",
          condition:
            item.heatCostAllocators &&
            item.heatCostAllocators.length &&
            item.heatCostAllocators[0].device &&
            item.heatCostAllocators[0].device.deviceStatuses &&
            item.heatCostAllocators[0].device.deviceStatuses.length
              ? translateConditionEnumValue(
                  item.heatCostAllocators[0].device.deviceStatuses[0].status
                )
              : "",
          type: item.heaterType.name,
          status: translateStatusEnumValue(item.status),
          isActive: item.isActive,
        },
        enableLinkAdd:
          item.status === "WITHOUT_DEVICE" || item.status === "DEMOUNTED"
            ? false
            : true,
      };
    });
  }

  let tableBodyDataDocuments = [];
  if (state.documentsLoaded) {
    tableBodyDataDocuments = state.documents.map((item) => {
      return {
        id: item.id,
        data: {
          name: item.name,
          date_updated: item.dateUpdated,
          isActive: item.isActive,
        },
      };
    });
  }

  let tableBodyDataComments = [];
  if (state.commentsLoaded) {
    tableBodyDataComments = state.comments.map((item) => {
      return {
        id: item.id,
        data: {
          comment: item.comment ? item.comment : "",
          date_updated: item.dateUpdated ? item.dateUpdated : "",
          isActive: item.isActive,
        },
      };
    });
  }

  let tableBodyDataContacts = [];
  if (state.contactsLoaded) {
    tableBodyDataContacts = state.contacts.map((item) => {
      return {
        id: item.id,
        data: {
          name: item.name ? item.name : "",
          phone: item.phoneNumber,
          email: item.email,
          hasBillByEmail:
            item.sendReconciliationBillEmail === true ? (
              <IconCheck />
            ) : (
              <IconX />
            ),
          comment: item.comment,
          date_updated: item.dateUpdated,
          status: item.isActive === true ? "Активен" : "Неактивен",
          isActive: item.isActive,
        },
      };
    });
  }

  let tableBodyDataOwnersHistory = [];
  if (state.ownersLoaded) {
    tableBodyDataOwnersHistory = state.owners_history.map((item) => {
      return {
        id: item.id,
        data: {
          name: item.name ? item.name : "",
          phone: item.phone,
          email: item.email,
          dateFrom: formatDateLocale(item.fromDate),
          dateTo: formatDateLocale(item.toDate),
          isActive: item.toDate === null,
        },
      };
    });
  }

  let tableBodyDataReportsWaterMeters = [];
  if (state.reportsLoaded) {
    tableBodyDataReportsWaterMeters = state.reports
      .filter((item) => item.device.deviceTypeId === 4)
      .map((item) => {
        return {
          id: item.reportId,
          deviceId: item.deviceId,
          reportId: item.reportId,
          editMode: true,
          data: {
            number: item.device.serialNumber,
            value: item.waterMeterValue,
            yearReport:
              item.report && item.report.isYearReport ? (
                <IconCheck />
              ) : (
                <IconX />
              ),
            date: item.report.reportDate,
            dateVisitation: item.report.visitationDate,
            isActive: true,
          },
        };
      });
  }

  let tableBodyDataReportsHeatMeters = [];
  if (state.reportsLoaded) {
    tableBodyDataReportsHeatMeters = state.reports
      .filter((item) => item.device.deviceTypeId === 2)
      .map((item) => {
        return {
          id: item.reportId,
          deviceId: item.deviceId,
          reportId: item.reportId,
          editMode: true,
          data: {
            number: item.device.serialNumber,
            value: item.currentHeat,
            yearReport:
              item.report && item.report.isYearReport ? (
                <IconCheck />
              ) : (
                <IconX />
              ),
            date: item.report.reportDate,
            dateVisitation: item.report.visitationDate,
            isActive: true,
          },
        };
      });
  }

  let tableBodyDataReportsHCA = [];
  if (state.reportsLoaded) {
    tableBodyDataReportsHCA = state.reports
      .filter((item) => item.device.deviceTypeId === 3)
      .map((item) => {
        return {
          id: item.deviceId,
          deviceId: item.deviceId,
          reportId: item.reportId,
          editMode: true,
          data: {
            number: item.device.serialNumber,
            value: item.currentHeat,
            yearReport:
              item.report && item.report.isYearReport ? (
                <IconCheck />
              ) : (
                <IconX />
              ),
            date: item.report.reportDate,
            dateVisitation: item.report.visitationDate,
            isActive: true,
          },
        };
      });
  }

  const toploficationDataRecords = state.document.toploficationData
    ? state.document.toploficationData.length
    : 0;

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl="12">
              <BackButton className="mb-4" />
            </Col>

            <Col xl="12">
              <Card>
                <CardBody className="mb-0 pb-0">
                  <Row>
                    <Col>
                      <CardTitle className="mb-4">{state.pageTitle}</CardTitle>
                    </Col>

                    <Col sm="3" className="text-right">
                      <Row>
                        <Col
                          xs="12"
                          className="d-flex align-items-center justify-content-end"
                        >
                          <ListActions
                            link={`/${state.document.id}`}
                            data={state.document}
                            mode={state.mode}
                            toggleSave={() => {
                              setState((state) => ({
                                ...state,
                                confirm_save: true,
                                actionType: "save",
                              }));
                            }}
                            toggleEdit={handleEdit}
                            toggle={() =>
                              setState((state) => ({
                                ...state,
                                confirm_toggle: true,
                                actionType: "toggle",
                              }))
                            }
                            delete={() =>
                              setState((state) => ({
                                ...state,
                                confirm_delete: true,
                                actionType: "delete",
                              }))
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col md="12">
              <AvForm className="needs-validation">
                <Row className="d-flex">
                  <Col xl="6" className="d-flex">
                    <Card className="w-100">
                      <CardBody>
                        <Row>
                          <Col md="12">
                            <h5>{i18next.t("address")}</h5>
                          </Col>

                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="validationCity" className="">
                                {i18next.t("label-city")}
                              </Label>
                              <AvField
                                name="document.city"
                                placeholder=""
                                type="text"
                                className="form-control"
                                id="validationCity"
                                value={
                                  state.document.address &&
                                  state.document.address.city
                                }
                                onChange={(e) =>
                                  setState((state) => ({
                                    ...state,
                                    document: {
                                      ...state.document,
                                      address: {
                                        ...state.document.address,
                                        city: e.target.value,
                                      },
                                    },
                                  }))
                                }
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                  maxLength: {
                                    value: 30,
                                  },
                                }}
                                {...(state.mode === "view" && {
                                  readOnly: true,
                                })}
                              />
                            </FormGroup>
                          </Col>

                          <Col md="4">
                            <FormGroup>
                              <Label
                                htmlFor="validationAddress_street"
                                className=""
                              >
                                {i18next.t("label-street")}
                              </Label>
                              <AvField
                                name="document.address.streetName"
                                placeholder=""
                                type="text"
                                className="form-control"
                                id="validationAddress_street"
                                value={
                                  state.document.address &&
                                  state.document.address.streetName
                                }
                                onChange={(e) =>
                                  setState((state) => ({
                                    ...state,
                                    document: {
                                      ...state.document,
                                      address: {
                                        ...state.document.address,
                                        streetName: e.target.value,
                                      },
                                    },
                                  }))
                                }
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                  maxLength: {
                                    value: 30,
                                  },
                                }}
                                {...(state.mode === "view" && {
                                  readOnly: true,
                                })}
                              />
                            </FormGroup>
                          </Col>

                          <Col md="4">
                            <FormGroup>
                              <Label
                                htmlFor="validationAddress_number"
                                className=""
                              >
                                {i18next.t("label-number")}
                              </Label>
                              <Row>
                                <Col md="6">
                                  <AvField
                                    name="document.address.buildingNumber"
                                    placeholder="№"
                                    type="text"
                                    className="form-control"
                                    id="validationAddress_number"
                                    onChange={(e) =>
                                      setState((state) => ({
                                        ...state,
                                        document: {
                                          ...state.document,
                                          address: {
                                            ...state.document.address,
                                            buildingNumber: e.target.value,
                                          },
                                        },
                                      }))
                                    }
                                    value={
                                      state.document.address &&
                                      state.document.address.buildingNumber
                                    }
                                    validate={{
                                      required: {
                                        value: true,
                                      },
                                      maxLength: {
                                        value: 30,
                                      },
                                    }}
                                    {...(state.mode === "view" && {
                                      readOnly: true,
                                    })}
                                  />
                                </Col>
                                <Col md="6">
                                  <AvField
                                    name="document.address.buildingSubNumber"
                                    placeholder="Подномер"
                                    type="text"
                                    className="form-control"
                                    id="building_address_building_sub_number"
                                    value={
                                      state.document.address &&
                                      state.document.address.buildingSubNumber
                                    }
                                    onChange={(e) => {
                                      setState((state) => ({
                                        ...state,
                                        document: {
                                          ...state.document,
                                          address: {
                                            ...state.document.address,
                                            buildingSubNumber: e.target.value,
                                          },
                                        },
                                      }));
                                    }}
                                    validate={{
                                      required: {
                                        value: true,
                                      },
                                      maxLength: {
                                        value: 30,
                                      },
                                    }}
                                    {...(state.mode === "view" && {
                                      readOnly: true,
                                    })}
                                  />
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>

                          <Col md="3">
                            <FormGroup>
                              <Label
                                htmlFor="validationAddress_entrance"
                                className=""
                              >
                                {i18next.t("label-entrance")}
                              </Label>
                              <AvField
                                name="document.entrance"
                                placeholder=""
                                type="number"
                                className="form-control"
                                id="validationAddress_entrance"
                                value={state.document.entrance}
                                onChange={(e) =>
                                  setState((state) => ({
                                    ...state,
                                    document: {
                                      ...state.document,
                                      entrance: e.target.value,
                                    },
                                  }))
                                }
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                  maxLength: {
                                    value: 30,
                                  },
                                }}
                                {...(state.mode === "view" && {
                                  readOnly: true,
                                })}
                              />
                            </FormGroup>
                          </Col>

                          <Col md="3">
                            <FormGroup>
                              <Label
                                htmlFor="validationAddress-floor"
                                className=""
                              >
                                {i18next.t("label-floor")}
                              </Label>

                              <AvField
                                name="document.floor"
                                placeholder=""
                                type="number"
                                min="0"
                                max="20"
                                className="form-control"
                                id="validationAddress-floor"
                                value={state.document.floor}
                                onChange={(e) =>
                                  setState((state) => ({
                                    ...state,
                                    document: {
                                      ...state.document,
                                      floor: e.target.value,
                                    },
                                  }))
                                }
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                  maxLength: {
                                    value: 30,
                                  },
                                }}
                                {...(state.mode === "view" && {
                                  readOnly: true,
                                })}
                              />
                            </FormGroup>
                          </Col>

                          <Col md="5">
                            <FormGroup>
                              <Label
                                htmlFor="validation_apartment_number"
                                className=""
                              >
                                {i18next.t("label-apartment-number")}
                              </Label>
                              <AvField
                                name="document.apartmentNumber"
                                placeholder=""
                                type="number"
                                className="form-control"
                                id="validation_apartment_number"
                                value={state.document.apartmentNumber}
                                onChange={(e) =>
                                  setState((state) => ({
                                    ...state,
                                    document: {
                                      ...state.document,
                                      apartmentNumber: e.target.value,
                                    },
                                  }))
                                }
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                  maxLength: {
                                    value: 30,
                                  },
                                }}
                                {...(state.mode === "view" && {
                                  readOnly: true,
                                })}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="validationCustomApartment_type">
                                {i18next.t("label-estate-type")}
                              </Label>
                              <AvField
                                type="select"
                                value={state.document.apartmentTypeId}
                                onChange={(e) =>
                                  setState((state) => ({
                                    ...state,
                                    document: {
                                      ...state.document,
                                      apartmentTypeId: e.target.value,
                                    },
                                  }))
                                }
                                name="document.apartmentTypeId"
                                id="validationCustomApartment_type"
                                errorMessage="Полето е задължително"
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                }}
                                {...(state.mode === "view" && {
                                  disabled: true,
                                })}
                              >
                                <option
                                  key={`apartment-type-default`}
                                  value="0"
                                  /* selected={
                                    state.document.apartmentTypeId === null
                                  }*/
                                >
                                  -- Изберете тип апартамент --
                                </option>
                                {state.apartment_type_options.map((option) => {
                                  return (
                                    <option
                                      key={`apartment-type-${option.id}`}
                                      value={option.id}
                                      selected={
                                        state.document.apartmentTypeId ===
                                        option.id
                                      }
                                    >
                                      {option.name}
                                    </option>
                                  );
                                })}
                              </AvField>
                            </FormGroup>
                          </Col>

                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="validationCustom-heatingVolume">
                                {i18next.t("label-heated-volume")}
                              </Label>
                              <AvField
                                disabled
                                name="document.toploficationData.estateVolume"
                                placeholder=""
                                type="number"
                                errorMessage="Enter "
                                className="form-control"
                                value={
                                  toploficationDataRecords > 0 &&
                                  state.document.toploficationData[
                                    toploficationDataRecords - 1
                                  ].estateVolume
                                }
                                onChange={(e) =>
                                  setState((state) => ({
                                    ...state,
                                    document: {
                                      ...state.document,
                                      toploficationData: {
                                        ...state.document.toploficationData,
                                        estateVolume: e.target.value,
                                      },
                                    },
                                  }))
                                }
                                validate={{
                                  required: {
                                    value: false,
                                  },
                                }}
                                id="validationCustom-heatingVolume"
                                {...(state.mode === "view" && {
                                  readOnly: true,
                                })}
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="12">
                            <h5>Собственик</h5>
                          </Col>

                          <Col md="12">
                            <FormGroup>
                              <Label htmlFor="validationCustom-owner">
                                {i18next.t("label-owner-name")}
                              </Label>

                              <AvField
                                name="document.currentOwner.name"
                                placeholder=""
                                type="text"
                                value={
                                  state.document.currentOwner &&
                                  state.document.currentOwner.name
                                }
                                onChange={(e) =>
                                  setState((state) => ({
                                    ...state,
                                    document: {
                                      ...state.document,
                                      currentOwner: {
                                        ...state.document.currentOwner,
                                        name: e.target.value,
                                      },
                                    },
                                  }))
                                }
                                className="form-control"
                                id="validationCustom-owner"
                                {...(state.mode === "view" && {
                                  readOnly: true,
                                })}
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <Label htmlFor="validationCustom-owner-phone">
                                {i18next.t("phone")}
                              </Label>

                              <AvField
                                name="document.currentOwner.phone"
                                placeholder=""
                                type="text"
                                value={
                                  state.document.currentOwner &&
                                  state.document.currentOwner.phone
                                }
                                onChange={(e) =>
                                  setState((state) => ({
                                    ...state,
                                    document: {
                                      ...state.document,
                                      currentOwner: {
                                        ...state.document.currentOwner,
                                        phone: e.target.value,
                                      },
                                    },
                                  }))
                                }
                                className="form-control"
                                id="validationCustom-owner-phone"
                                {...(state.mode === "view" && {
                                  readOnly: true,
                                })}
                              />
                            </FormGroup>
                          </Col>

                          <Col md="6">
                            <FormGroup>
                              <Label htmlFor="validationCustom-owner_email">
                                {i18next.t("email")}
                              </Label>

                              <AvField
                                name="document.currentOwner.email"
                                placeholder=""
                                type="email"
                                value={
                                  state.document.currentOwner &&
                                  state.document.currentOwner.email
                                }
                                onChange={(e) =>
                                  setState((state) => ({
                                    ...state,
                                    document: {
                                      ...state.document,
                                      currentOwner: {
                                        ...state.document.currentOwner,
                                        email: e.target.value,
                                      },
                                    },
                                  }))
                                }
                                className="form-control"
                                id="validationCustom-owner_email"
                                {...(state.mode === "view" && {
                                  readOnly: true,
                                })}
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <Row className="mb-2">
                              <Col md="6">
                                <div className="form-check form-check-right d-block">
                                  <input
                                    name="document.currentOwner.hasZeroingLot"
                                    className="form-check-input"
                                    type="checkbox"
                                    id="checkbox-tf-option1"
                                    checked={
                                      state.document.currentOwner &&
                                      state.document.currentOwner.hasZeroingLot
                                    }
                                    onChange={() =>
                                      setState((state) => ({
                                        ...state,
                                        document: {
                                          ...state.document,
                                          currentOwner: {
                                            ...state.document.currentOwner,
                                            hasZeroingLot:
                                              !state.document.currentOwner
                                                .hasZeroingLot,
                                          },
                                        },
                                      }))
                                    }
                                    {...(state.mode === "view" && {
                                      disabled: true,
                                    })}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="checkbox-tf-option1"
                                  >
                                    {i18next.t("label-hasZeroingLot")}
                                  </label>
                                </div>
                              </Col>
                            </Row>

                            <Row className="mb-2">
                              <Col md="6">
                                <div className="form-check form-check-right d-block">
                                  <input
                                    name="document.currentOwner.hasBillByEmail"
                                    className="form-check-input"
                                    type="checkbox"
                                    id="checkbox-email-subscribtion"
                                    checked={
                                      state.document.currentOwner &&
                                      state.document.currentOwner.hasBillByEmail
                                    }
                                    onChange={() =>
                                      setState((state) => ({
                                        ...state,
                                        document: {
                                          ...state.document,
                                          currentOwner: {
                                            ...state.document.currentOwner,
                                            hasBillByEmail:
                                              !state.document.currentOwner
                                                .hasBillByEmail,
                                          },
                                        },
                                      }))
                                    }
                                    {...(state.mode === "view" && {
                                      disabled: true,
                                    })}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="checkbox-email-subscribtion"
                                  >
                                    {i18next.t("label-hasBillByEmail")}
                                  </label>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col md="6">
                                <div className="form-check form-check-right d-block">
                                  <input
                                    name="document.currentOwner.hasEqualMonthlyInstallments"
                                    className="form-check-input"
                                    type="checkbox"
                                    id="checkbox-tf-option2"
                                    checked={
                                      state.document.currentOwner &&
                                      state.document.currentOwner
                                        .hasEqualMonthlyInstallments
                                    }
                                    onChange={() =>
                                      setState((state) => ({
                                        ...state,
                                        document: {
                                          ...state.document,
                                          currentOwner: {
                                            ...state.document.currentOwner,
                                            hasEqualMonthlyInstallments:
                                              !state.document.currentOwner
                                                .hasEqualMonthlyInstallments,
                                          },
                                        },
                                      }))
                                    }
                                    {...(state.mode === "view" && {
                                      disabled: true,
                                    })}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="checkbox-tf-option2"
                                  >
                                    {i18next.t(
                                      "label-hasEqualMonthlyInstallments"
                                    )}
                                  </label>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col xl="6" className="d-flex">
                    <Card className="w-100">
                      <CardBody>
                        <Row>
                          <Col md="7">
                            <h5 className="text-center">
                              {i18next.t("toplofication")}
                            </h5>

                            <Row>
                              <Col md="12">
                                <Label
                                  className="text-center d-block"
                                  htmlFor="validationCustombuAddToploFicationId"
                                >
                                  {i18next.t("label-subscriber-number")}
                                </Label>
                                <Row>
                                  <Col md={6}>
                                    <AvField
                                      name="document.externalId"
                                      disabled
                                      type="text"
                                      value={state.document.externalId}
                                      className="form-control"
                                      id="validationCustombuAddToploFicationId"
                                      {...(state.mode === "view" && {
                                        readOnly: true,
                                      })}
                                    />
                                  </Col>

                                  <Col md={6}>
                                    <AvField
                                      name="document.externalCode"
                                      disabled
                                      type="text"
                                      value={state.document.externalCode}
                                      className="form-control"
                                      id="validationCustombuAddToploFication_install"
                                      {...(state.mode === "view" && {
                                        readOnly: true,
                                      })}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="12">
                                <Label
                                  className="text-center d-block"
                                  htmlFor="validationCustombuAddToploFicationId"
                                >
                                  {i18next.t("Договорна сметка")}
                                </Label>

                                <Row>
                                  <Col md="6">
                                    <FormGroup>
                                      <AvField
                                        name="document.currentOwner.payerExternalId"
                                        disabled
                                        placeholder=""
                                        type="text"
                                        value={
                                          state.document.currentOwner &&
                                          state.document.currentOwner
                                            .payerExternalId
                                        }
                                        className="form-control"
                                        id="validationCustom-owner-payerExternalId"
                                        {...(state.mode === "view" && {
                                          readOnly: true,
                                        })}
                                      />
                                    </FormGroup>
                                  </Col>

                                  <Col md="6">
                                    <FormGroup>
                                      <AvField
                                        name="document.currentOwner.payerExternalCode"
                                        disabled
                                        type="text"
                                        value={
                                          state.document.currentOwner &&
                                          state.document.currentOwner
                                            .payerExternalCode
                                        }
                                        className="form-control"
                                        id="validationCustom-owner_payerExternalCode"
                                        {...(state.mode === "view" && {
                                          readOnly: true,
                                        })}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <Row>
                              <Col md="12">
                                <Row>
                                  <Col mb="12">
                                    <FormGroup>
                                      <Label
                                        className="text-center d-block"
                                        htmlFor="validationCustombuAddSofiaWaterContractAccount"
                                      >
                                        {i18next.t("label")}
                                      </Label>
                                      <AvField
                                        name="document.externalContractNumber"
                                        disabled
                                        type="string"
                                        value={
                                          state.document.externalContractNumber
                                        }
                                        className="form-control"
                                        id="validationCustombuAddSofiaWaterContractAccount"
                                        {...(state.mode === "view" && {
                                          readOnly: true,
                                        })}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>

                          <Col md="5">
                            <h5 className="text-center">
                              {i18next.t("water-supply")}
                            </h5>

                            <Row>
                              <Col mb="12">
                                <FormGroup>
                                  <Label
                                    className="text-center d-block"
                                    htmlFor="validationCustombuAddSofiaWaterReportId"
                                  >
                                    {i18next.t(
                                      "label-water-meter-subscribe-number"
                                    )}
                                  </Label>
                                  <AvField
                                    name="document.sofiaWaterData.subscriberNumber"
                                    placeholder="Въведи Номер от Софийска Вода"
                                    type="string"
                                    value={
                                      state.document.sofiaWaterData &&
                                      state.document.sofiaWaterData
                                        .subscriberNumber
                                    }
                                    onChange={(e) =>
                                      setState((state) => ({
                                        ...state,
                                        document: {
                                          ...state.document,
                                          sofiaWaterData: {
                                            ...state.document.sofiaWaterData,
                                            subscriberNumber: e.target.value,
                                          },
                                        },
                                      }))
                                    }
                                    errorMessage="Грешен номер"
                                    className="form-control"
                                    validate={{
                                      required: {
                                        value: true,
                                      },
                                      maxLength: {
                                        value: 10,
                                      },
                                    }}
                                    id="validationCustombuAddSofiaWaterReportId"
                                    {...(state.mode === "view" && {
                                      readOnly: true,
                                    })}
                                  />
                                </FormGroup>
                              </Col>

                              <Col md="12">
                                <FormGroup>
                                  <Label
                                    className="text-center d-block"
                                    htmlFor="validationCustombuAddSofiaWaterInstall"
                                  >
                                    {i18next.t("label-install")}
                                  </Label>
                                  <AvField
                                    name="document.sofiaWaterData.contractAccount"
                                    placeholder="Инсталация"
                                    type="string"
                                    value={
                                      state.document.sofiaWaterData &&
                                      state.document.sofiaWaterData
                                        .contractAccount
                                    }
                                    onChange={(e) =>
                                      setState((state) => ({
                                        ...state,
                                        document: {
                                          ...state.document,
                                          sofiaWaterData: {
                                            ...state.document.sofiaWaterData,
                                            contractAccount: e.target.value,
                                          },
                                        },
                                      }))
                                    }
                                    errorMessage="Грешен номер"
                                    className="form-control"
                                    validate={{
                                      required: {
                                        value: true,
                                      },
                                      maxLength: {
                                        value: 10,
                                      },
                                    }}
                                    id="validationCustombuAddSofiaWaterInstall"
                                    {...(state.mode === "view" && {
                                      readOnly: true,
                                    })}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </Col>

                          <Col md="12" className="mt-5">
                            <h5 className="text-center">
                              {i18next.t("Other")}
                            </h5>

                            <Row>
                              <Col md="4">
                                <FormGroup>
                                  <Label
                                    className="text-center d-block"
                                    htmlFor="validationCustombuAddTKNumber"
                                  >
                                    {i18next.t("label-inner-number")}
                                  </Label>

                                  <AvField
                                    name="document.number"
                                    placeholder=""
                                    type="text"
                                    value={state.document.number}
                                    errorMessage="Грешен номер"
                                    className="form-control"
                                    validate={{
                                      required: {
                                        value: false,
                                      },
                                      maxLength: {
                                        value: 10,
                                      },
                                    }}
                                    id="validationCustombuAddTKNumber"
                                    {...(state.mode === "view" && {
                                      readOnly: true,
                                    })}
                                  />
                                </FormGroup>
                              </Col>

                              <Col md="4">
                                <FormGroup>
                                  <Label
                                    className="text-center d-block"
                                    htmlFor="validationCustombuAddGasMeterId"
                                  >
                                    {i18next.t("label-gas")}
                                  </Label>
                                  <AvField
                                    name="document.gasMeter.number"
                                    placeholder=""
                                    type="text"
                                    value={
                                      state.document.gasMeter &&
                                      state.document.gasMeter.number
                                    }
                                    errorMessage="Грешен номер"
                                    className="form-control"
                                    validate={{
                                      required: {
                                        value: false,
                                      },
                                      maxLength: {
                                        value: 10,
                                      },
                                    }}
                                    id="validationCustombuAddGasMeterId"
                                    {...(state.mode === "view" && {
                                      readOnly: true,
                                    })}
                                  />
                                </FormGroup>
                              </Col>

                              <Col md="4">
                                <FormGroup>
                                  <Label
                                    className="text-center d-block"
                                    htmlFor="validationCustombuAddAmperageMeterId"
                                  >
                                    {i18next.t("label-electricity")}
                                  </Label>
                                  <AvField
                                    name="document.electricityMeter.number"
                                    placeholder=""
                                    type="text"
                                    value={
                                      state.document.electricityMeter &&
                                      state.document.electricityMeter.number
                                    }
                                    errorMessage="Грешен номер"
                                    className="form-control"
                                    validate={{
                                      required: {
                                        value: false,
                                      },
                                      maxLength: {
                                        value: 10,
                                      },
                                    }}
                                    id="validationCustombuAddAmperageMeterId"
                                    {...(state.mode === "view" && {
                                      readOnly: true,
                                    })}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </AvForm>
            </Col>

            <Col xl="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <Nav pills className="navtab-bg nav-justified">
                        {state.tabsNav.map((item) => (
                          <NavItem key={item.id}>
                            <NavLink
                              style={{
                                cursor: "pointer",
                              }}
                              className={classnames({
                                active: state.activeTab === item.index,
                              })}
                              onClick={() => {
                                toggleTabs(item.index);
                              }}
                            >
                              {item.label}
                            </NavLink>
                          </NavItem>
                        ))}
                      </Nav>

                      <TabContent activeTab={state.activeTab}>
                        {/* Прогнозни данни*/}

                        <TabPane tabId="1" className="p-3">
                          <Row className="mb-2">
                            <Col>
                              <CardTitle className="d-none mb-4">
                                Таблица с прогнозни данни
                              </CardTitle>
                            </Col>

                            <Col>
                              <div className="text-right">
                                <Tooltip
                                  title="Добави прогнозни данни"
                                  placement="right-start"
                                >
                                  <button
                                    className="btn btn-primary waves-effect waves-light"
                                    onClick={(id) =>
                                      openModal("estimated-toplofication-data")
                                    }
                                  >
                                    <i
                                      className="bx bx-plus-circle"
                                      style={{
                                        fontSize: "18px",
                                      }}
                                    />
                                  </button>
                                </Tooltip>
                              </div>
                            </Col>
                          </Row>

                          {tableBodyDataExpected ? (
                            <TableComponent
                              dataHead={[
                                "Брой лица",
                                "Отопление KWh/ден",
                                "Топла вода KWh/месец",
                                "Отопляем обем",
                                "Месец",
                                "Година",
                                "Ръчно въведена стойност",
                                "Дата на създаване",
                              ]}
                              dataBody={tableBodyDataExpected}
                              url_path={""}
                              category={""}
                              disableLink={true}
                              isLoading={state.isLoading}
                              isLoaded={state.isLoaded}
                            />
                          ) : null}
                        </TabPane>
                        <TabPane tabId="2" className="p-3">
                          {/* Водомери */}

                          <Row className="mb-2">
                            <Col>
                              <CardTitle className="d-none mb-4">
                                Таблица с водомери
                              </CardTitle>
                            </Col>

                            <Col>
                              <div className="text-right">
                                <Tooltip
                                  title="Добави нов водомер"
                                  placement="right-start"
                                >
                                  <button
                                    className="btn btn-primary waves-effect waves-light"
                                    onClick={(id) => openModal("water-meter")}
                                  >
                                    <i
                                      className="bx bx-plus-circle"
                                      style={{
                                        fontSize: "18px",
                                      }}
                                    />
                                  </button>
                                </Tooltip>
                              </div>
                            </Col>
                          </Row>

                          {tableBodyDataWaterMeter && (
                            <TableComponent
                              dataHead={[
                                "Помещение",
                                "Номер",
                                "Радио модул",
                                "Тип",
                                "Състояние",
                                "Коментар",
                                i18next.t("actions"),
                              ]}
                              dataBody={tableBodyDataWaterMeter}
                              url_path={`${currentLocation}/water-meter`}
                              category={""}
                              enableLinkView={true}
                              isLoaded={true}
                            />
                          )}
                        </TabPane>
                        <TabPane tabId="3" className="p-3">
                          {/* Топломери */}

                          <Row className="mb-2">
                            <Col>
                              <CardTitle className="d-none mb-4">
                                Таблица с топломери
                              </CardTitle>
                            </Col>

                            <Col>
                              <div className="text-right">
                                <Tooltip
                                  title="Добави нов топломер"
                                  placement="right-start"
                                >
                                  <button
                                    className="btn btn-primary waves-effect waves-light"
                                    onClick={(id) => openModal("heat-meter")}
                                  >
                                    <i
                                      className="bx bx-plus-circle"
                                      style={{
                                        fontSize: "18px",
                                      }}
                                    />
                                  </button>
                                </Tooltip>
                              </div>
                            </Col>
                          </Row>

                          {tableBodyDataHeatMeters && (
                            <TableComponent
                              dataHead={[
                                "Местоположение",
                                "Номер",
                                "Радио модул",
                                "Вид",
                                "Състояние",
                                "Коментар",
                                i18next.t("actions"),
                              ]}
                              dataBody={tableBodyDataHeatMeters}
                              url_path={`${currentLocation}/heat-meter`}
                              category={""}
                              enableLinkView={true}
                              isLoaded={true}
                            />
                          )}
                        </TabPane>
                        <TabPane tabId="4" className="p-3">
                          {/* Отоплителни тела */}

                          <Row className="mb-2">
                            <Col>
                              <CardTitle className="d-none mb-4">
                                Таблица с отоплителни тела
                              </CardTitle>
                            </Col>

                            <Col>
                              <div className="text-right">
                                <Tooltip
                                  title="Добави ново отоплително тяло"
                                  placement="right-start"
                                >
                                  <button
                                    className="btn btn-primary waves-effect waves-light"
                                    onClick={(id) => openModal("heater")}
                                  >
                                    <i
                                      className="bx bx-plus-circle"
                                      style={{
                                        fontSize: "18px",
                                      }}
                                    />
                                  </button>
                                </Tooltip>
                              </div>
                            </Col>
                          </Row>

                          {tableBodyDataHeaters ? (
                            <TableComponent
                              dataHead={[
                                "Помещение",
                                "Номер",
                                "Радио модул",
                                "Състояние - HCA",
                                "Тип",
                                "Състояние - Отопл. тяло",
                                i18next.t("actions"),
                              ]}
                              dataBody={tableBodyDataHeaters}
                              url_path={`${currentLocation}/heater`}
                              category={""}
                              enableLinkView={true}
                              linkAddTooltip={i18next.t("tooltip-add-hca")}
                              onModalHCAOpen={onModalHCAOpen}
                              isLoaded={true}
                            />
                          ) : null}
                        </TabPane>
                        <TabPane tabId="5" className="p-3">
                          {/* Фактури */}
                          <Row className="mb-2">
                            <Col>
                              <div className="year-select d-flex align-items-center justify-content-center mb-4">
                                <button
                                  onClick={decrementHeatingSeason}
                                  disabled={
                                    state.heating_season_options &&
                                    state.heating_season_options.find(
                                      (item) =>
                                        state.heating_season &&
                                        item.toYear ===
                                          state.heating_season.toYear - 1
                                    ) === undefined
                                      ? true
                                      : false
                                  }
                                  style={{
                                    background: "none",
                                    fontSize: "0",
                                    border: "none",
                                  }}
                                >
                                  <i
                                    className="bx bx-left-arrow-alt"
                                    style={{
                                      fontSize: "18px",
                                    }}
                                  />
                                </button>
                                <h6 className="mb-0 mx-2">
                                  {state.heating_season &&
                                    state.heating_season.name}
                                </h6>
                                <button
                                  onClick={incrementHeatingSeason}
                                  disabled={
                                    state.heating_season_options &&
                                    state.heating_season_options.find(
                                      (item) =>
                                        state.heating_season &&
                                        item.toYear ===
                                          state.heating_season.toYear + 1
                                    ) === undefined
                                      ? true
                                      : false
                                  }
                                  style={{
                                    background: "none",
                                    fontSize: "0",
                                    border: "none",
                                  }}
                                >
                                  <i
                                    className="bx bx-right-arrow-alt"
                                    style={{
                                      fontSize: "18px",
                                    }}
                                  />
                                </button>
                              </div>
                            </Col>
                          </Row>

                          {tableBodyDataInvoices ? (
                            <TableComponent
                              dataHead={[
                                "Дата",
                                "Отопление, kWh",
                                "Отопление, лв.",
                                "Сградна инст., kWh",
                                "Сградна инст., лв.",
                                "Разход ТВ, м3",
                                "Топла вода, kWh",
                                "Топла вода, лв.",
                                "Цена kWh, лв.",
                              ]}
                              dataBody={tableBodyDataInvoices}
                              disableLink={true}
                              /* url_path={`${currentLocation}/heater`}
                                  category={""}
                                  enableLinkView={false}
                                  enableLinkAdd={true}
                                  onModalHCAOpen={onModalHCAOpen}*/
                              isLoaded={true}
                            />
                          ) : null}
                        </TabPane>
                        <TabPane tabId="6" className="p-3">
                          {/* Изравнителни сметки */}

                          {tableBodyDataReconciliationBills ? (
                            <TableComponent
                              dataHead={[
                                "Сезон",
                                "Корекция",
                                "Годишен отчет",
                                i18next.t("actions"),
                              ]}
                              dataBody={tableBodyDataReconciliationBills}
                              /*url_path={`${currentLocation}/heater`}
                                  category={""}
                                  enableLinkView={true}
                                  enableLinkAdd={true}
                                  onModalHCAOpen={onModalHCAOpen}*/
                              isLoaded={true}
                            />
                          ) : null}
                        </TabPane>
                        <TabPane tabId="7" className="p-3">
                          {/* Контакти */}

                          <Row className="mb-2">
                            <Col>
                              <CardTitle className="d-none mb-4">
                                Таблица с контакти
                              </CardTitle>
                            </Col>

                            <Col>
                              <div className="text-right">
                                <Tooltip
                                  title="Добави нов контакт"
                                  placement="right-start"
                                >
                                  <button
                                    className="btn btn-primary waves-effect waves-light"
                                    onClick={(id) =>
                                      openModal("estate-contact")
                                    }
                                  >
                                    <i
                                      className="bx bx-plus-circle"
                                      style={{
                                        fontSize: "18px",
                                      }}
                                    />
                                  </button>
                                </Tooltip>
                              </div>
                            </Col>
                          </Row>

                          {tableBodyDataContacts ? (
                            <TableComponent
                              dataHead={[
                                "Име",
                                "Телефон",
                                "Email",
                                "Сметка по email",
                                "Коментар",
                                "Дата",
                                "Статус",
                                i18next.t("actions"),
                              ]}
                              dataBody={tableBodyDataContacts}
                              /* disableLink={true}*/
                              url_path={`${currentLocation}/contacts`}
                              category={""}
                              enableLinkView={true}
                              isLoaded={true}
                            />
                          ) : null}

                          {/* )} */}
                        </TabPane>
                        <TabPane tabId="8" className="p-3">
                          {/* Коментари */}

                          <Row className="mb-2">
                            <Col>
                              <CardTitle className="d-none mb-4">
                                Таблица с коментари
                              </CardTitle>
                            </Col>

                            <Col>
                              <div className="text-right">
                                <Tooltip
                                  title="Добави нов коментар"
                                  placement="right-start"
                                >
                                  <button
                                    className="btn btn-primary waves-effect waves-light"
                                    onClick={(id) =>
                                      openModal("estate-comment")
                                    }
                                  >
                                    <i
                                      className="bx bx-plus-circle"
                                      style={{
                                        fontSize: "18px",
                                      }}
                                    />
                                  </button>
                                </Tooltip>
                              </div>
                            </Col>
                          </Row>

                          {tableBodyDataComments ? (
                            <TableComponent
                              dataHead={[
                                "Коментар",
                                "Дата",
                                i18next.t("actions"),
                              ]}
                              dataBody={tableBodyDataComments}
                              url_path={""}
                              category={""}
                              /* disableLink={true}*/
                              enableLinkDelete={true}
                              onDelete={onEstateCommentDelete}
                              delete_tooltip={i18next.t(
                                "tooltip-delete-comment"
                              )}
                              isLoaded={true}
                            />
                          ) : null}
                        </TabPane>
                        <TabPane tabId="9" className="p-3">
                          <Row className="mb-2">
                            <Col>
                              <CardTitle className="d-none mb-4">
                                Документи
                              </CardTitle>
                            </Col>

                            <Col>
                              <div className="text-right">
                                <input
                                  name="files_new"
                                  placeholder=""
                                  id="field-AS"
                                  type="file"
                                  multiple
                                  accept="
                                    image/jpeg,
                                    image/png,
                                    .pdf,
                                    .doc,
                                    .docx,
                                    "
                                  onChange={onFileChange}
                                  className="w-100"
                                />

                                {state.files_new.length > 0 && (
                                  <button
                                    type="button"
                                    className="btn btn-primary waves-effect waves-light"
                                    onClick={uploadFiles}
                                  >
                                    {i18next.t("upload_files_add_new_file")}
                                  </button>
                                )}
                              </div>
                            </Col>
                          </Row>

                          {tableBodyDataDocuments ? (
                            <TableComponent
                              dataHead={[
                                "Име",
                                "Дата на обработка",
                                "Показване на документа в нов прозорец",
                              ]}
                              dataBody={tableBodyDataDocuments}
                              url_path={`/building/${id}/documents`}
                              category={""}
                              disableLink={true}
                              actionDownload={true}
                              isLoaded={true}
                            />
                          ) : null}
                        </TabPane>
                        <TabPane tabId="10" className="p-3">
                          {/* Стари собственици */}
                          <Row className="mb-2">
                            <Col>
                              <CardTitle className="d-none mb-4">
                                Таблица с история на собствениците
                              </CardTitle>
                            </Col>
                          </Row>

                          {tableBodyDataOwnersHistory ? (
                            <TableComponent
                              dataHead={[
                                "Име",
                                "Телефон",
                                "Имейл",
                                "Дата От",
                                "Дата До",
                              ]}
                              dataBody={tableBodyDataOwnersHistory}
                              url_path={""}
                              category={""}
                              disableLink={true}
                              isLoaded={true}
                            />
                          ) : null}

                          {/* )} */}
                        </TabPane>
                        <TabPane tabId="11" className="p-3">
                          {/* Отчети */}
                          <Row className="mb-2">
                            <Col>
                              <div className="year-select d-flex align-items-center justify-content-center mb-4">
                                <button
                                  onClick={decrementHeatingSeason}
                                  disabled={
                                    state.heating_season_options &&
                                    state.heating_season_options.find(
                                      (item) =>
                                        state.heating_season &&
                                        item.toYear ===
                                          state.heating_season.toYear - 1
                                    ) === undefined
                                      ? true
                                      : false
                                  }
                                  style={{
                                    background: "none",
                                    fontSize: "0",
                                    border: "none",
                                  }}
                                >
                                  <i
                                    className="bx bx-left-arrow-alt"
                                    style={{
                                      fontSize: "18px",
                                    }}
                                  />
                                </button>
                                <h6 className="mb-0 mx-2">
                                  {state.heating_season &&
                                    state.heating_season.name}
                                </h6>
                                <button
                                  onClick={incrementHeatingSeason}
                                  disabled={
                                    state.heating_season_options &&
                                    state.heating_season_options.find(
                                      (item) =>
                                        state.heating_season &&
                                        item.toYear ===
                                          state.heating_season.toYear + 1
                                    ) === undefined
                                      ? true
                                      : false
                                  }
                                  style={{
                                    background: "none",
                                    fontSize: "0",
                                    border: "none",
                                  }}
                                >
                                  <i
                                    className="bx bx-right-arrow-alt"
                                    style={{
                                      fontSize: "18px",
                                    }}
                                  />
                                </button>
                              </div>
                            </Col>
                          </Row>

                          <Row className="mb-2">
                            <Col>
                              <CardTitle className="text-center mb-4">
                                {i18next.t("reports-water-meters")}
                              </CardTitle>
                            </Col>
                          </Row>

                          {tableBodyDataReportsWaterMeters && (
                            <TableReports
                              dataHead={[
                                i18next.t("report-column-number"),
                                i18next.t("report-column-value"),
                                i18next.t("report-column-yearly"),
                                i18next.t("report-column-report-date"),
                                i18next.t("report-column-visitation-date"),
                                i18next.t("actions"),
                              ]}
                              dataBody={tableBodyDataReportsWaterMeters}
                              isEditable={true}
                              editableCell={"value"}
                              url_path={""}
                              category={""}
                              emitRowSave={emitRowSave}
                              deviceType={4}
                              enableLinkDelete={true}
                              onDelete={onModalDeviceReportDelete}
                              delete_tooltip={i18next.t(
                                "tooltip-delete-report"
                              )}
                              isLoaded={true}
                            />
                          )}

                          <Row className="mb-2">
                            <Col>
                              <CardTitle className="text-center mb-4">
                                {i18next.t("reports-heat-meters")}
                              </CardTitle>
                            </Col>
                          </Row>

                          {tableBodyDataReportsHeatMeters && (
                            <TableReports
                              dataHead={[
                                i18next.t("report-column-number"),
                                i18next.t("report-column-value"),
                                i18next.t("report-column-yearly"),
                                i18next.t("report-column-report-date"),
                                i18next.t("report-column-visitation-date"),
                                i18next.t("actions"),
                              ]}
                              dataBody={tableBodyDataReportsHeatMeters}
                              isEditable={true}
                              editableCell={"value"}
                              url_path={""}
                              category={""}
                              emitRowSave={emitRowSave}
                              deviceType={2}
                              enableLinkDelete={true}
                              onDelete={onModalDeviceReportDelete}
                              delete_tooltip={i18next.t(
                                "tooltip-delete-report"
                              )}
                              isLoaded={true}
                            />
                          )}

                          <Row className="mb-2">
                            <Col>
                              <CardTitle className="text-center mb-4">
                                {i18next.t("reports-hca")}
                              </CardTitle>
                            </Col>
                          </Row>

                          {tableBodyDataReportsHCA && (
                            <TableReports
                              dataHead={[
                                i18next.t("report-column-number"),
                                i18next.t("report-column-value"),
                                i18next.t("report-column-yearly"),
                                i18next.t("report-column-report-date"),
                                i18next.t("report-column-visitation-date"),
                                i18next.t("actions"),
                              ]}
                              dataBody={tableBodyDataReportsHCA}
                              isEditable={true}
                              editableCell={"value"}
                              url_path={""}
                              category={""}
                              emitRowSave={emitRowSave}
                              deviceType={3}
                              enableLinkDelete={true}
                              onDelete={onModalDeviceReportDelete}
                              delete_tooltip={i18next.t(
                                "tooltip-delete-report"
                              )}
                              isLoaded={true}
                            />
                          )}
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {state.isLoaded && (
            <ModalEstimatedToploficationData
              isModalOpen={state.showModal === "estimated-toplofication-data"}
              closeModal={closeModal}
              update={() => closeAndUpdate(getEstimatedToploficationData)}
              estate_id={id}
              estate_fish={state.document.externalId}
              emitErrors={emitErrors}
              emitSuccess={emitSuccess}
            />
          )}

          <ModalWaterMeter
            isModalOpen={state.showModal === "water-meter"}
            closeModal={closeModal}
            update={() => closeAndUpdate(getAllWaterMeters)}
            estate_id={id}
            estate_fish={state.document.externalId}
            emitErrors={emitErrors}
            emitSuccess={emitSuccess}
          />

          <ModalHeatMeter
            isModalOpen={state.showModal === "heat-meter"}
            closeModal={closeModal}
            update={() => closeAndUpdate(getAllHeatMeters)}
            estate_id={id}
            estate_fish={state.document.externalId}
            emitErrors={emitErrors}
            emitSuccess={emitSuccess}
          />

          <ModalHeater
            isModalOpen={state.showModal === "heater"}
            closeModal={closeModal}
            update={() => closeAndUpdate(getAllHeaters)}
            estate_id={id}
            estate_fish={state.document.externalId}
            emitErrors={emitErrors}
            emitSuccess={emitSuccess}
          />

          <ModalEstateContact
            isModalOpen={state.showModal === "estate-contact"}
            closeModal={closeModal}
            update={() => closeAndUpdate(getAllContacts)}
            estate_id={id}
            estate_fish={state.document.externalId}
            emitErrors={emitErrors}
            emitSuccess={emitSuccess}
          />

          <ModalEstateComment
            isModalOpen={state.showModal === "estate-comment"}
            closeModal={closeModal}
            update={() => closeAndUpdate(getAllComments)}
            estate_id={id}
            estate_fish={state.document.externalId}
            emitErrors={emitErrors}
            emitSuccess={emitSuccess}
          />

          <ModalHeatCostAllocator
            isModalOpen={state.showModal === "hca"}
            closeModal={closeModal}
            update={() => closeAndUpdate(getAllHeaters)}
            heater_id={state.heaterId}
            estate_id={id}
            emitErrors={emitErrors}
            emitSuccess={emitSuccess}
          />

          <ConfirmBox
            confirm_delete={state.confirm_delete}
            confirm_toggle={state.confirm_toggle}
            confirm_save={state.confirm_save}
            success_dlg={state.success_dlg}
            cancelConfirmBox={cancelConfirmBox}
            closeAfterConfirmDelete={closeAfterConfirmDelete}
            closeAfterConfirmToggle={closeAfterConfirmToggle}
            closeAfterConfirmSave={closeAfterConfirmSave}
            confirmDelete={confirmDelete}
            confirmToggle={confirmToggle}
            confirmSave={confirmSave}
            isActive={state.document.isActive}
            actionType={state.actionType}
          />
        </Container>
      </div>
    </>
  );
};

EstateSingle.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EstateSingle));

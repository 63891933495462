import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Table } from "reactstrap";
import * as endpoints from "constants/endpoints";
import { GetData } from "../../helpers/CRUD.js";
import { str2bool } from "../../helpers/utils.js";
import i18next from "i18next";

const TableRadioModuleTypes = (props) => {
  const [state, setState] = useState({
    equipmentManufacturers: [],
    equipmentManufacturersRadioModules: [],
  });

  const handleDataChange = (event) => {
    const value = event.target.value;

    setState((state) => ({
      ...state,
      [event.target.name]: value,
    }));

    props.data[event.target.name] = value;
  };

  const handleEdit = () => {
    setState((state) => ({ ...state, mode: "edit" }));
  };

  const handleSave = () => {
    setState((state) => ({ ...state, mode: "view" }));
  };

  const toggleActive = (e) => {
    setState((state) => ({ ...state, isActive: str2bool(e.target.value) }));

    props.data.isActive = str2bool(e.target.value);
  };

  const getEquipmentManufacturers = () => {
    const url = `${endpoints.NOMENCLATURE_ENDPOINT}/EquipmentManufacturer/GetAll`;

    GetData(
      url,
      (data) => {
        const equipmentManufacturersRadioModules = data.items.filter((item) =>
          item.type.includes(i18next.t("radio-module"))
        );

        console.log(equipmentManufacturersRadioModules);

        setState((state) => ({
          ...state,
          equipmentManufacturers: data.items,
          equipmentManufacturersRadioModules,
        }));
      },
      (error) => console.log(error)
    );
  };

  useEffect(() => {
    setState((state) => ({ ...state, ...props.data }));
    getEquipmentManufacturers();
  }, []);

  const radioStyle = {
    listStyle: "none",
    paddingLeft: "0",
  };
  const labelStyle = {
    paddingLeft: "20px",
  };

  const tableStyle = {
    tableLayout: "fixed",
  };

  const {
    id,
    name,
    equipmentManufacturerId,
    equipmentManufacturer,
    equipmentManufacturerRadioModuleId,
    equipmentManufacturerRaidoModule,
    model,
  } = props.data;

  return (
    <>
      <div className="table-responsive">
        <Table className="table-nowrap mb-0" style={tableStyle}>
          <tbody>
            <tr>
              <th>{i18next.t("name")}</th>

              <td>
                {props.mode === "edit" ? (
                  <input
                    name="name"
                    placeholder={i18next.t("placeholder_enter_name")}
                    id="radio_module_type_name"
                    onChange={(e) => handleDataChange(e)}
                    defaultValue={name}
                    className="w-100 input-styled"
                  />
                ) : (
                  name
                )}
              </td>
            </tr>

            <tr>
              <th>{i18next.t("manufacture")}</th>

              <td>
                {props.mode === "edit" ? (
                  <select
                    name="equipmentManufacturerId"
                    id="radio_module_type_еquipment_manufacturer"
                    onChange={(e) => handleDataChange(e)}
                    value={equipmentManufacturerId}
                    className="w-100 input-styled"
                  >
                    <option
                      value="0"
                      selected={equipmentManufacturerId === "" ? true : false}
                    >
                      {i18next.t("select_equipment_manufacturer")}
                    </option>
                    {state.equipmentManufacturers.length > 0 &&
                      state.equipmentManufacturers.map(
                        (еquipmentManufacturer) => (
                          <option
                            key={`option-${еquipmentManufacturer.id}`}
                            value={еquipmentManufacturer.id}
                            selected={
                              equipmentManufacturerId ===
                              еquipmentManufacturer.id
                                ? true
                                : false
                            }
                          >
                            {еquipmentManufacturer.name}
                          </option>
                        )
                      )}
                  </select>
                ) : (
                  props.data.equipmentManufacturer &&
                  props.data.equipmentManufacturer.name
                )}
              </td>
            </tr>

            <tr>
              <th>{i18next.t("deviceManufacturerRadioModule")}</th>

              <td>
                {props.mode === "edit" ? (
                  <select
                    name="equipmentManufacturerRadioModuleId"
                    id="radio_module_type_еquipment_manufacturer_radio_module"
                    onChange={(e) => handleDataChange(e)}
                    value={equipmentManufacturerRadioModuleId}
                    className="w-100 input-styled"
                  >
                    <option
                      value="0"
                      selected={
                        equipmentManufacturerRadioModuleId === "" ? true : false
                      }
                    >
                      {i18next.t("select_equipment_manufacturer_radio_module")}
                    </option>

                    {state.equipmentManufacturersRadioModules.length > 0 &&
                      state.equipmentManufacturersRadioModules.map(
                        (еquipmentManufacturer) => (
                          <option
                            key={`option-radio-module-${еquipmentManufacturer.id}`}
                            value={еquipmentManufacturer.id}
                            selected={
                              equipmentManufacturerRadioModuleId ===
                              еquipmentManufacturer.id
                                ? true
                                : false
                            }
                          >
                            {еquipmentManufacturer.name}
                          </option>
                        )
                      )}
                  </select>
                ) : (
                  props.data.equipmentManufacturerRadioModule &&
                  props.data.equipmentManufacturerRadioModule.name
                )}
              </td>
            </tr>

            <tr>
              <th>{i18next.t("model")}</th>

              <td>
                {props.mode === "edit" ? (
                  <input
                    name="model"
                    placeholder={i18next.t("placeholder_enter_model")}
                    id="radio_module_type_model"
                    onChange={(e) => handleDataChange(e)}
                    defaultValue={model}
                    className="w-100 input-styled"
                  />
                ) : (
                  model
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
};

TableRadioModuleTypes.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TableRadioModuleTypes));

export function authHeader() {
  var obj = JSON.parse(localStorage.getItem("authUser"));

  if (obj && obj.token) {
    return `Bearer ${obj.token}`;
  } else {
    //return {};
    this.props.history.push("/login");
  }
}

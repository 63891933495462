import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { Modal, Button } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import * as endpoints from "constants/endpoints";
import * as jsonpatch from "fast-json-patch";
import { CreateItem, GetData } from "../../helpers/CRUD.js";
import { heater_type_types } from "helpers/listsHardcodedData";
import i18next from "i18next";

const ModalHeaterType = (props) => {
  const old_state = {
    nomEquipmentManufacturers: [],
    toggleSwitch: true,
  };

  const [state, setState] = useState({
    ...old_state,
    document: {
      name: "",
      type: "",
      equipmentManufacturerId: null,
      size1: "",
      size2: "",
      size3: "",
      pow: "",
      ksK1: "",
      ksK2: "",
      ksK3: "",
      ksK4: "",
    },
  });

  const getInitialState = (old_state) => ({
    ...old_state,
    document: {
      name: "",
      type: "",
      equipmentManufacturerId: null,
      size1: "",
      size2: "",
      size3: "",
      pow: "",
      ksK1: "",
      ksK2: "",
      ksK3: "",
      ksK4: "",
    },
  });

  const resetData = () => {
    setState(getInitialState(state));
  };

  const toggleSwitch = () => {
    setState((state) => ({ ...state, toggleSwitch: !state.toggleSwitch }));
  };

  const handleValidSubmit = (event, values) => {
    if (state.toggleSwitch === true) {
      const document = state.document;

      document.name = `${state.document.name} ${state.document.size1}/${state.document.size2}/${state.document.size3}`;

      setState((state) => ({ ...state, document }));
    }

    let patch = jsonpatch.compare(getInitialState().document, state.document);

    const url = `${endpoints.NOMENCLATURE_ENDPOINT}/HeaterType/Add`;

    CreateItem(
      url,
      patch,
      (data) => {
        update();
        props.emitSuccess(i18next.t("entry_added_successfully"));
      },
      (error) => {
        props.emitErrors(error);
      }
    );
  };

  const closeModal = () => {
    props.closeModal();
    resetData();
  };

  const update = () => {
    props.update();
    resetData();
  };

  const getNomEquipmentManufacturers = () => {
    const url = `${endpoints.NOMENCLATURE_ENDPOINT}/EquipmentManufacturer/GetAllByDeviceType?deviceType=Отоплителни тела`;

    GetData(
      url,
      (data) => {
        const filtered = data.filter((item) =>
          item.type.includes(i18next.t("heater"))
        );

        setState((state) => ({
          ...state,
          nomEquipmentManufacturers: filtered,
        }));
      },
      (error) => console.log(error)
    );
  };

  useEffect(() => {
    getNomEquipmentManufacturers();
  }, []);

  const heater_type_types_list = heater_type_types();

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Modal isOpen={props.isModalOpen} scrollable={true}>
            <div className="modal-header">
              <h5 className="modal-title mt-0">
                {i18next.t("modal_heater_type_title")}
              </h5>

              <button
                type="button"
                onClick={() => closeModal()}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <Card className="mb-0">
                <CardBody>
                  <AvForm
                    onValidSubmit={handleValidSubmit}
                    className="needs-validation"
                  >
                    <Row className="mb-4">
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="heater_type_name">
                            {i18next.t("name")}
                          </Label>

                          <AvField
                            name="document.name"
                            placeholder={i18next.t("placeholder_enter_name")}
                            id="heater_type_name"
                            value={state.document.name}
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  name: e.target.value,
                                },
                              }))
                            }
                            className="w-100"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="heater_type_name">
                            {" "}
                            {i18next.t("generate-name")}
                          </Label>
                          <div
                            className="custom-control custom-switch mb-0 mt-0"
                            dir="ltr"
                          >
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="customSwitchsizesm"
                              checked={state.toggleSwitch}
                              onChange={() => {
                                toggleSwitch();
                              }}
                            />
                            <label
                              className="custom-control-label mb-0"
                              htmlFor="customSwitchsizesm"
                            ></label>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="heater_type_type">
                            {i18next.t("label-heater-type")}
                          </Label>

                          <AvField
                            type="select"
                            name="document.type"
                            id="heater_type_type"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  type: e.target.value,
                                },
                              }))
                            }
                            defaultValue={state.document.type}
                            className="w-100"
                          >
                            <option
                              value=""
                              selected={
                                state.document.type === "" ? true : false
                              }
                            >
                              {i18next.t("select_heater_type")}
                            </option>
                            {heater_type_types_list &&
                              heater_type_types_list.length > 0 &&
                              heater_type_types_list.map((type) => (
                                <option
                                  key={type.key}
                                  value={type.key}
                                  selected={
                                    state.document.type === type.key
                                      ? true
                                      : false
                                  }
                                >
                                  {type.value}
                                </option>
                              ))}
                          </AvField>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="heater_type_еquipment_manufacturer">
                            {i18next.t("manufacture")}
                          </Label>

                          <AvField
                            type="select"
                            name="document.equipmentManufacturerId"
                            id="heater_type_еquipment_manufacturer"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  equipmentManufacturerId: e.target.value,
                                },
                              }))
                            }
                            defaultValue={
                              state.document.equipmentManufacturerId
                            }
                            className="w-100"
                          >
                            <option
                              value=""
                              selected={
                                state.document.equipmentManufacturerId === ""
                                  ? true
                                  : false
                              }
                            >
                              {i18next.t("select_equipment_manufacturer")}
                            </option>
                            {state.nomEquipmentManufacturers &&
                              state.nomEquipmentManufacturers.length > 0 &&
                              state.nomEquipmentManufacturers.map(
                                (еquipmentManufacturer) => (
                                  <option
                                    key={`option-${еquipmentManufacturer.id}`}
                                    value={еquipmentManufacturer.id}
                                    selected={
                                      state.document.equipmentManufacturerId ===
                                      еquipmentManufacturer.id
                                        ? true
                                        : false
                                    }
                                  >
                                    {еquipmentManufacturer.name}
                                  </option>
                                )
                              )}
                          </AvField>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="heater_type_size1">Size 1</Label>

                          <AvField
                            name="document.size1"
                            placeholder={i18next.t("placeholder_enter_size")}
                            id="heater_type_size1"
                            value={state.document.size1}
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  size1: e.target.value,
                                },
                              }))
                            }
                            className="w-100"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="heater_type_size2">Size 2</Label>

                          <AvField
                            name="document.size2"
                            type="number"
                            step={1}
                            min={0}
                            placeholder={i18next.t("placeholder_enter_size")}
                            id="heater_type_size2"
                            value={state.document.size2}
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  size2: e.target.value,
                                },
                              }))
                            }
                            className="w-100"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="heater_type_size3">Size 3</Label>

                          <AvField
                            name="document.size3"
                            type="number"
                            step={1}
                            min={0}
                            placeholder={i18next.t("placeholder_enter_size")}
                            id="heater_type_size3"
                            value={state.document.size3}
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  size3: e.target.value,
                                },
                              }))
                            }
                            className="w-100"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="heater_type_pow">
                            {i18next.t("power")}
                          </Label>

                          <AvField
                            name="document.pow"
                            type="number"
                            step={0.1}
                            min={0}
                            placeholder={i18next.t("placeholder_enter_power")}
                            id="heater_type_pow"
                            value={state.document.pow}
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  pow: e.target.value,
                                },
                              }))
                            }
                            className="w-100"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="heater_type_ksK1">
                            {i18next.t("KCK 1")}
                          </Label>

                          <AvField
                            name="document.ksK1"
                            type="number"
                            step={0.1}
                            min={0}
                            placeholder={i18next.t("placeholder_enter_size")}
                            id="heater_type_ksK1"
                            value={state.document.ksK1}
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  ksK1: e.target.value,
                                },
                              }))
                            }
                            className="w-100"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="heater_type_ksK2">
                            {i18next.t("KCK 2")}
                          </Label>

                          <AvField
                            name="document.ksK2"
                            type="number"
                            step={0.1}
                            min={0}
                            placeholder={i18next.t("placeholder_enter_size")}
                            id="heater_type_ksK2"
                            value={state.document.ksK2}
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  ksK2: e.target.value,
                                },
                              }))
                            }
                            className="w-100"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="heater_type_ksK3">
                            {i18next.t("KCK 3")}
                          </Label>

                          <AvField
                            name="document.ksK3"
                            type="number"
                            step={0.1}
                            min={0}
                            placeholder={i18next.t("placeholder_enter_size")}
                            id="heater_type_ksK3"
                            value={state.document.ksK3}
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  ksK3: e.target.value,
                                },
                              }))
                            }
                            className="w-100"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="heater_type_ksK4">
                            {i18next.t("KCK 4")}
                          </Label>

                          <AvField
                            name="document.ksK4"
                            type="number"
                            step={0.1}
                            min={0}
                            placeholder={i18next.t("placeholder_enter_size")}
                            id="heater_type_ksK4"
                            value={state.document.ksK4}
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  ksK4: e.target.value,
                                },
                              }))
                            }
                            className="w-100"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <div className="modal-footer align-items-center justify-content-between">
                      <div>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => closeModal()}
                        >
                          <i className="fa fa-times mr-1" />
                          {i18next.t("button_cancel")}
                        </button>

                        <Button color="primary" type="submit" className="ml-2">
                          <i className="fa fa-plus-circle mr-1" />
                          {i18next.t("button_add")}
                        </Button>
                      </div>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </div>
          </Modal>
        </Container>
      </div>
    </>
  );
};

ModalHeaterType.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUsers: () => dispatch(getUsers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ModalHeaterType));

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Modal, FormGroup, Label, Button } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as endpoints from "constants/endpoints";
import { statuses } from "helpers/listsHardcodedData";
import * as jsonpatch from "fast-json-patch";
import { GetData, CreateItem } from "../../helpers/CRUD.js";
import i18next from "i18next";

const ModalHeater = (props) => {
  const old_state = {
    nomRoomTypes: [],
    nomHeaterTypes: [],
    all_heat_cost_allocators: [],
  };

  const [state, setState] = useState({
    ...old_state,
    document: {
      name: "",
      roomTypeId: null,
      heaterTypeId: null,
      valueOne: 0,
      valueTwo: 0,
      heatCostAllocatorId: null,
      status: "",
      demountingDate: "",
      comment: "",
      isActive: false,
    },
  });

  const getInitialState = (old_state) => ({
    ...old_state,
    document: {
      name: "",
      roomTypeId: null,
      heaterTypeId: null,
      valueOne: 0,
      valueTwo: 0,
      heatCostAllocatorId: null,
      status: "",
      demountingDate: "",
      comment: "",
      isActive: false,
    },
  });

  const resetData = () => {
    setState(getInitialState(state));
  };

  const handleAutoCompleteChangeHeaterType = (event, item) => {
    setState((state) => ({
      ...state,
      document: {
        ...state.document,
        heaterTypeId: item.id,
      },
      nomHeaterType: item.type,
      nomHeaterTypeName: item.name,
    }));
  };

  const handleValidSubmit = (event, values) => {
    let patch = jsonpatch.compare(getInitialState().document, state.document);

    if (props.estate_id) {
      patch.push({
        op: "replace",
        path: "/estateId",
        value: props.estate_id,
      });
    }

    if (props.shared_part_id) {
      patch.push({
        op: "replace",
        path: "/sharedPartId",
        value: props.shared_part_id,
      });
    }

    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/Heater/Add`;

    CreateItem(
      url,
      patch,
      (data) => {
        update();
        props.emitSuccess(i18next.t("entry_added_successfully"));
      },
      (error) => {
        props.emitErrors(error);
      }
    );
  };

  const closeModal = () => {
    props.closeModal();
    resetData();
  };

  const update = () => {
    props.update();
    resetData();
  };

  const getNomRoomTypes = () => {
    const url = `${endpoints.NOMENCLATURE_ENDPOINT}/RoomType/GetAll`;

    GetData(
      url,
      (data) => {
        setState((state) => ({ ...state, nomRoomTypes: data.items }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getNomHeaterTypes = () => {
    const url = `${endpoints.NOMENCLATURE_ENDPOINT}/HeaterType/GetAll`;

    GetData(
      url,
      (data) => {
        setState((state) => ({ ...state, nomHeaterTypes: data.items }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getAllHeatCostAllocators = () => {
    const url = `${endpoints.BUILDING_UNITS_REPORT_ENTITIES_ENDPOINT}/HeatCostAllocator/GetAll`;

    GetData(
      url,
      (data) => {
        setState((state) => ({
          ...state,
          all_heat_cost_allocators: data.items,
        }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    getNomRoomTypes();
    getNomHeaterTypes();
    getAllHeatCostAllocators();
  }, []);

  const nomHeaterTypeName = state.document.nomHeaterTypeName;
  const statuses_list = statuses();

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Modal isOpen={props.isModalOpen} scrollable={true}>
            <div className="modal-header">
              <h5 className="modal-title mt-0">
                {props.shared_part_id !== undefined &&
                  `${i18next.t("modal_heater_title")} ${i18next.t(
                    "to_shared_part"
                  )} ${props.shared_part_id}`}
                {props.estate_fish !== undefined &&
                  `${i18next.t("modal_heater_title")} ${i18next.t(
                    "to_estate"
                  )} ${props.estate_fish}`}
              </h5>

              <button
                type="button"
                onClick={() => closeModal()}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <Card className="mb-0">
                <CardBody>
                  <AvForm
                    onValidSubmit={handleValidSubmit}
                    className="needs-validation"
                  >
                    <Row className="mb-4">
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="heater_room_type">
                            {i18next.t("room")}
                          </Label>

                          <AvField
                            type="select"
                            name="document.roomTypeId"
                            id="heater_room_type"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  roomTypeId: e.target.value,
                                },
                              }))
                            }
                            className="w-100"
                          >
                            <option
                              key={`room-type-default`}
                              value=""
                              selected={state.document.roomTypeId === ""}
                            >
                              {i18next.t("select_room_type")}
                            </option>
                            {state.nomRoomTypes.length > 0 &&
                              state.nomRoomTypes.map((option) => (
                                <option
                                  key={`room-type-${option.id}`}
                                  value={option.id}
                                >
                                  {option.name}
                                </option>
                              ))}
                          </AvField>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Col md="12">
                        <div className="form_controls">
                          <label>{i18next.t("type")}</label>

                          <Autocomplete
                            options={state.nomHeaterTypes}
                            renderOption={(option) => <>{option.name}</>}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  {...params}
                                  label={
                                    nomHeaterTypeName
                                      ? nomHeaterTypeName
                                      : i18next.t("choose-type")
                                  }
                                  variant="outlined"
                                  fullWidth
                                />
                              );
                            }}
                            onChange={(e, option) =>
                              handleAutoCompleteChangeHeaterType(e, option)
                            }
                          />
                        </div>
                      </Col>
                    </Row>

                    {(state.nomHeaterType ===
                      i18next.t("heater_type_cast_iron") ||
                      state.nomHeaterType ===
                        i18next.t("heater_type_aluminum")) && (
                      <Row className="mb-4">
                        <Col md="12">
                          <FormGroup>
                            <Label htmlFor="heater_gliders_count">
                              {i18next.t("heater_gliders_count")}
                            </Label>

                            <AvField
                              type="text"
                              name="document.valueOne"
                              placeholder={i18next.t(
                                "placeholder_enter_gliders_count"
                              )}
                              id="heater_gliders_count"
                              onChange={(e) =>
                                setState((state) => ({
                                  ...state,
                                  document: {
                                    ...state.document,
                                    valueOne: e.target.value,
                                  },
                                }))
                              }
                              value={state.document.valueOne}
                              className="w-100"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    )}
                    {(state.nomHeaterType ===
                      i18next.t("heater_type_lyre_tube") ||
                      state.nomHeaterType ===
                        i18next.t("heater_type_lyre_pipe")) && (
                      <Row className="mb-4">
                        <Col md="12">
                          <FormGroup>
                            <Label htmlFor="heater_linear_meter">
                              {i18next.t("heater_linear_meter")}
                            </Label>

                            <AvField
                              type="text"
                              name="document.valueOne"
                              placeholder={i18next.t(
                                "placeholder_enter_linear_meter"
                              )}
                              id="heater_linear_meter"
                              onChange={(e) =>
                                setState((state) => ({
                                  ...state,
                                  document: {
                                    ...state.document,
                                    valueOne: e.target.value,
                                  },
                                }))
                              }
                              value={state.document.valueOne}
                              className="w-100"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    )}
                    {state.nomHeaterType ===
                      i18next.t("heater_type_lyre_tube") && (
                      <Row className="mb-4">
                        <Col md="12">
                          <Label htmlFor="heater_sections_count">
                            {i18next.t("heater_sections_count")}
                          </Label>
                          <AvField
                            type="text"
                            name="document.valueTwo"
                            placeholder={i18next.t(
                              "placeholder_enter_sections_count"
                            )}
                            id="heater_sections_count"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  valueTwo: e.target.value,
                                },
                              }))
                            }
                            value={state.document.valueTwo}
                            className="w-100"
                          />
                        </Col>
                      </Row>
                    )}

                    <Row className="mb-4">
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="heater_status">
                            {i18next.t("status")}
                          </Label>

                          <AvField
                            type="select"
                            name="document.status"
                            id="heater_status"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  status: e.target.value,
                                },
                              }))
                            }
                            className="w-100"
                          >
                            <option
                              key={`status-default`}
                              value=""
                              selected={state.document.status === ""}
                            >
                              {i18next.t("select_status")}
                            </option>
                            {statuses_list &&
                              statuses_list.length > 0 &&
                              statuses_list.map((option) => (
                                <option
                                  key={`status-${option.id}`}
                                  value={option.key}
                                  selected={
                                    state.document.status === option.key
                                  }
                                >
                                  {option.value}
                                </option>
                              ))}
                          </AvField>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="heater_demounting_date">
                            {i18next.t("demounting_date")}
                          </Label>

                          <AvField
                            type="date"
                            name="document.demountingDate"
                            id="heater_demounting_date"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  demountingDate: e.target.value,
                                },
                              }))
                            }
                            defaultValue={state.document.demountingDate}
                            className="w-100"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="heater_comment">
                            {i18next.t("field_comment")}
                          </Label>
                          <AvField
                            type="textarea"
                            name="document.comment"
                            placeholder={i18next.t("placeholder_enter_comment")}
                            id="heater_comment"
                            maxLength="200"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  comment: e.target.value,
                                },
                              }))
                            }
                            value={state.document.comment}
                            className="w-100"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => closeModal()}
                      >
                        <i className="fa fa-times mr-1" />
                        {i18next.t("button_cancel")}
                      </button>

                      <Button color="primary" type="submit">
                        <i className="fa fa-plus-circle mr-1" />
                        {i18next.t("button_add")}
                      </Button>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </div>
          </Modal>
        </Container>
      </div>
    </>
  );
};

ModalHeater.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUsers: () => dispatch(getUsers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ModalHeater));

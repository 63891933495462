import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, useParams } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import TableReportPeriodSW from "../../components/TableDetailed/TableReportPeriodSW.js";
import BackButton from "../../components/Common/BackButton.js";
import NomenclatureSingleWrapper from "../../components/Common/NomenclatureSingleWrapper.js";
import * as endpoints from "constants/endpoints";

const ReportPeriodSWSingle = (props) => {
  const [pageTitle, setPageTitle] = useState("Отчетен период на ВиК дружество");
  const [mode, setMode] = useState("view");
  const [document, setDocument] = useState({});
  const [loggedUserType, setLoggedUserType] = useState("");

  const { id } = useParams();

  const emitErrors = (error) => {
    props.onErrorsShow(error.body);
  };

  const emitSuccess = (success) => {
    props.onSuccessShow(success);
  };

  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    setLoggedUserType(obj.role);
  }, []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl="12">
              <BackButton className="mb-4" />

              <Card>
                <CardBody>
                  <NomenclatureSingleWrapper
                    childComponent={
                      <TableReportPeriodSW
                        data={document}
                        mode={mode}
                      ></TableReportPeriodSW>
                    }
                    getUrl={`${endpoints.NOMENCLATURE_ENDPOINT}/ReportPeriodSofiaWater/GetById?id=${id}`}
                    saveUrl={`${endpoints.NOMENCLATURE_ENDPOINT}/ReportPeriodSofiaWater/Update?id=${id}`}
                    toggleUrl={`${endpoints.NOMENCLATURE_ENDPOINT}/ReportPeriodSofiaWater/ToggleActive?id=${id}`}
                    deleteUrl={`${endpoints.NOMENCLATURE_ENDPOINT}/ReportPeriodSofiaWater/Delete?id=${id}`}
                    page_title={pageTitle}
                    emitErrors={emitErrors}
                    emitSuccess={emitSuccess}
                  ></NomenclatureSingleWrapper>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

ReportPeriodSWSingle.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUsers: () => dispatch(getUsers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ReportPeriodSWSingle));

import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Table } from "reactstrap";
import { translateMonthEnumValue } from "helpers/translateHardcodedData";
import { months } from "helpers/listsHardcodedData";
import { str2bool } from "../../helpers/utils.js";
import i18next from "i18next";

const TableServicePrice = (props) => {
  const [state, setState] = useState({});

  const handleDataChange = (event) => {
    const value = event.target.value;

    setState((state) => ({
      ...state,
      [event.target.name]: value,
    }));

    props.data[event.target.name] = value;
  };

  const handleEdit = () => {
    setState((state) => ({ ...state, mode: "edit" }));
  };

  const handleSave = () => {
    setState((state) => ({ ...state, mode: "view" }));
  };

  const toggleActive = (e) => {
    setState((state) => ({ ...state, isActive: str2bool(e.target.value) }));

    props.data.isActive = str2bool(e.target.value);
  };

  useEffect(() => {
    setState((state) => ({ ...state, ...props.data }));
  }, []);

  const radioStyle = {
    listStyle: "none",
    paddingLeft: "0",
  };
  const labelStyle = {
    paddingLeft: "20px",
  };

  const tableStyle = {
    tableLayout: "fixed",
  };

  const {
    id,
    startMonth,
    startYear,
    endMonth,
    endYear,
    pricePerHeatCostAllocator,
    pricePerWaterMeter,
    pricePerHeatMeter,
    pricePerEstate,
  } = props.data;

  const months_list = months();

  return (
    <>
      <div className="table-responsive">
        <Table className="table-nowrap mb-0" style={tableStyle}>
          <tbody>
            <tr>
              <th>{i18next.t("start-period")}</th>

              <td>
                {props.mode === "edit" ? (
                  <div className="d-flex">
                    <div className="form_group">
                      <select
                        name="startMonth"
                        id="startMonth"
                        onChange={(e) => handleDataChange(e)}
                        value={startMonth}
                        className="w-100 input-styled"
                      >
                        <option
                          value=""
                          selected={startMonth === "" ? true : false}
                        >
                          -- {i18next.t("choose-month")} --
                        </option>
                        {months_list &&
                          months_list.length > 0 &&
                          months_list.map((month) => (
                            <option
                              value={month.key}
                              selected={startMonth === month.key ? true : false}
                            >
                              {month.value}
                            </option>
                          ))}
                      </select>
                    </div>

                    <div className="form_group ml-2">
                      <input
                        type="number"
                        min={1990}
                        max={2099}
                        name="startYear"
                        placeholder={i18next.t("placeholder_enter_year")}
                        id="startYear"
                        onChange={(e) => handleDataChange(e)}
                        defaultValue={startYear}
                        className="w-100 input-styled"
                      />
                    </div>
                  </div>
                ) : (
                  `${translateMonthEnumValue(startMonth)} ${startYear}`
                )}
              </td>
            </tr>

            <tr>
              <th>{i18next.t("end-period")}</th>

              <td>
                {props.mode === "edit" ? (
                  <div className="d-flex">
                    <div className="form_group">
                      <select
                        name="endMonth"
                        id="endMonth"
                        onChange={(e) => handleDataChange(e)}
                        value={endMonth}
                        className="w-100 input-styled"
                      >
                        <option
                          value=""
                          selected={endMonth === "" ? true : false}
                        >
                          -- {i18next.t("choose-month")} --
                        </option>
                        {months_list &&
                          months_list.length > 0 &&
                          months_list.map((month) => (
                            <option
                              value={month.key}
                              selected={endMonth === month.key ? true : false}
                            >
                              {month.value}
                            </option>
                          ))}
                      </select>
                    </div>

                    <div className="form_group ml-2">
                      <input
                        type="number"
                        min={1990}
                        max={2099}
                        name="endYear"
                        placeholder={i18next.t("placeholder_enter_year")}
                        id="endYear"
                        onChange={(e) => handleDataChange(e)}
                        defaultValue={endYear}
                        className="w-100 input-styled"
                      />
                    </div>
                  </div>
                ) : (
                  `${translateMonthEnumValue(endMonth)} ${endYear}`
                )}
              </td>
            </tr>

            <tr>
              <th>{i18next.t("pricePerHeatCostAllocator")}</th>

              <td>
                {props.mode === "edit" ? (
                  <div className="form_group">
                    <input
                      type="number"
                      step={0.01}
                      min={0}
                      name="pricePerHeatCostAllocator"
                      placeholder={i18next.t("placeholder_enter_price_hca")}
                      id="pricePerHeatCostAllocator"
                      onChange={(e) => handleDataChange(e)}
                      defaultValue={pricePerHeatCostAllocator}
                      className="w-100 input-styled"
                    />
                  </div>
                ) : (
                  pricePerHeatCostAllocator
                )}
              </td>
            </tr>

            <tr>
              <th>{i18next.t("pricePerWaterMeter")}</th>

              <td>
                {props.mode === "edit" ? (
                  <div className="form_group">
                    <input
                      type="number"
                      step={0.01}
                      min={0}
                      name="pricePerWaterMeter"
                      placeholder={i18next.t(
                        "placeholder_enter_price_watermeter"
                      )}
                      id="pricePerWaterMeter"
                      onChange={(e) => handleDataChange(e)}
                      defaultValue={pricePerWaterMeter}
                      className="w-100 input-styled"
                    />
                  </div>
                ) : (
                  pricePerWaterMeter
                )}
              </td>
            </tr>

            <tr>
              <th>{i18next.t("pricePerHeatMeter")}</th>

              <td>
                {props.mode === "edit" ? (
                  <div className="form_group">
                    <input
                      type="number"
                      step={0.01}
                      min={0}
                      name="pricePerHeatMeter"
                      placeholder={i18next.t(
                        "placeholder_enter_price_heatmeter"
                      )}
                      id="pricePerHeatMeter"
                      onChange={(e) => handleDataChange(e)}
                      defaultValue={pricePerHeatMeter}
                      className="w-100 input-styled"
                    />
                  </div>
                ) : (
                  pricePerHeatMeter
                )}
              </td>
            </tr>

            <tr>
              <th>{i18next.t("pricePerEstate")}</th>

              <td>
                {props.mode === "edit" ? (
                  <div className="form_group">
                    <input
                      type="number"
                      step={0.01}
                      min={0}
                      name="pricePerEstate"
                      placeholder={i18next.t("placeholder_enter_price_estate")}
                      id="pricePerEstate"
                      onChange={(e) => handleDataChange(e)}
                      defaultValue={pricePerEstate}
                      className="w-100 input-styled"
                    />
                  </div>
                ) : (
                  pricePerEstate
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
};

TableServicePrice.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
};

const mapStateToProps = (state) => ({
  //userProfile: contacts.userProfile
});

const mapDispatchToProps = (dispatch) => ({
  // onGetUserProfile: () => dispatch(getUserProfile())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TableServicePrice));

import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";
import i18next from "i18next";

const FilteringBar = (props) => {
  const handleIsActiveChange = (e) => {
    const value = e.target.value;
    props.setState((state) => ({ ...state, isActive: value }));
  };

  const handleCategoryChange = (e) => {
    const value = e.target.value;
    props.setState((state) => ({
      ...state,
      categoryFilter: value !== "all" ? value : "",
    }));
  };

  const activity_options = [
    {
      label: i18next.t("option_active"),
      value: true,
    },
    {
      label: i18next.t("option_inactive"),
      value: false,
    },
  ];

  return (
    <>
      <div className="form-filters">
        <Row className="mb-4">
          {props.filterByCategory && (
            <Col md="4">
              <div className="form_controls">
                <label>{i18next.t("category")}</label>

                <select
                  name="selected_category"
                  id="select_selected_category"
                  onChange={(e) => handleCategoryChange(e)}
                  className="w-100 input-styled"
                  value={props.state.categoryFilter}
                >
                  <option value="all">{i18next.t("show_all")}</option>

                  {props.categories_options.map((option) => (
                    <option
                      key={`option-categories_options${option.key}`}
                      value={option.key}
                    >
                      {option.value}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
          )}

          {props.isActive && (
            <Col md="4">
              <div className="form_controls">
                <label>{i18next.t("condition")}</label>

                <select
                  name="selected_activity"
                  id="select_selected_activity"
                  onChange={(e) => handleIsActiveChange(e)}
                  className="w-100 input-styled"
                  value={props.state.isActive}
                >
                  {activity_options.map((option) => (
                    <option
                      key={`activity_options${option.value}`}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
          )}
        </Row>
      </div>
    </>
  );
};

FilteringBar.propTypes = {
  title: PropTypes.string,
};

export default FilteringBar;

import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Col, Container, Row, Label, FormGroup } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import BarPageTitle from "../../components/Common/BarPageTitle";
import { AvField, AvForm } from "availity-reactstrap-validation";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LinkView from "components/Common/LinkView";
import LinkCalculations from "components/Common/LinkCalculations";
import Pagination from "components/Common/Pagination";
import * as endpoints from "constants/endpoints";
import {FileDownload, GetData, PostData} from "../../helpers/CRUD.js";
import { throttle } from "lodash";
import i18next from "i18next";
import Loader from "../../components/Common/Loader";

const BillManagementMonth = (props) => {
  const [state, setState] = useState({
    buildings_all: [],
    currentHeatingSeason: "",
    months: [],
    selectedMonth: "",
    selectedAddressId: "",
    selectedBuildingId: "",
    tableData: [],
    page: 1,
    totalCount: null,
    pageSize: 100,
    historyIds: []
  });

  const emitErrors = (error) => {
    setState((state) => ({ ...state, isLoaded: false, isLoading: false }));
    props.onErrorsShow(error.body);
  };

  const emitSuccess = (success) => {
    setState((state) => ({ ...state, isLoaded: true, isLoading: false }));
    props.onSuccessShow(success);
  };

  const updatePage = (page) => {
    setState((state) => ({ ...state, page }));
  };

  const getCurrentHeatingSeason = () => {
    const url = `${endpoints.NOMENCLATURE_ENDPOINT}/HeatingSeason/GetWorkingHeatingSeason`;

    GetData(
      url,
      (data) => {
        setState((state) => ({ ...state, currentHeatingSeason: data.id }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getCurrentHeatingMonth = () => {
    const url = `${endpoints.NOMENCLATURE_ENDPOINT}/HeatingMonth/GetWorkingHeatingMonth`;

    GetData(
      url,
      (data) => {
        setState((state) => ({
          ...state,
          selectedMonth: {
            month: data.month,
            year: data.year,
            label: `${i18next.t(data.name.toLowerCase())}/${data.year}`,
          },
        }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getAllHeatingMonths = () => {
    const url = `${endpoints.NOMENCLATURE_ENDPOINT}/HeatingMonth/GetAllByHeatingSeasonId?id=${state.currentHeatingSeason}`;

    GetData(
      url,
      (data) => {
        const allMonths = data.map(
          (obj) =>
            obj !== {} && {
              month: obj.month,
              year: obj.year,
              isLocked: obj.isLocked,
              label: `${i18next.t(obj.name.toLowerCase())}/${obj.year}`,
            }
        );
        setState((state) => ({ ...state, months: allMonths }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getAllBuildings = () => {
    const url = `${endpoints.BUILDING}/GetAll`;

    GetData(
      url,
      (data) => {
        setState((state) => ({ ...state, buildings_all: data.items }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const filterBuildings = (value) => {
    let endpointQueryParam = "";

    if (value) {
      endpointQueryParam = `?filterText=${value}`;
    }

    const url = `${endpoints.BUILDING}/GetAll${endpointQueryParam}`;

    GetData(
      url,
      (data) => {
        setState((state) => ({ ...state, buildings_all: data.items }));
      },
      (error) => {
        console.log(error);
      }
    );
  };


  const filterBuildingsThrottled = throttle(filterBuildings, 800);

  const setAddress = (event) => {
    filterBuildings(event.target.outerText);
  };

  const handleAutoCompleteChangeBuilding = (event, item) => {
    if (item !== null) {
      setState((state) => ({
        ...state,
        selectedBuildingId: item.id,
      }));
    } else {
      setState((state) => ({
        ...state,
        selectedBuildingId: "",
      }));
    }
  };

  const getTableData = () => {
    const url = `${endpoints.CALCULATIONS}/GetAllBuildingsHistoryPerMonth/${state.selectedMonth.year}/${state.selectedMonth.month}?addressId=${state.selectedAddressId}&buildingId=${state.selectedBuildingId}&page=${state.page}&size=${state.pageSize}`;

    GetData(
      url,
      (data) => {
        setState((state) => ({
          ...state,
          tableData: data.items,
          totalCount: data.totalCount,
        }));
      },
      (error) => {
        emitErrors(error);
      }
    );
  };

  const addToChecked = (id, e) =>
  {
    let arr = state.historyIds;

    if(e.target.checked){
      arr.push(id);
      setState((state) => ({
        ...state,
        historyIds: arr,
         }));
    }else{
      const index = arr.indexOf(id);
      if (index > -1) {
        arr.splice(index, 1);
      }

      setState((state) => ({
        ...state,
        historyIds: arr,
      }));
    }
  }

  const selectAll = (e) =>
  {

    let arr = [];

    if(e.target.checked){
      state.tableData.forEach(element => {
        arr.push(element.id);
      });
    }

    setState((state) => ({
      ...state,
      historyIds: arr,
    }));
  }

  const recalculate = () => {
    setState((state) => ({ ...state,  isLoaded: false, isLoading: true }));
    const url = `${endpoints.CALCULATIONS}/RecalculateBulk`;

    PostData(
        url,
        state.historyIds,
        (data) => {
          emitSuccess(i18next.t("calculation_done_successfully_multiple_buildings"));
        },
        (error) => {
          emitErrors(error);
        }
    );
  }

  const confirm = () => {
    setState((state) => ({ ...state, isLoaded: false,  isLoading: true }));
    const url = `${endpoints.CALCULATIONS}/Confirm`;

    PostData(
        url,
        state.historyIds,
        (data) => {
          emitSuccess(i18next.t("calculation_confirmed_successfully_multiple_buildings"));
        },
        (error) => {
          emitErrors(error);
        }
    );
  }

  const downloadFiles = () => {
    setState((state) => ({ ...state,  isLoaded: false, isLoading: true }));
    let queryString = Object.keys(state.historyIds).map(key => 'buildingsToDownloadFiles' + '=' + state.historyIds[key]).join('&');

    const url = `${endpoints.EXPORT}/DownloadFiles/${state.selectedMonth.year}/${state.selectedMonth.month}?${queryString}`;

    FileDownload(
      url,
      (blob) => {
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");

        link.href = downloadUrl;
        link.setAttribute(
          "download",
          `Monthly files-${state.selectedMonth.month}${state.selectedMonth.year}`
        ); //any other extension

        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(link);
        setState((state) => ({ ...state, isLoaded: true, isLoading: false }));
      },
      (error) => {
        emitErrors(error);
      }
    );
  };

  const openLogErrors = () => {
    console.log("openLogErrors")
  }

  useEffect(() => {
    state.currentHeatingSeason && getAllHeatingMonths();
  }, [state.currentHeatingSeason]);

  useEffect(() => {
    getCurrentHeatingSeason();
    getCurrentHeatingMonth();
  }, []);

  useEffect(() => {
    getAllBuildings();
  }, [state.isLoaded]);

  useEffect(() => {
    state.selectedMonth && getTableData();
  }, [state.selectedMonth, state.isLoaded]);

  const tableHead = {
    backgroundColor: "#F0F0F0",
    borderColor: "#C8C8C8",
    verticalAlign: "middle",
  };

  const tableBody = {
    borderColor: "#C8C8C8",
  };

  const tableData = {
    color: "black",
  };

  const tableDataInactive = {
    color: "darkgray",
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          {state.isLoading && !state.isLoaded && <Loader showLoader={true} />}

          <BarPageTitle pageTitle={i18next.t("monthly-bill-management")} />
          <AvForm className="form-horizontal">
            <Row className="mb-5 justify-content-end">
              <button
                  className="btn bill-management-btn"
                  style={{ background: "#D9EC00" }}
                  onClick={recalculate}
                  disabled={state.historyIds.length === 0}
                  type="button"
              >
                <i className="fas fa-calculator mr-1" />
                {i18next.t("btn-recalculate")}
              </button>
              <button
                className="btn bill-management-btn"
                style={{ background: "#EBFEE5" }}
                onClick={confirm}
                disabled={state.historyIds.length === 0}
                type="button"
              >
                <i className="fa fa-times mr-1" />
                {i18next.t("placeholder_end")}
              </button>
              <button
                className="btn bill-management-btn"
                style={{ background: "#E7E5FE" }}
                onClick={downloadFiles}
                disabled={state.historyIds.length === 0}
                type="button"
              >
                <i className="fa fa-download mr-1" />
                {i18next.t("download-files")}
              </button>
              <button
                className="btn bill-management-btn"
                style={{ background: "#FDFBC3" }}
                onClick={openLogErrors}
                type="button"
              >
                <i className="fa fa-exclamation-triangle mr-1" />
                {i18next.t("log-errors")}
              </button>
            </Row>
            <Row className="align-items-end mb-4">
              <Col md="3">
                <div className="form_controls">
                  <Label htmlFor="bill_month_selection" className="">
                    {i18next.t("month")}
                  </Label>
                  <FormGroup>
                    <Autocomplete
                      value={state.selectedMonth}
                      options={state.months}
                      renderOption={(option) => option.isLocked === true
                          ? (<span style={tableDataInactive}>
                                <i className="fa fa-lock"/>
                                &nbsp;
                                {option.label}
                              </span>)
                          : (<span style={tableData}>
                              <i className="fa fa-lock-open"/>
                              &nbsp;
                              {option.label}
                            </span>)}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            label={i18next.t("choose-month")}
                            variant="outlined"
                            name="selectedMonth"
                            fullWidth
                          />
                        );
                      }}
                      onChange={(event, item) => {
                        setState((state) => ({
                          ...state,
                          selectedMonth: item,
                        }));
                      }}
                    />
                  </FormGroup>
                </div>
              </Col>
              <Col md="3">
                <Label htmlFor="address_selection" className="">
                  {i18next.t("address")}
                </Label>
                <FormGroup>
                  <Autocomplete
                    options={state.buildings_all}
                    renderOption={(option) => <>{option.address.streetName}</>}
                    getOptionLabel={(option) => option.address.streetName}
                    getOptionSelected={(option) => option.address.streetName}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          label={i18next.t("choose-address")}
                          variant="outlined"
                          name="address_selection"
                          fullWidth
                          onChange={(e) =>
                            filterBuildingsThrottled(e.target.value)
                          }
                        />
                      );
                    }}
                    onChange={(event, value) => {
                      setAddress(event, value);
                      value
                        ? setState((state) => ({
                            ...state,
                            selectedAddressId: value.address.id,
                          }))
                        : setState((state) => ({
                            ...state,
                            selectedAddressId: "",
                          }));
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <Label htmlFor="external_id_selection" className="">
                  {i18next.t("building")}
                </Label>
                <FormGroup>
                  <Autocomplete
                    options={state.buildings_all}
                    renderOption={(option) => <>{option.externalId}</>}
                    getOptionLabel={(option) => option.externalId}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          label={i18next.t("choose-building")}
                          variant="outlined"
                          name="external_id_selection"
                          fullWidth
                          onChange={(e) =>
                            filterBuildingsThrottled(e.target.value)
                          }
                        />
                      );
                    }}
                    onChange={handleAutoCompleteChangeBuilding}
                  />
                </FormGroup>
              </Col>
              <Col md={{ offset: 1, size: 2 }}>
                <button
                  style={{ marginBottom: "1rem" }}
                  className="btn btn-primary btn-block waves-effect waves-light"
                  onClick={getTableData}
                  type="button"
                  disabled={!state.selectedMonth}
                >
                  <i className="fa fa-search mr-1" />
                  {i18next.t("placeholder_search")}
                </button>
              </Col>
            </Row>
            <Row>
              <Table
                className="table scrollable table-bordered"
                style={{ tableLayout: "fixed" }}
              >
                <Thead>
                  <Tr>
                    <Th style={tableHead}>
                      <div className="d-flex">
                        <AvField
                          type="checkbox"
                          name="checkAll"
                          // checked={}
                          // value={}
                          onChange={(e) => {selectAll(e)}}
                          id="checkAll"
                        />
                        {i18next.t("select_all")}
                      </div>
                    </Th>
                    <Th style={tableHead}>{i18next.t("building")}</Th>
                    <Th style={tableHead}>{i18next.t("address")}</Th>
                    <Th style={tableHead}>{i18next.t("percentageBuilding")}</Th>
                    <Th style={tableHead}>{i18next.t("SRShotWater")}</Th>
                    <Th style={tableHead}>{i18next.t("unit")}</Th>
                    <Th style={tableHead}>
                      {i18next.t("reportCommonWaterMeter")}
                    </Th>
                    <Th style={tableHead}>
                      {i18next.t("individualWatermetersSum")}
                    </Th>
                    <Th style={tableHead}>
                      {i18next.t("coefficientCommonWaterMeter")}
                    </Th>
                    <Th style={tableHead}>{i18next.t("status")}</Th>
                    <Th style={tableHead}>{i18next.t("actions")}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {state.tableData && state.tableData.length === 0 ? (
                    <Tr>
                      <Td
                        colSpan={11}
                        className="align-items-center text-center"
                        style={tableBody}
                      >
                        {i18next.t("table_missing_data")}
                      </Td>
                    </Tr>
                  ) : (
                      state.tableData && state.tableData.map((row, i) => (
                      <Tr key={`period-${row.id}`}>
                        <Td style={tableBody}>
                          <AvField
                            type="checkbox"
                            name="checkTableItem"
                            // checked={}
                            // value={}
                            onChange={(e) => {addToChecked(row.id, e)}}
                            id="checkTableItem"
                          />
                        </Td>
                        <Td style={tableBody} className="text-right">
                          {row.buildingExternalId}
                        </Td>
                        <Td style={tableBody}>{row.address}</Td>
                        <Td style={tableBody} className="text-right">
                          {row.buildingInstallationPercent.toFixed(6)}
                        </Td>
                        <Td style={tableBody} className="text-right">
                          {row.quantityHotWater.toFixed(6)}
                        </Td>
                        <Td style={tableBody} className="text-right">
                          {row.heatingEnergyPerUnit.toFixed(6)}
                        </Td>
                        <Td style={tableBody} className="text-right">
                          {row.globalWaterReadingDifference}
                        </Td>
                        <Td style={tableBody} className="text-right">
                          {row.sumWaterMetersConsumption.toFixed(2)}
                        </Td>
                        <Td style={tableBody} className="text-right">
                          {row.correctionCoefficient.toFixed(6)}
                        </Td>
                        <Td style={tableBody} className="text-center">
                          {row.calculationHistoryStatus === 1 && (
                            <div
                              style={{
                                width: 23,
                                height: 23,
                                background: "#B80000",
                                display: "inline-block",
                              }}
                            ></div>
                          )}
                          {row.calculationHistoryStatus === 2 && (
                            <div
                              style={{
                                width: 23,
                                height: 23,
                                background: "#D07E03",
                                display: "inline-block",
                              }}
                            ></div>
                          )}
                          {row.calculationHistoryStatus === 3 && (
                            <div
                              style={{
                                width: 23,
                                height: 23,
                                background: "#EDD60A",
                                display: "inline-block",
                              }}
                            ></div>
                          )}
                          {row.calculationHistoryStatus === 4 && (
                            <div
                              style={{
                                width: 23,
                                height: 23,
                                background: "#04B800",
                                display: "inline-block",
                              }}
                            ></div>
                          )}
                        </Td>
                        <Td style={tableBody}>
                          <div className="d-flex align-items-center">
                            <LinkCalculations
                              link={`/monthly-detailed-calculations/${row.id}`}
                              id={row.id}
                            ></LinkCalculations>
                            <LinkView
                              link={`/monthly-history/${row.id}`}
                              id={row.id}
                            ></LinkView>
                          </div>
                        </Td>
                      </Tr>
                    ))
                  )}
                </Tbody>
              </Table>
            </Row>
            <Row className="justify-content-center">
              <Pagination
                updatePage={updatePage}
                page={state.page}
                totalCount={state.totalCount}
                pageSize={state.pageSize}
              />
            </Row>
          </AvForm>
        </Container>
      </div>
    </>
  );
};

BillManagementMonth.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BillManagementMonth));

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, useLocation } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Modal, FormGroup, Label } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { getUsers } from "store/contacts/actions";
import * as endpoints from "constants/endpoints";
import { GetData } from "../../helpers/CRUD.js";
import i18next from "i18next";

const ModalReport = (props) => {
  const location = useLocation();

  const [state, setState] = useState({
    zones: [],
    zoneId: "",
    sharedParts: [],
    sharedPartId: "",
    estates: [],
    estateId: "",
    zoneSelected: false,
    buildingLoaded: false,
    buildingId: 0,
    building_top: "",
  });

  const handleSubmit = (event, errors, values) => {
    setState((state) => ({ ...state, errors, values }));
  };

  const handleValidSubmit = (event, values) => {
    setState((state) => ({ ...state, values }));
  };

  const handleDataChange = (event) => {
    const value = event.target.value;

    setState((state) => ({
      ...state,
      [event.target.name]: value,
    }));
  };

  const handleSelectChange = (event) => {
    const value = event.target.value;

    setState((state) => ({
      ...state,
      [event.target.name]: value,
      zoneSelected: true,
    }));
  };

  useEffect(() => {
    state.zoneSelected && getAdditionalData();
  }, [state.zoneSelected]);

  const addSharedPartsReport = () => {
    const currentLocation = window.location.href;
    const newLocation = currentLocation.replace(location.pathname, "");

    window.open(
      `${newLocation}/reportAdd/shared-parts/${state.sharedPartId}`,
      "_blank"
    );
  };

  const addEstateReport = () => {
    const currentLocation = window.location.href;
    const newLocation = currentLocation.replace(location.pathname, "");

    window.open(`${newLocation}/reportAdd/estate/${state.estateId}`, "_blank");
  };

  const getBuildingById = (id) => {
    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/Building/GetById?id=${id}`;

    GetData(
      url,
      (data) => {
        setState((state) => ({
          ...state,
          building_top: data.externalId,
          buildingLoaded: true,
        }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getZones = () => {
    const url = `${endpoints.ZONE}/GetAllByBuildingId?id=${state.buildingId}&isActive=true`;

    GetData(
      url,
      (data) => {
        setState((state) => ({ ...state, zones: data, zonesLoaded: true }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getSharedParts = () => {
    const url = `${endpoints.SHAREDPART}/GetAllByZoneId?id=${state.zoneId}&isActive=true`;

    GetData(
      url,
      (data) => {
        setState((state) => ({
          ...state,
          sharedParts: data,
          sharedPartsLoaded: true,
        }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getAllEstates = () => {
    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/Estate/GetAllByZoneId?id=${state.zoneId}&isActive=true`;

    GetData(
      url,
      (data) => {
        setState((state) => ({ ...state, estates: data, estatesLoaded: true }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getAdditionalData = () => {
    getAllEstates();
    getSharedParts();
  };

  const getData = () => {
    getBuildingById(props.building_id);
    getZones();
  };

  useEffect(() => {
    state.buildingLoaded && getData();
  }, [state.buildingLoaded]);

  const closeModal = () => {
    props.closeModal();
  };

  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const buildingId = props.building_id;

    setState((state) => ({
      ...state,
      loggedUserType: obj.role,
      buildingId: buildingId,
      buildingLoaded: true,
    }));
  }, []);

  return (
    <>
      <Modal isOpen={props.isModalOpen} scrollable={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            {i18next.t("modal_building_report")} {state.building_top}
          </h5>

          <button
            type="button"
            onClick={() => closeModal()}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <Card className="mb-0">
            <CardBody>
              <AvForm className="needs-validation">
                {state.zones.length > 0 && (
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <Label htmlFor="validationZoneSelect">
                          {i18next.t("zone_select")}
                        </Label>
                        <AvField
                          type="select"
                          value={state.zoneId}
                          onChange={(e) => handleSelectChange(e)}
                          name="zoneId"
                          id="validationZoneSelect"
                        >
                          <option
                            value=""
                            selected={state.zoneId === "" ? true : false}
                          >
                            {i18next.t("select_zone")}
                          </option>

                          {state.zones.map((option) => (
                            <option
                              key={`zone-${option.id}`}
                              value={option.id}
                              selected={
                                state.zoneId === option.id ? true : false
                              }
                            >
                              {option.name}
                            </option>
                          ))}
                        </AvField>
                      </FormGroup>
                    </Col>
                  </Row>
                )}

                {state.zoneId && (
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label htmlFor="validationSharedPart">
                          {i18next.t("shared_part_select")}
                        </Label>
                        <AvField
                          type="select"
                          value={state.sharedPartId}
                          onChange={(e) => handleDataChange(e)}
                          name="sharedPartId"
                          id="validationSharedPart"
                        >
                          <option
                            value=""
                            selected={state.sharedPartId === "" ? true : false}
                          >
                            {i18next.t("select_shared_part")}
                          </option>

                          {state.sharedParts.map((option) => (
                            <option
                              key={`shared-part-${option.id}`}
                              value={option.id}
                              selected={
                                state.sharedPartId === option.id ? true : false
                              }
                            >
                              {option.comment}
                            </option>
                          ))}
                        </AvField>
                      </FormGroup>
                    </Col>

                    <Col md="6">
                      <FormGroup>
                        <Label htmlFor="validationEstate">
                          {i18next.t("estate_select")}
                        </Label>
                        <AvField
                          type="select"
                          value={state.estateId}
                          onChange={(e) => handleDataChange(e)}
                          name="estateId"
                          id="validationEstate"
                        >
                          <option
                            value=""
                            selected={state.estateId === "" ? true : false}
                          >
                            {i18next.t("select_estate")}
                          </option>

                          {state.estates.map((option) => (
                            <option
                              key={`estate-${option.id}`}
                              value={option.id}
                              selected={
                                state.estateId === option.id ? true : false
                              }
                            >
                              {option.name} ({i18next.t("estate_externalId")}{" "}
                              {option.externalId})
                            </option>
                          ))}
                        </AvField>
                      </FormGroup>
                    </Col>
                  </Row>
                )}

                <div className="modal-footer d-block">
                  <Row>
                    <Col md="6">
                      {state.sharedPartId && (
                        <button
                          className="btn btn-primary waves-effect waves-light d-block"
                          onClick={addSharedPartsReport}
                        >
                          <i className="fa fa-plus-circle mr-1" />
                          {i18next.t("add_report_shared_part")}
                        </button>
                      )}
                    </Col>

                    <Col md="6">
                      {state.estateId && (
                        <button
                          className="btn btn-primary waves-effect waves-light d-block"
                          onClick={addEstateReport}
                        >
                          <i className="fa fa-plus-circle mr-1" />
                          {i18next.t("add_report_estate")}
                        </button>
                      )}
                    </Col>
                  </Row>
                </div>
              </AvForm>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </>
  );
};

ModalReport.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUsers: () => dispatch(getUsers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ModalReport));

import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import BarPageTitle from "../../components/Common/BarPageTitle";
import TableComponent from "components/Common/TableComponent";
import { TextField, Tooltip } from "@material-ui/core";
import { throttle } from "lodash";
import ModalReport from "components/Modals/ModalReport";
import { GetData } from "../../helpers/CRUD.js";
import i18next from "i18next";
import Pagination from "components/Common/Pagination";
import PaginationSelect from "components/Common/PaginationSelect";
import * as endpoints from "constants/endpoints";

const Reports = (props) => {
  const [state, setState] = useState({
    pageTitle: i18next.t("tootltip-report-add"),
    showModal: null,
    buildingId: "",
    buildings: [],
    page: 1,
    totalCount: null,
    pageSize: 100,
    sorterLabel: "",
    sorterDirection: "",
  });

  const emitErrors = (error) => {
    props.onErrorsShow(error.body);
  };

  const openModal = (id) => {
    setState((state) => ({ ...state, showModal: id }));
  };

  const closeModal = () => {
    setState((state) => ({ ...state, showModal: null, buildingId: "" }));
    removeBodyCss();
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };

  const onBuildingSelect = (modalName, id) => {
    setState((state) => ({ ...state, buildingId: id }));
    openModal(modalName);
  };

  const filterBuildings = (value) => {
    let endpointQueryParam = "";
    let endpointSortAdd = "";

    if (value) {
      endpointQueryParam = `filterText=${value}`;
    }

    if (state.sorterLabel !== "") {
      endpointSortAdd = `&orderBy=${state.sorterLabel}_${state.sorterDirection}`;
    }

    const url = `${endpoints.BUILDING}/GetAll?${endpointQueryParam}&page=${state.page}&size=${state.pageSize}${endpointSortAdd}`;

    GetData(
      url,
      (data) => {
        let tableBodyData;

        if (data) {
          tableBodyData = data.items.map((item) => {
            return {
              id: item.id,
              data: {
                number: item.externalId,
                address:
                  item.address && item.address.streetName
                    ? `${item.address.streetName} ${item.address.streetCode}`
                    : "",
                isActive: item.isActive,
              },
            };
          });
        }

        setState((state) => ({
          ...state,
          buildings: tableBodyData,
          totalCount: data.totalCount,
        }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const filterBuildingsThrottled = throttle(filterBuildings, 800);

  const updatePage = (page) => {
    setState((state) => ({ ...state, page }));
  };

  const onPageSizeUpdate = (pageSize) => {
    setState((state) => ({ ...state, pageSize, page: 1 }));
  };

  const onSorterClick = (label, direction) => {
    setState((state) => ({
      ...state,
      page: 1,
      sorterLabel: label,
      sorterDirection: direction,
    }));
  };

  useEffect(() => {
    filterBuildings();
  }, [state.page, state.pageSize]);

  console.log(state);
  return (
    <>
      <div className="page-content">
        <Container fluid>
          <BarPageTitle pageTitle={state.pageTitle} />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col>
                      <Tooltip
                        title="Търсете по Абонатен номер или Адрес"
                        placement="right-start"
                      >
                        <TextField
                          InputLabelProps={{
                            style: {
                              pointerEvents: "auto",
                            },
                          }}
                          placeholder={i18next.t("placeholder_search")}
                          variant="outlined"
                          type="text"
                          size="small"
                          onChange={(e) =>
                            filterBuildingsThrottled(e.target.value)
                          }
                        />
                      </Tooltip>
                    </Col>

                    <Col md="2" className="ml-auto mr-0">
                      <PaginationSelect
                        pageSize={state.pageSize}
                        index={1}
                        onPageSizeUpdate={onPageSizeUpdate}
                      />
                    </Col>
                  </Row>

                  {state.buildings ? (
                    <TableComponent
                      dataHead={[
                        i18next.t("subscriber-number"),
                        i18next.t("address"),
                        i18next.t("actions"),
                      ]}
                      dataBody={state.buildings}
                      sorterData={["externalId", "address.streetName"]}
                      onSorterClick={onSorterClick}
                      url_path={"buildings"}
                      category={"buildings"}
                      disableLink={true}
                      actionReport={true}
                      onBuildingSelect={onBuildingSelect}
                      isLoaded={true}
                    />
                  ) : null}

                  <Pagination
                    updatePage={updatePage}
                    page={state.page}
                    pageSize={state.pageSize}
                    totalCount={state.totalCount}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          {state.buildingId !== "" && (
            <ModalReport
              isModalOpen={state.showModal === "report" ? true : false}
              closeModal={closeModal}
              building_id={state.buildingId}
              emitErrors={emitErrors}
            ></ModalReport>
          )}
        </Container>
      </div>
    </>
  );
};

Reports.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUsers: () => dispatch(getUsers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Reports));

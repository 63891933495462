import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { Card, CardBody, FormGroup, Label } from "reactstrap";
import {
  translateTypeEnumValue,
  translateDeviceLocationEnumValue,
  translateHeaterTypeEnumValue,
} from "helpers/translateHardcodedData";
import { Tooltip } from "@material-ui/core";
import * as endpoints from "constants/endpoints";
import * as jsonpatch from "fast-json-patch";
import { cloneDeep } from "lodash";
import {SaveItem, CreateItem, GetData} from "../../helpers/CRUD.js";
import { conditions } from "../../helpers/listsHardcodedData";
import i18next from "i18next";
import {BUILDING_UNITS_ADDITIONAL_ENDPOINT, REPORTS_ENDPOINT} from "constants/endpoints";

const reportAddBox = (props) => {
  const [state, setState] = useState({
    mode: "edit",
    isReportSent: false,
    document: {
      watermeterValue: "",
      currentHeat: "",
      memoryHeat: "",
    },
    documentStatus: {
      status: "",
    },
    currentReport: {

    },
    previousReport: {

    },
    nextReport: {

    }
  });

  const handleSubmit = (event, errors, values) => {
    setState((state) => ({ ...state, errors, values }));
  };

  const createReport = () => {
    console.log("props.report: ", props.report);
    console.log("props.device: ", props.device);
    let deviceMapPatch = jsonpatch.compare(state.initialState, state.document);

    let urlAddition = state.isReportSent ? "/Update" : "/Add";
    const url = `${endpoints.REPORT_DEVICE_MAP}${urlAddition}`;

    deviceMapPatch.push({
      op: "replace",
      path: "/reportId",
      value: props.reportId,
    });

    deviceMapPatch.push({
      op: "replace",
      path: "/deviceId",
      value: props.device.id,
    });

    deviceMapPatch.push({
      op: "replace",
      path: "/reportDate",
      value: props.report.reportDate,
    });

    deviceMapPatch.push({
      op: "replace",
      path: "/heatingSeasonId",
      value: props.report.heatingSeasonId,
    });

    console.log(props.device)

    deviceMapPatch.push({
      op: "replace",
      path: "/estateId",
      value: props.device && props.device.estateId,
    });

    if(props.report.isYearReport === true) {
      deviceMapPatch.push({
        op: "replace",
        path: "/isYearReport",
        value: props.report.isYearReport,
      });
    }

    let deviceStatusPatch = null;

    if(state.documentStatus.status !== "") {
      deviceStatusPatch = jsonpatch.compare(
          state.initialStateStatus,
          state.documentStatus
      );

      deviceStatusPatch.push({
        op: "add",
        path: "/reportId",
        value: props.reportId,
      });

      deviceStatusPatch.push({
        op: "add",
        path: "/deviceId",
        value: props.device.id,
      });

      deviceStatusPatch.push({
        op: "add",
        path: "/status",
        value: state.documentStatus.status,
      });

      deviceStatusPatch.push({
        op: "add",
        path: "/fromDate",
        value: props.visitationDate,
      });
    }

    const patch = {
      deviceTypeId: props.device.deviceTypeId,
      reportDeviceMapPatch: deviceMapPatch,
      deviceStatusPatch: deviceStatusPatch,
      reportDate: props.report.reportDate,
    }

    if (state.isReportSent) {
      SaveItem(
        `${url}?reportId=${props.reportId}&deviceId=${props.device.id}`,
          patch,
        (data) => {
          setState((state) => ({ ...state, mode: "view" }));
        },
        (error) => {
          props.emitErrors(error);
        }
      );
    } else {
      CreateItem(
        url,
          patch,
        (data) => {
          props.emitSuccess(i18next.t("entry_added_successfully"));
          setState((state) => ({ ...state, mode: "view", isReportSent: true }));
        },
        (error) => {
          console.log(error);
          props.emitErrors(error);
        }
      );
    }
  };

  const handleValidSubmit = (event, values) => {
    createReport();
  };

  const changeView = (e) => {
    e.preventDefault();
    setState((state) => ({ ...state, mode: "edit" }));
  };

  useEffect(() => {
    const initialState = cloneDeep(state.document);
    const initialStateStatus = cloneDeep(state.documentStatus);
    getDeviceOtherReports();
    setState((state) => ({ ...state, initialState }));
    setState((state) => ({ ...state, initialStateStatus }));
  }, []);

  const getDeviceOtherReports = () => {
    console.log(props.device.id);
    console.log(props.report.reportDate);
    console.log(props.report.reportDate.toJSON().slice(0,10).replace(/-/g,'-'));
    const dateFormatted = props.report.reportDate.toJSON().slice(0,10).replace(/-/g,'-');
    const url = `${endpoints.REPORTS_ENDPOINT}/ReportDeviceMap/GetOtherReports?deviceId=${props.device.id}&reportDate=${dateFormatted}`;

    GetData(
        url,
        (data) => {
          console.log("previous and next report", data);

          setState((state) => ({
            ...state,
            currentReport: data.currentReport,
            previousReport: data.previousReport,
            nextReport: data.nextReport,
          }));
        },
        (error) => {
          props.onErrorsShow(error.body);
        }
    );
  }
  const conditions_list = conditions();
  let conditionsByType = [];

  switch (props.type) {
    case "watermeter": {
      conditionsByType = conditions_list.filter((item) =>
        item.categories.includes("water-meter")
      );
      break;
    }
    case "hca": {
      conditionsByType = conditions_list.filter((item) =>
        item.categories.includes("heat-cost-allocator")
      );
      break;
    }
    case "heatmeter": {
      conditionsByType = conditions_list.filter((item) =>
        item.categories.includes("heat-meter")
      );
      break;
    }
  }

  const showReport = (reportData, labelReportValue) =>
    {
      return reportData && (
        <>
          <p>
            <u><b>{labelReportValue}{i18next.t("reportedValueFromDate")}</b>&nbsp;{reportData.report && reportData.report.reportDate}</u>
          </p>
          {props.type === "watermeter" ? (
              <p>
                <b>{i18next.t("reportValue")}:</b>&nbsp;{reportData.waterMeterValue}
              </p>
          ) : (
              <>
                <p>
                  <b>{i18next.t("reportValue")}:</b>&nbsp;{reportData.currentHeat}
                </p>
                {props.type === "hca" && (
                    <p>
                      <b>{i18next.t("memoryValue")}:</b>&nbsp;{reportData.memoryHeat}
                    </p>
                )}
              </>
          )}
        </>
    );
  }


  return (
    <>
      <Card style={{ flexGrow: 1 }}>
        <CardBody>
          <AvForm
            className="needs-validation"
            onSubmit={handleSubmit}
            onValidSubmit={handleValidSubmit}
          >
            {props.type === "watermeter" && (
              <h5>{i18next.t("watermeter_single")}</h5>
            )}
            {props.type === "hca" && <h5>{i18next.t("hca_single")}</h5>}

            {props.type === "heatmeter" && (
              <h5>{i18next.t("heatmeter_single")}</h5>
            )}


            {(props.type === "watermeter" || props.type === "heatmeter") && (
              <p>
                <b>{i18next.t("room")}:</b>&nbsp;
                {translateDeviceLocationEnumValue(
                    props.device.deviceLocation
                )}
              </p>
            )}

            {props.type === "hca" && (
              <p>
                <b>{i18next.t("room")}:</b>&nbsp;{props.reportEntity.roomType.name}
              </p>
            )}

            <p>
              <b>{i18next.t("field_serial_number")}:</b>&nbsp;{props.device.serialNumber}
            </p>

            {props.type === "watermeter" && (
              <p>
                <b>{i18next.t("type")}:</b>&nbsp;
                {translateTypeEnumValue(props.device.deviceSubType)}
              </p>
            )}
            {props.type === "hca" && (
              <p>
                <b>{i18next.t("type")}:</b>&nbsp;
                {translateHeaterTypeEnumValue(
                  props.reportEntity.heaterType.type
                )}
              </p>
            )}
            {props.type === "heatmeter" && (
              <p>
                <b>{i18next.t("kind")}:</b>&nbsp;
                {props.reportEntity.heatMeterType
                  ? props.reportEntity.heatMeterType.name
                  : ""}
              </p>
            )}

            {/* show current, previous and next reports */}

            {showReport(state.previousReport ,i18next.t("previousReportValue"))}
            {showReport(state.currentReport, i18next.t("currentReportValue"))}
            {showReport(state.nextReport, i18next.t("nextReportValue"))}

            {
              <FormGroup>
                <Label htmlFor="device_status">
                  {i18next.t("deviceStatuses")}
                </Label>

                <AvField
                  type="select"
                  name="documentStatus.status"
                  id="device_status"
                  onChange={(e) =>
                    setState((state) => ({
                      ...state,
                      documentStatus: {
                        ...state.documentStatus,
                        status: e.target.value,
                      },
                    }))
                  }
                 /* value={state.documentStatus.status}*/
                    value={props.type !== "hca" ? props.device && props.device.deviceStatuses && props.device.deviceStatuses.find(item => item.toDate === null).status
                    : props.device.device && props.device.device.deviceStatuses && props.device.device.deviceStatuses.find(item => item.toDate === null).status }
                  className="w-100"
                >
                  {conditionsByType &&
                  conditionsByType.length > 0 &&
                  conditionsByType.map((option) => (
                      <option
                        value={option.key}
                        selected={
                          state.documentStatus.status === option.key
                            ? true
                            : false
                        }
                      >
                        {option.value}
                      </option>
                    ))}
                </AvField>
              </FormGroup>
            }

            {props.type === "watermeter" && (
              <FormGroup>
                <Label htmlFor={`watermeterValue-${props.device.id}`}>
                  {i18next.t("indication")}
                </Label>

                {state.mode === "edit" ? (
                  <AvField
                    type="number"
                    name="document.watermeterValue"
                    id={`watermeterValue-${props.device.id}`}
                    onChange={(e) =>
                      setState((state) => ({
                        ...state,
                        document: {
                          ...state.document,
                          watermeterValue: e.target.value,
                        },
                      }))
                    }
                    defaultValue={state.document.watermeterValue}
                    className="w-100"
                  />
                ) : (
                  <p>{state.document.watermeterValue}</p>
                )}
              </FormGroup>
            )}

            {(props.type === "hca" || props.type === "heatmeter") && (
              <FormGroup>
                <Label htmlFor={`currentHeat-${props.device.id}`}>
                  {i18next.t("indication")}
                </Label>

                {state.mode === "edit" ? (
                  <AvField
                    type="number"
                    name="document.currentHeat"
                    id={`currentHeat-${props.device.id}`}
                    onChange={(e) =>
                      setState((state) => ({
                        ...state,
                        document: {
                          ...state.document,
                          currentHeat: e.target.value,
                        },
                      }))
                    }
                    defaultValue={state.document.currentHeat}
                    className="w-100"
                  />
                ) : (
                  <p>{state.document.currentHeat}</p>
                )}
              </FormGroup>
            )}
            {console.log(props.device)}

            {props.type === "hca" && props.device.zeroingMonth !== 12 && (
              <FormGroup>
                <Label htmlFor={`memoryHeat-${props.device.id}`}>memory</Label>

                {state.mode === "edit" ? (
                  <AvField
                    type="number"
                    name="document.memoryHeat"
                    id={`memoryHeat-${props.device.id}`}
                    onChange={(e) =>
                      setState((state) => ({
                        ...state,
                        document: {
                          ...state.document,
                          memoryHeat: e.target.value,
                        },
                      }))
                    }
                    defaultValue={state.document.memoryHeat}
                    className="w-100"
                  />
                ) : (
                  <p>{state.document.memoryHeat}</p>
                )}
              </FormGroup>
            )}

            {state.mode === "edit" ? (
              <Tooltip
                title={i18next.t("tooltip-save")}
                placement="right-start"
              >
                <button
                  className="ml-2"
                  style={{
                    border: "none",
                    background: "none",
                    fontSize: "0",
                  }}
                  type="submit"
                >
                  <i
                    style={{
                      fontSize: 0,
                    }}
                  >
                    <svg
                      width="18"
                      height="18"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g fill="#000" fillRule="evenodd">
                        <path d="M5.633 3.321h6.714c.2 0 .364-.163.364-.363V0H5.269v2.958c0 .2.163.363.364.363z" />
                        <path
                          d="M17.699 4.523L13.909.718v2.24c0 .861-.7 1.562-1.562 1.562H5.633a1.564 1.564 0 01-1.562-1.562V0H1.65A1.65 1.65 0 000 1.65v14.68c0 .91.739 1.65 1.65 1.65h14.68c.91 0 1.65-.74 1.65-1.65V5.202c0-.255-.102-.5-.281-.68zm-2.516 9.673c0 .532-.431.963-.964.963H3.76a.963.963 0 01-.962-.963v-5.02c0-.53.43-.962.963-.962h10.46c.532 0 .963.432.963.963v5.02z"
                          fillRule="nonzero"
                        />
                        <path d="M13.123 9.76H4.857a.599.599 0 100 1.198h8.266a.599.599 0 100-1.199zM11.924 12.157H6.056a.599.599 0 100 1.198h5.868a.6.6 0 100-1.198z" />
                      </g>
                    </svg>
                  </i>
                </button>
              </Tooltip>
            ) : (
              <Tooltip
                title={i18next.t("tooltip-edit")}
                placement="right-start"
              >
                <button
                  className="ml-2"
                  style={{
                    border: "none",
                    background: "none",
                    fontSize: "0",
                  }}
                  type="button"
                  onClick={(e) => changeView(e)}
                >
                  <i className="bx bx-pencil" style={{ fontSize: "22px" }} />
                </button>
              </Tooltip>
            )}
          </AvForm>
        </CardBody>
      </Card>
    </>
  );
};

reportAddBox.propTypes = {
  title: PropTypes.string,
};

export default reportAddBox;

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  withRouter,
  useHistory,
  useParams,
  useLocation,
} from "react-router-dom";
import { AvField, AvForm } from "availity-reactstrap-validation";
import {
  FormGroup,
  Label,
  TabPane,
  TabContent,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { cloneDeep } from "lodash";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import BackButton from "../../components/Common/BackButton.js";
import ConfirmBox from "../../components/Common/ConfirmBox.js";
import ListActions from "../../components/Common/ListActions.js";
import TableComponent from "../../components/Common/TableComponent";
import classnames from "classnames";
import ModalEstate from "../../components/Modals/ModalEstate";
import ModalSharedPart from "../../components/Modals/ModalSharedPart";
import * as endpoints from "../../constants/endpoints";
import * as jsonpatch from "fast-json-patch";
import { Tooltip } from "@material-ui/core";
import {
  GetData,
  SaveItem,
  DeleteItem,
  ToggleItem,
} from "../../helpers/CRUD.js";
import i18next from "i18next";

const ZoneSingle = (props) => {
  const old_state = {
    pageTitle: "Зона",
    mode: "view",
    estates: null,
    sharedParts: null,
    id: "",
    building_id: "",
    activeTab: "1",
    showModal: null,
    tabsNav: [
      { index: "1", id: "tab-nav-1", label: "Имоти" },
      { index: "2", id: "tab-nav-2", label: "Общи части" },
    ],
  };
  const { zoneId, buildingId } = useParams();
  const history = useHistory();
  const location = useLocation();

  const [state, setState] = useState({
    ...old_state,
    document: {
      entrance: "",
      comment: "",
      volume: 0,
      isActive: false,
    },
  });

  const emitErrors = (error) => {
    props.onErrorsShow(error.body);
  };

  const emitSuccess = (success) => {
    props.onSuccessShow(success);
  };

  const handleEdit = () => {
    setState((state) => ({ ...state, mode: "edit" }));
  };

  const openModal = (id) => {
    setState((state) => ({ ...state, showModal: id }));
  };

  const closeModal = () => {
    setState((state) => ({ ...state, showModal: null }));
    removeBodyCss();
  };

  const closeAndUpdate = (modalCallback) => {
    setState((state) => ({ ...state, showModal: null }));
    modalCallback();
    removeBodyCss();
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };

  const handleSave = () => {
    let patch = jsonpatch.compare(state.initialDocument, state.document);

    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/Zone/Update?id=${zoneId}`;

    SaveItem(
      url,
      patch,
      () => {
        props.onSuccessShow(i18next.t("entry_updated_successfully"));
        setState((state) => ({ ...state, mode: "view" }));
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  const handleDelete = () => {
    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/Zone/Delete?id=${zoneId}`;

    DeleteItem(url, history.goBack, (error) => {
      props.onErrorsShow(error.body);
    });
  };

  const toggleActive = () => {
    let old_state = state.document;
    old_state.isActive = !state.document.isActive;

    setState((state) => ({ ...state, document: old_state }));
    handleToggleStatusPost();
  };

  const handleToggleStatusPost = () => {
    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/Zone/ToggleActive?id=${zoneId}&isActive=${state.document.isActive}`;

    ToggleItem(
      url,
      () => {
        state.document.isActive
          ? props.onSuccessShow(i18next.t("entry_activated_successfully"))
          : props.onSuccessShow(i18next.t("entry_deactivated_successfully"));
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  const getZoneById = () => {
    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/Zone/GetById?id=${zoneId}`;

    GetData(
      url,
      (data) => {
        setState((state) => ({
          ...state,
          document: { ...data },
          initialDocument: cloneDeep(data),
        }));
        fetchCallback();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const fetchCallback = () => {
    getAllEstates();
    getAllSharedParts();
    setState((state) => ({ ...state, zoneDataIsLoaded: true }));
  };

  const getAllEstates = () => {
    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/Estate/GetAllByZoneId?id=${zoneId}`;

    GetData(
      url,
      (data) => {
        setState((state) => ({ ...state, estates: data, estatesLoaded: true }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getAllEstatesInactive = () => {
    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/Estate/GetAllByZoneId?id=${zoneId}&isActive=false`;

    GetData(
      url,
      (data) => {
        let old_data = state.estates;
        old_data.push(...data);

        setState((state) => ({ ...state, estates: old_data }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    state.estatesLoaded && getAllEstatesInactive();
  }, [state.estatesLoaded]);

  const getAllSharedParts = () => {
    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/SharedPart/GetAllByZoneId?id=${zoneId}`;

    GetData(
      url,
      (data) => {
        setState((state) => ({
          ...state,
          sharedParts: data,
          sharedPartsLoaded: true,
        }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getAllSharedPartsInactive = () => {
    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/SharedPart/GetAllByZoneId?id=${zoneId}&isActive=false`;

    GetData(
      url,
      (data) => {
        let old_data = state.sharedParts;
        old_data.push(...data);

        setState((state) => ({ ...state, sharedParts: old_data }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    state.sharedPartsLoaded && getAllSharedPartsInactive();
  }, [state.sharedPartsLoaded]);

  const cancelConfirmBox = () => {
    setState((state) => ({
      ...state,
      confirm_delete: false,
      confirm_toggle: false,
      confirm_save: false,
    }));
  };

  const confirmDelete = () => {
    setState((state) => ({
      ...state,
      confirm_delete: false,
      success_dlg: true,
    }));
  };

  const confirmToggle = () => {
    setState((state) => ({
      ...state,
      confirm_toggle: false,
      success_dlg: true,
    }));
  };

  const confirmSave = () => {
    setState((state) => ({
      ...state,
      confirm_save: false,
      success_dlg: true,
      mode: "view",
    }));
  };

  const closeAfterConfirmDelete = () => {
    setState((state) => ({ ...state, success_dlg: false }));
    handleDelete();
  };

  const closeAfterConfirmToggle = () => {
    setState((state) => ({ ...state, success_dlg: false }));
    toggleActive();
  };

  const closeAfterConfirmSave = () => {
    setState((state) => ({ ...state, success_dlg: false }));
    handleSave();
  };

  const toggleTabs = (tab) => {
    if (state.activeTab !== tab) {
      setState((state) => ({ ...state, activeTab: tab }));
    }
  };

  useEffect(() => {
    getZoneById();
  }, []);

  const radioStyle = {
    listStyle: "none",
    paddingLeft: "0",
  };
  const labelStyle = {
    paddingLeft: "20px",
  };

  const tableStyle = {
    tableLayout: "fixed",
  };

  let currentLocation = location.pathname;

  if (currentLocation.charAt(0) === "/") {
    currentLocation = currentLocation.substring(1);
  }

  let tableBodyDataEstates = [];
  if (state.estatesLoaded) {
    tableBodyDataEstates = state.estates.map((item) => {
      return {
        id: item.id,
        data: {
          name: item.name,
          subscriber_station:
            item.zone && item.zone.subscriberStation
              ? item.zone.subscriberStation.number
              : "",
          tec_number: item.externalId ? item.externalId : "",
          sw_number:
            item.sofiaWaterData && item.sofiaWaterData.subscriberNumber
              ? item.sofiaWaterData.subscriberNumber &&
                item.sofiaWaterData.subscriberNumber === "empty" &&
                "-"
              : "",
          number_tk: item.number ? item.number : "",
          volume:
            item.toploficationData && item.toploficationData.length > 0
              ? item.toploficationData[item.toploficationData.length - 1]
                  .estateVolume
              : "",
          expected_hot_water:
            item.toploficationData &&
            item.toploficationData.length > 0 &&
            item.toploficationData[item.toploficationData.length - 1]
              ? item.toploficationData[item.toploficationData.length - 1]
                  .estimatedHotWaterPerMonth
              : "",
          expected_heating:
            item.toploficationData &&
            item.toploficationData.length > 0 &&
            item.toploficationData[item.toploficationData.length - 1]
              ? item.toploficationData[item.toploficationData.length - 1]
                  .estimatedHeatingPerDay
              : "",
          isActive: item.isActive,
        },
      };
    });
  }

  let tableBodyDataSharedParts = [];
  if (state.sharedPartsLoaded) {
    tableBodyDataSharedParts = state.sharedParts.map((item) => {
      return {
        id: item.id,
        data: {
          comment: item.comment,
          isActive: item.isActive,
        },
      };
    });
  }

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl="12">
              <BackButton className="mb-4" />
            </Col>

            <Col xl="12">
              <Card>
                <CardBody className="mb-0 pb-0">
                  <Row>
                    <Col>
                      <CardTitle className="mb-4">{state.pageTitle}</CardTitle>
                    </Col>

                    <Col sm="3" className="text-right">
                      <Row>
                        <Col
                          xs="12"
                          className="d-flex align-items-center justify-content-end"
                        >
                          <ListActions
                            link={`/${zoneId}`}
                            data={state.document}
                            mode={state.mode}
                            toggleSave={() => {
                              setState((state) => ({
                                ...state,
                                confirm_save: true,
                                actionType: "save",
                              }));
                            }}
                            toggleEdit={handleEdit}
                            toggle={() =>
                              setState((state) => ({
                                ...state,
                                confirm_toggle: true,
                                actionType: "toggle",
                              }))
                            }
                            delete={() =>
                              setState((state) => ({
                                ...state,
                                confirm_delete: true,
                                actionType: "delete",
                              }))
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col md="12">
              <AvForm className="needs-validation">
                <Row className="d-flex">
                  <Col xl="6" className="d-flex">
                    <Card className="w-100">
                      <CardBody>
                        <Row>
                          <Col md="12">
                            <h5></h5>
                          </Col>

                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="validationName" className="">
                                Номер на вход
                              </Label>
                              <AvField
                                name="document.entrance"
                                placeholder="Въведете номер на вход"
                                type="number"
                                max={32767}
                                className="form-control"
                                id="validationName"
                                value={state.document.entrance}
                                onChange={(e) =>
                                  setState((state) => ({
                                    ...state,
                                    document: {
                                      ...state.document,
                                      entrance: e.target.value,
                                    },
                                  }))
                                }
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                  maxLength: {
                                    value: 30,
                                  },
                                }}
                                {...(state.mode === "view" && {
                                  readOnly: true,
                                })}
                              />
                            </FormGroup>
                          </Col>

                          <Col md="4">
                            <FormGroup>
                              <Label
                                htmlFor="validationAddress_street"
                                className=""
                              >
                                Кубатура
                              </Label>
                              <AvField
                                name="document.volume"
                                placeholder="Кубатура"
                                type="number"
                                className="form-control"
                                id="validationAddress_street"
                                value={state.document.volume}
                                onChange={(e) =>
                                  setState((state) => ({
                                    ...state,
                                    document: {
                                      ...state.document,
                                      volume: e.target.value,
                                    },
                                  }))
                                }
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                  maxLength: {
                                    value: 30,
                                  },
                                }}
                                {...(state.mode === "view" && {
                                  readOnly: true,
                                })}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <h5></h5>
                          </Col>

                          <Col md="12">
                            <FormGroup>
                              <Label htmlFor="validationSAstation" className="">
                                Номер на абонатна станция
                              </Label>
                              <AvField
                                name="document.subscriberStation.number"
                                placeholder="Номер на абонатна станция "
                                type="number"
                                className="form-control"
                                id="validationSAstation"
                                value={
                                  state.document.subscriberStation &&
                                  state.document.subscriberStation.number
                                }
                                onChange={(e) =>
                                  setState((state) => ({
                                    ...state,
                                    document: {
                                      ...state.document,
                                      subscriberStation: {
                                        ...state.document.subscriberStation,
                                        number: e.target.value,
                                      },
                                    },
                                  }))
                                }
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                  maxLength: {
                                    value: 30,
                                  },
                                }}
                                {...(state.mode === "view" && {
                                  readOnly: true,
                                })}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <h5></h5>
                          </Col>

                          <Col md="12">
                            <FormGroup>
                              <Label htmlFor="validationComment" className="">
                                Коментар
                              </Label>
                              <AvField
                                name="document.comment"
                                placeholder="Коментар"
                                type="textarea"
                                className="form-control"
                                id="validationComment"
                                maxLength="200"
                                value={state.document.comment}
                                onChange={(e) =>
                                  setState((state) => ({
                                    ...state,
                                    document: {
                                      ...state.document,
                                      comment: e.target.value,
                                    },
                                  }))
                                }
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                  maxLength: {
                                    value: 30,
                                  },
                                }}
                                {...(state.mode === "view" && {
                                  readOnly: true,
                                })}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col xl="6" className="d-flex">
                    <Card className="w-100">
                      <CardBody>
                        <Row>
                          <Col md="12">
                            <Row>
                              <Col md="12">
                                <h5></h5>
                              </Col>

                              <Col md="12">
                                <FormGroup>
                                  <Label
                                    htmlFor="validationindivualHeatmeter"
                                    className=""
                                  >
                                    Индивидуален топломер - Фаза 2
                                  </Label>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="12">
                                <h5></h5>
                              </Col>

                              <Col md="12">
                                <FormGroup>
                                  <Label
                                    htmlFor="validationindivualHeatmeter"
                                    className=""
                                  >
                                    Общ водомер - Фаза 2
                                  </Label>
                                </FormGroup>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </AvForm>
            </Col>

            <Col xl="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <Row>
                        <Col md="6">
                          <Nav pills className="navtab-bg nav-justified">
                            {state.tabsNav.map((item) => (
                              <NavItem key={item.id}>
                                <NavLink
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  className={classnames({
                                    active: state.activeTab === item.index,
                                  })}
                                  onClick={() => {
                                    toggleTabs(item.index);
                                  }}
                                >
                                  {item.label}
                                </NavLink>
                              </NavItem>
                            ))}
                          </Nav>
                        </Col>
                      </Row>

                      <TabContent
                        activeTab={state.activeTab}
                        tabId="1"
                        className="p-3"
                      >
                        <TabPane tabId="1" className="p-3">
                          {/* Имоти */}
                          <Row className="mb-2">
                            <Col></Col>
                          </Row>
                          {tableBodyDataEstates && (
                            <TableComponent
                              dataHead={[
                                "Име",
                                "Абонатна станция",
                                "Топлофикация номер",
                                "ВиК номер",
                                "Абонатен номер Вътрешен",
                                "Кубатура",
                                "Прогноза - Топла Вода",
                                "Прогноза - Отопление",
                                "Действия",
                              ]}
                              dataBody={tableBodyDataEstates}
                              url_path={`${currentLocation}/estate`}
                              category={""}
                              enableLinkView={true}
                              isLoaded={true}
                            />
                          )}
                        </TabPane>

                        <TabPane tabId="2" className="p-3">
                          {/* Общи части' */}
                          <Row className="mb-2">
                            <Col>
                              <div className="text-right">
                                <Tooltip
                                  title="Добави нова обща част"
                                  placement="right-start"
                                >
                                  <button
                                    className="btn btn-primary waves-effect waves-light"
                                    onClick={(id) => openModal("shared-part")}
                                  >
                                    <i
                                      className="bx bx-plus-circle"
                                      style={{
                                        fontSize: "18px",
                                      }}
                                    />
                                  </button>
                                </Tooltip>
                              </div>
                            </Col>
                          </Row>
                          {tableBodyDataSharedParts && (
                            <TableComponent
                              dataHead={["Коментар", "Действия"]}
                              dataBody={tableBodyDataSharedParts}
                              url_path={`${currentLocation}/shared-part`}
                              category={""}
                              enableLinkView={true}
                              isLoaded={true}
                            />
                          )}
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {state.zoneDataIsLoaded && (
            <ModalEstate
              isModalOpen={state.showModal === "estate"}
              closeModal={closeModal}
              update={() => closeAndUpdate(getAllEstates)}
              buildingId={buildingId}
              zoneId={zoneId}
              addressId={
                state.document.address?.id ? state.document.address.id : ""
              }
              externalId={
                state.document.externalId ? state.document.externalId : ""
              }
              emitErrors={emitErrors}
              emitSuccess={emitSuccess}
            ></ModalEstate>
          )}

          <ModalSharedPart
            isModalOpen={state.showModal === "shared-part"}
            closeModal={closeModal}
            update={() => closeAndUpdate(getAllSharedParts)}
            building_id={buildingId}
            zone_id={zoneId}
            emitErrors={emitErrors}
            emitSuccess={emitSuccess}
          ></ModalSharedPart>

          <ConfirmBox
            confirm_delete={state.confirm_delete}
            confirm_toggle={state.confirm_toggle}
            confirm_save={state.confirm_save}
            success_dlg={state.success_dlg}
            cancelConfirmBox={cancelConfirmBox}
            closeAfterConfirmDelete={closeAfterConfirmDelete}
            closeAfterConfirmToggle={closeAfterConfirmToggle}
            closeAfterConfirmSave={closeAfterConfirmSave}
            confirmDelete={confirmDelete}
            confirmToggle={confirmToggle}
            confirmSave={confirmSave}
            isActive={state.document.isActive}
            actionType={state.actionType}
          />
        </Container>
      </div>
    </>
  );
};

ZoneSingle.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ZoneSingle));

import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardBody, Col, Row, FormGroup, Label } from "reactstrap";
import { Modal, Button } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { getUsers } from "store/contacts/actions";
import { cloneDeep } from "lodash";
import * as endpoints from "constants/endpoints";
import * as jsonpatch from "fast-json-patch";
import { building_device_types } from "helpers/listsHardcodedData";
import { CreateItem, GetData } from "../../helpers/CRUD.js";
import i18next from "i18next";

const ModalBuilding = (props) => {
  const [state, setState] = useState({
    heatingAreas: [],
    initialState: null,
    document: {
      externalId: "",
      signedBy: "",
      dateStart: null,
      totalHeatingVolume: null,
      comments: "",
      heatingAreaId: null,
      toploficationReport: {
        reportDeviceType: null,
      },
      address: {
        city: null,
        streetName: null,
        streetCode: null,
        buildingNumber: null,
        buildingSubNumber: null,
      },
    },
  });

  const handleSubmit = (event, errors, values) => {
    setState((state) => ({ ...state, errors, values }));
  };

  var currentDate = new Date();
  var date = currentDate.toISOString();
  var inputDate = date.substr(0, 10);

  const handleValidSubmit = (event, values) => {
    console.log(state.document.dateStart);

    let patch = jsonpatch.compare(state.initialState, state.document);

    if (state.document.dateStart === null) {
      patch.push({
        op: "replace",
        path: "/dateStart",
        value: date,
      });
    }

    const url = `${endpoints.BUILDING}/Add`;

    CreateItem(
      url,
      patch,
      (data) => {
        update();

        const currentState = cloneDeep(state.initialState);
        setState((state) => ({ ...state, document: currentState }));

        props.emitSuccess(i18next.t("entry_added_successfully"));
      },
      (error) => {
        props.emitErrors(error);
      }
    );
  };

  const update = () => {
    props.update();
  };

  const getHeatingAreas = () => {
    const url = `${endpoints.NOMENCLATURE_ENDPOINT}/HeatingArea/GetAll`;

    GetData(
      url,
      (data) => {
        setState((state) => ({ ...state, heatingAreas: data.items }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    getHeatingAreas();
    const initialState = cloneDeep(state.document);
    setState((state) => ({ ...state, initialState }));
  }, []);

  const building_device_types_list = building_device_types();

  return (
    <>
      <Modal isOpen={props.isModalOpen} scrollable={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            {i18next.t("modal_building_title")}
          </h5>

          <button
            type="button"
            onClick={() => props.closeModal()}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Card className="mb-0">
            <CardBody>
              <AvForm
                onSubmit={handleSubmit}
                onValidSubmit={handleValidSubmit}
                className="needs-validation"
              >
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="validationCustomName">
                        {i18next.t("externalId")}
                      </Label>
                      <AvField
                        name="document.externalId"
                        placeholder={i18next.t("placeholder_enter_number")}
                        type="text"
                        className="form-control"
                        id="validationCustomName"
                        value={state.document.externalId}
                        onChange={(e) =>
                          setState((state) => ({
                            ...state,
                            document: {
                              ...state.document,
                              externalId: e.target.value,
                            },
                          }))
                        }
                      />
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="validationCustom-signedBy">
                        {i18next.t("contract-signed-by")}
                      </Label>
                      <AvField
                        name="document.signedBy"
                        placeholder={i18next.t("placeholder_enter_name")}
                        type="text"
                        className="form-control"
                        value={state.document.signedBy}
                        onChange={(e) =>
                          setState((state) => ({
                            ...state,
                            document: {
                              ...state.document,
                              signedBy: e.target.value,
                            },
                          }))
                        }
                        id="validationCustom-signedBy"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md="12">
                    <h5>{i18next.t("address")}</h5>
                  </Col>

                  <Col md="8">
                    <FormGroup>
                      <Label htmlFor="validationStreetName">
                        {i18next.t("label-street")}
                      </Label>
                      <Row>
                        <Col md="5">
                          <AvField
                            name="document.address.city"
                            placeholder={i18next.t("label-city")}
                            type="text"
                            className="form-control"
                            id="validationCity"
                            value={state.document.address.city}
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  address: {
                                    ...state.document.address,
                                    city: e.target.value,
                                  },
                                },
                              }))
                            }
                            {...(state.mode === "view" && {
                              readOnly: true,
                            })}
                          />
                        </Col>

                        <Col md="5">
                          <AvField
                            name="document.address.streetName"
                            placeholder={i18next.t("label-single-street")}
                            type="text"
                            className="form-control"
                            id="validationStreetName"
                            value={state.document.address.streetName}
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  address: {
                                    ...state.document.address,
                                    streetName: e.target.value,
                                  },
                                },
                              }))
                            }
                            {...(state.mode === "view" && {
                              readOnly: TRUE,
                            })}
                          />
                        </Col>

                        <Col md="2">
                          <AvField
                            name="document.address.streetCode"
                            placeholder="№"
                            type="text"
                            className="form-control"
                            s
                            id="validationShif"
                            value={state.document.address.streetCode}
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  address: {
                                    ...state.document.address,
                                    streetCode: e.target.value,
                                  },
                                },
                              }))
                            }
                            {...(state.mode === "view" && {
                              readOnly: true,
                            })}
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>

                  <Col md="4">
                    <FormGroup>
                      <Label htmlFor="validationAddress_buildingNumer">
                        {i18next.t("building")}
                      </Label>
                      <Row>
                        <Col md="6">
                          <AvField
                            name="document.address.buildingNumber"
                            placeholder={i18next.t("label-number")}
                            type="text"
                            className="form-control"
                            id="validationAddress_buildingNumber"
                            value={state.document.address.buildingNumber}
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  address: {
                                    ...state.document.address,
                                    buildingNumber: e.target.value,
                                  },
                                },
                              }))
                            }
                            {...(state.mode === "view" && {
                              readOnly: true,
                            })}
                          />
                        </Col>
                        <Col md="6">
                          <AvField
                            name="document.address.buildingSubNumber"
                            placeholder={i18next.t("label-subnumber")}
                            type="text"
                            className="form-control"
                            id="validationAddress_buildingSubNumber"
                            value={state.document.address.buildingSubNumer}
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  address: {
                                    ...state.document.address,
                                    buildingSubNumber: e.target.value,
                                  },
                                },
                              }))
                            }
                            {...(state.mode === "view" && {
                              readOnly: true,
                            })}
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="validationCustom-dateStart">
                        {i18next.t("start-date")}
                      </Label>

                      <AvField
                        type="date"
                        name="document.dateStart"
                        placeholder={i18next.t("placeholder_enter_start_date")}
                        id="validationCustom-dateStart"
                        onChange={(e) =>
                          setState((state) => ({
                            ...state,
                            document: {
                              ...state.document,
                              dateStart: e.target.value,
                            },
                          }))
                        }
                        value={state.document.dateStart}
                        defaultValue={inputDate}
                        className="form-control"
                      />
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="validationCustomtotalHeatingVolume">
                        {i18next.t("volume")}
                      </Label>
                      <AvField
                        name="document.totalHeatingVolume"
                        placeholder={i18next.t("placeholder_enter_volume")}
                        type="number"
                        min={0}
                        className="form-control"
                        value={state.document.totalHeatingVolume}
                        onChange={(e) =>
                          setState((state) => ({
                            ...state,
                            document: {
                              ...state.document,
                              totalHeatingVolume: e.target.value,
                            },
                          }))
                        }
                        id="validationCustomtotalHeatingVolume"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="validationCustomheatingAreaId">
                        {i18next.t("heating-area")}
                      </Label>
                      <AvField
                        type="select"
                        name="document.heatingAreaId"
                        id="validation-heating-area"
                        value={state.document.heatingAreaId}
                        onChange={(e) =>
                          setState((state) => ({
                            ...state,
                            document: {
                              ...state.document,
                              heatingAreaId: e.target.value,
                            },
                          }))
                        }
                        className="w-100"
                      >
                        <option
                          value=""
                          selected={state.heatingAreaId === "" ? true : false}
                        >
                          {i18next.t("select_heating_area")}
                        </option>

                        {state.heatingAreas &&
                          state.heatingAreas.length > 0 &&
                          state.heatingAreas.map((heatingArea) => (
                            <option
                              key={heatingArea.id}
                              value={heatingArea.id}
                              selected={
                                state.nomHeatingAreaId === heatingArea.id
                                  ? true
                                  : false
                              }
                            >
                              {heatingArea.name}
                            </option>
                          ))}
                      </AvField>
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="validationCustomtotalHeatingVolume">
                        {i18next.t("devicesType")}
                      </Label>
                      <AvField
                        type="select"
                        name="document.toploficationReport.reportDeviceType"
                        id="validation-device-type"
                        value={
                          state.document.toploficationReport.reportDeviceType
                        }
                        onChange={(e) =>
                          setState((state) => ({
                            ...state,
                            document: {
                              ...state.document,
                              toploficationReport: {
                                ...state.document.toploficationReport,
                                reportDeviceType: e.target.value,
                              },
                            },
                          }))
                        }
                        className="w-100"
                      >
                        <option
                          value=""
                          selected={state.device_type === "" ? true : false}
                        >
                          {i18next.t("select_devices_type")}
                        </option>

                        {building_device_types_list &&
                          building_device_types_list.length > 0 &&
                          building_device_types_list.map((deviceType) => (
                            <option
                              key={deviceType.key}
                              value={deviceType.key}
                              selected={
                                state.deviceType === deviceType.key
                                  ? true
                                  : false
                              }
                            >
                              {deviceType.value}
                            </option>
                          ))}
                      </AvField>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md="12">
                    <FormGroup>
                      <Label htmlFor="validationCustomComments">
                        {i18next.t("tab-comments")}
                      </Label>

                      <AvField
                        name="document.comments"
                        placeholder={i18next.t("tab-comments")}
                        type="textarea"
                        className="form-control"
                        maxLength="200"
                        onChange={(e) =>
                          setState((state) => ({
                            ...state,
                            document: {
                              ...state.document,
                              comments: e.target.value,
                            },
                          }))
                        }
                        value={state.document.comments}
                        id="validationCustomComments"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => props.closeModal()}
                  >
                    <i className="fa fa-times mr-1" />
                    {i18next.t("button_cancel")}
                  </button>

                  <Button color="primary" type="submit">
                    <i className="fa fa-plus-circle mr-1" />
                    {i18next.t("button_add")}
                  </Button>
                </div>
              </AvForm>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </>
  );
};

ModalBuilding.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUsers: () => dispatch(getUsers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ModalBuilding));

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import LinkView from "components/Common/LinkView";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { Tooltip } from "@material-ui/core";
import Loader from "../../components/Common/Loader.js";
import i18next from "i18next";

const TableComponent = (props) => {
  const [state, setState] = useState({
    tableHead: [],
    tableBody: [],
    sortingDirection: "",
    sortingLabel: "",
    url_path: "",
    category: "",
  });

  const loadInitalData = () => {
    setState((state) => ({
      ...state,
      tableHead: props.dataHead,
      tableBody: props.dataBody,
      url_path: props.url_path,
      category: props.category,
    }));
  };

  const openModal = (modalName, id) => {
    props.onBuildingSelect(modalName, id);
  };

  const handleTableSorting = (label) => {
    if (label === "") {
      return false;
    }

    if (state.sortingLabel === label) {
      setState((state) => ({
        ...state,
        sortingDirection: state.sortingDirection === "asc" ? "desc" : "asc",
      }));
      props.onSorterClick(state.sortingLabel, state.sortingDirection);
    } else {
      setState((state) => ({
        ...state,
        sortingLabel: label,
        sortingDirection: "asc",
      }));
      props.onSorterClick(state.sortingLabel, state.sortingDirection);
    }
  };

  useEffect(() => {
    loadInitalData();
  }, []);

  const WrapperTooltip = ({ condition, With, children, ...rest }) =>
    condition ? <With {...rest}>{children}</With> : children;

  const Wrapper = ({ children, tooltipText, ...rest }) => (
    <Tooltip title={tooltipText} placement="top-start" {...rest}>
      {children}
    </Tooltip>
  );

  const tableStyle = {
    tableLayout: "fixed",
  };

  const tableHead = {
    backgroundColor: "#f8f9fa",
    borderColor: "#eff2f7",
  };

  const getTableDataColor = (dataColor) => {
    if (!dataColor) {
      return { color: "black" };
    } else {
      return { color: dataColor };
    }
  };

  const tableDataInactive = {
    color: "darkgray",
  };

  return (
    <Table className="table scrollable table-bordered" style={tableStyle}>
      <Thead>
        <Tr>
          {props.dataHead && props.dataHead.length && (
            <React.Fragment>
              {props.dataHead.map((title, i) => (
                <Th
                  key={`${title}-${i}`}
                  style={tableHead}
                  onClick={() => handleTableSorting(props.sorterData[i])}
                >
                  <WrapperTooltip
                    condition={
                      props.dataTooltips && props.dataTooltips[i] !== ""
                    }
                    With={Wrapper}
                    tooltipText={
                      props.dataTooltips !== undefined
                        ? props.dataTooltips[i]
                        : ""
                    }
                  >
                    <div className="d-flex align-items-center">
                      <span className="flex-grow-1">{title}</span>

                      {props.sorterData &&
                        props.sorterData[i] !== "" &&
                        props.sorterData[i] !== undefined && (
                          <svg
                            width="8"
                            height="12"
                            xmlns="http://www.w3.org/2000/svg"
                            className="flex-shrink-0"
                            viewBox="0 0 8 12"
                          >
                            <g fill="#495057" fillRule="evenodd">
                              <path d="M4 0l4 5H0zM4 12L0 7h8z" />
                            </g>
                          </svg>
                        )}
                    </div>
                  </WrapperTooltip>
                </Th>
              ))}
            </React.Fragment>
          )}
        </Tr>
      </Thead>

      <Tbody>
        {props.isLoading && !props.isLoaded ? (
          <Tr>
            <Td
              colSpan={props.dataHead.length}
              className="align-items-center text-center"
            >
              <Loader showLoader={true} />
            </Td>
          </Tr>
        ) : null}

        {props.dataBody && props.isLoaded && props.dataBody.length === 0 ? (
          <Tr>
            <Td
              colSpan={props.dataHead.length}
              className="align-items-center text-center"
            >
              {i18next.t("table_missing_data")}
            </Td>
          </Tr>
        ) : (
          props.dataBody &&
          props.dataBody.length > 0 && (
            <>
              {props.dataBody.map((row) => (
                <Tr
                  key={row.id}
                  style={
                    row.data.isActive
                      ? getTableDataColor(row.rowColor ? row.rowColor : null)
                      : tableDataInactive
                  }
                >
                  {Object.keys(row.data).map((cell, i) => (
                    <React.Fragment key={`${row.id}-${i}`}>
                      {cell !== "isActive" && <Td>{row.data[cell]}</Td>}
                    </React.Fragment>
                  ))}

                  {!props.disableLink && (
                    <Td>
                      <div className="actions d-flex align-items-center">
                        {props.enableLinkView && (
                          <LinkView
                            link={`/${props.url_path}/${row.id}`}
                            id={"link-to" + row.id}
                          ></LinkView>
                        )}
                        {row.enableLinkAdd && (
                          <Tooltip
                            title={props.linkAddTooltip}
                            placement="right-start"
                          >
                            <button
                              className="ml-2"
                              style={{
                                border: "none",
                                background: "none",
                                fontSize: "0",
                              }}
                              onClick={() => props.onModalHCAOpen(row.id)}
                            >
                              <i
                                className="bx bx-plus-circle"
                                style={{
                                  fontSize: "18px",
                                }}
                              ></i>
                            </button>
                          </Tooltip>
                        )}
                        {props.enableLinkDelete && (
                          <Tooltip
                            title={props.delete_tooltip}
                            placement="right-start"
                          >
                            <button
                              className="ml-2"
                              style={{
                                border: "none",
                                background: "none",
                                fontSize: "0",
                              }}
                              onClick={() => props.onDelete(row.id)}
                            >
                              <i
                                className="bx bx-trash"
                                style={{
                                  fontSize: "18px",
                                }}
                              ></i>
                            </button>
                          </Tooltip>
                        )}
                      </div>
                    </Td>
                  )}
                  {props.actionDownload ? (
                    <Td>
                      <a href={`${props.url_path}/${row.id}`} download>
                        <i
                          className="bx bx-download"
                          style={{
                            fontSize: "24px",
                          }}
                        />
                      </a>
                    </Td>
                  ) : null}

                  {props.actionReport ? (
                    <Td>
                      <Tooltip
                        title={i18next.t("tootltip-report-add")}
                        placement="right-start"
                      >
                        <button
                          className="btn btn-primary waves-effect waves-light"
                          onClick={() => openModal("report", row.id)}
                        >
                          <i
                            className="bx bx-plus-circle"
                            style={{
                              fontSize: "18px",
                            }}
                          />
                        </button>
                      </Tooltip>
                    </Td>
                  ) : null}
                </Tr>
              ))}
            </>
          )
        )}
      </Tbody>
    </Table>
  );
};

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TableComponent));

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Search } from "react-bootstrap-table2-toolkit";
import { TextField, Tooltip } from "@material-ui/core";
import BarPageTitle from "../../components/Common/BarPageTitle";
import i18next from "i18next";
import ModalUser from "components/Modals/ModalUser";
import { throttle } from "lodash";
import * as endpoints from "constants/endpoints";
import ButtonAdd from "components/Common/ButtonAdd.js";
import TableComponent from "components/Common/TableComponent";
import { GetData } from "../../helpers/CRUD.js";
import Pagination from "components/Common/Pagination";
import PaginationSelect from "components/Common/PaginationSelect";

const UserList = (props) => {
  const [state, setState] = useState({
    pageTitle: "Списък потребители",
    users: [],
    isModalOpen: false,
    page: 1,
    totalCount: null,
    pageSize: 100,
  });

  const emitErrors = (error) => {
    props.onErrorsShow(error.body);
  };

  const openModal = () => {
    setState((state) => ({ ...state, isModalOpen: true }));

    removeBodyCss();
  };

  const closeModal = () => {
    setState((state) => ({ ...state, isModalOpen: false }));
    removeBodyCss();
  };

  const closeAndUpdate = (modalCallback) => {
    setState((state) => ({ ...state, isModalOpen: false }));
    modalCallback();
    removeBodyCss();
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };

  const addNewUser = () => {
    filterUsers();
  };

  const filterUsers = (value) => {
    const loggedUserRole = JSON.parse(localStorage.getItem("role"));

    let endpointQueryParam = "";

    if (value) {
      endpointQueryParam = `?filterText=${value}`;
    }

    const url = `${endpoints.USER}/${
      loggedUserRole === "1" ? "GetAll" : "GetAllNonSuperAdmins"
    }${endpointQueryParam}`;

    GetData(
      url,
      (data) => {
        let tableBodyData;

        if (data) {
          tableBodyData = data.items.map((item) => {
            console.log(item);
            return {
              id: item.id,
              data: {
                name: item.name ? item.name : "",
                username: item.username ? item.username : "",
                role: item.role ? item.role.roleName : "" + "no role assigned",
                phone: item.phoneNumber ? item.phoneNumber : "",
                email: item.email ? item.email : "",
                isActive: item.isActive,
              },
            };
          });
        }

        setState((state) => ({
          ...state,
          users: tableBodyData,
          totalCount: data.totalCount,
        }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const filterUsersThrottled = () => throttle(filterUsers, 800);

  const updatePage = (page) => {
    setState((state) => ({ ...state, page }));
  };

  const onPageSizeUpdate = (pageSize) => {
    setState((state) => ({ ...state, pageSize, page: 1 }));
  };

  useEffect(() => {
    filterUsers();
  }, [state.page, state.pageSize]);

  const pageOptions = {
    sizePerPage: 10,
    totalSize: 50,
    custom: true,
  };

  const { SearchBar } = Search;

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <BarPageTitle pageTitle={state.pageTitle} />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col>
                      <Tooltip
                        title="Търсете по Име, Роля или Имейл"
                        placement="right-start"
                      >
                        <TextField
                          InputLabelProps={{
                            style: {
                              pointerEvents: "auto",
                            },
                          }}
                          placeholder={i18next.t("placeholder_search")}
                          variant="outlined"
                          type="text"
                          size="small"
                          onChange={(e) => filterUsersThrottled(e.target.value)}
                        />
                      </Tooltip>
                    </Col>

                    <Col md="2">
                      <PaginationSelect
                        pageSize={state.pageSize}
                        index={1}
                        onPageSizeUpdate={onPageSizeUpdate}
                      />
                    </Col>

                    <Col md="1">
                      <div className="text-right">
                        <ButtonAdd
                          onBtnClick={openModal}
                          data-toggle="modal"
                          tooltip={i18next.t("tootltip-user-add")}
                        />
                      </div>
                    </Col>
                  </Row>

                  {state.users && state.users.length > 0 ? (
                    <TableComponent
                      dataHead={[
                        i18next.t("name"),
                        i18next.t("username"),
                        i18next.t("role"),
                        i18next.t("phone"),
                        i18next.t("email"),
                        i18next.t("actions"),
                      ]}
                      dataBody={state.users}
                      url_path={"user-profile"}
                      category={"user-profile"}
                      enableLinkView={true}
                    />
                  ) : null}

                  {state.totalCount > state.pageSize && (
                    <Pagination
                      updatePage={updatePage}
                      page={state.page}
                      totalPage={
                        state.totalCount !== undefined
                          ? Math.ceil(state.totalCount / state.pageSize)
                          : 1
                      }
                    />
                  )}

                  <ModalUser
                    isModalOpen={state.isModalOpen}
                    closeModal={closeModal}
                    addNewUser={addNewUser}
                    emitErrors={emitErrors}
                    update={() => closeAndUpdate(filterUsers)}
                  ></ModalUser>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

UserList.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUsers: () => dispatch(filterUsers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserList));

import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardBody, Col, Row, FormGroup, Label } from "reactstrap";
import { Modal, Button } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { cloneDeep } from "lodash";
import * as endpoints from "constants/endpoints";
import * as jsonpatch from "fast-json-patch";
import { CreateItem } from "../../helpers/CRUD.js";
import { roles } from "../../helpers/listsHardcodedData";
import i18next from "i18next";

const ModalUser = (props) => {
  const [state, setState] = useState({
    initialState: null,
    roles: roles(),
    document: {
      username: "",
      password: "",
      email: "",
      name: "",
      phoneNumber: "",
      roleId: "",
    },
  });

  const handleValidSubmit = (event, values) => {
    let patch = jsonpatch.compare(state.initialState, state.document);

    patch.push({
      op: "replace",
      path: "/isActive",
      value: true,
    });

    const url = `${endpoints.USER}/Add`;

    CreateItem(
      url,
      patch,
      (data) => {
        update();

        const currentState = cloneDeep(state.initialState);
        setState((state) => ({ ...state, document: currentState }));

        props.emitSuccess(i18next.t("entry_added_successfully"));
      },
      (error) => {
        props.emitErrors(error);
      }
    );
  };

  const update = () => {
    props.update();
  };

  useEffect(() => {
    const initialState = cloneDeep(state.document);
    setState((state) => ({ ...state, initialState }));
  }, []);

  return (
    <>
      <Modal isOpen={props.isModalOpen} scrollable={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">{i18next.t("modal_user_title")}</h5>

          <button
            type="button"
            onClick={() => props.closeModal()}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Card className="mb-0">
            <CardBody>
              <AvForm
                className="needs-validation"
                onValidSubmit={handleValidSubmit}
              >
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="validationUsername">
                        {i18next.t("username")}
                      </Label>
                      <AvField
                        name="document.username"
                        placeholder={i18next.t("placeholder_enter_username")}
                        type="text"
                        className="form-control"
                        value={state.document.username}
                        id="validationUsername"
                        onChange={(e) =>
                          setState((state) => ({
                            ...state,
                            document: {
                              ...state.document,
                              username: e.target.value,
                            },
                          }))
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="validationPassword">
                        {i18next.t("password")}
                      </Label>
                      <AvField
                        name="document.password"
                        placeholder={i18next.t("placeholder_enter_password")}
                        type="text"
                        className="form-control"
                        value={state.document.password}
                        id="validationPassword"
                        onChange={(e) =>
                          setState((state) => ({
                            ...state,
                            document: {
                              ...state.document,
                              password: e.target.value,
                            },
                          }))
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="validationEmail">
                        {i18next.t("email")}
                      </Label>
                      <AvField
                        name="document.email"
                        placeholder={i18next.t("placeholder_enter_email")}
                        type="text"
                        className="form-control"
                        id="validationEmail"
                        onChange={(e) =>
                          setState((state) => ({
                            ...state,
                            document: {
                              ...state.document,
                              email: e.target.value,
                            },
                          }))
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="validationName">
                        {" "}
                        {i18next.t("name")}
                      </Label>
                      <AvField
                        name="document.name"
                        placeholder={i18next.t("placeholder_enter_name")}
                        type="text"
                        className="form-control"
                        value={state.document.name}
                        id="validationName"
                        onChange={(e) =>
                          setState((state) => ({
                            ...state,
                            document: {
                              ...state.document,
                              name: e.target.value,
                            },
                          }))
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="validationCustomPhone">
                        {i18next.t("phone")}
                      </Label>
                      <AvField
                        name="document.phoneNumber"
                        placeholder={i18next.t("placeholder_enter_phone")}
                        type="text"
                        value={state.document.phoneNumber}
                        id="validationCustomPhone"
                        onChange={(e) =>
                          setState((state) => ({
                            ...state,
                            document: {
                              ...state.document,
                              phoneNumber: e.target.value,
                            },
                          }))
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="validationCustomUserRole">
                        {i18next.t("role")}
                      </Label>

                      <AvField
                        type="select"
                        name="document.roleId"
                        id="validationCustomUserRole"
                        value={state.document.roleId}
                        onChange={(e) =>
                          setState((state) => ({
                            ...state,
                            document: {
                              ...state.document,
                              roleId: e.target.value,
                            },
                          }))
                        }
                      >
                        <option
                          value=""
                          selected={state.document.roleId === "" ? true : false}
                        >
                          {i18next.t("select_role")}
                        </option>

                        {state.roles &&
                          state.roles.length > 0 &&
                          state.roles.map((role) => (
                            <option
                              key={role.value}
                              value={role.value}
                              selected={
                                state.document.roleId === role.value
                                  ? true
                                  : false
                              }
                            >
                              {role.name}
                            </option>
                          ))}
                      </AvField>
                    </FormGroup>
                  </Col>
                </Row>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => props.closeModal()}
                  >
                    <i className="fa fa-times mr-1" />
                    {i18next.t("button_cancel")}
                  </button>

                  <Button color="primary" type="submit">
                    <i className="fa fa-plus-circle mr-1" />
                    {i18next.t("button_add")}
                  </Button>
                </div>
              </AvForm>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </>
  );
};

ModalUser.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUsers: () => dispatch(getUsers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ModalUser));

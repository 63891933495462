import PropTypes from "prop-types";
import { UncontrolledTooltip } from "reactstrap";
import { Link } from "react-router-dom";

const LinkAdd = (props) => {
  return (
    <>
      <Link to={props.link} target="_blank" id={"link-to" + props.id}>
        <i className="bx bx-plus-circle" style={{ fontSize: "18px" }} />

        <UncontrolledTooltip placement="top" target={"link-to" + props.id}>
          {props.tooltip}
        </UncontrolledTooltip>
      </Link>
    </>
  );
};

LinkAdd.propTypes = {
  title: PropTypes.string,
};

export default LinkAdd;

import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Modal, FormGroup, Label, Button } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import * as endpoints from "constants/endpoints";
import {
  meter_types,
  collage_types,
  device_locations,
} from "helpers/listsHardcodedData";
import * as jsonpatch from "fast-json-patch";
import { GetData, CreateItem } from "../../helpers/CRUD.js";

import i18next from "i18next";

const ModalWaterMeter = (props) => {
  const old_state = {
    nomRadioModuleTypes: [],
    nomEquipmentManufacturers: [],
    nomRoomTypes: [],
    water_meter_types: [],
  };

  const [state, setState] = useState({
    ...old_state,
    document: {
      radioModuleNumber: "",
      device: {
        serialNumber: "",
        comment: "",
        deviceLocation: "",
        deviceSubType: "",
      },
      equipmentManufacturerId: null,
      radioModuleTypeId: null,
      roomTypeId: null,
      productionYear: 0,
      validityYear: 0,
      collage: 0,
      sealNumber: "",
      columnExportNumber: 0,
      correctionValueRadioModule: 0,
      isActive: false,
    },
  });

  const getInitialState = (old_state) => ({
    ...old_state,
    document: {
      radioModuleNumber: "",
      device: {
        serialNumber: "",
        comment: "",
        deviceLocation: "",
        deviceSubType: "",
      },
      equipmentManufacturerId: null,
      radioModuleTypeId: null,
      roomTypeId: null,
      productionYear: 0,
      validityYear: 0,
      collage: 0,
      sealNumber: "",
      columnExportNumber: 0,
      correctionValueRadioModule: 0,
      isActive: false,
    },
  });

  const resetData = () => {
    setState(getInitialState(state));
  };

  const handleValidSubmit = (event, values) => {
    let patch = jsonpatch.compare(getInitialState().document, state.document);

    if (props.estate_id) {
      patch.push({
        op: "replace",
        path: "/device/estateId",
        value: props.estate_id,
      });
    }

    if (props.shared_part_id) {
      patch.push({
        op: "replace",
        path: "/device/sharedPartId",
        value: props.shared_part_id,
      });
    }

    patch.push({ op: "replace", path: "/device/deviceTypeId", value: 4 });

    const url = `${endpoints.BUILDING_UNITS_REPORT_ENTITIES_ENDPOINT}/WaterMeter/Add`;
    CreateItem(
      url,
      patch,
      (data) => {
        update();
        props.emitSuccess(i18next.t("entry_added_successfully"));
      },
      (error) => {
        props.emitErrors(error);
      }
    );
  };

  const closeModal = () => {
    props.closeModal();
    resetData();
  };

  const update = () => {
    props.update();
    resetData();
  };

  const getNomRadioModuleTypes = () => {
    const url = `${endpoints.NOMENCLATURE_ENDPOINT}/RadioModuleType/GetAll`;

    GetData(
      url,
      (data) => {
        setState((state) => ({ ...state, nomRadioModuleTypes: data.items }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getNomEquipmentManufacturers = () => {
    const url = `${endpoints.NOMENCLATURE_ENDPOINT}/EquipmentManufacturer/GetAllByDeviceType?deviceType=Водомери`;

    GetData(
      url,
      (data) => {
        setState((state) => ({
          ...state,
          nomEquipmentManufacturers: data,
        }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getNomRoomTypes = () => {
    const url = `${endpoints.NOMENCLATURE_ENDPOINT}/RoomType/GetAll`;

    GetData(
      url,
      (data) => {
        setState((state) => ({ ...state, nomRoomTypes: data.items }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    getNomRadioModuleTypes();
    getNomEquipmentManufacturers();
    getNomRoomTypes();
  }, []);

  const meter_types_list = meter_types();
  const collage_types_list = collage_types();
  const device_locations_list = device_locations();

  const water_meter_types = meter_types_list.filter((item) =>
    item.categories.includes("water-meter")
  );

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Modal isOpen={props.isModalOpen} scrollable={true}>
            <div className="modal-header">
              <h5 className="modal-title mt-0">
                {props.shared_part_id !== undefined &&
                  `${i18next.t("modal_water_meter_title")} ${i18next.t(
                    "to_shared_part"
                  )} ${props.shared_part_id}`}
                {props.estate_fish !== undefined &&
                  `${i18next.t("modal_water_meter_title")} ${i18next.t(
                    "to_estate"
                  )} ${props.estate_fish}`}
              </h5>

              <button
                type="button"
                onClick={() => closeModal()}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <Card className="mb-0">
                <CardBody>
                  <AvForm
                    onValidSubmit={handleValidSubmit}
                    className="needs-validation"
                  >
                    <Row className="mb-4">
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="water_meter_serial_number">
                            {i18next.t("field_serial_number")}
                          </Label>

                          <AvField
                            name="document.device.serialNumber"
                            placeholder={i18next.t(
                              "placeholder_enter_serial_number"
                            )}
                            id="water_meter_serial_number"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  device: {
                                    ...state.document.device,
                                    serialNumber: e.target.value,
                                  },
                                },
                              }))
                            }
                            value={state.document.device.serialNumber}
                            className="w-100"
                          />
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="water_meter_radio_number">
                            {i18next.t("field_radio_number")}
                          </Label>

                          <AvField
                            name="document.radioModuleNumber"
                            placeholder={i18next.t(
                              "placeholder_enter_radio_number"
                            )}
                            id="water_meter_radio_number"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  radioModuleNumber: e.target.value,
                                },
                              }))
                            }
                            value={state.document.radioModuleNumber}
                            className="w-100"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="water_meter_equipment_manufacturer">
                            {i18next.t("manufacture")}
                          </Label>

                          <AvField
                            type="select"
                            name="document.equipmentManufacturerId"
                            id="water_meter_equipment_manufacturer"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  equipmentManufacturerId: e.target.value,
                                },
                              }))
                            }
                            value={state.document.equipmentManufacturerId}
                            className="w-100"
                          >
                            <option
                              key={`equipment-manufacturer-id-default`}
                              value=""
                              selected={
                                state.document.equipmentManufacturerId === ""
                              }
                            >
                              {i18next.t("select_equipment_manufacturer")}
                            </option>
                            {state.nomEquipmentManufacturers.length > 0 &&
                              state.nomEquipmentManufacturers.map((option) => (
                                <option
                                  key={`equipment-manufacturer-id-${option.id}`}
                                  value={option.id}
                                >
                                  {option.name}
                                </option>
                              ))}
                          </AvField>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="water_meter_radio_module_type">
                            {i18next.t("radioModuleTypeId")}
                          </Label>

                          <AvField
                            type="select"
                            name="document.radioModuleTypeId"
                            id="water_meter_radio_module_type"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  radioModuleTypeId: e.target.value,
                                },
                              }))
                            }
                            value={state.document.radioModuleTypeId}
                            className="w-100"
                          >
                            <option
                              key={`radio-module-type-default`}
                              value=""
                              selected={state.document.radioModuleTypeId === ""}
                            >
                              {i18next.t("select_radio_module_type")}
                            </option>
                            {state.nomRadioModuleTypes.length > 0 &&
                              state.nomRadioModuleTypes.map((option) => (
                                <option
                                  key={`radio-module-type-${option.id}`}
                                  value={option.id}
                                >
                                  {option.name}
                                </option>
                              ))}
                          </AvField>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="water_meter_device_location">
                            {i18next.t("device_location")}
                          </Label>

                          <AvField
                            type="select"
                            name="document.device.deviceLocation"
                            id="water_meter_device_location"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  device: {
                                    ...state.document.device,
                                    deviceLocation: e.target.value,
                                  },
                                },
                              }))
                            }
                            value={state.document.device.deviceLocation}
                            className="w-100"
                          >
                            <option
                              key={`device-location-default`}
                              value=""
                              selected={state.document.deviceLocation === ""}
                            >
                              {i18next.t("select_location")}
                            </option>
                            {device_locations_list &&
                              device_locations_list.length > 0 &&
                              device_locations_list.map((option) => (
                                <option
                                  key={`device-location-${option.id}`}
                                  value={option.key}
                                  selected={
                                    state.document.device.deviceLocation ===
                                    option.key
                                  }
                                >
                                  {option.value}
                                </option>
                              ))}
                          </AvField>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="water_meter_room_type">
                            {i18next.t("location_in_apartment")}
                          </Label>
                          <AvField
                            type="select"
                            name="document.roomTypeId"
                            id="water_meter_room_type"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  roomTypeId: e.target.value,
                                },
                              }))
                            }
                            value={state.document.roomTypeId}
                            className="w-100"
                          >
                            <option
                              key={`room-type-default`}
                              value=""
                              selected={state.document.roomTypeId === ""}
                            >
                              {i18next.t("select_room_type")}
                            </option>
                            {state.nomRoomTypes.length > 0 &&
                              state.nomRoomTypes.map((option) => (
                                <option
                                  key={`room-type-${option.id}`}
                                  value={option.id}
                                >
                                  {option.name}
                                </option>
                              ))}
                          </AvField>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="water_meter_production_year">
                            {i18next.t("production_year")}
                          </Label>
                          <AvField
                            type="number"
                            min={1900}
                            max={2099}
                            name="document.productionYear"
                            placeholder={i18next.t(
                              "placeholder_enter_production_year"
                            )}
                            id="water_meter_production_year"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  productionYear: e.target.value,
                                  validityYear: parseInt(e.target.value) + 10
                                },
                              }))
                            }
                            value={state.document.productionYear}
                            className="w-100"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="water_meter_validity_year">
                            {i18next.t("validity_year")}
                          </Label>
                          <AvField
                            type="number"
                            min={1900}
                            max={2099}
                            name="document.validityYear"
                            placeholder={i18next.t(
                              "placeholder_enter_validity_year"
                            )}
                            id="water_meter_validity_year"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  validityYear: e.target.value,
                                },
                              }))
                            }
                            value={state.document.validityYear}
                            className="w-100"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="water_meter_collage">
                            {i18next.t("collage")}
                          </Label>
                          <AvField
                            type="select"
                            name="document.collage"
                            id="water_meter_collage"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  collage: e.target.value,
                                },
                              }))
                            }
                            value={state.document.collage}
                            className="w-100"
                          >
                            <option
                              key={`collage-default`}
                              value=""
                              selected={state.document.collage === ""}
                            >
                              {i18next.t("select_collage")}
                            </option>

                            {collage_types_list &&
                              collage_types_list.length > 0 &&
                              collage_types_list.map((option) => (
                                <option
                                  key={`collage-${option.id}`}
                                  value={option.key}
                                  selected={
                                    state.document.collage === option.key
                                  }
                                >
                                  {option.value}
                                </option>
                              ))}
                          </AvField>
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="water_meter_type">
                            {i18next.t("type")}
                          </Label>
                          <AvField
                            type="select"
                            name="document.device.deviceSubType"
                            id="water_meter_type"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  device: {
                                    ...state.document.device,
                                    deviceSubType: e.target.value,
                                  },
                                },
                              }))
                            }
                            value={state.document.device.deviceSubType}
                            className="w-100"
                          >
                            <option
                              key={`type-default`}
                              value=""
                              selected={
                                state.document.device.deviceSubType === ""
                              }
                            >
                              {i18next.t("select_type")}
                            </option>

                            {water_meter_types &&
                              water_meter_types.length > 0 &&
                              water_meter_types.map((option) => (
                                <option
                                  key={`type-${option.id}`}
                                  value={option.key}
                                  selected={
                                    state.document.device.deviceSubType ===
                                    option.key
                                  }
                                >
                                  {option.value}
                                </option>
                              ))}
                          </AvField>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="water_meter_seal_number">
                            {i18next.t("field_seal_number")}
                          </Label>
                          <AvField
                            type="text"
                            name="document.sealNumber"
                            placeholder={i18next.t(
                              "placeholder_enter_seal_number"
                            )}
                            id="water_meter_seal_number"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  sealNumber: e.target.value,
                                },
                              }))
                            }
                            value={state.document.sealNumber}
                            className="w-100"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="water_meter_comment">
                            {i18next.t("field_comment")}
                          </Label>
                          <AvField
                            type="textarea"
                            name="document.device.comment"
                            placeholder={i18next.t("placeholder_enter_comment")}
                            id="water_meter_comment"
                            maxLength="200"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  device: {
                                    ...state.document.device,
                                    comment: e.target.value,
                                  },
                                },
                              }))
                            }
                            value={state.document.device.comment}
                            className="w-100"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col md="6">
                        {state.document.device.deviceSubType === "WARM" && (
                          <FormGroup>
                            <Label htmlFor="water_meter_export_number_column">
                              {i18next.t("columnExportNumber")}
                            </Label>
                            <AvField
                              type="number"
                              min={1}
                              max={6}
                              name="document.columnExportNumber"
                              placeholder={i18next.t(
                                "placeholder_enter_column_export_number"
                              )}
                              id="water_meter_export_number_column"
                              onChange={(e) =>
                                setState((state) => ({
                                  ...state,
                                  document: {
                                    ...state.document,
                                    columnExportNumber: e.target.value,
                                  },
                                }))
                              }
                              value={state.document.columnExportNumber}
                              className="w-100"
                            />
                          </FormGroup>
                        )}
                      </Col>

                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="water_meter_radio_number_correction_value">
                            {i18next.t("correctionValueRadioModule")}
                          </Label>

                          <AvField
                            type="number"
                            min={0}
                            name="document.correctionValueRadioModule"
                            placeholder={i18next.t(
                              "placeholder_enter_correction_value_radio_module"
                            )}
                            id="water_meter_radio_number_correction_value"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  correctionValueRadioModule: e.target.value,
                                },
                              }))
                            }
                            value={state.document.correctionValueRadioModule}
                            className="w-100"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => closeModal()}
                      >
                        <i className="fa fa-times mr-1" />
                        {i18next.t("button_cancel")}
                      </button>

                      <Button color="primary" type="submit">
                        <i className="fa fa-plus-circle mr-1" />
                        {i18next.t("button_add")}
                      </Button>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </div>
          </Modal>
        </Container>
      </div>
    </>
  );
};

ModalWaterMeter.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUsers: () => dispatch(getUsers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ModalWaterMeter));

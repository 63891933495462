import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";

const BarPageTitle = (props) => {
  return (
    <>
      <Row>
        <Col xs="12">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h3>{props.pageTitle}</h3>
          </div>
        </Col>
      </Row>
    </>
  );
};

BarPageTitle.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
};

export default BarPageTitle;

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

const ProfileMenu = (props) => {
  const [state, setState] = useState({
    menu: false,
    user: {},
  });

  const toggle = () => {
    setState((state) => ({
      menu: !state.menu,
    }));
  };

  useEffect(() => {
    // if (localStorage.getItem('authUser')) {
    //   const obj = JSON.parse(localStorage.getItem('authUser'));
    //   setState(state=>({...state, user: obj }));
    // }
  }, []);

  const nameid = JSON.parse(localStorage.getItem("nameid"));

  return (
    <>
      <Dropdown isOpen={state.menu} toggle={toggle} className="d-inline-block">
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <span className="d-none d-lg-inline-block ml-2 mr-1">
            {state.user?.username}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem tag="a" href={`/user-profile/${nameid}`}>
            <i className="bx bx-user font-size-16 align-middle mr-1" />
            {props.t("Profile")}
          </DropdownItem>

          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

ProfileMenu.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(ProfileMenu));

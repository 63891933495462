import i18next from "i18next";

const translateHeaterTypeEnumCollection = (array) => {
  return array.map((element) => {
    let name = element.name;

    switch (element.type.trim()) {
      case i18next.t("heater_type_old_panel"):
        name = element.name.replace(
          element.type,
          i18next.t("heater_type_old_panel")
        );
        break;
      case i18next.t("heater_type_new_panel"):
        name = element.name.replace(
          element.type,
          i18next.t("heater_type_new_panel")
        );
        break;
      case i18next.t("heater_type_cast_iron"):
        name = element.name.replace(
          element.type,
          i18next.t("heater_type_cast_iron")
        );
        break;
      case i18next.t("heater_type_aluminum"):
        name = element.name.replace(
          element.type,
          i18next.t("heater_type_aluminum")
        );
        break;
      case i18next.t("heater_type_lyre_pipe"):
        name = element.name.replace(
          element.type,
          i18next.t("heater_type_lyre_pipe")
        );
        break;
      case i18next.t("heater_type_lyre_dryer"):
        name = element.name.replace(
          element.type,
          i18next.t("heater_type_lyre_dryer")
        );
        break;
      case i18next.t("heater_type_lyre_tube"):
        name = element.name.replace(
          element.type,
          i18next.t("heater_type_lyre_tube")
        );
        break;
    }

    const item = {
      id: element.id,
      name: name,
      type: element.type,
    };

    return item;
  });
};

// heater_type_types_list
const translateHeaterTypeNameEnumValue = (item) => {
  let name = item.name;

  switch (item.type.trim()) {
    case "radiator-panel-old":
      name = item.name.replace(item.type, "Панелен радиатор (стар)");
      break;
    case "radiator-panel-new":
      name = item.name.replace(item.type, "Панелен радиатор (нов)");
      break;
    case "radiator-iron":
      name = item.name.replace(item.type, "Чугунен радиатор");
      break;
    case "radiator-aluminium":
      name = item.name.replace(item.type, "Алуминиев радиатор");
      break;
    case "lire-rise":
      name = item.name.replace(item.type, i18next.t("heater_type_lyre_pipe"));
      break;
    case "lire-dryer":
      name = item.name.replace(item.type, i18next.t("heater_type_lyre_dryer"));
      break;
    case i18next.t("heater_type_lyre_tube"):
      name = item.name.replace(item.type, i18next.t("heater_type_lyre_tube"));
      break;
  }

  return name;
};

const translateHeaterTypeEnumValue = (type) => {
  let typeValue = "";

  switch (type && type.trim()) {
    case i18next.t("heater_type_old_panel"):
      typeValue = i18next.t("heater_type_old_panel");
      break;
    case i18next.t("heater_type_new_panel"):
      typeValue = i18next.t("heater_type_new_panel");
      break;
    case i18next.t("heater_type_cast_iron"):
      typeValue = i18next.t("heater_type_cast_iron");
      break;
    case i18next.t("heater_type_aluminum"):
      typeValue = i18next.t("heater_type_aluminum");
      break;
    case i18next.t("heater_type_lyre_pipe"):
      typeValue = i18next.t("heater_type_lyre_pipe");
      break;
    case i18next.t("heater_type_lyre_dryer"):
      typeValue = i18next.t("heater_type_lyre_dryer");
      break;
    case i18next.t("heater_type_lyre_tube"):
      typeValue = i18next.t("heater_type_lyre_tube");
      break;
  }

  return typeValue;
};

//statuses_list
const translateStatusEnumValue = (status) => {
  let statusValue = "";

  switch (status) {
    case "DEMOUNTED":
      statusValue = i18next.t("demounted");
      break;
    case "STRAIGHT":
      statusValue = i18next.t("with-device");
      break;
    case "WITHOUT_DEVICE":
      statusValue = i18next.t("without-device");
      break;
  }

  return statusValue;
};

//report_statuses_list
const translateReportStatusEnumValue = (status) => {
  let statusValue = "";

  switch (status) {
    case "ACCESS_ALLOWED":
      statusValue = i18next.t("report_access_allowed");
      break;
    case "ACCESS_DENIED":
      statusValue = i18next.t("report_access_denied");
      break;
    case "ACCESS_DENIED_FIRST_DATE":
      statusValue = i18next.t("report_access_denied_first_date");
      break;
    case "ACCESS_DENIED_SECOND_DATE":
      statusValue = i18next.t("report_access_denied_seconde_date");
      break;
    case "THIRD_DATE_PAID_REPORT":
      statusValue = i18next.t("report_third_date_paid");
      break;
    case "REMOTE_REPORT":
      statusValue = i18next.t("report_remote");
      break;
  }

  return statusValue;
};

//installation_types_list
const translateInstallationTypeEnumValue = (installationType) => {
  let installationTypeValue = "";

  switch (installationType) {
    case "ks":
      installationTypeValue = i18next.t("installation_plate_new");
      break;
    case "ks1":
      installationTypeValue = i18next.t("installation_plate_old");
      break;
    case "ks3":
      installationTypeValue = i18next.t("installation_aparator");
      break;
    case "ks2":
      installationTypeValue = i18next.t("installation_siemens_old_device");
      break;
    case "FILLING":
      installationTypeValue = i18next.t("installation_giving");
      break;
    case "RETURNING":
      installationTypeValue = i18next.t("installation_returning");
      break;
  }

  return installationTypeValue;
};

//device_locations_list
const translateDeviceLocationEnumValue = (deviceLocation) => {
  let deviceLocationValue = "";

  switch (deviceLocation) {
    case "INSIDE":
      deviceLocationValue = i18next.t("inside");
      break;
    case "OUTSIDE":
      deviceLocationValue = i18next.t("outside");
      break;
  }

  return deviceLocationValue;
};

//collage_types_list
const translateCollageEnumValue = (collage) => {
  let collageValue = "";

  switch (parseFloat(collage)) {
    case 0.5:
      collageValue = "½";
      break;
    case 0.75:
      collageValue = "¾";
      break;
  }

  return collageValue;
};

//meter_types_list
const translateTypeEnumValue = (type) => {
  let typeValue = "";

  switch (type) {
    case "COLD":
      typeValue = i18next.t("installation_cold");
      break;
    case "WARM":
      typeValue = i18next.t("installation_hot");
      break;
    case "HEAT":
      typeValue = i18next.t("heatmeter");
      break;
    case "HEAT_COOL":
      typeValue = i18next.t("heat_cool_meter");
      break;
  }

  return typeValue;
};

//conditions
const translateConditionEnumValue = (condition) => {
  let conditionValue = "";

  switch (condition) {
    case "STRAIGHT":
      conditionValue = i18next.t("straight");
      break;
    case "IMPACTED":
      conditionValue = i18next.t("impacted");
      break;
    case "EXPIRED_METROLOGY":
      conditionValue = i18next.t("expired-metrology");
      break;
    case "DEFECTIVE":
      conditionValue = i18next.t("defective");
      break;
    case "DAMAGED_FIRST_SEASON":
      conditionValue = i18next.t("damaged-first-season");
      break;
    case "DAMAGED_SECOND_OR_NEXT_SEASON":
      conditionValue = i18next.t("damaged-second-next-season");
      break;
    case "NO_DEVICE":
      conditionValue = i18next.t("no-device");
      break;

  }

  return conditionValue;
};

//measure_units_list
const translateMeasureUnitEnumValue = (measureUnit) => {
  let measureUnitValue = "";

  switch (measureUnit) {
    case "kwh":
      measureUnitValue = i18next.t("kwh");
      break;
    case "mwh":
      measureUnitValue = i18next.t("mwh");
      break;
    case "gj":
      measureUnitValue = i18next.t("gj");
      break;
    case "mcal":
      measureUnitValue = i18next.t("mcal");
      break;
  }

  return measureUnitValue;
};

//heat_meter_kinds_list
const translateHeatMeterKindEnumValue = (kind) => {
  let kindValue = "";

  switch (kind) {
    case "TURBINE":
      kindValue = i18next.t("heat-meter-turbine");
      break;
    case "ULTRASONIC":
      kindValue = i18next.t("heat-meter-ultrasonic");
      break;
  }

  return kindValue;
};

//hca_type_types_list
const translateHCATypeEnumValue = (type) => {
  let typeValue = "";

  switch (type) {
    case "ONE_SENSOR":
      typeValue = i18next.t("hca_type_one_sensor");
      break;
    case "TWO_SENSOR":
      typeValue = i18next.t("hca_type_two_sensor");
      break;
    case "OUTER_SENSOR":
      typeValue = i18next.t("hca_type_outer_sensor");
      break;
  }

  return typeValue;
};

//device_types_list
const translateDeviceTypeEnumValue = (type) => {
  let typeValue = "";

  switch (type) {
    case "1":
      typeValue = i18next.t("subscriber_station");
      break;
    case "2":
      typeValue = i18next.t("heatmeter_single");
      break;
    case "3":
      typeValue = i18next.t("allocator_single");
      break;
    case "4":
      typeValue = i18next.t("watermeter_single");
      break;
  }

  return typeValue;
};

//building_device_types_list
const translateBuildingDeviceTypeEnumValue = (type) => {
  let typeValue = "";

  switch (type) {
    case "HEAT_COST_ALLOCATORS":
      typeValue = i18next.t("allocator_single");
      break;
    case "HEAT_METERS":
      typeValue = i18next.t("heatmeter_apartment");
      break;
    case "MIXED_HEAT_METERS":
      typeValue = i18next.t("allocator_mixed_building");
      break;
    case "MIXED_HEAT_COST_ALLOCATORS":
      typeValue = i18next.t("heatmeter_apartment_mixed_building");
      break;
  }
};

//report_types_list
const translateReportTypeEnumValue = (type) => {
  let typeValue = "";

  switch (type) {
    case "common-part":
      typeValue = i18next.t("shared-part");
      break;
    case "estate":
      typeValue = i18next.t("estate");
      break;
  }

  return typeValue;
};

const translateMonthEnumValue = (type) => {
  let typeValue = "";

  switch (parseInt(type)) {
    case 1:
      typeValue = i18next.t("january");
      break;
    case 2:
      typeValue = i18next.t("february");
      break;
    case 3:
      typeValue = i18next.t("march");
      break;
    case 4:
      typeValue = i18next.t("april");
      break;
    case 5:
      typeValue = i18next.t("may");
      break;
    case 6:
      typeValue = i18next.t("june");
      break;
    case 7:
      typeValue = i18next.t("july");
      break;
    case 8:
      typeValue = i18next.t("august");
      break;
    case 9:
      typeValue = i18next.t("september");
      break;
    case 10:
      typeValue = i18next.t("october");
      break;
    case 11:
      typeValue = i18next.t("november");
      break;
    case 12:
      typeValue = i18next.t("december");
      break;
  }

  return typeValue;
};

const translateFieldProp = (type) => {
  let typeValue = "";

  switch (type) {
    case "FIELD_MIN_VALUE":
      typeValue = i18next.t("field_min_value");
      break;
    case "FIELD_MAX_VALUE":
      typeValue = i18next.t("field_max_value");
      break;
  }

  return typeValue;
};

//report_frequency_list
const translateReportFrequencyEnumValue = (type) => {
  let frequencyValue = "";

  switch (type) {
    case "PER_MONTH":
      frequencyValue = i18next.t("monthly_frequency");
      break;
    case "PER_QUARTER":
      frequencyValue = i18next.t("three_monthly_frequency");
      break;
    case "PER_YEAR":
      frequencyValue = i18next.t("yearly_frequency");
      break;
  }

  return frequencyValue;
};

export {
  translateHeaterTypeEnumCollection,
  translateHeaterTypeNameEnumValue,
  translateHeaterTypeEnumValue,
  translateStatusEnumValue,
  translateReportStatusEnumValue,
  translateInstallationTypeEnumValue,
  translateDeviceLocationEnumValue,
  translateCollageEnumValue,
  translateTypeEnumValue,
  translateConditionEnumValue,
  translateMeasureUnitEnumValue,
  translateHeatMeterKindEnumValue,
  translateHCATypeEnumValue,
  translateDeviceTypeEnumValue,
  translateBuildingDeviceTypeEnumValue,
  translateReportTypeEnumValue,
  translateMonthEnumValue,
  translateFieldProp,
  translateReportFrequencyEnumValue
};

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardBody, Col, Row, FormGroup, Label } from "reactstrap";
import { Modal, Button } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { getUsers } from "store/contacts/actions";
import * as endpoints from "constants/endpoints";
import * as jsonpatch from "fast-json-patch";
import { GetData, CreateItem } from "../../helpers/CRUD.js";
import i18next from "i18next";

const ModalEstateComment = (props) => {
  const old_state = {
    nomProfessionalHouseManagementCompany: [],
  };

  const [state, setState] = useState({
    ...old_state,
    document: {
      comment: "",
      dateCreated: "",
      isActive: false,
    },
  });

  const getInitialState = (old_state) => ({
    ...old_state,
    document: {
      comment: "",
      dateCreated: "",
      isActive: false,
    },
  });

  const resetData = () => {
    setState(getInitialState(state));
  };

  const handleSubmit = (event, errors, values) => {
    setState((state) => ({ ...state, errors, values }));
  };

  const handleValidSubmit = (event, values) => {
    let patch = jsonpatch.compare(getInitialState().document, state.document);

    if (props.estate_id) {
      patch.push({
        op: "replace",
        path: "/estateId",
        value: props.estate_id,
      });
    }

    const url = `${endpoints.BUILDING_UNITS_ADDITIONAL_ENDPOINT}/EstateComment/Add`;

    CreateItem(
      url,
      patch,
      (data) => {
        update();
        props.emitSuccess(i18next.t("entry_added_successfully"));
      },
      (error) => {
        props.emitErrors(error);
      }
    );
  };

  const closeModal = () => {
    props.closeModal();
    resetData();
  };

  const update = () => {
    props.update();
    resetData();
  };

  return (
    <>
      <Modal isOpen={props.isModalOpen} scrollable={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            {i18next.t("modal_estate_comment_title")} {props.estate_fish}
          </h5>

          <button
            type="button"
            onClick={() => closeModal()}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Card className="mb-0">
            <CardBody>
              <AvForm
                onSubmit={handleSubmit}
                onValidSubmit={handleValidSubmit}
                className="needs-validation"
              >
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <Label htmlFor="estate_comment">
                        {i18next.t("field_comment")}
                      </Label>
                      <AvField
                        name="document.comment"
                        placeholder={i18next.t("placeholder_enter_comment")}
                        type="textarea"
                        errorMessage={i18next.t("wrong-comment")}
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                          },
                          maxLength: { value: 400 },
                        }}
                        value={state.document.comment}
                        onChange={(e) =>
                          setState((state) => ({
                            ...state,
                            document: {
                              ...state.document,
                              comment: e.target.value,
                            },
                          }))
                        }
                        id="estate_comment"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => closeModal()}
                  >
                    <i className="fa fa-times mr-1" />
                    {i18next.t("button_cancel")}
                  </button>

                  <Button color="primary" type="submit">
                    <i className="fa fa-plus-circle mr-1" />
                    {i18next.t("button_add")}
                  </Button>
                </div>
              </AvForm>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </>
  );
};

ModalEstateComment.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUsers: () => dispatch(getUsers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ModalEstateComment));

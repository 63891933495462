import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Container } from "reactstrap";
import { Modal } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import * as endpoints from "constants/endpoints";
import { GetData } from "../../helpers/CRUD.js";
import i18next from "i18next";
import {
  translateDeviceLocationEnumValue,
} from "helpers/translateHardcodedData"

const ModalEstateReportsDetails = (props) => {
  const [state, setState] = useState({
    tableData: [],
  });

  const closeModal = () => {
    props.closeModal();
  };

  const getTableData = () => {
    const url = `${endpoints.CALCULATIONS}/GetAllDevicesPerEstateHistory/${props.estateHistoryId}`;

    GetData(
      url,
      (data) => {
        setState((state) => ({
          ...state,
          tableData: data,
        }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    props.isModalOpen && getTableData();
  }, [props.isModalOpen]);

  const tableHead = {
    backgroundColor: "#F0F0F0",
    borderColor: "#C8C8C8",
    verticalAlign: "middle",
  };

  const tableBody = {
    borderColor: "#C8C8C8",
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <AvForm>
            <Modal isOpen={props.isModalOpen} scrollable={true}>
              <div className="modal-header">
                <h5 className="modal-title mt-0">
                  {i18next.t("modal_estate_reports_details")}
                </h5>

                <button
                  type="button"
                  onClick={() => closeModal()}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div style={{ padding: 16 }}>
                <Table
                  className="table scrollable table-bordered"
                  style={{ tableLayout: "fixed" }}
                >
                  <Thead>
                    <Tr>
                      <Th style={tableHead}>{i18next.t("deviceNumber")}</Th>
                      <Th style={tableHead}>
                        {i18next.t("deviceTypeLocation")}
                      </Th>
                      <Th style={tableHead}>{i18next.t("old")}</Th>
                      <Th style={tableHead}>{i18next.t("new")}</Th>
                      <Th style={tableHead}>{i18next.t("official")}</Th>
                      <Th style={tableHead}>{i18next.t("consumption")}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {state.tableData && state.tableData.length === 0 ? (
                      <Tr>
                        <Td
                          colSpan={6}
                          className="align-items-center text-center"
                          style={tableBody}
                        >
                          {i18next.t("table_missing_data")}
                        </Td>
                      </Tr>
                    ) : (
                      state.tableData.map((row, i) => (
                        <Tr key={`period-${row.id}`}>
                          <Td style={tableBody}>{row.serialNumber}</Td>
                          <Td style={tableBody}>{row.name}{row.deviceLocation && ` - ${translateDeviceLocationEnumValue(row.deviceLocation)}`}</Td>
                          <Td style={tableBody}>{row.oldReading.toFixed(6)}</Td>
                          <Td style={tableBody}>{row.newReading.toFixed(6)}</Td>
                          <Td style={tableBody}>
                            <AvField
                              type="checkbox"
                              name="checkAll"
                              checked={row.isCalculatedValue}
                              disabled
                              id="checkAll"
                            />
                          </Td>
                          <Td style={tableBody}>{row.consumption.toFixed(6)}</Td>
                        </Tr>
                      ))
                    )}
                  </Tbody>
                </Table>
              </div>
            </Modal>
          </AvForm>
        </Container>
      </div>
    </>
  );
};

ModalEstateReportsDetails.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUsers: () => dispatch(getUsers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ModalEstateReportsDetails));

import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import BarPageTitle from "../../components/Common/BarPageTitle";
import ModalBuilding from "components/Modals/ModalBuilding";
import TableComponent from "components/Common/TableComponent";
import { TextField, Tooltip } from "@material-ui/core";
import { throttle } from "lodash";
import ButtonAdd from "components/Common/ButtonAdd.js";
import { GetData } from "../../helpers/CRUD.js";
import i18next from "i18next";
import Pagination from "components/Common/Pagination";
import PaginationSelect from "components/Common/PaginationSelect";
import FilterBar from "components/Common/FilteringBar";
import * as endpoints from "constants/endpoints";
import {translateReportFrequencyEnumValue} from "helpers/translateHardcodedData";

const Buildings = (props) => {
  const [state, setState] = useState({
    pageTitle: i18next.t("menu-buildings"),
    isModalOpen: false,
    buildings: [],
    isLoading: false,
    isLoaded: false,
    page: 1,
    totalCount: null,
    pageSize: 100,
    sorterLabel: "",
    sorterDirection: "",
    isActive: true,
  });

  const emitErrors = (error) => {
    props.onErrorsShow(error.body);
  };

  const emitSuccess = (success) => {
    props.onSuccessShow(success);
  };

  const openModal = () => {
    setState((state) => ({ ...state, isModalOpen: !state.isModalOpen }));
    removeBodyCss();
  };

  const closeModal = () => {
    setState((state) => ({ ...state, isModalOpen: false }));
    removeBodyCss();
  };

  const closeAndUpdate = (modalCallback) => {
    setState((state) => ({ ...state, isModalOpen: false }));
    modalCallback();
    removeBodyCss();
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };

  const filterBuildings = (value) => {
    let endpointQueryParam = "";
    let endpointSortAdd = "";

    if (value) {
      endpointQueryParam = `filterText=${value}`;
    }

    if (state.sorterLabel !== "") {
      endpointSortAdd = `&orderBy=${state.sorterLabel}_${state.sorterDirection}`;
    }

    const url = `${endpoints.BUILDING}/GetAll?${endpointQueryParam}&page=${state.page}&size=${state.pageSize}${endpointSortAdd}&isActive=${state.isActive}`;

    GetData(
      url,
      (data) => {
        let tableBodyData;

        if (data) {
          tableBodyData = data.items.map((item) => {
            const zones = item.zones.map((zone) => {
              return `${i18next.t("label-entrance")} ${zone.entrance}`;
            });
            return {
              id: item.id,
              data: {
                address:
                    item.address && item.address.streetName
                        ? `${item.address.streetName}${
                            item.address.buildingNumber
                                ? " - " + item.address.buildingNumber
                                : ""
                        }${item.address.buildingSubNumber
                        ? " - " + item.address.buildingSubNumber
                        : ""}`
                        : "",
                number: item.externalId,
                //innerNumber: "?",
                zones: zones.join(", "),
                reportToplofikaciq: item.toploficationReport
                    ? ( item.toploficationReport.toploficationReportType
                          ? translateReportFrequencyEnumValue(item.toploficationReport.toploficationReportType).toLowerCase()
                        : "")
                    : "",
                reportSw: item.sofiaWaterReport
                    ? ( item.sofiaWaterReport.reportFrequency
                          ? translateReportFrequencyEnumValue(item.sofiaWaterReport.reportFrequency).toLowerCase()
                          : "")
                    : "",
                /*reportGas: "",
                reportElectricity: "",
                reportCooling:"",*/
                isActive: item.isActive,
              },
            };
          });
        }

        setState((state) => ({
          ...state,
          buildings: tableBodyData,
          isLoaded: true,
          totalCount: data.totalCount,
        }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const filterBuildingsThrottled = throttle(filterBuildings, 800);

  const updatePage = (page) => {
    setState((state) => ({ ...state, page }));
  };

  const onPageSizeUpdate = (pageSize) => {
    setState((state) => ({ ...state, pageSize, page: 1 }));
  };

  const onSorterClick = (label, direction) => {
    setState((state) => ({
      ...state,
      page: 1,
      sorterLabel: label,
      sorterDirection: direction,
    }));
  };

  useEffect(() => {
    filterBuildings();
  }, [state.page, state.pageSize, state.isActive]);

  useEffect(() => {
    onPageSizeUpdate(state.pageSize);
  }, [state.isActive]);

  useEffect(() => {
    setState((state) => ({ ...state, isLoading: true }));
  }, []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <BarPageTitle pageTitle={state.pageTitle} />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <ModalBuilding
                    isModalOpen={state.isModalOpen}
                    closeModal={closeModal}
                    update={() => closeAndUpdate(filterBuildings)}
                    emitErrors={emitErrors}
                    emitSuccess={emitSuccess}
                  ></ModalBuilding>

                  <Row className="mb-4">
                    <Col>
                      <Tooltip
                        title={i18next.t("tooltip-search-subscriber-number")}
                        placement="right-start"
                      >
                        <TextField
                          InputLabelProps={{
                            style: {
                              pointerEvents: "auto",
                            },
                          }}
                          placeholder={i18next.t("placeholder_search")}
                          variant="outlined"
                          type="text"
                          size="small"
                          onChange={(e) =>
                            filterBuildingsThrottled(e.target.value)
                          }
                        />
                      </Tooltip>
                    </Col>

                    <Col md="2" className="ml-auto mr-0">
                      <PaginationSelect
                        pageSize={state.pageSize}
                        index={1}
                        onPageSizeUpdate={onPageSizeUpdate}
                      />
                    </Col>

                    <Col md="1" style={{display:"none"}}>
                      <div className="text-right">
                        <ButtonAdd
                          onBtnClick={openModal}
                          data-toggle="modal"
                          tooltip={i18next.t("tooltip-add-new-building")}
                        />
                      </div>
                    </Col>
                  </Row>

                  <FilterBar
                    tableData={state.buildings}
                    isActive
                    state={state}
                    setState={setState}
                  ></FilterBar>

                  <TableComponent
                    dataHead={[
                      i18next.t("address"),
                      i18next.t("subscriber-number-id"),
                     /* i18next.t("inner-number"),*/
                      i18next.t("zones"),
                      i18next.t("report-toplofikaciq"),
                      i18next.t("report-sw"),
                      /*i18next.t("report-gas"),
                      i18next.t("report-electricity"),
                      i18next.t("report-cooling"),*/
                      i18next.t("actions"),
                    ]}
                    dataBody={state.buildings}
                    sorterData={["externalId", "address.streetName"]}
                    dataTooltips={["tooltip text 1", "", "tooltip text 3"]}
                    onSorterClick={onSorterClick}
                    url_path={"buildings"}
                    category={"buildings"}
                    enableLinkView={true}
                    isLoading={state.isLoading}
                    isLoaded={state.isLoaded}
                  />

                  <Pagination
                    updatePage={updatePage}
                    page={state.page}
                    totalCount={state.totalCount}
                    pageSize={state.pageSize}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

Buildings.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUsers: () => dispatch(getUsers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Buildings));

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import { cloneDeep } from "lodash";
import { CardTitle, Col, Row } from "reactstrap";
import ConfirmBox from "../../components/Common/ConfirmBox.js";
import ListActions from "../../components/Common/ListActions.js";
import {
  SaveItem,
  DeleteItem,
  ToggleItem,
  GetData,
} from "../../helpers/CRUD.js";
import * as jsonpatch from "fast-json-patch";
import i18next from "i18next";

const NomenclatureSingleWrapper = (props) => {
  const history = useHistory();

  const [state, setState] = useState({
    mode: "view",
    document: {},
    confirm_delete: false,
    confirm_toggle: false,
    confirm_save: false,
    success_dlg: false,
    error_dlg: false,
    actionType: "",
  });

  const getData = (url) => {
    GetData(
      url,
      (data) => {
        const initialDocument = cloneDeep(data);

        setState((state) => ({ ...state, document: data, initialDocument }));
      },
      (error) => {
        props.emitErrors(error);
      }
    );
  };

  const toggleActive = () => {
    const document = state.document;
    document.isActive = !document.isActive;

    setState((state) => ({ ...state, document }));
    handleToggleStatusPost();
  };

  const handleToggleStatusPost = () => {
    const url = `${props.toggleUrl}&isActive=${state.document.isActive}`;

    ToggleItem(
      url,
      () => {
        getData(props.getUrl);
        state.document.isActive
          ? props.emitSuccess(i18next.t("entry_activated_successfully"))
          : props.emitSuccess(i18next.t("entry_deactivated_successfully"));
      },
      (error) => {
        props.emitErrors(error);
      }
    );
  };

  const handleEdit = () => {
    setState((state) => ({ ...state, mode: "edit" }));
  };

  const handleSave = () => {
    setState((state) => ({ ...state, mode: "view" }));

    let patch = jsonpatch.compare(state.initialDocument, state.document);

    const url = props.saveUrl;

    SaveItem(
      url,
      patch,
      () => {
        props.emitSuccess(i18next.t("entry_updated_successfully"));
        setState((state) => ({ ...state, isModalOpen: false }));
      },
      (error) => {
        props.emitErrors(error);
      }
    );
  };

  const handleDelete = () => {
    const url = props.deleteUrl;

    DeleteItem(url, history.goBack, (error) => {
      props.emitErrors(error);
    });
  };

  const cancelConfirmBox = () => {
    setState((state) => ({
      ...state,
      confirm_delete: false,
      confirm_toggle: false,
      confirm_save: false,
    }));
  };

  const confirmDelete = () => {
    setState((state) => ({
      ...state,
      confirm_delete: false,
      success_dlg: true,
    }));
  };

  const confirmToggle = () => {
    setState((state) => ({
      ...state,
      confirm_toggle: false,
      success_dlg: true,
    }));
  };

  const confirmSave = () => {
    setState((state) => ({
      ...state,
      confirm_save: false,
      success_dlg: true,
      mode: "view",
    }));
  };

  const closeAfterConfirmDelete = () => {
    setState((state) => ({ ...state, success_dlg: false }));
    handleDelete();
  };

  const closeAfterConfirmToggle = () => {
    setState((state) => ({ ...state, success_dlg: false }));
    toggleActive();
  };

  const closeAfterConfirmSave = () => {
    setState((state) => ({ ...state, success_dlg: false }));
    handleSave();
  };

  useEffect(() => {
    getData(props.getUrl);
  }, []);

  let childrenComponent = React.cloneElement(props.childComponent, {
    data: state.document,
    mode: state.mode,
  });

  return (
    <>
      <Row className="justify-content-between">
        <Col>
          <CardTitle className="mb-4">{props.page_title}</CardTitle>
        </Col>

        <Col sm="3" className="text-right">
          <Row>
            <Col xs="12">
              <ListActions
                  hideToggle={props.disableToggleActive}
                link={`/${state.document.id}`}
                data={state.document}
                mode={state.mode}
                toggleSave={() => {
                  setState((state) => ({
                    ...state,
                    confirm_save: true,
                    actionType: "save",
                  }));
                }}
                toggleEdit={handleEdit}
                toggle={() =>
                  setState((state) => ({
                    ...state,
                    confirm_toggle: true,
                    actionType: "toggle",
                  }))
                }
                delete={() =>
                  setState((state) => ({
                    ...state,
                    confirm_delete: true,
                    actionType: "delete",
                  }))
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>

      {childrenComponent}

      <ConfirmBox
        confirm_delete={state.confirm_delete}
        confirm_toggle={state.confirm_toggle}
        confirm_save={state.confirm_save}
        success_dlg={state.success_dlg}
        cancelConfirmBox={cancelConfirmBox}
        closeAfterConfirmDelete={closeAfterConfirmDelete}
        closeAfterConfirmToggle={closeAfterConfirmToggle}
        closeAfterConfirmSave={closeAfterConfirmSave}
        confirmDelete={confirmDelete}
        confirmToggle={confirmToggle}
        confirmSave={confirmSave}
        isActive={state.document.isActive}
        actionType={state.actionType}
      />
    </>
  );
};

NomenclatureSingleWrapper.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NomenclatureSingleWrapper));

import { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import Loader from "../../components/Common/Loader.js";
import { cloneDeep } from "lodash";
import {
  GetData,
  SaveItem,
  CreateItem,
  DeleteItem,
} from "../../helpers/CRUD.js";
import * as endpoints from "constants/endpoints";
import * as jsonpatch from "fast-json-patch";
import i18next from "i18next";

const FileUpload_Data = (props) => {
  const initialState = {
    data: [],
    observer: null,
    document: {
      averageOutsideTemp: null,
      tempCoefficient: null,
      year: null,
      month: null,
    },
  };
  const date = new Date();

  const [state, setState] = useState(initialState);
  const [year, setYear] = useState(date.getFullYear());

  const resetData = () => {
    setState(initialState);
  };

  const onFileChange = (event) => {
    const file = event.target.files[0];
    setState((state) => ({ ...state, file_new: file }));
  };

  const getData = () => {
    const url = `${endpoints.CALCULATION_CONFIGURATION}/GetAll?filterText=${year}`;

    GetData(
      url,
      (data) => {
        setState((state) => ({
          ...state,
          data: data.items,
          initialData: cloneDeep(data.items),
          dataIsLoaded: true,
        }));
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const handleValidSubmit = (event, values) => {
    let patch = jsonpatch.compare(initialState.document, state.document);

    const url = `${endpoints.CALCULATION_CONFIGURATION}/Add`;

    CreateItem(
      url,
      patch,
      (data, resp) => {
        if (resp.status === 200) {
          getData();

          const document = {
            averageOutsideTemp: "",
            tempCoefficient: "",
            year: "",
            month: "",
          };

          setState((state) => ({
            ...state,
            data: [],
            observer: null,
            document: document,
            values: [],
          }));
        }
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  const handleChange = (e, id) => {
    console.log(e.nativeEvent.target.value);
    const items = state.data;
    const index = state.data.findIndex((item) => item.id === id);
    const prop = e.nativeEvent.target.name;
    const item = items[index];

    items[index][`${prop}`] = e.nativeEvent.target.value;
    setState((state) => ({ ...state, data: items }));
  };

  const updateItem = (id) => {
    let items = state.data;

    const index = state.data.findIndex((item) => item.id === id);

    items[index].edit = false;
    delete items[index].edit;
    setState((state) => ({ ...state, data: items }));

    let patch = jsonpatch.compare(state.initialData[index], items[index]);

    const url = `${endpoints.CALCULATION_CONFIGURATION}/Update?id=${id}`;

    SaveItem(
      url,
      patch,
      () => {
        props.onSuccessShow(i18next.t("entry_updated_successfully"));
        resetData();
        getData();
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  const editItem = (id) => {
    let items = state.data;

    const index = state.data.findIndex((item) => item.id === id);

    items[index].edit = true;
    setState((state) => ({ ...state, data: items }));
  };

  const incrementYear = () => {
    setYear(year + 1);
  };

  const decrementYear = () => {
    setYear(year - 1);
  };

  const deleteItem = (id) => {
    const url = `${endpoints.CALCULATION_CONFIGURATION}/Delete?id=${id}`;

    DeleteItem(url, getData, (error) => {
      props.onErrorsShow(error.body);
    });
  };

  useEffect(() => {
    var observer = jsonpatch.observe(state.document);
    setState((state) => ({
      ...state,
      showLoader: true,
      observer,
    }));
  }, []);

  useEffect(() => {
    getData();
  }, [year]);

  const tableStyle = {
    tableLayout: "fixed",
  };

  const tableHead = {
    backgroundColor: "#f8f9fa",
    borderColor: "#eff2f7",
  };

  const tableData = {
    color: "black",
  };

  const tableDataRed = {
    color: "red",
  };

  const tableDataBlue = {
    color: "blue",
  };

  console.log(state.document);
  console.log(state.data);
  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Card className="overflow-hidden">
            <CardBody className="pt-0">
              <div className="year-select d-flex align-items-center justify-content-center mt-4">
                <button
                  onClick={decrementYear}
                  style={{
                    background: "none",
                    fontSize: "0",
                    border: "none",
                  }}
                >
                  <i
                    className="bx bx-left-arrow-alt"
                    style={{ fontSize: "18px" }}
                  />
                </button>
                <h6 className="mb-0 mx-2">{year}</h6>
                <button
                  onClick={incrementYear}
                  style={{
                    background: "none",
                    fontSize: "0",
                    border: "none",
                  }}
                >
                  <i
                    className="bx bx-right-arrow-alt"
                    style={{ fontSize: "18px" }}
                  />
                </button>
              </div>
            </CardBody>
          </Card>

          <Card className="overflow-hidden">
            <CardBody className="pt-3">
              <Table
                className="table scrollable table-bordered"
                style={tableStyle}
              >
                <Thead>
                  <Tr>
                    <Th style={tableHead}>
                      {i18next.t("average-outside-temp")}
                    </Th>
                    <Th style={tableHead}>{i18next.t("temp-coefficient")}</Th>
                    <Th style={tableHead}>{i18next.t("month")}</Th>
                    <Th style={tableHead}>{i18next.t("year")}</Th>
                    <Th style={tableHead}>{i18next.t("actions")} </Th>
                  </Tr>
                </Thead>

                <Tbody>
                  {state.data && state.data.length === 0 ? (
                    <Tr>
                      <Td
                        colSpan={5}
                        className="align-items-center text-center"
                      >
                        <Loader
                          showLoader={state.showLoader}
                          loaded={state.dataIsLoaded}
                        />
                        {i18next.t("table_missing_data")}
                      </Td>
                    </Tr>
                  ) : null}

                  {state.data &&
                    state.data.length > 0 &&
                    state.data.map((row, i) => (
                      <Tr key={`period-${row.id}`}>
                        <Td>
                          {row.edit ? (
                            <input
                              type="number"
                              step={0.01}
                              name="averageOutsideTemp"
                              value={state.data[i].averageOutsideTemp}
                              onChange={(e) => handleChange(e, row.id)}
                            />
                          ) : (
                            row.averageOutsideTemp
                          )}
                        </Td>
                        <Td>
                          {row.edit ? (
                            <input
                              type="number"
                              step={0.01}
                              min={0}
                              name="tempCoefficient"
                              value={state.data[i].tempCoefficient}
                              onChange={(e) => handleChange(e, row.id)}
                            />
                          ) : (
                            row.tempCoefficient
                          )}
                        </Td>
                        <Td>
                          {row.edit ? (
                            <input
                              type="number"
                              name="month"
                              min={1}
                              max={12}
                              value={state.data[i].month}
                              onChange={(e) => handleChange(e, row.id)}
                            />
                          ) : (
                            row.month
                          )}
                        </Td>
                        <Td>
                          {row.edit ? (
                            <input
                              type="number"
                              name="year"
                              min={1990}
                              max={2099}
                              value={state.data[i].year}
                              onChange={(e) => handleChange(e, row.id)}
                            />
                          ) : (
                            row.year
                          )}
                        </Td>
                        <Td>
                          <div className="d-flex align-items-center">
                            {row.edit ? (
                              <button
                                onClick={() => updateItem(row.id)}
                                style={{
                                  background: "none",
                                  fontSize: "0",
                                  border: "none",
                                }}
                              >
                                Запази
                                <i>
                                  <svg
                                    width="18"
                                    height="18"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g fill="#000" fillRule="evenodd">
                                      <path d="M5.633 3.321h6.714c.2 0 .364-.163.364-.363V0H5.269v2.958c0 .2.163.363.364.363z" />
                                      <path
                                        d="M17.699 4.523L13.909.718v2.24c0 .861-.7 1.562-1.562 1.562H5.633a1.564 1.564 0 01-1.562-1.562V0H1.65A1.65 1.65 0 000 1.65v14.68c0 .91.739 1.65 1.65 1.65h14.68c.91 0 1.65-.74 1.65-1.65V5.202c0-.255-.102-.5-.281-.68zm-2.516 9.673c0 .532-.431.963-.964.963H3.76a.963.963 0 01-.962-.963v-5.02c0-.53.43-.962.963-.962h10.46c.532 0 .963.432.963.963v5.02z"
                                        fillRule="nonzero"
                                      />
                                      <path d="M13.123 9.76H4.857a.599.599 0 100 1.198h8.266a.599.599 0 100-1.199zM11.924 12.157H6.056a.599.599 0 100 1.198h5.868a.6.6 0 100-1.198z" />
                                    </g>
                                  </svg>
                                </i>
                              </button>
                            ) : (
                              <button
                                onClick={() => editItem(row.id)}
                                className=""
                                style={{
                                  background: "none",
                                  fontSize: "0",
                                  border: "none",
                                }}
                              >
                                Редактирай
                                <i
                                  className="bx bx-pencil"
                                  style={{
                                    fontSize: "20px",
                                    color: "black",
                                  }}
                                />
                              </button>
                            )}

                            <button
                              onClick={() => deleteItem(row.id)}
                              style={{
                                background: "none",
                                fontSize: "0",
                                border: "none",
                              }}
                            >
                              <i
                                className="bx bx-trash"
                                style={{
                                  fontSize: "20px",
                                  color: "black",
                                }}
                              />
                            </button>
                          </div>
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </CardBody>
          </Card>

          <Card className="overflow-hidden">
            <CardBody className="pt-3">
              <AvForm
                className="form-horizontal"
                onValidSubmit={handleValidSubmit}
              >
                <Row className="align-items-end">
                  <Col md={3}>
                    <AvField
                      name="document.averageOutsideTemp"
                      label={i18next.t("average-outside-temp")}
                      className="form-control"
                      placeholder=""
                      type="number"
                      step={0.01}
                      onChange={(e) =>
                        setState((state) => ({
                          ...state,
                          document: {
                            ...state.document,
                            averageOutsideTemp: e.target.value,
                          },
                        }))
                      }
                      value={state.document.averageOutsideTemp}
                    />
                  </Col>

                  <Col md={3}>
                    <AvField
                      name="document.tempCoefficient"
                      label={i18next.t("temp-coefficient")}
                      type="number"
                      step={0.01}
                      min={0}
                      placeholder=""
                      onChange={(e) =>
                        setState((state) => ({
                          ...state,
                          document: {
                            ...state.document,
                            tempCoefficient: e.target.value,
                          },
                        }))
                      }
                      value={state.document.tempCoefficient}
                    />
                  </Col>

                  <Col md={3}>
                    <AvField
                      name="document.month"
                      label={i18next.t("month")}
                      type="number"
                      step={1}
                      min={1}
                      max={12}
                      placeholder=""
                      onChange={(e) =>
                        setState((state) => ({
                          ...state,
                          document: {
                            ...state.document,
                            month: e.target.value,
                          },
                        }))
                      }
                      value={state.document.month}
                    />
                  </Col>

                  <Col md={3}>
                    <AvField
                      name="document.year"
                      label={i18next.t("year")}
                      type="number"
                      step={1}
                      min={1990}
                      max={2099}
                      placeholder=""
                      onChange={(e) =>
                        setState((state) => ({
                          ...state,
                          document: {
                            ...state.document,
                            year: e.target.value,
                          },
                        }))
                      }
                      value={state.document.year}
                    />
                  </Col>

                  <Col md={3}>
                    <div className="form-group">
                      <button
                        className="btn btn-primary btn-block waves-effect waves-light"
                        type="submit"
                      >
                        <i className="fa fa-paper-plane mr-1" />
                        Изпрати
                      </button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
};

FileUpload_Data.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FileUpload_Data));

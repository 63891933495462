import PropTypes from "prop-types";
import { UncontrolledTooltip } from "reactstrap";
import i18next from "i18next";

const ListActions = (props) => {
  const handleDelete = (e) => {
    e.preventDefault();
    if (window.confirm("Delete selected User?")) {
      // action to delete selected used
    }
  };

  const iconSize = {
    fontSize: "22px",
  };

  return (
    <>
      <div className="list-actions d-inline-flex">
        <ul className="d-flex align-items-center font-size-20 contact-links mb-0 ml-0 pl-1">
          <li
            className="list-inline-item px-2"
            style={{
              fontSize: 0,
            }}
          >
            <a
              onClick={() => props.toggleEdit()}
              id={"edit" + props.data.id}
              style={{
                display: props.mode === "view" ? "block" : "none",
                fontSize: "0px",
              }}
            >
              <i className="bx bx-pencil" style={iconSize} />

              <UncontrolledTooltip
                placement="top"
                target={"edit" + props.data.id}
              >
                {i18next.t("tooltip-edit")}
              </UncontrolledTooltip>
            </a>
            <a
              onClick={() => props.toggleSave()}
              id={"save" + props.data.id}
              style={{
                display: props.mode === "edit" ? "block" : "none",
                fontSize: "0",
              }}
            >
              <i
                style={{
                  fontSize: 0,
                }}
              >
                <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
                  <g fill="#000" fillRule="evenodd">
                    <path d="M5.633 3.321h6.714c.2 0 .364-.163.364-.363V0H5.269v2.958c0 .2.163.363.364.363z" />
                    <path
                      d="M17.699 4.523L13.909.718v2.24c0 .861-.7 1.562-1.562 1.562H5.633a1.564 1.564 0 01-1.562-1.562V0H1.65A1.65 1.65 0 000 1.65v14.68c0 .91.739 1.65 1.65 1.65h14.68c.91 0 1.65-.74 1.65-1.65V5.202c0-.255-.102-.5-.281-.68zm-2.516 9.673c0 .532-.431.963-.964.963H3.76a.963.963 0 01-.962-.963v-5.02c0-.53.43-.962.963-.962h10.46c.532 0 .963.432.963.963v5.02z"
                      fillRule="nonzero"
                    />
                    <path d="M13.123 9.76H4.857a.599.599 0 100 1.198h8.266a.599.599 0 100-1.199zM11.924 12.157H6.056a.599.599 0 100 1.198h5.868a.6.6 0 100-1.198z" />
                  </g>
                </svg>
              </i>

              <UncontrolledTooltip
                placement="top"
                target={"save" + props.data.id}
              >
                {i18next.t("tooltip-save")}
              </UncontrolledTooltip>
            </a>
          </li>

          {!props.hideToggle && (
            <li
              className="list-inline-item px-2"
              style={{
                fontSize: 0,
              }}
            >
              <a
                onClick={(e) => props.toggle(e, props.data.id)}
                id={"activate" + props.data.id}
                style={{
                  fontSize: "20px",
                  lineHeight: "1",
                }}
              >
                {props.data.isActive ? (
                  <i
                    className="bx bx-x"
                    style={
                      props.data.isActive
                        ? {
                            color: "red",
                            fontSize: "24px",
                          }
                        : { fontSize: "24px" }
                    }
                  />
                ) : (
                  <i
                    className="bx bx-check"
                    style={
                      props.data.isActive
                        ? {
                            color: "orange",
                            fontSize: "24px",
                          }
                        : { fontSize: "24px" }
                    }
                  />
                )}

                <UncontrolledTooltip
                  placement="top"
                  target={"activate" + props.data.id}
                >
                  {props.data.isActive
                    ? i18next.t("tootltip-deactivate")
                    : i18next.t("tootltip-activate")}
                </UncontrolledTooltip>
              </a>
            </li>
          )}

          {!props.hideDelete && (
            <li
              className="list-inline-item px-2"
              style={{
                fontSize: 0,
              }}
            >
              <a
                onClick={(e) => props.delete(e, props.data.id)}
                id={"delete" + props.data.id}
                style={{
                  fontSize: "20px",
                  lineHeight: "1",
                }}
              >
                <i className="bx bx-trash" style={iconSize} />

                <UncontrolledTooltip
                  placement="top"
                  target={"delete" + props.data.id}
                >
                  {i18next.t("tootltip-delete")}
                </UncontrolledTooltip>
              </a>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};

ListActions.propTypes = {
  title: PropTypes.string,
};

export default ListActions;

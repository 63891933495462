import i18next from "i18next";

const manufacturer_product_options = () => [
  {
    value: i18next.t("watermeter"),
    name: i18next.t("watermeter"),
    isChecked: false,
  },
  {
    value: i18next.t("heatmeter"),
    name: i18next.t("heatmeter"),
    isChecked: false,
  },
  {
    value: i18next.t("allocators"),
    name: i18next.t("allocators"),
    isChecked: false,
  },
  {
    value: i18next.t("radio-module"),
    name: i18next.t("radio-module"),
    isChecked: false,
  },
  { value: i18next.t("heater"), name: i18next.t("heater"), isChecked: false },
];

const heater_types_list = [];

const statuses = () => [
  {
    key: "DEMOUNTED",
    value: i18next.t("demounted"),
  },
  {
    key: "STRAIGHT",
    value: i18next.t("with-device"),
  },
  {
    key: "WITHOUT_DEVICE",
    value: i18next.t("without-device"),
  },
];

const installation_types = () => [
  {
    key: "ks",
    value: i18next.t("installation_plate_new"),
    categories: ["heat-cost-allocator"],
  },
  {
    key: "ks1",
    value: i18next.t("installation_plate_old"),
    categories: ["heat-cost-allocator"],
  },
  {
    key: "ks3",
    value: i18next.t("installation_aparator"),
    categories: ["heat-cost-allocator"],
  },
  {
    key: "ks2",
    value: i18next.t("installation_siemens_old_device"),
    categories: ["heat-cost-allocator"],
  },
  {
    key: "FILLING",
    value: i18next.t("installation_giving"),
    categories: ["heat-meter-types"],
  },
  {
    key: "RETURNING",
    value: i18next.t("installation_returning"),
    categories: ["heat-meter-types"],
  },
];

const device_locations = () => [
  {
    key: "INSIDE",
    value: i18next.t("inside"),
  },
  {
    key: "OUTSIDE",
    value: i18next.t("outside"),
  },
];

const collage_types = () => [
  {
    key: 0.5,
    value: "½",
  },
  {
    key: 0.75,
    value: "¾",
  },
];

const meter_types = () => [
  {
    key: "COLD",
    value: i18next.t("installation_cold"),
    categories: ["water-meter"],
  },
  {
    key: "WARM",
    value: i18next.t("installation_hot"),
    categories: ["water-meter"],
  },
  {
    key: "HEAT",
    value: i18next.t("heatmeter"),
    categories: ["heat-meter-types"],
  },
  {
    key: "HEAT_COOL",
    value: i18next.t("heat_cool_meter"),
    categories: ["heat-meter-types"],
  },
];

const conditions = () => [
  {
    key: "STRAIGHT",
    value: i18next.t("straight"),
    categories: ["heat-cost-allocator", "water-meter", "heat-meter"],
  },
  {
    key: "IMPACTED",
    value: i18next.t("impacted"),
    categories: ["heat-cost-allocator", "heat-meter"],
  },
  {
    key: "DAMAGED_FIRST_SEASON",
    value: i18next.t("damaged-first-season"),
    categories: ["heat-cost-allocator", "heat-meter"],
  },
  {
    key: "DAMAGED_SECOND_OR_NEXT_SEASON",
    value: i18next.t("damaged-second-next-season"),
    categories: ["heat-cost-allocator", "heat-meter"],
  },
  {
    key: "EXPIRED_METROLOGY",
    value: i18next.t("expired-metrology"),
    categories: ["water-meter"],
  },
  {
    key: "DEFECTIVE",
    value: i18next.t("defective"),
    categories: ["water-meter"],
  },
  {
    key: "NO_DEVICE",
    value: i18next.t("no-device"),
    categories: ["heat-cost-allocator"],
  },
];

const measure_units = () => [
  {
    key: "kwh",
    value: i18next.t("kwh"),
  },
  {
    key: "mwh",
    value: i18next.t("mwh"),
  },
  {
    key: "gj",
    value: i18next.t("gj"),
  },
  {
    key: "mcal",
    value: i18next.t("mcal"),
  },
];

const heat_meter_kinds = () => [
  {
    key: "TURBINE",
    value: i18next.t("heat-meter-turbine"),
  },
  {
    key: "ULTRASONIC",
    value: i18next.t("heat-meter-ultrasonic"),
  },
];

const heater_type_types = () => [
  {
    key: i18next.t("heater_type_old_panel"),
    value: i18next.t("heater_type_old_panel"),
  },
  {
    key: i18next.t("heater_type_new_panel"),
    value: i18next.t("heater_type_new_panel"),
  },
  {
    key: i18next.t("heater_type_cast_iron"),
    value: i18next.t("heater_type_cast_iron"),
  },
  {
    key: i18next.t("heater_type_aluminum"),
    value: i18next.t("heater_type_aluminum"),
  },
  {
    key: i18next.t("heater_type_lyre_pipe"),
    value: i18next.t("heater_type_lyre_pipe"),
  },
  {
    key: i18next.t("heater_type_lyre_dryer"),
    value: i18next.t("heater_type_lyre_dryer"),
  },
  {
    key: i18next.t("heater_type_lyre_tube"),
    value: i18next.t("heater_type_lyre_tube"),
  },
];

const hca_type_types = () => [
  {
    key: "ONE_SENSOR",
    value: i18next.t("hca_type_one_sensor"),
  },
  {
    key: "TWO_SENSOR",
    value: i18next.t("hca_type_two_sensor"),
  },
  {
    key: "OUTER_SENSOR",
    value: i18next.t("hca_type_outer_sensor"),
  },
];

const report_statuses = () => [
  {
    key: "ACCESS_ALLOWED",
    value: i18next.t("report_access_allowed"),
  },
  {
    key: "ACCESS_DENIED",
    value: i18next.t("report_access_denied"),
  },
  {
    key: "ACCESS_DENIED_FIRST_DATE",
    value: i18next.t("report_access_denied_first_date"),
  },
  {
    key: "ACCESS_DENIED_SECOND_DATE",
    value: i18next.t("report_access_denied_seconde_date"),
  },
  {
    key: "THIRD_DATE_PAID_REPORT",
    value: i18next.t("report_third_date_paid"),
  },
];

const device_types = () => [
  {
    key: "1",
    value: i18next.t("subscriber_station"),
  },
  {
    key: "2",
    value: i18next.t("heatmeter_single"),
  },
  {
    key: "3",
    value: i18next.t("allocator_single"),
  },
  {
    key: "4",
    value: i18next.t("watermeter_single"),
  },
];

const building_device_types = () => [
  {
    key: "HEAT_COST_ALLOCATORS",
    value: i18next.t("allocator_single"),
  },
  {
    key: "HEAT_METERS",
    value: i18next.t("heatmeter_apartment"),
  },
  {
    key: "MIXED_HEAT_METERS",
    value: i18next.t("allocator_mixed_building"),
  },
  {
    key: "MIXED_HEAT_COST_ALLOCATORS",
    value: i18next.t("heatmeter_apartment_mixed_building"),
  },
];

const report_types = () => [
  {
    key: "common-part",
    value: i18next.t("shared-part"),
  },
  {
    key: "estate",
    value: i18next.t("estate"),
  },
];

const water_report_frequency = ()  =>  [
  {
    key: "PER_MONTH",
    value: i18next.t("monthly_frequency"),
  },
  {
    key: "PER_QUARTER",
    value: i18next.t("three_monthly_frequency"),
  },
];

const months = () => [
  {
    key: 1,
    value: i18next.t("january"),
  },
  {
    key: 2,
    value: i18next.t("february"),
  },
  {
    key: 3,
    value: i18next.t("march"),
  },
  {
    key: 4,
    value: i18next.t("april"),
  },
  {
    key: 5,
    value: i18next.t("may"),
  },
  {
    key: 6,
    value: i18next.t("june"),
  },
  {
    key: 7,
    value: i18next.t("july"),
  },
  {
    key: 8,
    value: i18next.t("august"),
  },
  {
    key: 9,
    value: i18next.t("september"),
  },
  {
    key: 10,
    value: i18next.t("october"),
  },
  {
    key: 11,
    value: i18next.t("november"),
  },
  {
    key: 12,
    value: i18next.t("december"),
  },
];

const year = () => ["2020", "2021"];

const walkby_file_categories = () => [
  {
    id: "file-category-1",
    label: "Apator-TXT",
    type: "text/plain",
  },
  { id: "file-category-2", label: "Apator-CSV", type: ".csv" },
  { id: "file-category-3", label: "Engelman", type: ".csv" },
  { id: "file-category-4", label: "Siemens", type: ".rep" },
  { id: "file-category-5", label: "Remote file", type: ".csv" },
];

const roles = () => [
  { value: 1, name: "SuperAdmin" },
  { value: 2, name: "Administrator" },
  { value: 3, name: "Employee" },
  { value: 4, name: "Reporter" },
];

export {
  manufacturer_product_options,
  conditions,
  meter_types,
  collage_types,
  device_locations,
  measure_units,
  installation_types,
  statuses,
  heat_meter_kinds,
  heater_type_types,
  hca_type_types,
  report_statuses,
  device_types,
  building_device_types,
  report_types,
  water_report_frequency,
  months,
  year,
  walkby_file_categories,
  roles,
};

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";

const ButtonAdd = (props) => {
  const handleModalOpen = () => {
    props.onBtnClick();
  };

  const id = `button`;

  return (
    <>
      <button
        id={id}
        type="button"
        className="btn btn-primary waves-effect waves-light"
        onClick={handleModalOpen}
      >
        <i className="bx bx-plus-circle" style={{ fontSize: "18px" }} />
        <UncontrolledTooltip placement="top" target={id}>
          {props.tooltip}
        </UncontrolledTooltip>
      </button>
    </>
  );
};

ButtonAdd.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUsers: () => dispatch(getUsers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ButtonAdd));

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Modal, FormGroup, Label, Button } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import * as endpoints from "constants/endpoints";
import { installation_types } from "helpers/listsHardcodedData";
import * as jsonpatch from "fast-json-patch";
import { GetData, CreateItem } from "../../helpers/CRUD.js";
import i18next from "i18next";

const ModalHeatCostAllocator = (props) => {
  const old_state = {
    nomHCATypes: [],
  };

  const [state, setState] = useState({
    ...old_state,
    document: {
      device: {
        serialNumber: "",
        comment: "",
      },
      zeroingMonth: 0,
      heatCostAllocatorTypeId: null,
      installationType: "",
      isActive: false,
    },
  });

  const getInitialState = (old_state) => ({
    ...old_state,
    all_installation_types: [],
    document: {
      device: {
        serialNumber: "",
        comment: "",
      },
      zeroingMonth: 0,
      heatCostAllocatorTypeId: null,
      installationType: "",
      isActive: false,
    },
  });

  const resetData = () => {
    setState(getInitialState(state));
  };

  const handleValidSubmit = (event, values) => {
    let patch = jsonpatch.compare(getInitialState().document, state.document);

    if (props.heater_id) {
      patch.push({
        op: "replace",
        path: "/heaterId",
        value: props.heater_id,
      });
    }

    if (props.estate_id) {
      patch.push({
        op: "replace",
        path: "/device/estateId",
        value: props.estate_id,
      });
    }

    if (props.shared_part_id) {
      patch.push({
        op: "replace",
        path: "/device/sharedPartId",
        value: props.shared_part_id,
      });
    }

    patch.push({ op: "replace", path: "/device/deviceTypeId", value: 3 });

    const url = `${endpoints.BUILDING_UNITS_REPORT_ENTITIES_ENDPOINT}/HeatCostAllocator/Add`;

    CreateItem(
      url,
      patch,
      (data) => {
        update();
        props.emitSuccess(i18next.t("entry_added_successfully"));
      },
      (error) => {
        props.emitErrors(error);
      }
    );
  };

  const closeModal = () => {
    props.closeModal();
    resetData();
  };

  const update = () => {
    props.update();
    resetData();
  };

  const getNomHCATypes = () => {
    const url = `${endpoints.NOMENCLATURE_ENDPOINT}/HeatCostAllocatorType/GetAll`;

    GetData(
      url,
      (data) => {
        setState((state) => ({ ...state, nomHCATypes: data.items }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    getNomHCATypes();
  }, []);

  const installation_types_list = installation_types();
  state.all_installation_types = installation_types_list.filter((item) =>
    item.categories.includes("heat-cost-allocator")
  );

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Modal isOpen={props.isModalOpen} scrollable={true}>
            <div className="modal-header">
              <h5 className="modal-title mt-0">
                {i18next.t("modal_heat_cost_allocator_title")}
              </h5>

              <button
                type="button"
                onClick={() => closeModal()}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <Card className="mb-0">
                <CardBody>
                  <AvForm
                    onValidSubmit={handleValidSubmit}
                    className="needs-validation"
                  >
                    <Row className="mb-4">
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="heat_cost_allocator_serial_number">
                            {i18next.t("field_serial_number")}
                          </Label>

                          <AvField
                            name="document.device.serialNumber"
                            placeholder={i18next.t(
                              "placeholder_enter_serial_number"
                            )}
                            id="heat_cost_allocator_serial_number"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  device: {
                                    ...state.document.device,
                                    serialNumber: e.target.value,
                                  },
                                },
                              }))
                            }
                            value={state.document.device.serialNumber}
                            className="w-100"
                          />
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="heat_cost_allocator_zeroing_date">
                            {i18next.t("zeroingMonth")}
                          </Label>

                          <AvField
                            type="number"
                            min={1}
                            max={12}
                            name="document.zeroingMonth"
                            id="heat_cost_allocator_zeroing_date"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  zeroingMonth: e.target.value,
                                },
                              }))
                            }
                            defaultValue={state.document.zeroingMonth}
                            className="w-100"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="heat_cost_allocator_type">
                            {i18next.t("heatCostAllocatorType")}
                          </Label>

                          <AvField
                            type="select"
                            name="document.heatCostAllocatorTypeId"
                            id="heat_cost_allocator_type"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  heatCostAllocatorTypeId: e.target.value,
                                },
                              }))
                            }
                            value={state.document.heatCostAllocatorTypeId}
                            className="w-100"
                          >
                            <option
                              key={`heat-cost-allocator-type-default`}
                              value={null}
                              selected={
                                state.document.heatCostAllocatorTypeId === null
                              }
                            >
                              {i18next.t("select_allocator_type")}
                            </option>
                            {state.nomHCATypes.length > 0 &&
                              state.nomHCATypes.map((option) => (
                                <option
                                  key={`heat-cost-allocator-type-${option.id}`}
                                  value={option.id}
                                >
                                  {option.name}
                                </option>
                              ))}
                          </AvField>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="heat_cost_allocator_installation_type">
                            {i18next.t("installation")}
                          </Label>
                          <AvField
                            type="select"
                            name="document.installationType"
                            id="heat_cost_allocator_installation_type"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  installationType: e.target.value,
                                },
                              }))
                            }
                            value={state.document.installationType}
                            className="w-100"
                          >
                            <option
                              key={`installation-type-default`}
                              value=""
                              selected={state.document.installationType === ""}
                            >
                              {i18next.t("select_installation_type")}
                            </option>

                            {state.all_installation_types &&
                              state.all_installation_types.length > 0 &&
                              state.all_installation_types.map((option) => (
                                <option
                                  key={`installation-type-${option.key}`}
                                  value={option.key}
                                  selected={
                                    state.document.installationType ===
                                    option.key
                                  }
                                >
                                  {option.value}
                                </option>
                              ))}
                          </AvField>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="heat_cost_allocator_comment">
                            {i18next.t("field_comment")}
                          </Label>
                          <AvField
                            type="textarea"
                            name="document.device.comment"
                            placeholder={i18next.t("placeholder_enter_comment")}
                            id="heat_cost_allocator_comment"
                            maxLength="200"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  device: {
                                    ...state.document.device,
                                    comment: e.target.value,
                                  },
                                },
                              }))
                            }
                            value={state.document.device.comment}
                            className="w-100"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => closeModal()}
                      >
                        <i className="fa fa-times mr-1" />
                        {i18next.t("button_cancel")}
                      </button>

                      <Button color="primary" type="submit">
                        <i className="fa fa-plus-circle mr-1" />
                        {i18next.t("button_add")}
                      </Button>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </div>
          </Modal>
        </Container>
      </div>
    </>
  );
};

ModalHeatCostAllocator.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUsers: () => dispatch(getUsers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ModalHeatCostAllocator));

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  withRouter,
  useParams,
  useHistory,
  useLocation,
} from "react-router-dom";
import { AvField, AvForm } from "availity-reactstrap-validation";
import {
  FormGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { cloneDeep } from "lodash";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import ModalCondition from "components/Modals/ModalCondition";
import BackButton from "../../../components/Common/BackButton.js";
import ListActions from "../../../components/Common/ListActions.js";
import * as endpoints from "constants/endpoints";
import {
  conditions,
  device_locations,
  collage_types,
  meter_types,
} from "helpers/listsHardcodedData";
import { translateConditionEnumValue } from "helpers/translateHardcodedData";
import { formatDateLocale } from "../../../helpers/utils";
import * as jsonpatch from "fast-json-patch";
import { Tooltip } from "@material-ui/core";
import classnames from "classnames";
import TableComponent from "../../../components/Common/TableComponent";
import TableReports from "../../../components/Common/TableReports";
import IconCheck from "../../../components/Common/IconCheck";
import IconX from "../../../components/Common/IconX";
import {
  GetData,
  SaveItem,
  DeleteItem,
  ToggleItem,
} from "../../../helpers/CRUD.js";
import i18next from "i18next";
import {REPORT_DEVICE_MAP, REPORTS_ENDPOINT} from "constants/endpoints";

const WaterMeterSingle = (props) => {
  const old_state = {
    pageTitle: "Водомер",
    mode: "view",
    nomEquipmentManufacturers: [],
    nomRadioModuleTypes: [],
    nomRoomTypes: [],
    activeTab: "1",
    tabsNav: [
      { index: "1", id: "tab-nav-1", label: "Отчети" },
      { index: "2", id: "tab-nav-2", label: "Състояния" },
    ],
    device_reports: [],
    device_statuses: [],
  };
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();

  const [state, setState] = useState({
    ...old_state,
    document: {
      radioModuleNumber: "",
      device: {
        serialNumber: "",
        comment: "",
        deviceLocation: "",
        deviceSubType: "",
      },
      equipmentManufacturerId: null,
      radioModuleTypeId: null,
      roomTypeId: null,
      productionYear: 0,
      validityYear: 0,
      collage: 0,
      sealNumber: "",
      columnExportNumber: 0,
      correctionValueRadioModule: 0,
      isActive: false,
      deviceId: 0,
    },
    isLoaded: false,
  });

  const emitErrors = (error) => {
    props.onErrorsShow(error.body);
  };

  const emitSuccess = (success) => {
    props.onSuccessShow(success);
  };

  const onModalDeviceStatusDelete = (id) => {
    const url = `${endpoints.BUILDING_UNITS_ADDITIONAL_ENDPOINT}/DeviceStatus/Delete?id=${id}`;

    DeleteItem(
      url,
      () => {
        props.onSuccessShow(i18next.t("entry_deleted_successfully"));
        getAllDeviceStatuses();
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  const onModalDeviceReportDelete = (row) => {
    const url = `${endpoints.REPORT_DEVICE_MAP}/Delete?reportId=${row.reportId}&deviceId=${row.deviceId}`;

    DeleteItem(
        url,
        () => {
          props.onSuccessShow(i18next.t("entry_deleted_successfully"));
          getAllDeviceReports();
        },
        (error) => {
          props.onErrorsShow(error.body);
        }
    );
  };

  const openModal = (id) => {
    setState((state) => ({ ...state, showModal: id }));
  };

  const closeModal = () => {
    setState((state) => ({ ...state, showModal: null }));
    removeBodyCss();
  };

  const closeAndUpdate = (modalCallback) => {
    setState((state) => ({ ...state, showModal: null }));
    modalCallback();
    removeBodyCss();
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };

  const getNomEquipmentManufacturers = () => {
    const url = `${endpoints.NOMENCLATURE_ENDPOINT}/EquipmentManufacturer/GetAllByDeviceType?deviceType=Водомери`;

    GetData(
      url,
      (data) => {
        setState((state) => ({
          ...state,
          nomEquipmentManufacturers: data,
        }));
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  const getNomRadioModuleTypes = () => {
    const url = `${endpoints.NOMENCLATURE_ENDPOINT}/RadioModuleType/GetAll`;

    GetData(
      url,
      (data) => {
        setState((state) => ({ ...state, nomRadioModuleTypes: data.items }));
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  const getNomRoomTypes = () => {
    const url = `${endpoints.NOMENCLATURE_ENDPOINT}/RoomType/GetAll`;

    GetData(
      url,
      (data) => {
        setState((state) => ({ ...state, nomRoomTypes: data.items }));
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  const getWaterMeterById = (id) => {
    const url = `${endpoints.BUILDING_UNITS_REPORT_ENTITIES_ENDPOINT}/WaterMeter/GetById?id=${id}`;

    GetData(
      url,
      (data) => {
        setState((state) => ({
          ...state,
          document: { ...data },
          initialDocument: cloneDeep(data),
          isLoaded: true,
        }));
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  useEffect(() => {
    state.isLoaded && fetchCallback();
  }, [state.isLoaded]);

  const handleEdit = () => {
    setState((state) => ({ ...state, mode: "edit" }));
  };

  const handleSave = () => {
    let patch = jsonpatch.compare(state.initialDocument, state.document);

    const url = `${endpoints.BUILDING_UNITS_REPORT_ENTITIES_ENDPOINT}/WaterMeter/Update?id=${id}`;

    SaveItem(
      url,
      patch,
      (data) => {
        props.onSuccessShow(i18next.t("entry_updated_successfully"));
        setState((state) => ({ ...state, mode: "view" }));
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  const handleDelete = () => {
    const url = `${endpoints.BUILDING_UNITS_REPORT_ENTITIES_ENDPOINT}/WaterMeter/Delete?id=${id}`;

    DeleteItem(url, history.goBack, (error) => {
      props.onErrorsShow(error.body);
    });
  };

  const toggleTabs = (tab) => {
    if (state.activeTab !== tab) {
      setState((state) => ({ ...state, activeTab: tab }));
    }
  };

  const toggleActive = () => {
    let old_state = state.document;
    old_state.isActive = !state.document.isActive;

    setState((state) => ({ ...state, document: old_state }));
    handleToggleStatusPost();
  };

  const handleToggleStatusPost = () => {
    const url = `${endpoints.BUILDING_UNITS_REPORT_ENTITIES_ENDPOINT}/WaterMeter/ToggleActive?id=${id}&isActive=${state.document.isActive}`;

    ToggleItem(
      url,
      () => {
        getWaterMeterById(state.document.id);
        state.document.isActive
          ? props.onSuccessShow(i18next.t("entry_activated_successfully"))
          : props.onSuccessShow(i18next.t("entry_deactivated_successfully"));
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  const cancelConfirmBox = () => {
    setState((state) => ({
      ...state,
      confirm_delete: false,
      confirm_toggle: false,
      confirm_save: false,
    }));
  };

  const confirmDelete = () => {
    setState((state) => ({
      ...state,
      confirm_delete: false,
      success_dlg: true,
    }));
  };

  const confirmToggle = () => {
    setState((state) => ({
      ...state,
      confirm_toggle: false,
      success_dlg: true,
    }));
  };

  const confirmSave = () => {
    setState((state) => ({
      ...state,
      confirm_save: false,
      success_dlg: true,
      mode: "view",
    }));
  };

  const closeAfterConfirmDelete = () => {
    setState((state) => ({ ...state, success_dlg: false }));
    handleDelete();
  };

  const closeAfterConfirmToggle = () => {
    setState((state) => ({ ...state, success_dlg: false }));
    toggleActive();
  };

  const closeAfterConfirmSave = () => {
    setState((state) => ({ ...state, success_dlg: false }));
    handleSave();
  };

  const getAllDeviceReports = () => {
    const url = `${endpoints.REPORTS_ENDPOINT}/ReportDeviceMap/GetAllByDeviceId?id=${state.document.deviceId}`;

    GetData(
      url,
      (data) => {
        setState((state) => ({
          ...state,
          device_reports: data,
          deviceReportsLoaded: true,
        }));
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  const getAllDeviceStatuses = () => {
    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/additional/DeviceStatus/GetAllByDeviceId?id=${state.document.deviceId}`;

    GetData(
      url,
      (data) => {
        setState((state) => ({
          ...state,
          device_statuses: data,
          deviceStatusesLoaded: true,
        }));
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  const fetchCallback = () => {
    getAllDeviceReports();
    getAllDeviceStatuses();
  };

  const emitRowSave = (row) => {
    const url = `${endpoints.REPORTS_ENDPOINT}/ReportDeviceMap/Update?deviceId=${row.deviceId}&reportId=${row.reportId}`;
    const patch = [
      { op: "replace", path: "/waterMeterValue", value: `${row.data.value}` },
    ];

    SaveItem(
      url,
      patch,
      (data) => {
        props.onSuccessShow(i18next.t("entry_updated_successfully"));
        getAllDeviceReports();
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem("authUser"));

    if (location.state) {
      setState((state) => ({
        ...state,
        loggedUserType: obj.role,
        mode: "edit",
      }));
    } else {
      setState((state) => ({ ...state, loggedUserType: obj.role }));
    }

    getWaterMeterById(id);
    getNomRadioModuleTypes();
    getNomEquipmentManufacturers();
    getNomRoomTypes();
  }, []);

  const radioStyle = {
    listStyle: "none",
    paddingLeft: "0",
  };
  const labelStyle = {
    paddingLeft: "20px",
  };

  const tableStyle = {
    tableLayout: "fixed",
  };

  const conditions_list = conditions();
  const meter_types_list = meter_types();
  const collage_types_list = collage_types();
  const device_locations_list = device_locations();

  const water_meter_conditions = conditions_list.filter((item) =>
    item.categories.includes("water-meter")
  );

  const water_meter_types = meter_types_list.filter((item) =>
    item.categories.includes("water-meter")
  );

  let tableBodyDeviceReports = [];
  if (state.deviceReportsLoaded) {
    tableBodyDeviceReports = state.device_reports.map((item) => {
      return {
        id: item.report.id,
        reportId: item.reportId,
        deviceId: item.deviceId,
        editMode: true,
        data: {
          date: item.report ? item.report.reportDate : null,
          visitationDate: item.report ? item.report.visitationDate : null,
          value: item.waterMeterValue,
          yearReport:
            item.report && item.report.isYearReport ? <IconCheck /> : <IconX />,
          isActive: true,
        },
      };
    });
  }

  let tableBodyDeviceStatuses = [];
  if (state.deviceStatusesLoaded) {
    tableBodyDeviceStatuses = state.device_statuses.map((item) => {
      return {
        id: item.id,
        data: {
          from_date: formatDateLocale(item.fromDate),
          to_date: formatDateLocale(item.toDate),
          condition: item.status
            ? translateConditionEnumValue(item.status)
            : "",
          isActive: item.toDate === null,
        },
      };
    });
  }

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl="12">
              <BackButton className="mb-4" />
            </Col>

            <Col xl="12">
              <Card>
                <CardBody className="mb-0 pb-0">
                  <Row>
                    <Col>
                      <CardTitle className="mb-4">{state.pageTitle}</CardTitle>
                    </Col>

                    <Col sm="3" className="text-right">
                      <Row>
                        <Col
                          xs="12"
                          className="d-flex align-items-center justify-content-end"
                        >
                          <ListActions
                            link={`/${state.document.id}`}
                            data={state.document}
                            mode={state.mode}
                            toggleSave={handleSave}
                            toggleEdit={handleEdit}
                            toggle={toggleActive}
                            delete={handleDelete}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col md="12">
              <AvForm className="needs-validation">
                <Row className="d-flex">
                  <Col xl="6" className="d-flex">
                    <Card className="w-100">
                      <CardBody>
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <Label
                                htmlFor="water_meter_serial_number"
                                className=""
                              >
                                Сериен номер
                              </Label>
                              <AvField
                                name="document.device.serialNumber"
                                placeholder="Сериен номер"
                                type="text"
                                className="form-control"
                                id="water_meter_serial_number"
                                value={state.document.device.serialNumber}
                                onChange={(e) =>
                                  setState((state) => ({
                                    ...state,
                                    document: {
                                      ...state.document,
                                      device: {
                                        ...state.document.device,
                                        serialNumber: e.target.value,
                                      },
                                    },
                                  }))
                                }
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                  maxLength: {
                                    value: 30,
                                  },
                                }}
                                {...(state.mode === "view" && {
                                  readOnly: true,
                                })}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label
                                htmlFor="water_meter_radio_module_number"
                                className=""
                              >
                                Радио номер
                              </Label>
                              <AvField
                                name="document.radioModuleNumber"
                                placeholder="Радио номер"
                                type="text"
                                className="form-control"
                                id="water_meter_radio_module_number"
                                value={state.document.radioModuleNumber}
                                onChange={(e) =>
                                  setState((state) => ({
                                    ...state,
                                    document: {
                                      ...state.document,
                                      radioModuleNumber: e.target.value,
                                    },
                                  }))
                                }
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                  maxLength: {
                                    value: 30,
                                  },
                                }}
                                {...(state.mode === "view" && {
                                  readOnly: true,
                                })}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <Label
                                htmlFor="water_meter_equipment_manufacturer"
                                className=""
                              >
                                Производител
                              </Label>

                              <AvField
                                type="select"
                                value={state.document.equipmentManufacturerId}
                                onChange={(e) =>
                                  setState((state) => ({
                                    ...state,
                                    document: {
                                      ...state.document,
                                      equipmentManufacturerId: e.target.value,
                                    },
                                  }))
                                }
                                name="document.equipmentManufacturerId"
                                id="water_meter_equipment_manufacturer"
                                errorMessage="Полето е задължително"
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                }}
                                {...(state.mode === "view" && {
                                  disabled: true,
                                })}
                              >
                                <option
                                  key={`equipment-manufacturer-missing`}
                                  value="0"
                                  selected={
                                    state.document.equipmentManufacturerId ===
                                    null
                                  }
                                >
                                  -- Изберете производител --
                                </option>
                                {state.nomEquipmentManufacturers.map(
                                  (option) => (
                                    <option
                                      key={`apartment-type-${option.id}`}
                                      value={option.id}
                                      selected={
                                        state.document
                                          .equipmentManufacturerId === option.id
                                      }
                                    >
                                      {option.name}
                                    </option>
                                  )
                                )}
                              </AvField>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label htmlFor="water_meter_radio_module_type">
                                Тип на радио модула
                              </Label>
                              <AvField
                                type="select"
                                value={state.document.radioModuleTypeId}
                                onChange={(e) =>
                                  setState((state) => ({
                                    ...state,
                                    document: {
                                      ...state.document,
                                      radioModuleTypeId: e.target.value,
                                    },
                                  }))
                                }
                                name="document.radioModuleTypeId"
                                id="water_meter_radio_module_type"
                                errorMessage="Полето е задължително"
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                }}
                                {...(state.mode === "view" && {
                                  disabled: true,
                                })}
                              >
                                <option
                                  key={`radio-module-type-missing`}
                                  value="0"
                                  selected={
                                    state.document.radioModuleTypeId === null
                                  }
                                >
                                  -- Изберете тип --
                                </option>
                                {state.nomRadioModuleTypes.map((option) => (
                                  <option
                                    key={`radio-module-type-${option.id}`}
                                    value={option.id}
                                    selected={
                                      state.document.radioModuleTypeId ===
                                      option.id
                                    }
                                  >
                                    {option.name}
                                  </option>
                                ))}
                              </AvField>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <Label
                                htmlFor="water_meter_device_location"
                                className=""
                              >
                                Разположение на уреда
                              </Label>

                              <AvField
                                type="select"
                                name="document.device.deviceLocation"
                                id="water_meter_device_location"
                                errorMessage="Select Device type"
                                value={state.document.device.deviceLocation}
                                onChange={(e) =>
                                    setState((state) => ({
                                      ...state,
                                      document: {
                                        ...state.document,
                                        device: {
                                          ...state.document.device,
                                          deviceLocation: e.target.value,
                                        },
                                      }}))
                                }
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                }}
                                {...(state.mode === "view" && {
                                  disabled: true,
                                })}
                              >
                                <option>-- Изберете разположение --</option>
                                {device_locations_list &&
                                  device_locations_list.length > 0 &&
                                  device_locations_list.map((deviceType) => (
                                    <option
                                      value={deviceType.key}
                                      selected={
                                        state.document.device.deviceLocation ===
                                        deviceType.key
                                      }
                                    >
                                      {deviceType.value}
                                    </option>
                                  ))}
                              </AvField>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label
                                htmlFor="water_meter_room_type"
                                className=""
                              >
                                Разположение в апартамента
                              </Label>

                              <AvField
                                type="select"
                                value={state.document.roomTypeId}
                                onChange={(e) =>
                                  setState((state) => ({
                                    ...state,
                                    document: {
                                      ...state.document,
                                      roomTypeId: e.target.value,
                                    },
                                  }))
                                }
                                name="document.roomTypeId"
                                id="water_meter_room_type"
                                errorMessage="Полето е задължително"
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                }}
                                {...(state.mode === "view" && {
                                  disabled: true,
                                })}
                              >
                                <option
                                  key={`room-type-missing`}
                                  value="0"
                                  selected={state.document.roomTypeId === null}
                                >
                                  -- Изберете стая --
                                </option>
                                {state.nomRoomTypes.map((option) => (
                                  <option
                                    key={`room-type-${option.id}`}
                                    value={option.id}
                                    selected={
                                      state.document.roomTypeId === option.id
                                    }
                                  >
                                    {option.name}
                                  </option>
                                ))}
                              </AvField>
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xl="6" className="d-flex">
                    <Card className="w-100">
                      <CardBody>
                        <Row>
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="water_meter_type" className="">
                                Тип
                              </Label>

                              <AvField
                                type="select"
                                name="document.device.deviceSubType"
                                id="water_meter_type"
                                errorMessage="Select Device type"
                                value={state.document.device.deviceSubType}
                                onChange={(e) =>
                                  setState((state) => ({
                                    ...state,
                                    document: {
                                      ...state.document,
                                      device: {
                                        ...state.document.device,
                                        deviceSubType: e.target.value,
                                      },
                                    }}))
                                }
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                }}
                                {...(state.mode === "view" && {
                                  disabled: true,
                                })}
                              >
                                <option>-- Изберете тип --</option>
                                {water_meter_types &&
                                  water_meter_types.length > 0 &&
                                  water_meter_types.map((option) => (
                                    <option
                                      value={option.key}
                                      selected={
                                        state.document.device.deviceSubType === option.key
                                      }
                                    >
                                      {option.value}
                                    </option>
                                  ))}
                              </AvField>
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="water_meter_collage" className="">
                                Цолаж
                              </Label>

                              <AvField
                                type="select"
                                name="document.collage"
                                id="water_meter_collage"
                                errorMessage="Select Device type"
                                value={state.document.collage}
                                onChange={(e) =>
                                  setState((state) => ({
                                    ...state,
                                    document: {
                                      ...state.document,
                                      collage: e.target.value,
                                    },
                                  }))
                                }
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                }}
                                {...(state.mode === "view" && {
                                  disabled: true,
                                })}
                              >
                                <option>-- Изберете цолаж --</option>
                                {collage_types_list &&
                                  collage_types_list.length > 0 &&
                                  collage_types_list.map((option) => (
                                    <option
                                      value={option.key}
                                      selected={
                                        state.document.collage === option.key
                                      }
                                    >
                                      {option.value}
                                    </option>
                                  ))}
                              </AvField>
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="water_meter_production_year">
                                Година на производство
                              </Label>
                              <AvField
                                type="number"
                                min={1900}
                                max={2099}
                                name="document.productionYear"
                                placeholder="Въведете година на производство"
                                id="water_meter_production_year"
                                onChange={(e) =>
                                  setState((state) => ({
                                    ...state,
                                    document: {
                                      ...state.document,
                                      productionYear: e.target.value,
                                    },
                                  }))
                                }
                                value={state.document.productionYear}
                                className="w-100"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="water_meter_validity_year">
                                Година на валидност
                              </Label>
                              <AvField
                                type="number"
                                min={1900}
                                max={2099}
                                name="document.validityYear"
                                placeholder="Въведете година на производство"
                                id="water_meter_validity_year"
                                onChange={(e) =>
                                  setState((state) => ({
                                    ...state,
                                    document: {
                                      ...state.document,
                                      validityYear: e.target.value,
                                    },
                                  }))
                                }
                                value={state.document.validityYear}
                                className="w-100"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="water_meter_seal_number">
                                Номер на пломба
                              </Label>
                              <AvField
                                name="document.sealNumber"
                                placeholder="Въведете номер на пломба"
                                id="water_meter_seal_number"
                                onChange={(e) =>
                                  setState((state) => ({
                                    ...state,
                                    document: {
                                      ...state.document,
                                      sealNumber: e.target.value,
                                    },
                                  }))
                                }
                                value={state.document.sealNumber}
                                className="w-100"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <Label htmlFor="water_meter_export_number_column">
                                Номер на колона при експорт
                              </Label>
                              <AvField
                                type="number"
                                min={0}
                                name="document.columnExportNumber"
                                placeholder="Въведете номер на колона при експорт"
                                id="water_meter_export_number_column"
                                onChange={(e) =>
                                  setState((state) => ({
                                    ...state,
                                    document: {
                                      ...state.document,
                                      columnExportNumber: e.target.value,
                                    },
                                  }))
                                }
                                value={state.document.columnExportNumber}
                                className="w-100"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label htmlFor="water_meter_radio_number_correction_value">
                                Коригираща стойност на радио модула
                              </Label>

                              <AvField
                                type="number"
                                min={0}
                                name="document.correctionValueRadioModule"
                                placeholder="Въведете коригираща стойност на радио модула"
                                id="water_meter_radio_number_correction_value"
                                onChange={(e) =>
                                  setState((state) => ({
                                    ...state,
                                    document: {
                                      ...state.document,
                                      correctionValueRadioModule:
                                        e.target.value,
                                    },
                                  }))
                                }
                                value={
                                  state.document.correctionValueRadioModule
                                }
                                className="w-100"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <FormGroup>
                              <Label htmlFor="water_meter_comment">
                                Коментар
                              </Label>
                              <AvField
                                type="textarea"
                                name="document.device.comment"
                                placeholder="Въведете коментар"
                                id="water_meter_comment"
                                maxLength="200"
                                onChange={(e) =>
                                  setState((state) => ({
                                    ...state,
                                    document: {
                                      ...state.document,
                                      device: {
                                        ...state.document.device,
                                        comment: e.target.value,
                                      },
                                    },
                                  }))
                                }
                                value={state.document.device.comment}
                                className="w-100"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </AvForm>
            </Col>

            <Col xl="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <Row>
                        <Col md="6">
                          <Nav pills className="navtab-bg nav-justified">
                            {state.tabsNav.map((item) => (
                              <NavItem key={item.id}>
                                <NavLink
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  className={classnames({
                                    active: state.activeTab === item.index,
                                  })}
                                  onClick={() => {
                                    toggleTabs(item.index);
                                  }}
                                >
                                  {item.label}
                                </NavLink>
                              </NavItem>
                            ))}
                          </Nav>
                        </Col>
                      </Row>

                      <TabContent activeTab={state.activeTab}>
                        <TabPane tabId="1" className="p-3">
                          {/* Отчети */}
                          <Row className="mb-2">
                            <Col>
                              <CardTitle className="d-none mb-4">
                                Таблица с отчетните стойности
                              </CardTitle>
                            </Col>
                          </Row>
                          {tableBodyDeviceReports && (
                            <TableReports
                              dataHead={[
                                i18next.t("report-column-report-date"),
                                i18next.t("report-column-visitation-date"),
                                i18next.t("report-column-value"),
                                i18next.t("report-column-yearly"),
                                i18next.t("actions"),
                              ]}
                              dataBody={tableBodyDeviceReports}
                              isEditable={true}
                              editableCell={"value"}
                              url_path={""}
                              category={""}
                              emitRowSave={emitRowSave}
                              enableLinkDelete={true}
                              onDelete={onModalDeviceReportDelete}
                              delete_tooltip={i18next.t(
                                  "tooltip-delete-report"
                              )}
                              isLoaded={true}
                            />
                          )}
                        </TabPane>
                        <TabPane tabId="2" className="p-3">
                          {/* Състояния */}
                          <Row className="mb-2">
                            <Col>
                              <CardTitle className="d-none mb-4">
                                Таблица със състоянията
                              </CardTitle>
                            </Col>

                            <Col>
                              <div className="text-right">
                                <Tooltip
                                  title="Добави ново състояние"
                                  placement="right-start"
                                >
                                  <button
                                    className="btn btn-primary waves-effect waves-light"
                                    onClick={(id) => openModal("condition")}
                                  >
                                    <i
                                      className="bx bx-plus-circle"
                                      style={{
                                        fontSize: "18px",
                                      }}
                                    />
                                  </button>
                                </Tooltip>
                              </div>
                            </Col>
                          </Row>
                          {tableBodyDeviceStatuses && (
                            <TableComponent
                              dataHead={[
                                "От дата",
                                "До дата",
                                "Състояние",
                                "Действия",
                              ]}
                              dataBody={tableBodyDeviceStatuses}
                              url_path={""}
                              category={""}
                              enableLinkDelete={true}
                              onDelete={onModalDeviceStatusDelete}
                              delete_tooltip={i18next.t(
                                "tooltip-delete-condition"
                              )}
                              isLoaded={true}
                            />
                          )}
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <ModalCondition
            conditions={water_meter_conditions}
            isModalOpen={state.showModal === "condition"}
            closeModal={closeModal}
            update={() => closeAndUpdate(getAllDeviceStatuses)}
            device_id={state.document.deviceId}
            emitErrors={emitErrors}
            emitSuccess={emitSuccess}
          />
        </Container>
      </div>
    </>
  );
};

WaterMeterSingle.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(WaterMeterSingle));

import { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { Modal, Button } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import * as jsonpatch from "fast-json-patch";
import * as endpoints from "constants/endpoints";
import { CreateItem } from "../../helpers/CRUD.js";
import i18next from "i18next";

const ModalProfessionalHouseManagerCompanies = (props) => {
  const initial_state = {
    document: {
      name: "",
      contactName: "",
      contactPhone: "",
      email: "",
      comment: "",
    },
  };

  const [state, setState] = useState(initial_state);

  const resetData = () => {
    setState(initial_state);
  };

  const handleValidSubmit = (event, values) => {
    let patch = jsonpatch.compare(initial_state.document, state.document);

    const url = `${endpoints.NOMENCLATURE_ENDPOINT}/ProfessionalHouseManagementCompany/Add`;

    CreateItem(
      url,
      patch,
      (data) => {
        update();
        props.emitSuccess(i18next.t("entry_added_successfully"));
      },
      (error) => {
        props.emitErrors(error);
      }
    );
  };

  const closeModal = () => {
    props.closeModal();
    resetData();
  };

  const update = () => {
    props.update();
    resetData();
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Modal isOpen={props.isModalOpen} scrollable={true}>
            <div className="modal-header">
              <h5 className="modal-title mt-0">
                {i18next.t("modal_professional_house_management_company_title")}
              </h5>

              <button
                type="button"
                onClick={() => closeModal()}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <Card className="mb-0">
                <CardBody>
                  <AvForm
                    onValidSubmit={handleValidSubmit}
                    className="needs-validation"
                  >
                    <Row className="mb-4">
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="name">{i18next.t("name")}</Label>

                          <AvField
                            name="document.name"
                            placeholder={i18next.t("placeholder_enter_name")}
                            id="name"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  name: e.target.value,
                                },
                              }))
                            }
                            defaultValue={state.document.name}
                            className="w-100"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="contactName">
                            {i18next.t("contact-person")}
                          </Label>

                          <AvField
                            name="document.contactName"
                            placeholder={i18next.t(
                              "placeholder_enter_contact_person"
                            )}
                            id="contactName"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  contactName: e.target.value,
                                },
                              }))
                            }
                            defaultValue={state.document.contactName}
                            className="w-100"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="contactPhone">
                            {i18next.t("contactPhone")}
                          </Label>

                          <AvField
                            name="document.contactPhone"
                            placeholder={i18next.t(
                              "placeholder_enter_contact_phone"
                            )}
                            id="contactPhone"
                            type="text"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  contactPhone: e.target.value,
                                },
                              }))
                            }
                            defaultValue={state.document.contactPhone}
                            className="w-100"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="email">{i18next.t("email")}</Label>

                          <AvField
                            type="email"
                            name="document.email"
                            placeholder={i18next.t("placeholder_enter_email")}
                            id="email"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  email: e.target.value,
                                },
                              }))
                            }
                            defaultValue={state.document.email}
                            className="w-100"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="comments">
                            {i18next.t("field_comment")}
                          </Label>

                          <AvField
                            name="document.comment"
                            placeholder={i18next.t("placeholder_enter_comment")}
                            id="comments"
                            type="textarea"
                            maxLength="200"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  comment: e.target.value,
                                },
                              }))
                            }
                            defaultValue={state.document.comment}
                            className="w-100"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => closeModal()}
                      >
                        <i className="fa fa-times mr-1" />
                        {i18next.t("button_cancel")}
                      </button>

                      <Button color="primary" type="submit">
                        <i className="fa fa-plus-circle mr-1" />
                        {i18next.t("button_add")}
                      </Button>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </div>
          </Modal>
        </Container>
      </div>
    </>
  );
};

ModalProfessionalHouseManagerCompanies.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUsers: () => dispatch(getUsers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ModalProfessionalHouseManagerCompanies));

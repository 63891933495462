import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardBody, Col, Row, FormGroup, Label } from "reactstrap";
import { Modal, Button } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { getUsers } from "store/contacts/actions";
import * as endpoints from "constants/endpoints";
import * as jsonpatch from "fast-json-patch";
import { CreateItem } from "../../helpers/CRUD.js";
import { str2bool } from "../../helpers/utils.js";
import i18next from "i18next";
import { months } from "../../helpers/listsHardcodedData";

const ModalEstimatedToploficationData = (props) => {
  const initial_state = {
    document: {
      month: 0,
      year: 0,
      personCount: 0,
      estimatedHeatingPerDay: null,
      estimatedHotWaterPerMonth: null,
      estateVolume: null,
      percentVolumeUsedInCalc: null,
      isManualEstimation: false,
    },
  };

  const [state, setState] = useState(initial_state);

  const resetData = () => {
    setState(initial_state);
  };

  const handleSubmit = (event, errors, values) => {
    setState((state) => ({ ...state, errors, values }));
  };

  const toggleIsManualEstimation = (e) => {
    console.log(e.target.checked);

    setState((state) => ({
      ...state,
      document: {
        ...state.document,
        isManualEstimation: e.target.checked,
      },
    }));
  };

  const handleValidSubmit = (event, values) => {
    let patch = jsonpatch.compare(initial_state.document, state.document);

    patch.push({
      op: "replace",
      path: "/estateId",
      value: props.estate_id,
    });

    if(state.document.isManualEstimation === false) {
      patch.push({
        op: "add",
        path: "/isManualEstimation",
        value: state.document.isManualEstimation,
      });
    }

    const url = `${endpoints.BUILDING_UNITS_ADDITIONAL_ENDPOINT}/ToploficationData/Add`;

    CreateItem(
      url,
      patch,
      (data) => {
        update();
        props.emitSuccess(i18next.t("entry_added_successfully"));
      },
      (error) => {
        props.emitErrors(error);
      }
    );
  };

  const closeModal = () => {
    props.closeModal();
    resetData();
  };

  const update = () => {
    props.update();
    resetData();
  };

  const months_list = months();

  return (
    <>
      <Modal isOpen={props.isModalOpen} scrollable={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            {i18next.t("modal_estate_estimated_toplofication_data")}{" "}
            {props.estate_fish}
          </h5>

          <button
            type="button"
            onClick={() => closeModal()}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Card className="mb-0">
            <CardBody>
              <AvForm
                onSubmit={handleSubmit}
                onValidSubmit={handleValidSubmit}
                className="needs-validation"
              >
                <Row className="mb-4">
                  <Col md="6">
                    {/*<FormGroup>*/}
                    <div
                      className="custom-control custom-switch mb-0 mt-3"
                      dir="ltr"
                    >
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customSwitchsizesmIsYearReport"
                        checked={state.document.isManualEstimation}
                        onClick={(e) => toggleIsManualEstimation(e)}
                      />
                      <label
                        className="custom-control-label mb-0"
                        htmlFor="customSwitchsizesmIsYearReport"
                      >
                        {i18next.t("toplofication_data_manual_estimation")}
                      </label>
                    </div>
                    {/* </FormGroup>*/}
                  </Col>
                </Row>
                <Row className="mb-4">
                  {months_list && (
                    <Col md="6">
                      <FormGroup>
                        <Label htmlFor="validationMonth">
                          {i18next.t("month")}
                        </Label>

                        <AvField
                          type="select"
                          name="document.month"
                          id="validationMonth"
                          className="form-control"
                          errorMessage={i18next.t("choose-month")}
                          value={state.document.month}
                          validate={{
                            required: {
                              value: true,
                            },
                          }}
                          onChange={(e) =>
                            setState((state) => ({
                              ...state,
                              document: {
                                ...state.document,
                                month: e.target.value,
                              },
                            }))
                          }
                        >
                          <option
                            value=""
                            selected={
                              state.document.month === "" ? true : false
                            }
                          >
                            -- {i18next.t("choose-month")} --
                          </option>

                          {months_list.map((month) => (
                            <option
                              value={month.key}
                              key={month.key}
                              selected={
                                state.document.month === month.key
                                  ? true
                                  : false
                              }
                            >
                              {month.value}
                            </option>
                          ))}
                        </AvField>
                      </FormGroup>
                    </Col>
                  )}
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="year">{i18next.t("year")}</Label>

                      <AvField
                        name="document.year"
                        type="number"
                        min={1990}
                        max={2099}
                        placeholder={i18next.t("placeholder_enter_year")}
                        id="year"
                        onChange={(e) =>
                          setState((state) => ({
                            ...state,
                            document: {
                              ...state.document,
                              year: e.target.value,
                            },
                          }))
                        }
                        defaultValue={state.document.year}
                        className="w-100"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row className="mb-4">
                  <Col md="12">
                    <FormGroup>
                      <Label htmlFor="estimatedHeatingPerDay">
                        {i18next.t("estimatedHeatingPerDay")}
                      </Label>

                      <AvField
                        type="number"
                        step={0.01}
                        min={0}
                        name="document.estimatedHeatingPerDay"
                        placeholder={i18next.t(
                          "placeholder_enter_estimated_heating_day"
                        )}
                        id="estimatedHeatingPerDay"
                        onChange={(e) =>
                          setState((state) => ({
                            ...state,
                            document: {
                              ...state.document,
                              estimatedHeatingPerDay: e.target.value,
                            },
                          }))
                        }
                        defaultValue={state.document.estimatedHeatingPerDay}
                        className="w-100"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row className="mb-4">
                  <Col md="12">
                    <FormGroup>
                      <Label htmlFor="estimatedHotWaterPerMonth">
                        {i18next.t("estimatedHotWaterPerMonth")}
                      </Label>

                      <AvField
                        type="number"
                        step={0.01}
                        min={0}
                        name="document.estimatedHotWaterPerMonth"
                        placeholder={i18next.t(
                          "placeholder_enter_estimated_hotwater_month"
                        )}
                        id="estimatedHotWaterPerMonth"
                        onChange={(e) =>
                          setState((state) => ({
                            ...state,
                            document: {
                              ...state.document,
                              estimatedHotWaterPerMonth: e.target.value,
                            },
                          }))
                        }
                        defaultValue={state.document.estimatedHotWaterPerMonth}
                        className="w-100"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row className="mb-4">
                  <Col md="4">
                    <FormGroup>
                      <Label htmlFor="personCount">
                        {i18next.t("personCount")}
                      </Label>

                      <AvField
                        type="number"
                        step={1}
                        min={0}
                        name="document.personCount"
                        placeholder={i18next.t(
                          "placeholder_enter_person_count"
                        )}
                        id="personCount"
                        onChange={(e) =>
                          setState((state) => ({
                            ...state,
                            document: {
                              ...state.document,
                              personCount: e.target.value,
                            },
                          }))
                        }
                        defaultValue={state.document.personCount}
                        className="w-100"
                      />
                    </FormGroup>
                  </Col>

                  <Col md="4">
                    <FormGroup>
                      <Label htmlFor="estateVolume">
                        {i18next.t("estateVolume")}
                      </Label>

                      <AvField
                        type="number"
                        step={1}
                        min={0}
                        name="document.estateVolume"
                        placeholder={i18next.t(
                          "placeholder_enter_estate_volume"
                        )}
                        id="estateVolume"
                        onChange={(e) =>
                          setState((state) => ({
                            ...state,
                            document: {
                              ...state.document,
                              estateVolume: e.target.value,
                            },
                          }))
                        }
                        defaultValue={state.document.estateVolume}
                        className="w-100"
                      />
                    </FormGroup>
                  </Col>

                  <Col md="4">
                    <FormGroup>
                      <Label htmlFor="percentVolumeUsedInCalc">
                        {i18next.t("percentVolumeUsedInCalc")}
                      </Label>

                      <AvField
                        type="number"
                        step={1}
                        min={0}
                        name="document.percentVolumeUsedInCalc"
                        placeholder={i18next.t(
                          "placeholder_enter_calc_percent_volume"
                        )}
                        id="percentVolumeUsedInCalc"
                        onChange={(e) =>
                          setState((state) => ({
                            ...state,
                            document: {
                              ...state.document,
                              percentVolumeUsedInCalc: e.target.value,
                            },
                          }))
                        }
                        defaultValue={state.document.percentVolumeUsedInCalc}
                        className="w-100"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => closeModal()}
                  >
                    <i className="fa fa-times mr-1" />
                    {i18next.t("button_cancel")}
                  </button>

                  <Button color="primary" type="submit">
                    <i className="fa fa-plus-circle mr-1" />
                    {i18next.t("button_add")}
                  </Button>
                </div>
              </AvForm>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </>
  );
};

ModalEstimatedToploficationData.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUsers: () => dispatch(getUsers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ModalEstimatedToploficationData));

import { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  FormGroup,
  Label,
} from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as endpoints from "constants/endpoints";
import { GetData, GetFile } from "../../helpers/CRUD.js";
import { throttle } from "lodash";
import i18next from "i18next";
import Loader from "../../components/Common/Loader";

const ExportFileMonth = (props) => {
  const [state, setState] = useState({
    buildings_all: [],
    getAllBuildingsSwitch: false,
    exportEndpoints: ["FFile", "FPOTRFile", "UFile", "IFile"],
    years: ["2020", "2021"],
    months: [
      { value: 1, label: "january" },
      { value: 2, label: "february" },
      { value: 3, label: "march" },
      { value: 4, label: "april" },
      { value: 5, label: "may" },
      { value: 6, label: "june" },
      { value: 7, label: "july" },
      { value: 8, label: "august" },
      { value: 9, label: "september" },
      { value: 10, label: "october" },
      { value: 11, label: "november" },
      { value: 12, label: "december" },
    ],
    selectedMonth: "",
    selectedYear: "",
    document: {
      buildingId: "",
      heatingSeasonId: "",
    },
    isLoading: false,
    isLoaded: false,
  });

  const getAllBuildings = () => {
    const url = `${endpoints.BUILDING}/GetAll`;

    GetData(
      url,
      (data) => {
        setState((state) => ({ ...state, buildings_all: data.items }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const filterBuildings = (value) => {
    let endpointQueryParam = "";

    if (value) {
      endpointQueryParam = `?filterText=${value}`;
    }

    const url = `${endpoints.BUILDING}/GetAll${endpointQueryParam}`;

    GetData(
      url,
      (data) => {
        setState((state) => ({ ...state, buildings_all: data.items }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const filterBuildingsThrottled = throttle(filterBuildings, 800);

  const setAddress = (event) => {
    filterBuildings(event.target.outerText);
  };

  const handleAutoCompleteChangeBuilding = (event, item) => {
    if (item !== null) {
      setState((state) => ({
        ...state,
        document: {
          ...state.document,
          buildingId: item.id,
        },
      }));
    } else {
      setState((state) => ({
        ...state,
        document: {
          ...state.document,
          buildingId: "",
        },
      }));
    }
  };

  const sendData = (e, preview, endpoint) => {
    setState((state) => ({ ...state, isLoading: true }));

    const d = new Date();

    const baseUrl = `${endpoints.EXPORT_TEST}/Export`;
    let additionalUrl;

    if (state.getAllBuildingsSwitch === true) {
      additionalUrl = `?month=${state.selectedMonth}&year=${state.selectedYear}`;
    } else {
      additionalUrl = `?buildingId=${state.document.buildingId}&month=${state.selectedMonth}&year=${state.selectedYear}`;
    }

    const day = d.getDate();
    const month = d.getMonth();
    const yearr = d.getFullYear();

    const url = `${baseUrl}${endpoint}${additionalUrl}`;

    GetFile(
      url,
      (blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        a.download = `${endpoint}-${day}-${month + 1}-${yearr}.dbf`;
        a.click();
        window.URL.revokeObjectURL(url);
      },
      (error) => {
        setState((state) => ({
          ...state,
          isLoaded: false,
          isLoading: false,
        }));
        props.onErrorsShow(error.body);
      }
    );
  };

  useEffect(() => {
    getAllBuildings();
  }, []);

  const monthNames = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ];
  const d = new Date();
  const workingMonth = monthNames[d.getMonth() - 1];

  let disabledStyles = {
    pointerEvents: state.getAllBuildingsSwitch ? "none" : "auto",
    opacity: state.getAllBuildingsSwitch ? "0.4" : "1",
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Card className="overflow-hidden">
            <CardBody className="pt-3">
              <AvForm className="form-horizontal">
                <Row>
                  <Col md="2">
                    <h5>
                      {i18next.t("working-month")}: {i18next.t(workingMonth)}
                    </h5>
                  </Col>
                </Row>

                <Row className="align-items-end">
                  <Col md="2">
                    <div className="form_controls">
                      <Label htmlFor="building_heating_area" className="">
                        Месец
                      </Label>

                      <AvField
                        type="select"
                        value={state.selectedMonth}
                        onChange={(e) =>
                          setState((state) => ({
                            ...state,
                            selectedMonth: e.target.value,
                          }))
                        }
                        name="selectedMonth"
                        id="building_heating_area"
                        {...(state.mode === "view" && {
                          disabled: true,
                        })}
                      >
                        <option key={`month-missing`} value={null}>
                          Изберете Месец
                        </option>
                        {state.months.map((option, i) => (
                          <option key={`month-${i}`} value={option.value}>
                            {i18next.t(option.label)}
                          </option>
                        ))}
                      </AvField>
                    </div>
                  </Col>

                  <Col md="2">
                    <div className="form_controls">
                      <Label htmlFor="building_heating_area" className="">
                        Година
                      </Label>

                      <AvField
                        type="select"
                        value={state.selectedYear}
                        onChange={(e) =>
                          setState((state) => ({
                            ...state,
                            selectedYear: e.target.value,
                          }))
                        }
                        name="selectedYear"
                        id="building_heating_area2"
                        {...(state.mode === "view" && {
                          disabled: true,
                        })}
                      >
                        <option key={`year-missing`} value={null}>
                          Изберете Година
                        </option>
                        {state.years.map((option, i) => (
                          <option key={`year-${i}`} value={option}>
                            {option}
                          </option>
                        ))}
                      </AvField>
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="form_controls" style={disabledStyles}>
                      <label>{i18next.t("address")}</label>

                      <FormGroup>
                        <Autocomplete
                          options={state.buildings_all}
                          renderOption={(option) => (
                            <>{option.address.streetName}</>
                          )}
                          getOptionLabel={(option) => option.address.streetName}
                          getOptionSelected={(option) =>
                            option.address.streetName
                          }
                          renderInput={(params) => {
                            return (
                              <TextField
                                {...params}
                                label={i18next.t("choose-address")}
                                variant="outlined"
                                fullWidth
                                onChange={(e) =>
                                  filterBuildingsThrottled(e.target.value)
                                }
                              />
                            );
                          }}
                          onChange={(event, value) => {
                            setAddress(event, value);
                          }}
                        />
                      </FormGroup>
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="form_controls" style={disabledStyles}>
                      <label>{i18next.t("building")}</label>

                      <FormGroup>
                        <Autocomplete
                          options={state.buildings_all}
                          renderOption={(option) => <>{option.externalId}</>}
                          getOptionLabel={(option) => option.externalId}
                          renderInput={(params) => {
                            return (
                              <TextField
                                {...params}
                                label={i18next.t("choose-building")}
                                variant="outlined"
                                fullWidth
                                onChange={(e) =>
                                  filterBuildingsThrottled(e.target.value)
                                }
                              />
                            );
                          }}
                          onChange={handleAutoCompleteChangeBuilding}
                        />
                      </FormGroup>
                    </div>
                  </Col>
                </Row>

                <Row className="mb-4">
                  <Col>
                    <div className="form_controls">
                      <AvField
                        type="checkbox"
                        name="getAllBuildingsSwitch"
                        label={i18next.t("option_all")}
                        checked={state.getAllBuildingsSwitch === true}
                        value={state.getAllBuildingsSwitch}
                        onChange={() =>
                          setState((state) => ({
                            ...state,
                            getAllBuildingsSwitch: !state.getAllBuildingsSwitch,
                          }))
                        }
                        id="getAllBuildingsSwitch"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  {state.exportEndpoints.map((endpoint) => {
                    return (
                      <Col md={3}>
                        <button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          onClick={(e) => sendData(e, true, endpoint)}
                          type="button"
                        >
                          <i className="fa fa-download mr-1" />
                          {i18next
                            .t("btn-download-file")
                            .replace("{filename}", endpoint)}
                        </button>
                      </Col>
                    );
                  })}
                </Row>
              </AvForm>
            </CardBody>
          </Card>

          {state.isLoading && !state.isLoaded && <Loader showLoader={true} />}
        </Container>
      </div>
    </>
  );
};

ExportFileMonth.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ExportFileMonth));

import { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Col, Container, Row, Label, FormGroup } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import BarPageTitle from "../../components/Common/BarPageTitle";
import { AvField, AvForm } from "availity-reactstrap-validation";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LinkView from "components/Common/LinkView";
import LinkCalculations from "components/Common/LinkCalculations";
import Pagination from "components/Common/Pagination";
import { Tooltip } from "@material-ui/core";
import * as endpoints from "constants/endpoints";
import { FileDownload, GetData } from "../../helpers/CRUD.js";
import { throttle } from "lodash";
import i18next from "i18next";

const BillManagementMonth = (props) => {
  const [state, setState] = useState({
    buildings_all: [],
    heatingSeasons: [],
    selectedSeason: {},
    selectedAddressId: "",
    selectedBuildingId: "",
    tableData: [],
    page: 1,
    totalCount: null,
    pageSize: 100,
  });

  const emitErrors = (error) => {
    props.onErrorsShow(error.body);
  };

  const emitSuccess = (success) => {
    props.onSuccessShow(success);
  };

  const updatePage = (page) => {
    setState((state) => ({ ...state, page }));
  };

  const getCurrentHeatingSeason = () => {
    const url = `${endpoints.NOMENCLATURE_ENDPOINT}/HeatingSeason/GetWorkingHeatingSeason`;

    GetData(
      url,
      (data) => {
        setState((state) => ({
          ...state,
          selectedSeason: { id: data.id, name: data.name },
        }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getAllHeatingSeasons = () => {
    const url = `${endpoints.NOMENCLATURE_ENDPOINT}/HeatingSeason/GetAll`;

    GetData(
      url,
      (data) => {
        console.log(data);
        setState((state) => ({ ...state, heatingSeasons: data.items }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getAllBuildings = () => {
    const url = `${endpoints.BUILDING}/GetAll`;

    GetData(
      url,
      (data) => {
        setState((state) => ({ ...state, buildings_all: data.items }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const filterBuildings = (value) => {
    let endpointQueryParam = "";

    if (value) {
      endpointQueryParam = `?filterText=${value}`;
    }

    const url = `${endpoints.BUILDING}/GetAll${endpointQueryParam}`;

    GetData(
      url,
      (data) => {
        setState((state) => ({ ...state, buildings_all: data.items }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const filterBuildingsThrottled = throttle(filterBuildings, 800);

  const setAddress = (event) => {
    filterBuildings(event.target.outerText);
  };

  const handleAutoCompleteChangeBuilding = (event, item) => {
    if (item !== null) {
      setState((state) => ({
        ...state,
        selectedBuildingId: item.id,
      }));
    } else {
      setState((state) => ({
        ...state,
        selectedBuildingId: "",
      }));
    }
  };

  const getTableData = () => {
    const url = `${endpoints.CALCULATIONS}/GetAllBuildingsHistoryPerMonth/${state.selectedMonth.year}/${state.selectedMonth.month}?addressId=${state.selectedAddressId}&buildingId=${state.selectedBuildingId}&page=${state.page}&size=${state.pageSize}`;

    GetData(
      url,
      (data) => {
        setState((state) => ({
          ...state,
          tableData: data.items,
          totalCount: data.totalCount,
        }));
      },
      (error) => {
        emitErrors(error);
      }
    );
  };

  const downloadFiles = () => {
    const url = `${endpoints.EXPORT}/DownloadFiles/${state.selectedMonth.year}/${state.selectedMonth.month}`;

    FileDownload(
      url,
      (blob) => {
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");

        link.href = downloadUrl;
        link.setAttribute(
          "download",
          `Monthly files-${state.selectedMonth.month}${state.selectedMonth.year}`
        ); //any other extension

        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(link);
      },
      (error) => {
        console.log(error);
        setState((state) => ({
          ...state,
          isLoaded: false,
          isLoading: false,
        }));
        emitErrors(error);
      }
    );
  };

  useEffect(() => {
    getCurrentHeatingSeason();
    getAllHeatingSeasons();
    getAllBuildings();
  }, []);

  useEffect(() => {
    state.selectedMonth && getTableData();
  }, [state.selectedMonth]);

  const tableHead = {
    backgroundColor: "#F0F0F0",
    borderColor: "#C8C8C8",
    verticalAlign: "middle",
    padding: 6,
    textAlign: "center",
  };

  const tableBody = {
    borderColor: "#C8C8C8",
    padding: 6,
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <BarPageTitle pageTitle={i18next.t("yearly-bill-management")} />
          <AvForm className="form-horizontal">
            <Row className="mb-3">
              <Col md="3">
                <div className="form_controls">
                  <Label htmlFor="heating_season_selection" className="">
                    {i18next.t("heating-season")}
                  </Label>
                  <FormGroup>
                    <Autocomplete
                      value={state.selectedSeason}
                      options={state.heatingSeasons}
                      renderOption={(option) => <>{option.name}</>}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            label={i18next.t("choose-season")}
                            variant="outlined"
                            name="selectedHeatingSeason"
                            fullWidth
                          />
                        );
                      }}
                      onChange={(event, item) => {
                        setState((state) => ({
                          ...state,
                          selectedSeason: item,
                        }));
                      }}
                    />
                  </FormGroup>
                </div>
              </Col>
              <Col
                md="9"
                className="d-flex justify-content-end"
                style={{ height: 35 }}
              >
                <button
                  className="btn bill-management-btn"
                  style={{ background: "#EBFEE5" }}
                  onClick={() => {}}
                  type="button"
                >
                  <i className="fa fa-times mr-1" />
                  {i18next.t("placeholder_end")}
                </button>
                <button
                  className="btn bill-management-btn"
                  style={{ background: "#E7E5FE" }}
                  onClick={downloadFiles}
                  type="button"
                >
                  <i className="fa fa-download mr-1" />
                  {i18next.t("download-files")}
                </button>
                <button
                  className="btn bill-management-btn"
                  style={{ background: "#FDFBC3" }}
                  onClick={() => {}}
                  type="button"
                >
                  <i className="fa fa-exclamation-triangle mr-1" />
                  {i18next.t("log-errors")}
                </button>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <Label htmlFor="address_selection">
                  {i18next.t("address")}
                </Label>
                <FormGroup>
                  <Autocomplete
                    options={state.buildings_all}
                    renderOption={(option) => <>{option.address.streetName}</>}
                    getOptionLabel={(option) => option.address.streetName}
                    getOptionSelected={(option) => option.address.streetName}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          label={i18next.t("choose-address")}
                          variant="outlined"
                          name="address_selection"
                          fullWidth
                          onChange={(e) =>
                            filterBuildingsThrottled(e.target.value)
                          }
                        />
                      );
                    }}
                    onChange={(event, value) => {
                      setAddress(event, value);
                      value
                        ? setState((state) => ({
                            ...state,
                            selectedAddressId: value.address.id,
                          }))
                        : setState((state) => ({
                            ...state,
                            selectedAddressId: "",
                          }));
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <Label htmlFor="external_id_selection">
                  {i18next.t("building")}
                </Label>
                <FormGroup>
                  <Autocomplete
                    options={state.buildings_all}
                    renderOption={(option) => <>{option.externalId}</>}
                    getOptionLabel={(option) => option.externalId}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          label={i18next.t("choose-building")}
                          variant="outlined"
                          name="external_id_selection"
                          fullWidth
                          onChange={(e) =>
                            filterBuildingsThrottled(e.target.value)
                          }
                        />
                      );
                    }}
                    onChange={handleAutoCompleteChangeBuilding}
                  />
                </FormGroup>
              </Col>
              <Col md="6" className="d-flex justify-content-end">
                <div>
                  <label>{i18next.t("summarized-info")}</label>
                  <div className="d-flex justify-content-between info-box">
                    <div className="left-container">
                      <p>Ценови периоди: 2бр:</p>
                      <p>от месец - до месец - цена</p>
                      <p>от месец - до месец - цена</p>
                    </div>
                    <div>
                      <p>Приключени сгради: 5 от 44</p>
                      <p>Сгради с корекции: 4</p>
                      <p>Сгради с предадени изчисления: 4 от 5</p>
                      <p>Брой сгради с неотчетени имоти: 4</p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col className="d-flex mb-2" md="12">
                <AvField
                  type="checkbox"
                  name="checkAll"
                  // checked={}
                  // value={}
                  onChange={() => {}}
                  id="checkAll"
                />
                Покажи месечни
              </Col>
              <Col className="d-flex mb-2" md="12">
                <AvField
                  type="checkbox"
                  name="checkAll"
                  // checked={}
                  // value={}
                  onChange={() => {}}
                  id="checkAll"
                />
                Покажи само без отчет
              </Col>
              <Col className="d-flex mb-2" md="12">
                <AvField
                  type="checkbox"
                  name="checkAll"
                  // checked={}
                  // value={}
                  onChange={() => {}}
                  id="checkAll"
                />
                Покажи само с повредени уреди
              </Col>
              <Col className="d-flex mb-2" md="12">
                <AvField
                  type="checkbox"
                  name="checkAll"
                  // checked={}
                  // value={}
                  onChange={() => {}}
                  id="checkAll"
                />
                Покажи само сгради с имоти с разделяне
              </Col>
            </Row>
            <Row>
              <Col md="2">
                <button
                  style={{ marginBottom: "1rem" }}
                  className="btn btn-primary btn-block waves-effect waves-light"
                  onClick={getTableData}
                  type="button"
                  disabled={!state.selectedMonth}
                >
                  <i className="fa fa-search mr-1" />
                  {i18next.t("placeholder_search")}
                </button>
              </Col>
            </Row>
            <Row>
              <Table
                className="table scrollable table-bordered"
                style={{ tableLayout: "auto" }}
              >
                <Thead>
                  <Tr>
                    <Th style={tableHead}>
                      <div style={{ marginBottom: 20 }}>
                        <Tooltip
                          title={i18next.t("select_all")}
                          placement="right-start"
                        >
                          <AvField
                            type="checkbox"
                            name="checkAll"
                            // checked={}
                            // value={}
                            onChange={() => {}}
                            id="checkAll"
                          />
                        </Tooltip>
                      </div>
                    </Th>
                    <Th style={tableHead}>{i18next.t("building")}</Th>
                    <Th style={tableHead}>{i18next.t("address")}</Th>
                    <Th style={tableHead}>{i18next.t("percentageBuilding")}</Th>
                    <Th style={tableHead}>{i18next.t("SRShotWater")}</Th>
                    <Th style={tableHead}>{i18next.t("unitAbbrv")}</Th>
                    <Th style={tableHead}>
                      {i18next.t("reportCommonWaterMeter")}
                    </Th>
                    <Th style={tableHead}>
                      {i18next.t("individualWatermetersSum")}
                    </Th>
                    <Th style={tableHead}>
                      {i18next.t("coefficientCommonWaterMeter")}
                    </Th>
                    <Th style={tableHead}>
                      {i18next.t("estatesNumberDivision")}
                    </Th>
                    <Th style={tableHead}>{i18next.t("damagedDevices")}</Th>
                    <Th style={tableHead}>
                      {i18next.t("estatesWithoutReports")}
                    </Th>
                    <Th style={tableHead}>{i18next.t("status")}</Th>
                    <Th style={tableHead}>{i18next.t("completedBuildings")}</Th>
                    <Th style={tableHead}>{i18next.t("actions")}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {state.tableData && state.tableData.length === 0 ? (
                    <Tr>
                      <Td
                        colSpan={15}
                        className="align-items-center text-center"
                        style={tableBody}
                      >
                        {i18next.t("table_missing_data")}
                      </Td>
                    </Tr>
                  ) : (
                    state.tableData.map((row, i) => (
                      <Tr key={`period-${row.id}`}>
                        <Td style={tableBody}>
                          <AvField
                            type="checkbox"
                            name="checkTableItem"
                            // checked={}
                            // value={}
                            onChange={() => {}}
                            id="checkTableItem"
                          />
                        </Td>
                        <Td style={tableBody} className="text-right">
                          {row.buildingExternalId}
                        </Td>
                        <Td style={tableBody}>{row.address}</Td>
                        <Td style={tableBody} className="text-right">
                          {row.buildingInstallationPercent}
                        </Td>
                        <Td style={tableBody} className="text-right">
                          {row.quantityHotWater}
                        </Td>
                        <Td style={tableBody} className="text-right">
                          {row.heatingEnergyPerUnit}
                        </Td>
                        <Td style={tableBody} className="text-right">
                          {row.globalWaterReadingDifference}
                        </Td>
                        <Td style={tableBody} className="text-right">
                          {row.sumWaterMetersConsumption.toFixed(2)}
                        </Td>
                        <Td style={tableBody} className="text-right">
                          {row.correctionCoefficient.toFixed(6)}
                        </Td>
                        <Td style={tableBody} className="text-right">
                          {row.estatesNumberDivision}
                        </Td>
                        <Td style={tableBody} className="text-right">
                          {row.damagedDevices}
                        </Td>
                        <Td style={tableBody} className="text-right">
                          {row.estatesWithoutReports}
                        </Td>
                        <Td style={tableBody} className="text-center">
                          {row.calculationHistoryStatus === 1 && (
                            <div
                              style={{
                                width: 23,
                                height: 23,
                                background: "#B80000",
                                display: "inline-block",
                              }}
                            ></div>
                          )}
                          {row.calculationHistoryStatus === 2 && (
                            <div
                              style={{
                                width: 23,
                                height: 23,
                                background: "#D07E03",
                                display: "inline-block",
                              }}
                            ></div>
                          )}
                          {row.calculationHistoryStatus === 3 && (
                            <div
                              style={{
                                width: 23,
                                height: 23,
                                background: "#EDD60A",
                                display: "inline-block",
                              }}
                            ></div>
                          )}
                          {row.calculationHistoryStatus === 4 && (
                            <div
                              style={{
                                width: 23,
                                height: 23,
                                background: "#04B800",
                                display: "inline-block",
                              }}
                            ></div>
                          )}
                        </Td>
                        <Td style={tableBody} className="text-center">
                          {row.completedBuildings && (
                            <i
                              className="fa fa-check mr-1"
                              style={{ fontSize: 20, color: "#04B800" }}
                            />
                          )}
                        </Td>
                        <Td style={tableBody}>
                          <div className="d-flex align-items-center">
                            <LinkCalculations
                              link=""
                              id={row.id}
                            ></LinkCalculations>
                            <LinkView link="" id={row.id}></LinkView>
                          </div>
                        </Td>
                      </Tr>
                    ))
                  )}
                </Tbody>
              </Table>
            </Row>
            <Row className="justify-content-center">
              <Pagination
                updatePage={updatePage}
                page={state.page}
                totalCount={state.totalCount}
                pageSize={state.pageSize}
              />
            </Row>
          </AvForm>
        </Container>
      </div>
    </>
  );
};

BillManagementMonth.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BillManagementMonth));

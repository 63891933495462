import { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Row,
} from "reactstrap";
import TableWithAccordeon from "../../components/Common/TableWithAccordeon";
import * as endpoints from "constants/endpoints";
import {
  GetData,
  SaveItem,
  ToggleItem,
  FileUpload,
} from "../../helpers/CRUD.js";
import Loader from "../../components/Common/Loader";
import i18next from "i18next";

const FileUpload_FPER = (props) => {
  const [state, setState] = useState({
    file_new: null,
    showData: false,
    dataToShow: [],
    toggleOldStructure: false,
    toggleSwitch: false,
    estates: [],
    buildings: [],
    showMsg: false,
    message: "",
    showDiffButton: false,
    diffId: null,
    isFileUploaded: false,
    isLoading: false,
    isLoaded: false,
  });

  const onFileChange = (event) => {
    const file = event.target.files[0];
    setState((state) => ({ ...state, file_new: file }));
  };

  const uploadFile = () => {
    setState((state) => ({ ...state, isLoading: true }));

    const nameid = JSON.parse(localStorage.getItem("nameid"));
    const file = state.file_new;
    const data = new FormData();
    data.append("file", file);

    const url = `${endpoints.TPF}/UploadFPERFile?userId=${nameid}&isLegacyFile=${state.toggleOldStructure}`;

    FileUpload(
      url,
      data,
      (resp, data) => {
        if (resp.status === 200) {
          setState((state) => ({ ...state, isLoaded: true, isLoading: false }));

          if (data.diffMessage !== null) {
            setState((state) => ({
              ...state,
              showDiffButton: true,
              diffId: data.mergeDiffId,
            }));
          } else if (data.uploadMessage !== "") {
            props.onSuccessShow(data.uploadMessage);
          }
        }
      },
      (error) => {
        setState((state) => ({ ...state, isLoaded: false, isLoading: false }));
        props.onErrorsShow(error.body);
      }
    );
  };

  const getMergeData = () => {
    const url = `${endpoints.TPF}/GerMergeDiffDetails?mergeDiffId=${state.diffId}`;

    GetData(
      url,
      (data) => {
        const estatesCount = data.estatesMergeDiffDetails.length;
        const buildingsCount = data.buildingsMergeDiffDetails.length;

        const estates = [];
        const buildings = [];

        if (data.buildingsMergeDiffDetails.length) {
          for (var i = 0; i < buildingsCount; i++) {
            const newItem = {
              id: `building-${i}`,
              isCollapsed: true,
              isSelected: false,
              data: data.buildingsMergeDiffDetails[i].allEntities,
              category_diff:
                data.buildingsMergeDiffDetails[i].propertiesValueChanged,
              selectedRow: [
                { isSelected: false },
                { isSelected: false },
                { isSelected: false },
                { isSelected: false },
              ],
            };

            buildings.push(newItem);
          }
        }

        if (data.estatesMergeDiffDetails.length) {
          for (var i = 0; i < estatesCount; i++) {
            const newItem = {
              id: `estate-${i}`,
              isCollapsed: true,
              isSelected: false,
              data: data.estatesMergeDiffDetails[i].allEntities,
              category_diff:
                data.estatesMergeDiffDetails[i].propertiesValueChanged,
              selectedRow: [
                { isSelected: false },
                { isSelected: false },
                { isSelected: false },
                { isSelected: false },
              ],
            };

            estates.push(newItem);
          }
        }

        if (estates.length === 0 && buildings.length === 0) {
          setState((state) => ({
            ...state,
            estates,
            buildings,
            mergeData: data,
            showData: true,
            isFileUploaded: false,
          }));
        } else {
          setState((state) => ({
            ...state,
            estates,
            buildings,
            mergeData: data,
            showData: true,
            isFileUploaded: true,
          }));
        }
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  const toggleAcordion = (category, obj) => {
    const data = state[category];
    const index = data.findIndex((item) => item.id === obj.id);
    data[index].isCollapsed = !data[index].isCollapsed;

    setState((state) => ({ ...state, data }));
  };

  const selectReport = (category, parentId, objIndex) => {
    const data = state[category];
    const indexParent = data.findIndex((item) => item.id === parentId);

    if (data[indexParent].selectedRow[objIndex].isSelected) {
      data[indexParent].selectedRow[objIndex].isSelected = false;
    } else {
      data[indexParent].selectedRow.map((item) => {
        item.isSelected = false;
      });

      data[indexParent].selectedRow[objIndex].isSelected = true;
    }

    setState((state) => ({ ...state, data }));
  };

  const selectRow = (category, obj) => {
    const data = state[category];
    const index = data.findIndex((item) => item.id === obj.id);
    data[index].isSelected = true;

    setState((state) => ({ ...state, data }));
  };

  const sendSingleRowData = (id, category) => {
    const selectedGroupIndex = state[category].findIndex(
      (item) => item.id === id
    );
    const selectedGroup = state[category][selectedGroupIndex];
    const indexOfRow = selectedGroup.selectedRow.findIndex(
      (item) => item.isSelected === true
    );

    exportDataToApi(category, [
      {
        groupIndex: selectedGroupIndex,
        rowIndex: indexOfRow,
      },
    ]);
  };

  const sendData = (category) => {
    const items = state[category];
    const groupData = [];

    const selectedItems = items.map((item, itemIndex) => {
      if (
        item.isSelected &&
        item.selectedRow.some((element) => element.isSelected === true)
      ) {
        const indexOfSelectedOption = item.selectedRow.findIndex(
          (element) => element.isSelected === true
        );

        const selectedItem = item.data[indexOfSelectedOption];

        groupData.push({
          groupIndex: itemIndex,
          rowIndex: indexOfSelectedOption,
        });

        return selectedItem;
      } else {
        return false;
      }
    });

    exportDataToApi(category, groupData);
  };

  const exportDataToApi = (category, groupData) => {
    const url = `${endpoints.TPF}/UpdateData`;
    const mergeData = state.mergeData;
    const diffPerRow = [];
    const computedCategory =
      category === "estates"
        ? "estatesMergeDiffDetails"
        : "buildingsMergeDiffDetails";

    groupData.map((group) => {
      const diffDetailsId = Object.keys(
        mergeData[computedCategory][`${group.groupIndex}`].mergeDiffDetailsById
      );

      mergeData[computedCategory][`${group.groupIndex}`].mergeDiffDetailsById[
        `${diffDetailsId[0]}`
      ].map((diff) => {
        const rowData = {
          key: group.rowIndex + 1,
          value: diff.id,
        };

        diffPerRow.push(rowData);
      });
    });

    SaveItem(
      url,
      diffPerRow,
      (data) => {
        const items = state[`${category}`];

        const indexes = groupData.map((item) => {
          return item.groupIndex;
        });

        var pulled = _.pullAt(items, indexes);

        if (state.estates.length === 0 && state.buildings.length === 0) {
          setState((state) => ({
            ...state,
            [`${category}`]: items,
            isFileUploaded: false,
          }));
        } else {
          setState((state) => ({ ...state, [`${category}`]: items }));
        }
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  const resolveAll = () => {
    const url = `${endpoints.TPF}/ToggleIsMerged?id=${state.diffId}&isMerged=true`;

    ToggleItem(
      url,
      (resp) => {
        setState((state) => ({ ...state, estates: [], buildings: [] }));
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    setState((state) => ({ ...state, loggedUserType: obj.role }));
  }, []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          {state.isFileUploaded === false &&
            (state.buildings.length === 0 || state.estates.length === 0) && (
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <div>
                        <div className="d-flex align-items-center">
                          <input
                            name="file_new"
                            placeholder=""
                            id="field-AS"
                            type="file"
                            accept=".dbf"
                            onChange={(e) => onFileChange(e)}
                            className="flex-grow-1"
                          />

                          {state.file_new !== null ? (
                            <button
                              type="button"
                              className="btn btn-primary waves-effect waves-light flex-shrink-1"
                              onClick={uploadFile}
                            >
                              {i18next.t("upload_files_add_new_file")}
                            </button>
                          ) : null}
                        </div>

                        <div
                          className="custom-control custom-switch mb-0 mt-3"
                          dir="ltr"
                        >
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customSwitchsizesm"
                            onClick={() => {
                              setState((state) => ({
                                ...state,
                                toggleSwitch: !state.toggleSwitch,
                              }));
                            }}
                          />
                          <label
                            className="custom-control-label mb-0"
                            htmlFor="customSwitchsizesm"
                          >
                            {i18next.t("upload_files_yearly")}
                          </label>
                        </div>

                        <div
                            style={{display: `${state.hideUploadPanel ? 'none' : 'block'}`}}
                            className="custom-control custom-switch mb-0 mt-3"
                            dir="ltr"
                        >
                          <input
                              type="checkbox"
                              className="custom-control-input"
                              id="customSwitch-old-structure"
                              onClick={() => {
                                setState((state) => ({
                                  ...state,
                                  toggleOldStructure: !state.toggleOldStructure,
                                }));
                              }}
                          />
                          <label
                              className="custom-control-label mb-0"
                              htmlFor="customSwitch-old-structure"
                          >
                            {i18next.t("upload_files_old_f_structure")}
                          </label>
                        </div>

                        <div>
                          {state.showDiffButton && (
                            <button
                              type="button"
                              className="btn btn-primary waves-effect waves-light flex-shrink-1"
                              onClick={getMergeData}
                            >
                              {i18next.t("upload_files_show_differences")}
                            </button>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            )}

          {state.showMsg ? (
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <div className="text-center mb-2">
                      <h1>{state.message}</h1>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          ) : null}

          {state.estates && state.estates.length > 0 && (
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <CardTitle className="mb-0">Estate</CardTitle>

                      <button
                        className="btn btn-primary waves-effect waves-light"
                        onClick={() => sendData("estates")}
                      >
                        {i18next.t("upload_files_save_changes")}
                      </button>
                    </div>

                    <TableWithAccordeon
                      data={state.estates}
                      category={"estates"}
                      toggleAcordion={toggleAcordion}
                      selectReport={selectReport}
                      selectRow={selectRow}
                      sendSingleRowData={sendSingleRowData}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          )}

          {state.buildings && state.buildings.length > 0 && (
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <CardTitle className="mb-0">Builgings</CardTitle>

                      <button
                        className="btn btn-primary waves-effect waves-light"
                        onClick={() => sendData("buildings")}
                      >
                        {i18next.t("upload_files_save_changes")}
                      </button>
                    </div>

                    <TableWithAccordeon
                      data={state.buildings}
                      category={"buildings"}
                      toggleAcordion={toggleAcordion}
                      selectReport={selectReport}
                      selectRow={selectRow}
                      sendSingleRowData={sendSingleRowData}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          )}

          {(state.estates && state.estates.length > 0) ||
          (state.buildings && state.buildings.length > 0) ? (
            <button
              className="btn btn-primary waves-effect waves-light"
              onClick={() => resolveAll()}
            >
              Маркирай като одобрени
            </button>
          ) : null}

          {state.isLoading && !state.isLoaded && <Loader showLoader={true} />}
        </Container>
      </div>
    </>
  );
};

FileUpload_FPER.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FileUpload_FPER));

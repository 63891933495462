import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import i18next from "i18next";

const LinkCalculations = (props) => {
  return (
    <>
      <Tooltip
        title={i18next.t("tooltip-detailed-calculations")}
        placement="right-start"
      >
        <Link to={props.link} id={"link-to" + props.id}>
          <i>
            <svg width="24" height="16" xmlns="http://www.w3.org/2000/svg">
              <path
                fill="#4A4D46"
                fillRule="nonzero"
                d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
              ></path>
            </svg>
          </i>
        </Link>
      </Tooltip>
    </>
  );
};

LinkCalculations.propTypes = {
  title: PropTypes.string,
};

export default LinkCalculations;

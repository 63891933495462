import { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardBody, Col, Row, FormGroup, Label } from "reactstrap";
import { Modal, Button } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { getUsers } from "store/contacts/actions";
import * as endpoints from "constants/endpoints";
import * as jsonpatch from "fast-json-patch";
import { CreateItem } from "../../helpers/CRUD.js";
import { str2bool } from "../../helpers/utils.js";
import i18next from "i18next";

const ModalEstateContact = (props) => {
  const initial_state = {
    document: {
      name: "",
      email: "",
      phoneNumber: "",
      sendReconciliationBillEmail: false,
      comment: "",
    },
  };

  const [state, setState] = useState(initial_state);

  const resetData = () => {
    setState(initial_state);
  };

  const handleSubmit = (event, errors, values) => {
    setState((state) => ({ ...state, errors, values }));
  };

  const handleValidSubmit = (event, values) => {
    let patch = jsonpatch.compare(initial_state.document, state.document);

    patch.push({
      op: "replace",
      path: "/estateId",
      value: props.estate_id,
    });

    const url = `${endpoints.BUILDING_UNITS_ADDITIONAL_ENDPOINT}/EstateContact/Add`;

    CreateItem(
      url,
      patch,
      (data) => {
        update();
        props.emitSuccess(i18next.t("entry_added_successfully"));
      },
      (error) => {
        props.emitErrors(error);
      }
    );
  };

  const closeModal = () => {
    props.closeModal();
    resetData();
  };

  const update = () => {
    props.update();
    resetData();
  };

  return (
    <>
      <Modal isOpen={props.isModalOpen} scrollable={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            {i18next.t("modal_estate_contact_title")} {props.estate_fish}
          </h5>

          <button
            type="button"
            onClick={() => closeModal()}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Card className="mb-0">
            <CardBody>
              <AvForm
                onSubmit={handleSubmit}
                onValidSubmit={handleValidSubmit}
                className="needs-validation"
              >
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="estate_contact_name">
                        {i18next.t("name")}
                      </Label>
                      <AvField
                        name="document.name"
                        type="text"
                        placeholder={i18next.t("placeholder_enter_name")}
                        errorMessage={i18next.t("wrong-name")}
                        className="form-control"
                        id="estate_contact_name"
                        validate={{
                          required: {
                            value: true,
                          },
                          maxLength: { value: 30 },
                        }}
                        value={state.document.name}
                        onChange={(e) =>
                          setState((state) => ({
                            ...state,
                            document: {
                              ...state.document,
                              name: e.target.value,
                            },
                          }))
                        }
                      />
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="estate_contact_address">
                        {i18next.t("phone")}
                      </Label>
                      <AvField
                        name="document.phoneNumber"
                        type="text"
                        placeholder={i18next.t("placeholder_enter_phone")}
                        errorMessage={i18next.t("wrong-phone")}
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                          },
                          maxLength: { value: 15 },
                        }}
                        value={state.document.phoneNumber}
                        onChange={(e) =>
                          setState((state) => ({
                            ...state,
                            document: {
                              ...state.document,
                              phoneNumber: e.target.value,
                            },
                          }))
                        }
                        id="estate_contact_address"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="estate_contact_email">
                        {i18next.t("email")}
                      </Label>
                      <AvField
                        name="document.email"
                        type="email"
                        placeholder={i18next.t("placeholder_enter_email")}
                        errorMessage={i18next.t("wrong-email")}
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                          },
                          maxLength: { value: 25 },
                        }}
                        value={state.document.email}
                        onChange={(e) =>
                          setState((state) => ({
                            ...state,
                            document: {
                              ...state.document,
                              email: e.target.value,
                            },
                          }))
                        }
                        id="estate_contact_email"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <FormGroup>
                      <AvField
                        type="checkbox"
                        name="document.sendReconciliationBillEmail"
                        label={i18next.t("email-notifications")}
                        value={state.document.sendReconciliationBillEmail}
                        onChange={(e) =>
                          setState((state) => ({
                            ...state,
                            document: {
                              ...state.document,
                              sendReconciliationBillEmail: !str2bool(
                                e.target.value
                              ),
                            },
                          }))
                        }
                        id="estate_contact_reconciliation_bill"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md="12">
                    <FormGroup>
                      <Label htmlFor="estate_contact_comment">
                        {i18next.t("comment")}
                      </Label>
                      <AvField
                        type="textarea"
                        name="document.comment"
                        placeholder={i18next.t("placeholder_enter_comment")}
                        errorMessage={i18next.t("wrong-comment")}
                        className="form-control"
                        onChange={(e) =>
                          setState((state) => ({
                            ...state,
                            document: {
                              ...state.document,
                              comment: e.target.value,
                            },
                          }))
                        }
                        value={state.document.comment}
                        id="estate_contact_comment"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => closeModal()}
                  >
                    <i className="fa fa-times mr-1" />
                    {i18next.t("button_cancel")}
                  </button>

                  <Button color="primary" type="submit">
                    <i className="fa fa-plus-circle mr-1" />
                    {i18next.t("button_add")}
                  </Button>
                </div>
              </AvForm>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </>
  );
};

ModalEstateContact.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUsers: () => dispatch(getUsers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ModalEstateContact));

import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Inner Authentication
import Register1 from "../pages/AuthenticationInner/Register";
import Recoverpw from "../pages/AuthenticationInner/Recoverpw";

// *** User List
import UserList from "../pages/UserList/user-list";
import UserProfile from "../pages/UserList/user-profile";

// *** Buildings
import Buildings from "../pages/Buildings";
import BuildingSingle from "../pages/Buildings/BuildingSingle";

// *** Reports
import ReportPeriodSW from "../pages/ReportsSW";
import ReportPeriodSWSingle from "../pages/ReportsSW/ReportPeriodSWSingle.js";

// *** Heating Areas
import HeatingAreas from "../pages/HeatingAreas";
import HeatingAreaSingle from "../pages/HeatingAreas/HeatingAreaSingle.js";

// *** Heating Season
import HeatingSeasons from "../pages/HeatingSeasons";
import HeatingSeasonSingle from "../pages/HeatingSeasons/HeatingSeasonSingle";

// *** Heater Type
import HeaterType from "../pages/HeaterType";
import HeaterTypeSingle from "../pages/HeaterType/HeaterTypeSingle.js";

// *** Heat Meter Types
import HeatMeterTypes from "../pages/HeatMeterTypes";
import HeatMeterTypesSingle from "../pages/HeatMeterTypes/HeatMeterTypesSingle.js";

// *** Radio Module Type
import RadioModuleTypes from "../pages/RadioModuleTypes";
import RadioModuleSingle from "../pages/RadioModuleTypes/RadioModuleTypeSingle.js";

// *** EquipmentManufacturer
import EquipmentManufacturer from "../pages/EquipmentManufacturer";
import EquipmentManufacturerSingle from "../pages/EquipmentManufacturer/EquipmentManufacturerSingle.js";

// *** ApartmentTypes
import ApartmentTypes from "../pages/ApartmentTypes";
import ApartmentTypesSingle from "../pages/ApartmentTypes/ApartmentTypeSingle.js";

// *** RoomTypes
import RoomTypes from "../pages/RoomTypes";
import RoomTypesSingle from "../pages/RoomTypes/RoomTypeSingle.js";

// *** ProfessionalHouseManagerCompanies
import ProfessionalHouseManagerCompanies from "../pages/ProfessionalHouseManagerCompanies";
import ProfessionalHouseManagerCompanySingle from "../pages/ProfessionalHouseManagerCompanies/ProfessionalHouseManagerCompanySingle.js";

// *** Nomenclature
import Nomenclature from "../pages/Nomenclature";
import CalculationConfiguration from "pages/CalculationConfiguration";

// *** SevicePrice
import ServicePrice from "../pages/ServicePrice";
import ServicePriceSingle from "../pages/ServicePrice/ServicePriceSingle.js";

import Zone from "../pages/Zone";
import ZoneSingle from "../pages/Zone/ZoneSingle.js";

import Estate from "../pages/Estate";
import EstateSingle from "../pages/Estate/EstateSingle.js";

import SharedPart from "../pages/SharedPart";
import SharedPartSingle from "../pages/SharedPart/SharedPartSingle.js";

import Heater from "../pages/BuildingTopology/Heater";
import HeaterSingle from "../pages/BuildingTopology/Heater/HeaterSingle.js";

// *** Reports
import Reports from "../pages/Reports";
import AddReport from "../pages/Reports/AddReport.js";
import BuildingContactsSingle from "../pages/Contacts/BuildingContactsSingle.js";
import EstateContactsSingle from "../pages/Contacts/EstateContactsSingle.js";

// *** ReportEntities
import SubscriberStation from "../pages/ReportEntities/SubscriberStation";
import SubscriberStationSingle from "../pages/ReportEntities/SubscriberStation/SubscriberStationSingle.js";

import HeatMeterSingle from "../pages/ReportEntities/HeatMeter/HeatMeterSingle.js";

import HeatCostAllocatorSingle from "../pages/ReportEntities/HeatCostAllocators/HeatCostAllocatorSingle.js";
import ConditionSingle from "../components/Common/Condition/ConditionSingle.js";

import WaterMeterSingle from "../pages/ReportEntities/WaterMeter/WaterMeterSingle.js";
import HCATypes from "pages/HCATypes";
import HCATypesSingle from "pages/HCATypes/HCATypeSingle";

// *** File Upload
import FileUpload_F from "pages/FileUpload-F";
import FileUpload_V from "pages/FileUpload-V";
import FileUpload_FTOP from "pages/FileUpload-FTOP";
import FileUpload_FRD from "pages/FileUpload-FRD";
import FileUpload_FPER from "pages/FileUpload-FPER";
import FileUpload_TEC from "pages/FileUpload-TEC";
import HistoryUploadedFiles from "pages/HistoryUploadedFiles";

// Годишни изравнителни сметки
import AnnualCalculationBill from "pages/AnnualCalculationBill";

import ExportFileMonth from "pages/ExportFile/exportFileMonth.js";
import ExportFileYear from "pages/ExportFile/exportFileYear.js";

import BillManagementMonth from "pages/MonthlyBillManagement/billManagementMonth.js";
import BillManagementYear from "pages/YearlyBillManagement/billManagementYear.js";

import MonthlyHistory from "pages/MonthlyBillManagement/MonthlyHistory";
import MonthlyDetailedCalculations from "pages/MonthlyBillManagement/MonthlyDetailedCalculations";

// *** Walk By
import WalkBy from "pages/WalkBy";

const authProtectedRoutes = [
  // *** Users
  { path: "/user-list", component: UserList },
  { path: "/user-profile/:id", component: UserProfile },

  // *** Clients
  {
    path: "/report-period-sw",
    component: ReportPeriodSW,
    layout: "layout-nomenclature",
  },
  { path: "/report-period-sw/:id", component: ReportPeriodSWSingle },

  // *** Heating Area
  {
    path: "/heating-areas",
    component: HeatingAreas,
    layout: "layout-nomenclature",
  },
  { path: "/heating-areas/:id", component: HeatingAreaSingle },

  // *** Heating Season
  {
    path: "/heating-seasons",
    component: HeatingSeasons,
    layout: "layout-nomenclature",
  },
  { path: "/heating-seasons/:id", component: HeatingSeasonSingle },

  // *** Heater Type
  {
    path: "/heater-type",
    component: HeaterType,
    layout: "layout-nomenclature",
  },
  { path: "/heater-type/:id", component: HeaterTypeSingle },

  // *** Heater Type
  {
    path: "/heat-meter-types",
    component: HeatMeterTypes,
    layout: "layout-nomenclature",
  },
  { path: "/heat-meter-types/:id", component: HeatMeterTypesSingle },

  // *** Radio Modyle Types
  {
    path: "/radio-module-types",
    component: RadioModuleTypes,
    layout: "layout-nomenclature",
  },
  { path: "/radio-module-types/:id", component: RadioModuleSingle },

  // *** EquipmentManufacturer Types
  {
    path: "/equipment-manufacturer",
    component: EquipmentManufacturer,
    layout: "layout-nomenclature",
  },
  {
    path: "/equipment-manufacturer/:id",
    component: EquipmentManufacturerSingle,
  },

  // *** Apartment Types
  {
    path: "/apartment-types",
    component: ApartmentTypes,
    layout: "layout-nomenclature",
  },
  { path: "/apartment-types/:id", component: ApartmentTypesSingle },

  // *** Room Types
  {
    path: "/room-types",
    component: RoomTypes,
    layout: "layout-nomenclature",
  },
  { path: "/room-types/:id", component: RoomTypesSingle },

  // *** Professional House Manager Companies
  {
    path: "/professional-house-manager-companies",
    component: ProfessionalHouseManagerCompanies,
    layout: "layout-nomenclature",
  },
  {
    path: "/professional-house-manager-companies/:id",
    component: ProfessionalHouseManagerCompanySingle,
  },

  // *** HCA Types
  {
    path: "/hca-types",
    component: HCATypes,
    layout: "layout-nomenclature",
  },
  { path: "/hca-types/:id", component: HCATypesSingle },

  // *** Nomenclature
  {
    path: "/nomenclature",
    component: Nomenclature,
    layout: "layout-nomenclature",
  },

  // *** Service Price
  {
    path: "/service-price",
    component: ServicePrice,
    layout: "layout-nomenclature",
  },

  {
    path: "/service-price/:id",
    component: ServicePriceSingle,
  },

  {
    path: "/calculation-configuration",
    component: CalculationConfiguration,
    layout: "layout-nomenclature",
  },

  // *** BuildingTopology
  { path: "/heater/:id", component: HeaterSingle },

  // *** Buildings
  { path: "/buildings", component: Buildings },
  { path: "/buildings/:id", component: BuildingSingle },

  { path: "/buildings/:buildingId/zone", component: Zone },
  { path: "/buildings/:buildingId/zone/:zoneId", component: ZoneSingle },

  // Estates
  { path: "/estate", component: Estate },
  {
    path: "/estate/:id",
    component: EstateSingle,
  },

  { path: "/buildings/:buildingId/estate", component: Estate },
  {
    path: "/buildings/:buildingId/estate/:id",
    component: EstateSingle,
  },

  { path: "/buildings/:buildingId/zone/:zoneId/estate", component: Estate },
  {
    path: "/buildings/:buildingId/zone/:zoneId/estate/:id",
    component: EstateSingle,
  },

  { path: "/reports", component: Reports },

  {
    path: "/reportAdd/estate/:estateId",
    component: AddReport,
  },

  {
    path: "/reportAdd/shared-parts/:sharedPartId",
    component: AddReport,
  },

  {
    path: "/buildings/:buildingId/zone/:zoneId/shared-parts",
    component: SharedPart,
  },
  {
    path: "/buildings/:buildingId/zone/:zoneId/shared-part/:id",
    component: SharedPartSingle,
  },
  {
    path: "/buildings/:buildingId/report/:id",
    component: AddReport,
  },
  {
    path: "/estates/:estateId/report/:id",
    component: AddReport,
  },
  {
    path: "/buildings/:buildingId/zone/:zoneId/common-parts/:commonPartId/report/add",
    component: AddReport,
  },
  {
    path: "/buildings/:buildingId/zone/:zoneId/common-parts/:commonPartId/report/:id",
    component: AddReport,
  },
  {
    path: "/buildings/:buildingId/zone/:zoneId/common-parts/:commonPartId/report/add/:nomHeatingSeasonId/:vistitationDate/:status/:comment/:countDataForTheEndPeriod/:isYearReport",
    component: AddReport,
  },

  // *** Heater pages
  {
    path: "/buildings/:buildingId/zone/:zoneId/common-parts/:common-partsId/heater",
    component: Heater,
  },
  {
    path: "/buildings/:buildingId/zone/:zoneId/common-parts/:commonPartId/heater/:id",
    component: HeaterSingle,
  },

  {
    path: "/buildings/:buildingId/zone/:zoneId/estate/:estateId/heater",
    component: Heater,
  },
  {
    path: "/buildings/:buildingId/zone/:zoneId/estate/:estateId/heater/:id",
    component: HeaterSingle,
  },

  // *** Contacts Single
  {
    path: "/buildings/:buildingId/contacts/:id",
    component: BuildingContactsSingle,
  },
  {
    path: "/buildings/:buildingId/zone/:zoneId/estate/:estateId/contacts/:id",
    component: BuildingContactsSingle,
  },

  {
    path: "/buildings/:buildingId/estate/:estateId/contacts/:id",
    component: EstateContactsSingle,
  },
  {
    path: "/estate/:estateId/contacts/:id",
    component: EstateContactsSingle,
  },
  {
    path: "/buildings/:buildingId/zone/:zoneId/estate/:estateId/contacts/:id",
    component: EstateContactsSingle,
  },

  // *** ReportEntities
  { path: "/heat-cost-allocator/:id", component: HeatCostAllocatorSingle },

  {
    path: "/heat-cost-allocator/:id/condition/:id",
    component: ConditionSingle,
  },

  /* Water Meter */
  {
    path: "/buildings/:buildingId/zone/:zoneId/estate/:estateId/water-meter/:id",
    component: WaterMeterSingle,
  },
  {
    path: "/buildings/:buildingId/estate/:estateId/water-meter/:id",
    component: WaterMeterSingle,
  },
  {
    path: "/estate/:estateId/water-meter/:id",
    component: WaterMeterSingle,
  },
  {
    path: "/buildings/:buildingId/zone/:zoneId/shared-part/:sharedPartId/water-meter/:id",
    component: WaterMeterSingle,
  },

  /*  Heat Meter */
  {
    path: "/buildings/:buildingId/zone/:zoneId/estate/:estateId/heat-meter/:id",
    component: HeatMeterSingle,
  },
  {
    path: "/buildings/:buildingId/estate/:estateId/heat-meter/:id",
    component: HeatMeterSingle,
  },
  {
    path: "/estate/:estateId/heat-meter/:id",
    component: HeatMeterSingle,
  },

  /*  Heater */
  {
    path: "/buildings/:buildingId/zone/:zoneId/estate/:estateId/heater/:id",
    component: HeaterSingle,
  },
  {
    path: "/buildings/:buildingId/estate/:estateId/heater/:id",
    component: HeaterSingle,
  },
  {
    path: "/estate/:estateId/heater/:id",
    component: HeaterSingle,
  },
  {
    path: "/buildings/:buildingId/zone/:zoneId/shared-part/:sharedPartId/heater/:id",
    component: HeaterSingle,
  },

  /*  Heat Cost Allocator */
  {
    path: "/buildings/:buildingId/zone/:zoneId/estate/:estateId/heater/:heaterId/heat-cost-allocator/:id",
    component: HeatCostAllocatorSingle,
  },
  {
    path: "/buildings/:buildingId/estate/:estateId/heater/:heaterId/heat-cost-allocator/:id",
    component: HeatCostAllocatorSingle,
  },
  {
    path: "/estate/:estateId/heater/:heaterId/heat-cost-allocator/:id",
    component: HeatCostAllocatorSingle,
  },
  {
    path: "/buildings/:buildingId/zone/:zoneId/shared-part/:sharedPartId/heater/:heaterId/heat-cost-allocator/:id",
    component: HeatCostAllocatorSingle,
  },

  /*  Conditions (Device Statuses) */

/*  {
    path: "/water-meter/:id/condition/:id",
    component: ConditionSingle,
  },*/

  {
    path: "/history-uploaded-files",
    component: HistoryUploadedFiles,
  },

  {
    path: "/file-upload-f",
    component: FileUpload_F,
  },
  {
    path: "/file-upload-v",
    component: FileUpload_V,
  },
  {
    path: "/file-upload-ftop",
    component: FileUpload_FTOP,
  },
  {
    path: "/file-upload-frd",
    component: FileUpload_FRD,
  },

  {
    path: "/file-upload-fper",
    component: FileUpload_FPER,
  },

  {
    path: "/walk-by",
    component: WalkBy,
  },
  {
    path: "/file-upload-tec",
    component: FileUpload_TEC,
  },
  {
    path: "/annual-calculation-bill",
    component: AnnualCalculationBill,
  },

  {
    path: "/export-file-month",
    component: ExportFileMonth,
  },
  {
    path: "/export-file-year",
    component: ExportFileYear,
  },
  {
    path: "/bill-management-month",
    component: BillManagementMonth,
  },
  {
    path: "/bill-management-year",
    component: BillManagementYear,
  },
  {
    path: "/monthly-history/:id",
    component: MonthlyHistory,
  },
  {
    path: "/monthly-detailed-calculations/:id",
    component: MonthlyDetailedCalculations,
  },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/user-list" /> },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },

  // Authentication Inner
  { path: "/pages-register", component: Register1 },
  { path: "/page-recoverpw", component: Recoverpw },
];

export { authProtectedRoutes, publicRoutes };

import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { Modal, Button } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import * as endpoints from "constants/endpoints";
import { device_locations, measure_units } from "helpers/listsHardcodedData";
import * as jsonpatch from "fast-json-patch";
import { GetData, CreateItem } from "../../helpers/CRUD.js";
import i18next from "i18next";

const ModalHeatMeter = (props) => {
  const old_state = {
    nomHeatMeterTypes: [],
  };

  const [state, setState] = useState({
    ...old_state,
    document: {
      radioModuleNumber: "",
      device: {
        serialNumber: "",
        comment: "",
        deviceLocation: "",
      },
      heatMeterTypeId: null,
      installationDate: "",
      measureUnit: 0,
      productionYear: "",
      sealNumber: "",
      isActive: false,
    },
  });

  const getInitialState = (old_state) => ({
    ...old_state,
    document: {
      radioModuleNumber: "",
      device: {
        serialNumber: "",
        comment: "",
        deviceLocation: "",
      },
      heatMeterTypeId: null,
      installationDate: "",
      measureUnit: 0,
      productionYear: "",
      sealNumber: "",
      isActive: false,
    },
  });

  const resetData = () => {
    setState(getInitialState(state));
  };

  const handleValidSubmit = (event, values) => {
    let patch = jsonpatch.compare(getInitialState().document, state.document);

    patch.push({
      op: "replace",
      path: "/device/estateId",
      value: props.estate_id,
    });

    patch.push({ op: "replace", path: "/device/deviceTypeId", value: 2 });

    const url = `${endpoints.BUILDING_UNITS_REPORT_ENTITIES_ENDPOINT}/HeatMeter/Add`;

    CreateItem(
      url,
      patch,
      (data) => {
        update();
        props.emitSuccess(i18next.t("entry_added_successfully"));
      },
      (error) => {
        props.emitErrors(error);
      }
    );
  };

  const closeModal = () => {
    props.closeModal();
    resetData();
  };

  const update = () => {
    props.update();
    resetData();
  };

  const getNomHeatMeterTypes = () => {
    const url = `${endpoints.NOMENCLATURE_ENDPOINT}/HeatMeterType/GetAll`;

    GetData(
      url,
      (data) => {
        setState((state) => ({ ...state, nomHeatMeterTypes: data.items }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    getNomHeatMeterTypes();
  }, []);

  const device_locations_list = device_locations();
  const measure_units_list = measure_units();

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Modal isOpen={props.isModalOpen} scrollable={true}>
            <div className="modal-header">
              <h5 className="modal-title mt-0">
                {props.shared_part_id !== undefined &&
                  `${i18next.t("modal_heat_meter_title")} ${i18next.t(
                    "to_shared_part"
                  )} ${props.shared_part_id}`}
                {props.estate_fish !== undefined &&
                  `${i18next.t("modal_heat_meter_title")} ${i18next.t(
                    "to_estate"
                  )} ${props.estate_fish}`}
              </h5>

              <button
                type="button"
                onClick={() => closeModal()}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <Card className="mb-0">
                <CardBody>
                  <AvForm
                    onValidSubmit={handleValidSubmit}
                    className="needs-validation"
                  >
                    <Row className="mb-4">
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="heat_meter_serial_number">
                            {i18next.t("field_serial_number")}
                          </Label>

                          <AvField
                            name="document.device.serialNumber"
                            placeholder={i18next.t(
                              "placeholder_enter_serial_number"
                            )}
                            id="heat_meter_serial_number"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  device: {
                                    ...state.document.device,
                                    serialNumber: e.target.value,
                                  },
                                  radioModuleNumber: e.target.value,
                                },
                              }))
                            }
                            defaultValue={state.document.device.serialNumber}
                            className="w-100"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="heat_meter_radio_module_number">
                            {i18next.t("field_radio_number")}
                          </Label>

                          <AvField
                            name="document.radioModuleNumber"
                            placeholder={i18next.t(
                              "placeholder_enter_radio_number"
                            )}
                            id="heat_meter_radio_module_number"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  radioModuleNumber: e.target.value,
                                },
                              }))
                            }
                            value={state.document.radioModuleNumber}
                            className="w-100"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="heat_meter_device_locaion">
                            {i18next.t("device_location")}
                          </Label>

                          <AvField
                            type="select"
                            name="document.device.deviceLocation"
                            id="heat_meter_device_locaion"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  device: {
                                    ...state.document.device,
                                    deviceLocation: e.target.value,
                                  },
                                },
                              }))
                            }
                            value={state.document.device.deviceLocation}
                            className="w-100"
                          >
                            <option
                              key={`device-location-default`}
                              value=""
                              selected={
                                state.document.device.deviceLocation === ""
                              }
                            >
                              {i18next.t("select_location")}
                            </option>
                            {device_locations_list &&
                              device_locations_list.length > 0 &&
                              device_locations_list.map((option) => (
                                <option
                                  key={`device-location-${option.id}`}
                                  value={option.key}
                                  selected={
                                    state.document.device.deviceLocation ===
                                    option.key
                                  }
                                >
                                  {option.value}
                                </option>
                              ))}
                          </AvField>
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="heat_meter_type">
                            {i18next.t("heatMeterTypeId")}
                          </Label>

                          <AvField
                            type="select"
                            name="document.heatMeterTypeId"
                            id="heat_meter_type"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  heatMeterTypeId: e.target.value,
                                },
                              }))
                            }
                            value={state.document.heatMeterTypeId}
                            className="w-100"
                          >
                            <option
                              key={`heat-meter-type-default`}
                              value=""
                              selected={state.document.heatMeterTypeId === ""}
                            >
                              {i18next.t("select_type")}
                            </option>
                            {state.nomHeatMeterTypes.length > 0 &&
                              state.nomHeatMeterTypes.map((option) => (
                                <option
                                  key={`heat-meter-type-${option.id}`}
                                  value={option.id}
                                >
                                  {option.name}
                                </option>
                              ))}
                          </AvField>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="heat_meter_installation_date">
                            {i18next.t("installationDate")}
                          </Label>
                          <AvField
                            type="date"
                            name="document.installationDate"
                            id="heat_meter_installation_date"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  installationDate: e.target.value,
                                },
                              }))
                            }
                            value={state.document.installationDate}
                            className="w-100"
                          />
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="heat_meter_measure_unit">
                            {i18next.t("measureUnit")}
                          </Label>
                          <AvField
                            type="select"
                            name="document.measureUnit"
                            id="heat_meter_measure_unit"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  measureUnit: e.target.value,
                                },
                              }))
                            }
                            value={state.document.measureUnit}
                            className="w-100"
                          >
                            <option
                              key={`measure-unit-default`}
                              value=""
                              selected={state.document.measureUnit === ""}
                            >
                              {i18next.t("select_measure_unit")}
                            </option>
                            {measure_units_list &&
                              measure_units_list.length > 0 &&
                              measure_units_list.map((option) => (
                                <option
                                  key={`measure-unit-${option.id}`}
                                  value={option.key}
                                  selected={
                                    state.document.measureUnit === option.key
                                  }
                                >
                                  {option.value}
                                </option>
                              ))}
                          </AvField>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="heat_meter_year_created">
                            {i18next.t("production_year")}
                          </Label>
                          <AvField
                            type="number"
                            min={1900}
                            max={2099}
                            name="document.productionYear"
                            placeholder={i18next.t(
                              "placeholder_enter_production_year"
                            )}
                            id="heat_meter_year_created"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  productionYear: e.target.value,
                                },
                              }))
                            }
                            defaultValue={state.document.productionYear}
                            className="w-100"
                          />
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="heat_meter_seal_number">
                            {i18next.t("sealNumber")}
                          </Label>
                          <AvField
                            name="document.sealNumber"
                            placeholder={i18next.t(
                              "placeholder_enter_seal_number"
                            )}
                            id="heat_meter_seal_number"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  sealNumber: e.target.value,
                                },
                              }))
                            }
                            defaultValue={state.document.sealNumber}
                            className="w-100"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="heat_meter_comment">
                            {i18next.t("file_upload_comment")}
                          </Label>
                          <AvField
                            type="textarea"
                            name="document.device.comment"
                            placeholder={i18next.t("placeholder_enter_comment")}
                            id="heat_meter_comment"
                            maxLength="200"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  device: {
                                    ...state.document.device,
                                    comment: e.target.value,
                                  },
                                },
                              }))
                            }
                            value={state.document.device.comment}
                            className="w-100"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => closeModal()}
                      >
                        <i className="fa fa-times mr-1" />
                        {i18next.t("button_cancel")}
                      </button>

                      <Button color="primary" type="submit">
                        <i className="fa fa-plus-circle mr-1" />
                        {i18next.t("button_add")}
                      </Button>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </div>
          </Modal>
        </Container>
      </div>
    </>
  );
};

ModalHeatMeter.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUsers: () => dispatch(getUsers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ModalHeatMeter));

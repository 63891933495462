import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, useParams } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import TableHCATypes from "../../components/TableDetailed/TableHCATypes.js";
import BackButton from "../../components/Common/BackButton.js";
import NomenclatureSingleWrapper from "../../components/Common/NomenclatureSingleWrapper.js";
import * as endpoints from "constants/endpoints";

const HCATypeSingle = (props) => {
  const [pageTitle, setPageTitle] = useState("Вид разпределители (HCA)");
  const [loggedUserType, setLoggedUserType] = useState("");
  const [mode, setMode] = useState("view");
  const [document, setDocument] = useState({});

  const { id } = useParams();

  const emitErrors = (error) => {
    props.onErrorsShow(error.body);
  };

  const emitSuccess = (success) => {
    props.onSuccessShow(success);
  };

  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    setLoggedUserType(obj.role);
  }, []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl="12">
              <BackButton className="mb-4" />

              <Card>
                <CardBody>
                  <NomenclatureSingleWrapper
                    childComponent={
                      <TableHCATypes
                        data={document}
                        mode={mode}
                      ></TableHCATypes>
                    }
                    getUrl={`${endpoints.NOMENCLATURE_ENDPOINT}/HeatCostAllocatorType/GetById?id=${id}`}
                    saveUrl={`${endpoints.NOMENCLATURE_ENDPOINT}/HeatCostAllocatorType/Update?id=${id}`}
                    toggleUrl={`${endpoints.NOMENCLATURE_ENDPOINT}/HeatCostAllocatorType/ToggleActive?id=${id}`}
                    deleteUrl={`${endpoints.NOMENCLATURE_ENDPOINT}/HeatCostAllocatorType/Delete?id=${id}`}
                    page_title={pageTitle}
                    emitErrors={emitErrors}
                    emitSuccess={emitSuccess}
                  ></NomenclatureSingleWrapper>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

HCATypeSingle.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HCATypeSingle));

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import BarPageTitle from "../../components/Common/BarPageTitle";
import TableComponent from "../../components/Common/TableComponent";
import { TextField, Tooltip } from "@material-ui/core";
import { throttle } from "lodash";
import * as endpoints from "constants/endpoints";
import { GetData } from "../../helpers/CRUD.js";
import i18next from "i18next";

const SharedPart = (props) => {
  const [state, setState] = useState({
    pageTitle: "Имоти",
    isModalOpen: false,
    data: [],
    shared_parts: [],
  });

  const openModal = () => {
    setState((state) => ({ ...state, isModalOpen: !state.isModalOpen }));
    removeBodyCss();
  };

  const closeModal = () => {
    setState((state) => ({ ...state, isModalOpen: false }));
    removeBodyCss();
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };

  const filterSharedParts = (value) => {
    let endpointQueryParam = "";

    if (value) {
      endpointQueryParam = `?filterText=${value}`;
    }

    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/SharedPart/GetAll${endpointQueryParam}`;

    GetData(
      url,
      (data) => {
        let tableBodyData;

        if (data) {
          tableBodyData = data.items.map((item) => {
            return {
              id: item.id,
              data: {
                owner:
                  item.owners && item.owners.length ? item.owners[0].name : "",
                address: item.address
                  ? `${item.address.streetName} ${item.address.streetCode} ${item.address.buildingNumber} ${item.address.buildingSubNumber}`
                  : "",
                tec: item.externalId ? item.externalId : "",
                sw:
                  item.sofiaWaterData &&
                  item.sofiaWaterData.subscriberNumber === "empty"
                    ? "-"
                    : item.sofiaWaterData.subscriberNumber,
                number: item.number,
              },
            };
          });
        }

        setState((state) => ({ ...state, shared_parts: tableBodyData }));
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  const filterSharedPartsThrottled = throttle(filterSharedParts, 800);

  useEffect(() => {
    filterSharedParts();
  }, []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <BarPageTitle pageTitle={state.pageTitle} />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col>
                      <Tooltip
                        title="Търсете по Собственик, Адрес или Абонатен номер Топлофикация"
                        placement="right-start"
                      >
                        <TextField
                          InputLabelProps={{
                            style: {
                              pointerEvents: "auto",
                            },
                          }}
                          placeholder={i18next.t("placeholder_search")}
                          variant="outlined"
                          type="text"
                          size="small"
                          onChange={(e) =>
                            filterSharedPartsThrottled(e.target.value)
                          }
                        />
                      </Tooltip>
                    </Col>
                  </Row>

                  {state.shared_parts && state.shared_parts.length > 0 ? (
                    <TableComponent
                      dataHead={[
                        "Собственик",
                        "Адрес",
                        "Абонатен Номер Топлофикация",
                        "Абонатен Номер ВиК",
                        "Вътрешен Аб. номер",
                        "Действия",
                      ]}
                      dataBody={state.shared_parts}
                      url_path={"estate"}
                      category={"shared_parts"}
                    />
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

SharedPart.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUsers: () => dispatch(getUsers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SharedPart));

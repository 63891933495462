import { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { Modal, Button } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import * as jsonpatch from "fast-json-patch";
import * as endpoints from "constants/endpoints";
import { months } from "helpers/listsHardcodedData";
import { CreateItem } from "../../helpers/CRUD.js";
import i18next from "i18next";

const ModalServicePrice = (props) => {
  const initial_state = {
    document: {
      startMonth: "",
      startYear: "",
      endMonth: "",
      endYear: "",
      pricePerHeatCostAllocator: null,
      pricePerWaterMeter: null,
      pricePerHeatMeter: null,
      pricePerEstate: null,
    },
  };

  const [state, setState] = useState(initial_state);

  const resetData = () => {
    setState(initial_state);
  };

  const handleValidSubmit = (event, values) => {
    let patch = jsonpatch.compare(initial_state.document, state.document);

    const url = `${endpoints.NOMENCLATURE_ENDPOINT}/ServicePrice/Add`;
    CreateItem(
      url,
      patch,
      (data) => {
        console.log(data);
        update();
        props.emitSuccess(i18next.t("entry_added_successfully"));
      },
      (error) => {
        console.log(error);
        props.emitErrors(error);
      }
    );
  };

  const closeModal = () => {
    props.closeModal();
    resetData();
  };

  const update = () => {
    props.update();
    resetData();
  };

  const months_list = months();

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Modal isOpen={props.isModalOpen} scrollable={true}>
            <div className="modal-header">
              <h5 className="modal-title mt-0">
                {i18next.t("modal_service_price_title")}
              </h5>

              <button
                type="button"
                onClick={() => closeModal()}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <Card className="mb-0">
                <CardBody>
                  <AvForm
                    onValidSubmit={handleValidSubmit}
                    className="needs-validation"
                  >
                    <Row className="mb-4">
                      <Col md="12">
                        <h5>{i18next.t("start-period")}</h5>
                      </Col>
                      {months_list && (
                        <Col md="6">
                          <FormGroup>
                            <Label htmlFor="validationStartMonth">
                              {i18next.t("month")}
                            </Label>

                            <AvField
                              type="select"
                              name="document.startMonth"
                              id="validationStartMonth"
                              className="form-control"
                              errorMessage={i18next.t("choose-month")}
                              value={state.document.startMonth}
                              validate={{
                                required: {
                                  value: true,
                                },
                              }}
                              onChange={(e) =>
                                setState((state) => ({
                                  ...state,
                                  document: {
                                    ...state.document,
                                    startMonth: e.target.value,
                                  },
                                }))
                              }
                            >
                              <option
                                value=""
                                selected={
                                  state.document.startMonth === ""
                                    ? true
                                    : false
                                }
                              >
                                -- {i18next.t("choose-month")} --
                              </option>

                              {months_list.map((month) => (
                                <option
                                  value={month.key}
                                  key={month.key}
                                  selected={
                                    state.document.startMonth === month.key
                                      ? true
                                      : false
                                  }
                                >
                                  {month.value}
                                </option>
                              ))}
                            </AvField>
                          </FormGroup>
                        </Col>
                      )}
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="startYear">{i18next.t("year")}</Label>

                          <AvField
                            name="document.startYear"
                            type="number"
                            min={1990}
                            max={2099}
                            placeholder={i18next.t("placeholder_enter_year")}
                            id="startYear"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  startYear: e.target.value,
                                },
                              }))
                            }
                            defaultValue={state.document.startYear}
                            className="w-100"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col md="12">
                        <h5>{i18next.t("end-period")}</h5>
                      </Col>
                      {months_list && (
                        <Col md="6">
                          <FormGroup>
                            <Label htmlFor="validationEndMonth">
                              {i18next.t("month")}
                            </Label>

                            <AvField
                              type="select"
                              name="document.endMonth"
                              id="validationEndMonth"
                              className="form-control"
                              errorMessage={i18next.t("choose-month")}
                              value={state.document.endMonth}
                              validate={{
                                required: {
                                  value: true,
                                },
                              }}
                              onChange={(e) =>
                                setState((state) => ({
                                  ...state,
                                  document: {
                                    ...state.document,
                                    endMonth: e.target.value,
                                  },
                                }))
                              }
                            >
                              <option
                                value=""
                                selected={
                                  state.document.endMonth === "" ? true : false
                                }
                              >
                                -- {i18next.t("choose-month")} --
                              </option>

                              {months_list.map((month) => (
                                <option
                                  value={month.key}
                                  key={month.key}
                                  selected={
                                    state.document.endMonth === month.key
                                      ? true
                                      : false
                                  }
                                >
                                  {month.value}
                                </option>
                              ))}
                            </AvField>
                          </FormGroup>
                        </Col>
                      )}
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="endYear">{i18next.t("year")}</Label>

                          <AvField
                            name="document.endYear"
                            type="number"
                            min={1990}
                            max={2099}
                            placeholder={i18next.t("placeholder_enter_year")}
                            id="endYear"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  endYear: e.target.value,
                                },
                              }))
                            }
                            defaultValue={state.document.endYear}
                            className="w-100"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="pricePerHeatCostAllocator">
                            {i18next.t("pricePerHeatCostAllocator")}
                          </Label>

                          <AvField
                            type="number"
                            step={0.01}
                            min={0}
                            name="document.pricePerHeatCostAllocator"
                            placeholder={i18next.t(
                              "placeholder_enter_price_hca"
                            )}
                            id="pricePerHeatCostAllocator"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  pricePerHeatCostAllocator: e.target.value,
                                },
                              }))
                            }
                            defaultValue={
                              state.document.pricePerHeatCostAllocator
                            }
                            className="w-100"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="pricePerWaterMeter">
                            {i18next.t("pricePerWaterMeter")}
                          </Label>

                          <AvField
                            type="number"
                            step={0.01}
                            min={0}
                            name="document.pricePerWaterMeter"
                            placeholder={i18next.t(
                              "placeholder_enter_price_watermeter"
                            )}
                            id="pricePerWaterMeter"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  pricePerWaterMeter: e.target.value,
                                },
                              }))
                            }
                            defaultValue={state.document.pricePerWaterMeter}
                            className="w-100"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="pricePerHeatMeter">
                            {i18next.t("pricePerHeatMeter")}
                          </Label>

                          <AvField
                            type="number"
                            step={0.01}
                            min={0}
                            name="document.pricePerHeatMeter"
                            placeholder={i18next.t(
                              "placeholder_enter_price_heatmeter"
                            )}
                            id="pricePerHeatMeter"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  pricePerHeatMeter: e.target.value,
                                },
                              }))
                            }
                            defaultValue={state.document.pricePerHeatMeter}
                            className="w-100"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="pricePerEstate">
                            {i18next.t("pricePerEstate")}
                          </Label>

                          <AvField
                            type="number"
                            step={0.01}
                            min={0}
                            name="document.pricePerEstate"
                            placeholder={i18next.t(
                              "placeholder_enter_price_estate"
                            )}
                            id="pricePerEstate"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  pricePerEstate: e.target.value,
                                },
                              }))
                            }
                            defaultValue={state.document.pricePerEstate}
                            className="w-100"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => closeModal()}
                      >
                        <i className="fa fa-times mr-1" />
                        {i18next.t("button_cancel")}
                      </button>

                      <Button color="primary" type="submit">
                        <i className="fa fa-plus-circle mr-1" />
                        {i18next.t("button_add")}
                      </Button>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </div>
          </Modal>
        </Container>
      </div>
    </>
  );
};

ModalServicePrice.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUsers: () => dispatch(getUsers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ModalServicePrice));

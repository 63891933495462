import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  withRouter,
  useHistory,
  useParams,
  useLocation,
} from "react-router-dom";
import { AvField, AvForm } from "availity-reactstrap-validation";
import {
  FormGroup,
  Label,
  TabPane,
  TabContent,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { cloneDeep } from "lodash";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import BackButton from "../../components/Common/BackButton.js";
import ConfirmBox from "../../components/Common/ConfirmBox.js";
import ListActions from "../../components/Common/ListActions.js";
import * as endpoints from "../../constants/endpoints";
import * as jsonpatch from "fast-json-patch";
import {
  GetData,
  SaveItem,
  DeleteItem,
  ToggleItem,
} from "../../helpers/CRUD.js";
import i18next from "i18next";
import {BUILDING_UNITS_ADDITIONAL_ENDPOINT} from "../../constants/endpoints";

const BuildingContactsSingle = (props) => {
  const old_state = {
    pageTitle: "Контакт",
    mode: "view",
    id: "",
    building_id: "",
    nomProfessionalHouseManagementCompany: [],
    activeTab: "1",
    showModal: null,
  };
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();

  const [state, setState] = useState({
    ...old_state,
    document: {
      name: "",
      email:"",
      phoneContact: "",
      location:"",
      position:"",
      professionalHouseManagementCompanyId: null,
      isActive: false,
    },
  });

  const emitErrors = (error) => {
    props.onErrorsShow(error.body);
  };

  const emitSuccess = (success) => {
    props.onSuccessShow(success);
  };

  const handleEdit = () => {
    setState((state) => ({ ...state, mode: "edit" }));
  };

  const openModal = (id) => {
    setState((state) => ({ ...state, showModal: id }));
  };

  const closeModal = () => {
    setState((state) => ({ ...state, showModal: null }));
    removeBodyCss();
  };

  const closeAndUpdate = (modalCallback) => {
    setState((state) => ({ ...state, showModal: null }));
    modalCallback();
    removeBodyCss();
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };

  const getAllProfessionalHouseManagementCompany = () => {
    const url = `${endpoints.NOMENCLATURE_ENDPOINT}/ProfessionalHouseManagementCompany/GetAll`;

    GetData(
        url,
        (data) => {
          setState((state) => ({
            ...state,
            nomProfessionalHouseManagementCompany: data.items,
          }));
        },
        (error) => {
          console.log(error);
        }
    );
  };

  const handleSave = () => {
    let patch = jsonpatch.compare(state.initialDocument, state.document);

    const url = `${endpoints.BUILDING_UNITS_ADDITIONAL_ENDPOINT}/BuildingContact/Update?id=${id}`;

    SaveItem(
        url,
        patch,
        () => {
          props.onSuccessShow(i18next.t("entry_updated_successfully"));
          setState((state) => ({ ...state, mode: "view" }));
        },
        (error) => {
          props.onErrorsShow(error.body);
        }
    );
  };

  const handleDelete = () => {
    const url = `${endpoints.BUILDING_UNITS_ADDITIONAL_ENDPOINT}/BuildingContact/Delete?id=${id}`;

    DeleteItem(url, history.goBack, (error) => {
      props.onErrorsShow(error.body);
    });
  };

  const toggleActive = () => {
    let old_state = state.document;
    old_state.isActive = !state.document.isActive;

    setState((state) => ({ ...state, document: old_state }));
    handleToggleStatusPost();
  };

  const handleToggleStatusPost = () => {
    const url = `${endpoints.BUILDING_UNITS_ADDITIONAL_ENDPOINT}/BuildingContact/ToggleActive?id=${id}&isActive=${state.document.isActive}`;

    ToggleItem(
        url,
        () => {
          state.document.isActive
              ? props.onSuccessShow(i18next.t("entry_activated_successfully"))
              : props.onSuccessShow(i18next.t("entry_deactivated_successfully"));
        },
        (error) => {
          props.onErrorsShow(error.body);
        }
    );
  };

  const getBuildingContactById = () => {
    const url = `${endpoints.BUILDING_UNITS_ADDITIONAL_ENDPOINT}/BuildingContact/GetById?id=${id}`;

    GetData(
        url,
        (data) => {
          console.log(data);
          setState((state) => ({
            ...state,
            document: { ...data },
            initialDocument: cloneDeep(data),
          }));
        },
        (error) => {
          console.log(error);
        }
    );
  };

  const cancelConfirmBox = () => {
    setState((state) => ({
      ...state,
      confirm_delete: false,
      confirm_toggle: false,
      confirm_save: false,
    }));
  };

  const confirmDelete = () => {
    setState((state) => ({
      ...state,
      confirm_delete: false,
      success_dlg: true,
    }));
  };

  const confirmToggle = () => {
    setState((state) => ({
      ...state,
      confirm_toggle: false,
      success_dlg: true,
    }));
  };

  const confirmSave = () => {
    setState((state) => ({
      ...state,
      confirm_save: false,
      success_dlg: true,
      mode: "view",
    }));
  };

  const closeAfterConfirmDelete = () => {
    setState((state) => ({ ...state, success_dlg: false }));
    handleDelete();
  };

  const closeAfterConfirmToggle = () => {
    setState((state) => ({ ...state, success_dlg: false }));
    toggleActive();
  };

  const closeAfterConfirmSave = () => {
    setState((state) => ({ ...state, success_dlg: false }));
    handleSave();
  };

  const toggleTabs = (tab) => {
    if (state.activeTab !== tab) {
      setState((state) => ({ ...state, activeTab: tab }));
    }
  };

  useEffect(() => {
    getBuildingContactById();
    getAllProfessionalHouseManagementCompany();
  }, []);

  const radioStyle = {
    listStyle: "none",
    paddingLeft: "0",
  };
  const labelStyle = {
    paddingLeft: "20px",
  };

  const tableStyle = {
    tableLayout: "fixed",
  };

  let currentLocation = location.pathname;

  if (currentLocation.charAt(0) === "/") {
    currentLocation = currentLocation.substring(1);
  }

  return (
      <>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xl="12">
                <BackButton className="mb-4" />
              </Col>

              <Col xl="12">
                <Card>
                  <CardBody className="mb-0 pb-0">
                    <Row>
                      <Col>
                        <CardTitle className="mb-4">{state.pageTitle}</CardTitle>
                      </Col>

                      <Col sm="3" className="text-right">
                        <Row>
                          <Col
                              xs="12"
                              className="d-flex align-items-center justify-content-end"
                          >
                            <ListActions
                                link={`/${id}`}
                                data={state.document}
                                mode={state.mode}
                                toggleSave={() => {
                                  setState((state) => ({
                                    ...state,
                                    confirm_save: true,
                                    actionType: "save",
                                  }));
                                }}
                                toggleEdit={handleEdit}
                                toggle={() =>
                                    setState((state) => ({
                                      ...state,
                                      confirm_toggle: true,
                                      actionType: "toggle",
                                    }))
                                }
                                delete={() =>
                                    setState((state) => ({
                                      ...state,
                                      confirm_delete: true,
                                      actionType: "delete",
                                    }))
                                }
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>

              <Col md="12">
                <AvForm className="needs-validation">
                  <Row className="d-flex">
                    <Col xl="6" className="d-flex">
                      <Card className="w-100">
                        <CardBody>
                          <Row>
                            <Col md="12">
                              <h5></h5>
                            </Col>

                            <Col md="4">
                              <FormGroup>
                                <Label htmlFor="validationName" className="">
                                  {i18next
                                    .t("name")}
                                </Label>
                                <AvField
                                    name="document.name"
                                    placeholder={`Въведете ${i18next
                                        .t("name")
                                        .toLowerCase()}`}
                                    type="text"
                                    className="form-control"
                                    id="validationName"
                                    value={state.document.name}
                                    onChange={(e) =>
                                        setState((state) => ({
                                          ...state,
                                          document: {
                                            ...state.document,
                                            name: e.target.value,
                                          },
                                        }))
                                    }
                                    validate={{
                                      required: {
                                        value: true,
                                      },
                                      maxLength: {
                                        value: 30,
                                      },
                                    }}
                                    {...(state.mode === "view" && {
                                      readOnly: true,
                                    })}
                                />
                              </FormGroup>
                            </Col>

                            <Col md="4">
                              <FormGroup>
                                <Label
                                    htmlFor="validationAddress_street"
                                    className=""
                                >
                                  {i18next
                                    .t("email")}
                                </Label>
                                <AvField
                                    name="document.email"
                                    placeholder={`Въведете ${i18next
                                        .t("email")
                                        .toLowerCase()}`}
                                    type="email"
                                    className="form-control"
                                    id="validationAddress_street"
                                    value={state.document.email}
                                    onChange={(e) =>
                                        setState((state) => ({
                                          ...state,
                                          document: {
                                            ...state.document,
                                            email: e.target.value,
                                          },
                                        }))
                                    }
                                    validate={{
                                      required: {
                                        value: true,
                                      },
                                      maxLength: {
                                        value: 30,
                                      },
                                    }}
                                    {...(state.mode === "view" && {
                                      readOnly: true,
                                    })}
                                />
                              </FormGroup>
                            </Col>

                            <Col md="4">
                              <FormGroup>
                                <Label
                                    htmlFor="validationAddress_street"
                                    className=""
                                >
                                  {i18next
                                    .t("phone")}
                                </Label>
                                <AvField
                                    name="document.phoneContact"
                                    placeholder={`Въведете ${i18next
                                        .t("phone")
                                        .toLowerCase()}`}
                                    type="phone"
                                    className="form-control"
                                    id="validationAddress_street"
                                    value={state.document.phoneContact}
                                    onChange={(e) =>
                                        setState((state) => ({
                                          ...state,
                                          document: {
                                            ...state.document,
                                            phoneContact: e.target.value,
                                          },
                                        }))
                                    }
                                    validate={{
                                      required: {
                                        value: true,
                                      },
                                      maxLength: {
                                        value: 30,
                                      },
                                    }}
                                    {...(state.mode === "view" && {
                                      readOnly: true,
                                    })}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12">
                              <h5></h5>
                            </Col>

                            <Col md="6">
                              <FormGroup>
                                <Label
                                    htmlFor="validationAddress_street"
                                    className=""
                                >
                                 {i18next
                                    .t("location")}
                                </Label>
                                <AvField
                                    name="document.location"
                                    placeholder={`Въведете ${i18next
                                        .t("location")
                                        .toLowerCase()}`}
                                    type="text"
                                    className="form-control"
                                    id="validationAddress_street"
                                    value={state.document.location}
                                    onChange={(e) =>
                                        setState((state) => ({
                                          ...state,
                                          document: {
                                            ...state.document,
                                            location: e.target.value,
                                          },
                                        }))
                                    }
                                    validate={{
                                      required: {
                                        value: true,
                                      },
                                      maxLength: {
                                        value: 30,
                                      },
                                    }}
                                    {...(state.mode === "view" && {
                                      readOnly: true,
                                    })}
                                />
                              </FormGroup>
                            </Col>

                            <Col md="6">
                              <FormGroup>
                                <Label
                                    htmlFor="water_meter_equipment_manufacturer"
                                    className=""
                                >
                                  Фирма за професионален домоуправител
                                </Label>

                                <AvField
                                    type="select"
                                    value={state.document.professionalHouseManagementCompanyId}
                                    onChange={(e) =>
                                        setState((state) => ({
                                          ...state,
                                          document: {
                                            ...state.document,
                                            professionalHouseManagementCompanyId: e.target.value,
                                          },
                                        }))
                                    }
                                    name="document.professionalHouseManagementCompanyId"
                                    id="water_meter_equipment_manufacturer"
                                    errorMessage="Полето е задължително"
                                    validate={{
                                      required: {
                                        value: true,
                                      },
                                    }}
                                    {...(state.mode === "view" && {
                                      disabled: true,
                                    })}
                                >
                                  <option
                                      key={`equipment-manufacturer-missing`}
                                      value="0"
                                      selected={
                                        state.document.professionalHouseManagementCompanyId ===
                                        null
                                      }
                                  >
                                    -- Изберете фирма за проф ДУ. --
                                  </option>
                                  {state.nomProfessionalHouseManagementCompany.map(
                                      (option) => (
                                          <option
                                              key={`apartment-type-${option.id}`}
                                              value={option.id}
                                              selected={
                                                state.document
                                                    .professionalHouseManagementCompanyId === option.id
                                              }
                                          >
                                            {option.name}
                                          </option>
                                      )
                                  )}
                                </AvField>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12">
                              <h5></h5>
                            </Col>

                            <Col md="12">
                              <FormGroup>
                                <Label htmlFor="validationComment" className="">
                                  {i18next
                                    .t("position")}
                                </Label>
                                <AvField
                                    name="document.position"
                                    placeholder={`Въведете ${i18next
                                        .t("position")
                                        .toLowerCase()}`}
                                    type="text"
                                    className="form-control"
                                    id="validationComment"
                                    value={state.document.position}
                                    onChange={(e) =>
                                        setState((state) => ({
                                          ...state,
                                          document: {
                                            ...state.document,
                                            position: e.target.value,
                                          },
                                        }))
                                    }
                                    validate={{
                                      required: {
                                        value: true,
                                      },
                                      maxLength: {
                                        value: 30,
                                      },
                                    }}
                                    {...(state.mode === "view" && {
                                      readOnly: true,
                                    })}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </AvForm>
              </Col>
            </Row>

            <ConfirmBox
                confirm_delete={state.confirm_delete}
                confirm_toggle={state.confirm_toggle}
                confirm_save={state.confirm_save}
                success_dlg={state.success_dlg}
                cancelConfirmBox={cancelConfirmBox}
                closeAfterConfirmDelete={closeAfterConfirmDelete}
                closeAfterConfirmToggle={closeAfterConfirmToggle}
                closeAfterConfirmSave={closeAfterConfirmSave}
                confirmDelete={confirmDelete}
                confirmToggle={confirmToggle}
                confirmSave={confirmSave}
                isActive={state.document.isActive}
                actionType={state.actionType}
            />
          </Container>
        </div>
      </>
  );
};

BuildingContactsSingle.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(BuildingContactsSingle));

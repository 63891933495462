import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, useParams } from "react-router-dom";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { FormGroup, Label } from "reactstrap";
import { cloneDeep } from "lodash";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import { Tooltip } from "@material-ui/core";
import ReportAddBox from "../../components/Common/ReportAddBox.js";
import * as endpoints from "constants/endpoints";
import * as jsonpatch from "fast-json-patch";
import { report_statuses } from "helpers/listsHardcodedData";
import { GetData, CreateItem } from "../../helpers/CRUD.js";
import i18next from "i18next";

const AddReport = (props) => {
  const { buildingId, estateId, sharedPartId } = useParams();

  const [state, setState] = useState({
    page_title: "Добавяне на отчет за обща част",
    report: {
      heatingSeasonId: null,
      visitationDate: null,
      reportDate: null,

      status: null,
      comment: "",
      countDataForTheEndPeriod: null,
      isYearReport: null,
    },
    fieldDisabled: false,
    initialStateReport: {},
    reportId: "",
    heatingSeasons: [],
    currentHeatingSeason: null,
    estateData: null,
    sharedPartData: null,
    isReportDataSend: false,
    estateDataLoaded: false,
    mainCategory: "",
    buildingId: 0,
    estateId: 0,
    sharedPartId: 0,
    loggedUserType: "",
    parametersLoaded: false,
    month: "",
    year: "",
    years: [],
    months: [
      { value: 1, label: "january" },
      { value: 2, label: "february" },
      { value: 3, label: "march" },
      { value: 4, label: "april" },
      { value: 5, label: "may" },
      { value: 6, label: "june" },
      { value: 7, label: "july" },
      { value: 8, label: "august" },
      { value: 9, label: "september" },
      { value: 10, label: "october" },
      { value: 11, label: "november" },
      { value: 12, label: "december" },
    ],
  });

  const emitErrors = (error) => {
    props.onErrorsShow(error.body);
  };

  const emitSuccess = (success) => {
    props.onSuccessShow(success);
  };

  const toggleCountDataForTheEndPeriod = (e) => {
    setState((state) => ({
      ...state,
      report: {
        ...state.report,
        countDataForTheEndPeriod: e.target.checked,
      },
    }));

    /* setState((state) => ({
      ...state,
      ...state.report,
      countDataForTheEndPeriod: e.target.checked,
    }));*/
  };

  const toggleIsYearReport = (e) => {
    console.log(e.target.checked);

    setState((state) => ({
      ...state,
      month: e.target.checked ? 4 : state.month,
      year: e.target.checked
        ? state.currentHeatingSeason && state.currentHeatingSeason.toYear
        : state.year,
      report: {
        ...state.report,
        isYearReport: e.target.checked,
      },
      fieldDisabled: e.target.checked,
    }));
  };
  const currentDate = new Date();
  const date = currentDate.toISOString();
  const inputDate = date.substr(0, 10);

  const handleValidSubmit = () => {
    let patch = jsonpatch.compare(state.initialStateReport, state.report);

    console.log(state);

    if (state.report.visitationDate === null) {
      patch.push({
        op: "replace",
        path: "/visitationDate",
        value: date,
      });
    }
    patch.push({
      op: "replace",
      path: "/buildingId",
      value: state.buildingId,
    });

    if (state.estateData.id !== 0) {
      patch.push({
        op: "replace",
        path: "/estateId",
        value: state.estateData.id,
      });
    } else {
      patch.push({
        op: "replace",
        path: "/sharedPartId",
        value: state.sharedPart,
      });
    }

    if(state.report.heatingSeasonId !== 0) {
      patch.push({
        op: "replace",
        path: "/heatingSeasonId",
        value: state.report.heatingSeasonId,
      });
    }

    if(state.isYearReport === true) {
      patch.push({
        op: "replace",
        path: "/isYearReport",
        value: state.report.isYearReport,
      });
    }

    let daysInMonth = 1;

    if (state.report.countDataForTheEndPeriod) {
      daysInMonth = new Date(Date.UTC(state.year, state.month, 0)).getDate();
    }

    const reportDate = new Date(
      Date.UTC(state.year, state.month - 1, daysInMonth)
    );

    setState((state) => ({
      ...state,
      report: {
        ...state.report,
        reportDate: reportDate,
      },
    }))

    patch.push({
      op: "replace",
      path: "/reportDate",
      value: reportDate,
    });

    const url = `${endpoints.REPORTS_ENDPOINT}/Report/Add`;
    CreateItem(
      url,
      patch,
      (data) => {
        props.onSuccessShow(i18next.t("entry_added_successfully"));
        setState((state) => ({
          ...state,
          isReportDataSend: true,
          reportId: data.id,
        }));
      },
      (error) => {
        console.log(error);
        props.onErrorsShow(error.body);
      }
    );
  };

  const getMainData = () => {
    let id = null;
    if (state.mainCategory === "Estate") {
      id = estateId;
    } else if (state.mainCategory === "SharedPart") {
      id = sharedPartId;
    }

    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/${state.mainCategory}/GetById?id=${id}`;

    GetData(
      url,
      (data) => {
        setState((state) => ({
          ...state,
          estateData: data,
          buildingId: data.buildingId,
          estateDataLoaded: true,
        }));
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  useEffect(() => {
    state.parametersLoaded && getMainData();
  }, [state.parametersLoaded]);

  const getAllWaterMeters = (id) => {
    if (id !== null) {
      const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/reportEntities/WaterMeter/GetAllBy${state.mainCategory}Id?id=${id}&isActive=true`;

      GetData(
        url,
        (data) => {
          setState((state) => ({
            ...state,
            watermeters: data,
            watermeterLoaded: true,
          }));
        },
        (error) => {
          props.onErrorsShow(error.body);
        }
      );
    }
  };

  const getAllHeatMeters = (id) => {

    if (id !== null) {
      const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/reportEntities/HeatMeter/GetAllBy${state.mainCategory}Id?id=${id}&isActive=true`;

      GetData(
        url,
        (data) => {
          setState((state) => ({
            ...state,
            heatmeters: data,
            heatmetersLoaded: true,
          }));
        },
        (error) => {
          props.onErrorsShow(error.body);
        }
      );
    }
  };

  const getAllHeaters = (id) => {
    if (id !== null) {
      const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/Heater/GetAllBy${state.mainCategory}Id?id=${id}&isActive=true`;

      GetData(
        url,
        (data) => {
          setState((state) => ({
            ...state,
            heaters: data,
            heatersLoaded: true,
          }));
        },
        (error) => {
          props.onErrorsShow(error.body);
        }
      );
    }
  };

  const setCurrentHeatingSeason = () => {
    console.log(state.year);
    console.log(state.heatingSeasons);

    let currentHeatingSeason = state.month < 5
        ? state.heatingSeasons.find(
            (a) => a.toYear.toString()  === state.year.toString()
        )
      : state.heatingSeasons.find(
              (a) => a.fromYear.toString()  === state.year.toString()
          );

    console.log("currentHeatingSeason", currentHeatingSeason);

    setState((state) => ({
        ...state,
        currentHeatingSeason: currentHeatingSeason,
        report: {
          ...state.report,
          heatingSeasonId: currentHeatingSeason ? currentHeatingSeason.id : 0,
        },
      })
    );

  }

  const getAllHeatingSeasons = () => {
    const url = `${endpoints.NOMENCLATURE_ENDPOINT}/HeatingSeason/GetAll?isActive=true`;

    GetData(
      url,
      (data) => {
        setState((state) => ({
          ...state,
          heatingSeasons: data.items,
          years: [
            ...data.items.map((item) => item.fromYear),
            ...data.items.map((item) => item.toYear),
          ],
          currentHeatingSeason: data.items.find(
            (a) => a.toYear === new Date().getFullYear()
          ),
        }));
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  const getReport = (id) => {
    const url = `${endpoints.REPORTS_ENDPOINT}/Report/GetById?id=${id}`;

    GetData(
      url,
      (data) => {
        setState((state) => ({ ...state, ...data }));
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  const getDevices = () => {
    let id = null;

    if (state.mainCategory === "Estate") {
      id = estateId;
    } else if (state.mainCategory === "SharedPart") {
      id = sharedPartId;
    }

    getAllWaterMeters(id);
    getAllHeaters(id);

    if (state.mainCategory === "Estate") {
      getAllHeatMeters(id);
    }
  };

  useEffect(() => {
    setCurrentHeatingSeason();
  }, [state.month, state.year, state.report.isYearReport]);

  useEffect(() => {
    state.estateDataLoaded && getDevices();
  }, [state.estateDataLoaded]);

  useEffect(() => {
    const initialStateReport = cloneDeep(state.report);
    const obj = JSON.parse(localStorage.getItem("authUser"));

    let mainCategory = "";

    if (sharedPartId === undefined && estateId !== 0) {
      mainCategory = "Estate";
    }

    if (estateId === undefined && sharedPartId !== 0) {
      mainCategory = "SharedPart";
    }

    setState((state) => ({
      ...state,
      initialStateReport: initialStateReport,
      buildingId,
      estateId,
      sharedPartId,
      mainCategory,
      loggedUserType: obj.role,
      parametersLoaded: true,
    }));

    getAllHeatingSeasons();
  }, []);

  const radioStyle = {
    listStyle: "none",
    paddingLeft: "0",
  };
  const labelStyle = {
    paddingLeft: "20px",
  };

  const tableStyle = {
    tableLayout: "fixed",
  };

  const report_statuses_list = report_statuses()

/*  console.log(state.watermeters);
  console.log(state.watermeters !== null);
  console.log(state.watermeters && state.watermeters.filter( device => device.deviceLocation === 'OUTSIDE'));
  console.log(state.watermeters && !state.watermeters.filter( device => device.deviceLocation === 'OUTSIDE').length);

  console.log(state.heatmeters);
  console.log(state.heatmeters !== null);
  console.log(state.heatmeters && state.heatmeters.filter( device => device.deviceLocation === 'OUTSIDE'));
  console.log(state.heatmeters && !state.heatmeters.filter( device => device.deviceLocation === 'OUTSIDE').length );*/

  return (
    <>
      <div className="page-content">
        {state.mainCategory === "Estate" && (
          <Container fluid>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    {state.estateDataLoaded && state.estateDataLoaded === true && (
                      <Col md="10">
                        <CardTitle className="mb-4">Данни за имота  ({i18next.t("estate_externalId")} {state.estateData.externalId})</CardTitle>
                      </Col>
                    )}
                  </Row>

                  <Row>
                    {state.estateDataLoaded && state.estateDataLoaded === true && (
                      <Col md="4">
                        <div className="form_controls">
                          <label>{state.estateData.name}</label>
                        </div>
                      </Col>
                    )}
                    {state.estateDataLoaded && state.estateDataLoaded === true && (
                      <Col md="4">
                        <div className="form_controls">
                          <label>{state.estateData && state.estateData.address && state.estateData.address.streetName}</label>
                        </div>
                      </Col>
                    )}

                    {state.estateDataLoaded && state.estateDataLoaded === true && (
                      <Col md="4">
                        <div className="form_controls">
                          <label>
                            бл.
                            {state.estateData && state.estateData.address && state.estateData.address.buildingNumber}

                            {state.estateData && state.estateData.address && state.estateData.address.buildingSubNumber ? - state.estateData.address.buildingSubNumber : ""}
                          </label>
                        </div>
                      </Col>
                    )}

                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        )}

        {state.mainCategory === "SharedPart" && (
            <Container fluid>
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <Row>
                        {state.estateDataLoaded && state.estateDataLoaded === true && (
                            <Col md="10">
                              <CardTitle className="mb-4">Данни за общата част</CardTitle>
                            </Col>
                        )}
                      </Row>

                      <Row>
                        {state.estateDataLoaded && state.estateDataLoaded === true && (
                            <Col md="4">
                              <div className="form_controls">
                                <label>{state.estateData.comment}</label>
                              </div>
                            </Col>
                        )}
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
        )}

        {state.isReportDataSend === false && (
          <Container fluid>
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <AvForm
                      onValidSubmit={handleValidSubmit}
                      className="needs-validation"
                    >
                      <Row>
                        <Col md="10">
                          <CardTitle className="mb-4">
                            Данни за отчета
                          </CardTitle>
                        </Col>

                        <Col md="2">
                          <Tooltip title="Запази" placement="right-start">
                            <button
                              className="ml-2"
                              style={{
                                border: "none",
                                background: "none",
                                fontSize: "0",
                              }}
                              type="submit"
                            >
                              <i
                                style={{
                                  fontSize: 0,
                                }}
                              >
                                <svg
                                  width="18"
                                  height="18"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g fill="#000" fillRule="evenodd">
                                    <path d="M5.633 3.321h6.714c.2 0 .364-.163.364-.363V0H5.269v2.958c0 .2.163.363.364.363z" />
                                    <path
                                      d="M17.699 4.523L13.909.718v2.24c0 .861-.7 1.562-1.562 1.562H5.633a1.564 1.564 0 01-1.562-1.562V0H1.65A1.65 1.65 0 000 1.65v14.68c0 .91.739 1.65 1.65 1.65h14.68c.91 0 1.65-.74 1.65-1.65V5.202c0-.255-.102-.5-.281-.68zm-2.516 9.673c0 .532-.431.963-.964.963H3.76a.963.963 0 01-.962-.963v-5.02c0-.53.43-.962.963-.962h10.46c.532 0 .963.432.963.963v5.02z"
                                      fillRule="nonzero"
                                    />
                                    <path d="M13.123 9.76H4.857a.599.599 0 100 1.198h8.266a.599.599 0 100-1.199zM11.924 12.157H6.056a.599.599 0 100 1.198h5.868a.6.6 0 100-1.198z" />
                                  </g>
                                </svg>
                              </i>
                            </button>
                          </Tooltip>
                        </Col>
                      </Row>

                      <Row>
                        {/*<Col md="4">
                          <FormGroup>
                            <Label htmlFor="heating_season">
                              Отоплителен сезон
                            </Label>

                            <AvField
                              type="select"
                              name="report.heatingSeasonId"
                              id="heating_season"
                              onChange={(e) =>
                                setState((state) => ({
                                  ...state,
                                  report: {
                                    ...state.report,
                                    heatingSeasonId: e.target.value,
                                  },
                                }))
                              }
                              className="w-100"
                            >
                              <option
                                value=""
                                selected={
                                  state.report.heatingSeasonId === ""
                                    ? true
                                    : false
                                }
                              >
                                -- Изберете Сезон --
                              </option>
                              {state.heatingSeasons.length > 0 &&
                                state.heatingSeasons.map((heatingSeason) => (
                                  <option value={heatingSeason.id}>
                                    {heatingSeason.name}
                                  </option>
                                ))}
                            </AvField>
                          </FormGroup>
                        </Col>*/}

                        <Col md="4">
                          <FormGroup>
                            <Label htmlFor="visitationDate">
                              Дата на посещение:
                            </Label>
                            <AvField
                              type="date"
                              name="report.visitationDate"
                              id="visitationDate"
                              onChange={(e) =>
                                setState((state) => ({
                                  ...state,
                                  report: {
                                    ...state.report,
                                    visitationDate: e.target.value,
                                  },
                                }))
                              }
                              value={state.report.visitationDate}
                              defaultValue={inputDate}
                              className="w-100"
                            />
                          </FormGroup>
                        </Col>

                        <Col md="3">
                          <div className="form_controls">
                            <Label htmlFor="building_heating_area" className="">
                              Период на отчет
                            </Label>

                            <AvField
                              type="select"
                              value={state.month}
                              onChange={(e) =>
                                setState((state) => ({
                                  ...state,
                                  month: e.target.value,
                                }))
                              }
                              name="selectedMonth"
                              id="building_heating_area"
                              disabled={state.fieldDisabled}
                              {...(state.mode === "view" && {
                                disabled: "disabled",
                              })}
                            >
                              <option key={`month-missing`} value={null}>
                                Изберете Месец
                              </option>
                              {state.months.map((option, i) => (
                                <option key={`month-${i}`} value={option.value}>
                                  {i18next.t(option.label)}
                                </option>
                              ))}
                            </AvField>
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="form_controls">
                            <Label htmlFor="building_heating_area" className="">
                              &nbsp;
                            </Label>

                            <AvField
                              type="select"
                              value={state.year}
                              onChange={(e) =>
                                setState((state) => ({
                                  ...state,
                                  year: e.target.value,
                                }))
                              }
                              name="selectedYear"
                              id="building_heating_area2"
                              disabled={state.fieldDisabled}
                              {...(state.mode === "view" && {
                                disabled: "disabled",
                              })}
                            >
                              <option key={`year-missing`} value={null}>
                                Изберете Година
                              </option>
                              {[...new Set(state.years)].map((option, i) => (
                                <option key={`year-${i}`} value={option}>
                                  {option}
                                </option>
                              ))}
                            </AvField>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <FormGroup>
                            <Label htmlFor="statusField">Статус:</Label>

                            <AvField
                              type="select"
                              name="report.status"
                              id="statusField"
                              onChange={(e) =>
                                setState((state) => ({
                                  ...state,
                                  report: {
                                    ...state.report,
                                    status: e.target.value,
                                  },
                                }))
                              }
                              className="w-100"
                            >
                              <option
                                value=""
                                selected={
                                  state.report.status === ""
                                }
                              >
                                -- Изберете Статус --
                              </option>
                              {report_statuses_list &&
                                report_statuses_list.length > 0 &&
                                report_statuses_list.map((status) => (
                                  <option value={status.key}>
                                    {status.value}
                                  </option>
                                ))}
                            </AvField>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <Label htmlFor="comment">Коментар:</Label>
                            <AvField
                              type="textarea"
                              name="report.comment"
                              id="comment"
                              maxLength="200"
                              onChange={(e) =>
                                setState((state) => ({
                                  ...state,
                                  report: {
                                    ...state.report,
                                    comment: e.target.value,
                                  },
                                }))
                              }
                              value={state.report.comment}
                              className="w-100"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <div
                          className="custom-control custom-switch mb-0 mt-3"
                          dir="ltr"
                        >
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customSwitchsizesmIsYearReport"
                            checked={state.report.isYearReport}
                            onClick={(e) => toggleIsYearReport(e)}
                          />
                          <label
                            className="custom-control-label mb-0"
                            htmlFor="customSwitchsizesmIsYearReport"
                          >
                            {i18next.t("report_yearly")}
                          </label>
                        </div>
                      </Row>
                      <Row>
                        <div
                          className="custom-control custom-switch mb-0 mt-3"
                          dir="ltr"
                        >
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customSwitchsizesmCountDataForTheEndOfThePeriod"
                            checked={state.report.countDataForTheEndPeriod}
                            onClick={(e) => toggleCountDataForTheEndPeriod(e)}
                          />
                          <label
                            className="custom-control-label mb-0"
                            htmlFor="customSwitchsizesmCountDataForTheEndOfThePeriod"
                          >
                            Считай данните за края на отчетния период
                          </label>
                        </div>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        )}

        {state.isReportDataSend === true &&
          (state.report.status === "ACCESS_ALLOWED" ||
            state.report.status === "THIRD_DATE_PAID_REPORT") && (
            <Container fluid>
              <Card>
                <CardBody>
                  <Row>
                    <Col md="12">
                      <CardTitle className="mb-1">Отчетни устройства</CardTitle>
                    </Col>
                  </Row>

                  {state.watermeters &&
                    state.watermeters.length === 0 &&
                    state.heaters &&
                    state.heaters.length === 0 &&
                    state.heatmeters &&
                    state.heatmeters.length === 0 && (
                      <Row>
                        <Col
                          md="12"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <div>{i18next.t("table_missing_data")}</div>
                        </Col>{" "}
                      </Row>
                    )}

                  <Row>
                    {state.watermeters &&
                      state.watermeters.length > 0 &&
                      state.reportId !== "" &&
                      state.watermeters.map((device) => {
                        return (
                          <Col
                            md="3"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <ReportAddBox
                              device={device.device}
                              reportEntity={device}
                              type={"watermeter"}
                              report={state.report}
                              reportId={state.reportId}
                              visitationDate={state.report.visitationDate === null ? date : state.report.visitationDate}
                              /*reportDate={state.report.reportDate}*/
                              emitErrors={emitErrors}
                              emitSuccess={emitSuccess}
                            />
                          </Col>
                        );
                      })}

                    {state.heaters &&
                      state.heaters.length > 0 &&
                      state.reportId !== "" &&
                      state.heaters.map((device) => {
                        if (
                          device.heatCostAllocators &&
                          device.heatCostAllocators.length === 0
                        ) {
                          return null;
                        } else if (
                          device.heatCostAllocators &&
                          device.heatCostAllocators.length
                        ) {
                          return (
                            <Col
                              md="3"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <ReportAddBox
                                device={device.heatCostAllocators[0].device}
                                reportEntity={device}
                                type={"hca"}
                                report={state.report}
                                reportId={state.reportId}
                                visitationDate={state.report.visitationDate === null ? date : state.report.visitationDate}
                                  /*reportDate={state.report.reportDate}*/
                                emitErrors={emitErrors}
                                emitSuccess={emitSuccess}
                              />
                            </Col>
                          );
                        }
                      })}

                    {state.heatmeters &&
                      state.heatmeters.length > 0 &&
                      state.reportId !== "" &&
                      state.heatmeters.map((device) => {
                        return (
                          <Col
                            md="3"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <ReportAddBox
                                device={device.device}
                                reportEntity={device}
                                type={"heatmeter"}
                                report={state.report}
                                reportId={state.reportId}
                                visitationDate={state.report.visitationDate === null ? date : state.report.visitationDate}
                                /*reportDate={state.report.reportDate}*/
                                emitErrors={emitErrors}
                                emitSuccess={emitSuccess}
                            />
                          </Col>
                        );
                      })}
                  </Row>
                </CardBody>
              </Card>
            </Container>
          )}

        {state.isReportDataSend === true &&
        (state.report.status === "ACCESS_DENIED" ||
            state.report.status === "ACCESS_DENIED_FIRST_DATE" || state.report.status === "ACCESS_DENIED_SECOND_DATE") && (
            <Container fluid>
              <Card>
                <CardBody>
                  <Row>
                    <Col md="12">
                      <CardTitle className="mb-1">Отчетни устройства</CardTitle>
                    </Col>
                  </Row>

                  {state.watermeters !== null &&
                  !state.watermeters.filter( device => device.deviceLocation === 'OUTSIDE').length &&
                  state.heatmeters !== null &&
                  !state.heatmeters.filter( device => device.deviceLocation === 'OUTSIDE').length && (
                      <Row>
                        <Col
                            md="12"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                        >
                          <div>{i18next.t("table_missing_data")}</div>
                        </Col>
                      </Row>
                  )}

                  <Row>
                    {state.watermeters &&
                    state.watermeters.length > 0 &&
                    state.reportId !== "" &&
                    state.watermeters.map((device) => (device.deviceLocation === "OUTSIDE") && (
                            <Col
                                md="3"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                            >
                              <ReportAddBox
                                  device={device.device}
                                  reportEntity={device}
                                  type={"watermeter"}
                                  report={state.report}
                                  reportId={state.reportId}
                                  visitationDate={state.report.visitationDate === null ? date : state.report.visitationDate}
                                  /*reportDate={state.report.reportDate}*/
                                  emitErrors={emitErrors}
                                  emitSuccess={emitSuccess}
                              />
                            </Col>
                        )
                      )}

                    {state.heatmeters &&
                    state.heatmeters.length > 0 &&
                    state.reportId !== "" &&
                    state.heatmeters.map((device) => device.deviceLocation === "OUTSIDE" && (
                          <Col
                              md="3"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                          >
                            <ReportAddBox
                                device={device.device}
                                reportEntity={device}
                                type={"heatmeter"}
                                report={state.report}
                                reportId={state.reportId}
                                visitationDate={state.report.visitationDate === null ? date : state.report.visitationDate}
                                /*reportDate={state.report.reportDate}*/
                                emitErrors={emitErrors}
                                emitSuccess={emitSuccess}
                            />
                          </Col>
                      )
                      )}
                  </Row>
                </CardBody>
              </Card>
            </Container>
        )}
      </div>
    </>
  );
};

AddReport.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AddReport));

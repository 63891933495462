const users = [
  {
    id: 1,
    name: "David McHenry",
    username: "David_McHenry",
    password: "",
    designation: "Admin",
    color: "primary",
    email: "david@skote.com",
    phone: "+35982123141",
    projects: "125",
    skills: [{ name: "Photoshop" }, { name: "illustrator" }],
  },
  {
    id: 2,
    img: "avatar2",
    name: "Frank Kirk",
    username: "Frank_Kirk",
    password: "",
    designation: "reporter",
    email: "frank@skote.com",
    phone: "+35982123141",
    projects: "132",
    skills: [{ name: "Html" }, { name: "Css" }, { name: "2 + more" }],
  },
  {
    id: 3,
    img: "avatar3",
    name: "Rafael Morales",
    username: "Rafael_Morales",
    password: "",
    designation: "reporter",
    email: "Rafael@skote.com",
    phone: "+35982123141",
    projects: "1112",
    skills: [{ name: "Php" }, { name: "Java" }, { name: "Python" }],
  },
  {
    id: 4,
    name: "Mark Ellison",
    username: "Mark_Ellison",
    password: "",
    designation: "employee",
    color: "success",
    email: "mark@skote.com",
    phone: "+35982123141",
    projects: "121",
    skills: [{ name: "Ruby" }, { name: "Php" }, { name: "2 + more" }],
  },
  {
    id: 5,
    img: "avatar4",
    name: "Minnie Walter",
    username: "Minnie_Walter",
    password: "",
    designation: "reporter",
    email: "minnie@skote.com",
    phone: "+35982123141",
    projects: "145",
    skills: [{ name: "Html" }, { name: "Css" }, { name: "2 + more" }],
  },
  {
    id: 6,
    img: "avatar5",
    name: "Shirley Smith",
    username: "Shirley_Smith",
    password: "",
    designation: "admin",
    email: "shirley@skote.com",
    phone: "+35982123141",
    projects: "136",
    skills: [{ name: "Photoshop" }, { name: "UI/UX Designer" }],
  },
  {
    id: 7,
    name: "John Santiago",
    username: "John_Santiago",
    password: "",
    designation: "employee",
    color: "info",
    email: "john@skote.com",
    phone: "+35982123141",
    projects: "125",
    skills: [{ name: "Ruby" }, { name: "Php" }, { name: "2 + more" }],
  },
  {
    id: 8,
    img: "avatar7",
    name: "Colin Melton",
    username: "Colin_Melton",
    password: "",
    designation: "reporter",
    color: "",
    email: "colin@skote.com",
    phone: "+35982123141",
    projects: "136",
    skills: [{ name: "Php" }, { name: "Java" }, { name: "Python" }],
  },
  {
    id: 9,
    name: "David McHenry",
    username: "David_McHenry",
    password: "",
    designation: "Admin",
    color: "primary",
    email: "david@skote.com",
    phone: "+35982123141",
    projects: "125",
    skills: [{ name: "Photoshop" }, { name: "illustrator" }],
  },
  {
    id: 10,
    img: "avatar2",
    name: "Frank Kirk",
    username: "Frank_Kirk",
    password: "",
    designation: "reporter",
    email: "frank@skote.com",
    phone: "+35982123141",
    projects: "132",
    skills: [{ name: "Html" }, { name: "Css" }, { name: "2 + more" }],
  },
  {
    id: 11,
    img: "avatar3",
    name: "Rafael Morales",
    username: "Rafael_Morales",
    password: "",
    designation: "reporter",
    email: "Rafael@skote.com",
    phone: "+35982123141",
    projects: "1112",
    skills: [{ name: "Php" }, { name: "Java" }, { name: "Python" }],
  },
  {
    id: 12,
    name: "Mark Ellison",
    username: "Mark_Ellison",
    password: "",
    designation: "employee",
    color: "success",
    email: "mark@skote.com",
    phone: "+35982123141",
    projects: "121",
    skills: [{ name: "Ruby" }, { name: "Php" }, { name: "2 + more" }],
  },
  {
    id: 13,
    img: "avatar4",
    name: "Minnie Walter",
    username: "Minnie_Walter",
    password: "",
    designation: "reporter",
    email: "minnie@skote.com",
    phone: "+35982123141",
    projects: "145",
    skills: [{ name: "Html" }, { name: "Css" }, { name: "2 + more" }],
  },
  {
    id: 14,
    img: "avatar5",
    name: "Shirley Smith",
    username: "Shirley_Smith",
    password: "",
    designation: "admin",
    email: "shirley@skote.com",
    phone: "+35982123141",
    projects: "136",
    skills: [{ name: "Photoshop" }, { name: "UI/UX Designer" }],
  },
  {
    id: 15,
    name: "John Santiago",
    username: "John_Santiago",
    password: "",
    designation: "employee",
    color: "info",
    email: "john@skote.com",
    phone: "+35982123141",
    projects: "125",
    skills: [{ name: "Ruby" }, { name: "Php" }, { name: "2 + more" }],
  },
  {
    id: 16,
    img: "avatar7",
    name: "Colin Melton",
    username: "Colin_Melton",
    password: "",
    designation: "reporter",
    color: "",
    email: "colin@skote.com",
    phone: "+35982123141",
    projects: "136",
    skills: [{ name: "Php" }, { name: "Java" }, { name: "Python" }],
  },
];

const userProfile = {
  id: 1,
  name: "Cynthia Price",
  username: "Cynthia_Price",
  password: "abcd",
  designation: "admin",
  img: "avatar1",
  projectCount: 125,
  revenue: 1245,
  personalDetail:
    "Hi I'm Cynthia Price,has been the industry's standard dummy text To an English person, it will seem like simplified English, as a skeptical Cambridge.",
  phone: "+35982123141",
  email: "cynthiaskote@gmail.com",
  location: "Sofia, Bulgaria",
  assigned_buildingsId: ["building1", "building2", "building6", "building12"],
  experiences: [
    {
      id: 1,
      iconClass: "bx-server",
      link: "#",
      designation: "Back end Developer",
      timeDuration: "2016 - 19",
    },
    {
      id: 2,
      iconClass: "bx-code",
      link: "#",
      designation: "Front end Developer",
      timeDuration: "2013 - 16",
    },
    {
      id: 3,
      iconClass: "bx-edit",
      link: "#",
      designation: "UI /UX Designer",
      timeDuration: "2011 - 13",
    },
  ],
  projects: [
    {
      id: 1,
      name: "Skote admin UI",
      startDate: "2 Sep, 2019",
      deadline: "20 Oct, 2019",
      budget: "$506",
    },
    {
      id: 2,
      name: "Skote admin Logo",
      startDate: "1 Sep, 2019",
      deadline: "2 Sep, 2019",
      budget: "$94",
    },
    {
      id: 3,
      name: "Redesign - Landing page",
      startDate: "21 Sep, 2019",
      deadline: "29 Sep, 2019",
      budget: "$156",
    },
    {
      id: 4,
      name: "App Landing UI",
      startDate: "29 Sep, 2019",
      deadline: "04 Oct, 2019",
      budget: "$122",
    },
    {
      id: 5,
      name: "Blog Template",
      startDate: "05 Oct, 20199",
      deadline: "16 Oct, 2019",
      budget: "$164",
    },
    {
      id: 6,
      name: "Redesign - Multipurpose Landing",
      startDate: "17 Oct, 2019",
      deadline: "05 Nov, 2019",
      budget: "$192",
    },
    {
      id: 7,
      name: "Logo Branding",
      startDate: "04 Nov, 2019",
      deadline: "05 Nov, 2019",
      budget: "$94",
    },
  ],
};

export { users, userProfile };

import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  withRouter,
  useParams,
  useHistory,
  useLocation,
} from "react-router-dom";
import { AvField, AvForm } from "availity-reactstrap-validation";
import {
  FormGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { cloneDeep } from "lodash";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import ModalCondition from "components/Modals/ModalCondition";
import BackButton from "../../../components/Common/BackButton.js";
import ListActions from "../../../components/Common/ListActions.js";
import * as endpoints from "constants/endpoints";
import {
  conditions,
  installation_types,
} from "helpers/listsHardcodedData";
import {translateConditionEnumValue, translateHeaterTypeEnumValue} from "helpers/translateHardcodedData";
import classnames from "classnames";
import { Tooltip } from "@material-ui/core";
import TableComponent from "../../../components/Common/TableComponent";
import TableReports from "../../../components/Common/TableReports";
import * as jsonpatch from "fast-json-patch";
import { formatDateLocale } from "../../../helpers/utils";
import IconCheck from "../../../components/Common/IconCheck";
import IconX from "../../../components/Common/IconX";
import {
  GetData,
  ToggleItem,
  SaveItem,
  DeleteItem,
} from "../../../helpers/CRUD";
import i18next from "i18next";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

const HeatCostAllocatorSingle = (props) => {
  const old_state = {
    pageTitle: "Разпределител",
    mode: "view",
    nomHCATypes: [],
    nomHeaterTypes: [],
    activeTab: "1",
    tabsNav: [
      { index: "1", id: "tab-nav-1", label: "Отчети" },
      { index: "2", id: "tab-nav-2", label: "Състояния" },
    ],
    device_reports: [],
    device_statuses: [],
  };
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();

  const [state, setState] = useState({
    ...old_state,
    document: {
      device: {
        serialNumber: "",
        comment: "",
      },
      zeroingMonth: 0,
      heatCostAllocatorId: null,
      installationType: "",
      isActive: false,
      deviceId: 0,
    },
    //heaterId: 0,
    heaterType: null,
    isLoaded: false,
  });

  const emitErrors = (error) => {
    props.onErrorsShow(error.body);
  };

  const emitSuccess = (success) => {
    props.onSuccessShow(success);
  };

  const onModalDeviceStatusDelete = (id) => {
    const url = `${endpoints.BUILDING_UNITS_ADDITIONAL_ENDPOINT}/DeviceStatus/Delete?id=${id}`;

    DeleteItem(
      url,
      () => {
        props.onSuccessShow(i18next.t("entry_deleted_successfully"));
        getAllDeviceStatuses();
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  const onModalDeviceReportDelete = (row) => {
    const url = `${endpoints.REPORT_DEVICE_MAP}/Delete?reportId=${row.reportId}&deviceId=${row.deviceId}`;

    DeleteItem(
        url,
        () => {
          props.onSuccessShow(i18next.t("entry_deleted_successfully"));
          getAllDeviceReports();
        },
        (error) => {
          props.onErrorsShow(error.body);
        }
    );
  };

  const openModal = (id) => {
    setState((state) => ({ ...state, showModal: id }));
  };

  const closeModal = () => {
    setState((state) => ({ ...state, showModal: null }));
    removeBodyCss();
  };

  const closeAndUpdate = (modalCallback) => {
    setState((state) => ({ ...state, showModal: null }));
    modalCallback();
    removeBodyCss();
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };

  const getNomHCATypes = () => {
    const url = `${endpoints.NOMENCLATURE_ENDPOINT}/HeatCostAllocatorType/GetAll`;

    GetData(
      url,
      (data) => {
        setState((state) => ({ ...state, nomHCATypes: data.items }));
      },
      (error) => console.log(error)
    );
  };

  const getHeatCostAllocatorById = () => {
    const url = `${endpoints.BUILDING_UNITS_REPORT_ENTITIES_ENDPOINT}/HeatCostAllocator/GetById?id=${id}`;

    GetData(
      url,
      (data) => {
        setState((state) => ({
          ...state,
          document: { ...data },
          initialDocument: cloneDeep(data),
          estateId: data.device && data.device.estateId,
          isLoaded: true,
        }));
      },
      (error) => console.log(error)
    );
  };

  const getAllHeatersByEstateId = () => {
    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/Heater/GetAllByEstateId?id=${state.estateId}`;

    GetData(
        url,
        (data) => {
          const allHeatersForEstate = data.map(item => { return {heaterId: item.id, heaterType: item.heaterType}});

          setState((state) => ({ ...state, nomHeaterTypes: allHeatersForEstate }));
        },
        (error) => {
          console.log(error);
        }
    );
  };

  const getHeaterById = (id) => {
    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/Heater/GetById?id=${id}`;

    GetData(
        url,
        (data) => {
          setState((state) => ({
            ...state,
            heaterType: data.heaterType,
            nomHeaterType: data.heaterType.type,
            nomHeaterTypeName: data.heaterType.name,
          }));
        },
        (error) => {
          console.log(error);
        }
    );
  };

  useEffect(() => {
    state.isLoaded && fetchCallback();
  }, [state.isLoaded]);

  const handleEdit = () => {
    setState((state) => ({ ...state, mode: "edit" }));
  };

  const handleSave = () => {
    let patch = jsonpatch.compare(state.initialDocument, state.document);

    const url = `${endpoints.BUILDING_UNITS_REPORT_ENTITIES_ENDPOINT}/HeatCostAllocator/Update?id=${id}`;

    SaveItem(
      url,
      patch,
      (data) => {
        props.onSuccessShow(i18next.t("entry_updated_successfully"));
        setState((state) => ({ ...state, mode: "view" }));
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  const handleDelete = () => {
    const url = `${endpoints.BUILDING_UNITS_REPORT_ENTITIES_ENDPOINT}/HeatCostAllocator/Delete?id=${id}`;

    DeleteItem(
      url,
      () => {
        //todo add this in notifications list
        props.onSuccessShow(i18next.t("entry_deleted_successfully"));

        history.goBack();
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  const toggleTabs = (tab) => {
    if (state.activeTab !== tab) {
      setState((state) => ({ ...state, activeTab: tab }));
    }
  };

  const toggleActive = () => {
    let old_state = state.document;
    old_state.isActive = !state.document.isActive;

    setState((state) => ({ ...state, document: old_state }));
    handleToggleStatusPost();
  };

  const handleToggleStatusPost = () => {
    const url = `${endpoints.BUILDING_UNITS_REPORT_ENTITIES_ENDPOINT}/HeatCostAllocator/ToggleActive?id=${id}&isActive=${state.document.isActive}`;

    ToggleItem(
      url,
      () => {
        getHeatCostAllocatorById(state.document.id);
        state.document.isActive
          ? props.onSuccessShow(i18next.t("entry_activated_successfully"))
          : props.onSuccessShow(i18next.t("entry_deactivated_successfully"));
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  const cancelConfirmBox = () => {
    setState((state) => ({
      ...state,
      confirm_delete: false,
      confirm_toggle: false,
      confirm_save: false,
    }));
  };

  const confirmDelete = () => {
    setState((state) => ({
      ...state,
      confirm_delete: false,
      success_dlg: true,
    }));
  };

  const confirmToggle = () => {
    setState((state) => ({
      ...state,
      confirm_toggle: false,
      success_dlg: true,
    }));
  };

  const confirmSave = () => {
    setState((state) => ({
      ...state,
      confirm_save: false,
      success_dlg: true,
      mode: "view",
    }));
  };

  const closeAfterConfirmDelete = () => {
    setState((state) => ({ ...state, success_dlg: false }));
    handleDelete();
  };

  const closeAfterConfirmToggle = () => {
    setState((state) => ({ ...state, success_dlg: false }));
    toggleActive();
  };

  const closeAfterConfirmSave = () => {
    setState((state) => ({ ...state, success_dlg: false }));
    handleSave();
  };

  const getAllDeviceReports = () => {
    const url = `${endpoints.REPORTS_ENDPOINT}/ReportDeviceMap/GetAllByDeviceId?id=${state.document.deviceId}`;

    GetData(
      url,
      (data) => {
        setState((state) => ({
          ...state,
          device_reports: data,
          deviceReportsLoaded: true,
        }));
      },
      (error) => console.log(error)
    );
  };

  const getAllDeviceStatuses = () => {
    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/additional/DeviceStatus/GetAllByDeviceId?id=${state.document.deviceId}`;

    GetData(
      url,
      (data) => {
        setState((state) => ({
          ...state,
          device_statuses: data,
          deviceStatusesLoaded: true,
        }));
      },
      (error) => console.log(error)
    );
  };

  const fetchCallback = () => {
    getAllDeviceReports();
    getAllDeviceStatuses();
    getHeaterById(state.document.heaterId);
    getAllHeatersByEstateId();
  };

  const emitRowSave = (row) => {
    const url = `${endpoints.REPORTS_ENDPOINT}/ReportDeviceMap/Update?deviceId=${row.deviceId}&reportId=${row.reportId}`;
    const patch = [
      { op: "replace", path: "/currentHeat", value: `${row.data.value}` },
    ];

    SaveItem(
      url,
      patch,
      (data) => {
        props.onSuccessShow(i18next.t("entry_updated_successfully"));
        getAllDeviceReports();
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem("authUser"));

    if (location.state) {
      setState((state) => ({
        ...state,
        loggedUserType: obj.role,
        mode: "edit",
      }));
    } else {
      setState((state) => ({ ...state, loggedUserType: obj.role }));
    }

    getHeatCostAllocatorById();
    getNomHCATypes();
  }, []);

  const radioStyle = {
    listStyle: "none",
    paddingLeft: "0",
  };
  const labelStyle = {
    paddingLeft: "20px",
  };

  const tableStyle = {
    tableLayout: "fixed",
  };

  const conditions_list = conditions();
  const installation_types_list = installation_types();

  const heat_cost_allocator_conditions = conditions_list.filter((item) =>
    item.categories.includes("heat-cost-allocator")
  );

  const heat_cost_allocator_installation_types = installation_types_list.filter(
    (item) => item.categories.includes("heat-cost-allocator")
  );

  let tableBodyDeviceReports = [];
  if (state.deviceReportsLoaded) {
    tableBodyDeviceReports = state.device_reports.map((item) => {
      return {
        id: item.report.id,
        reportId: item.reportId,
        deviceId: item.deviceId,
        editMode: true,
        data: {
          date: item.report ? item.report.reportDate : null,
          visitationDate: item.report ? item.report.visitationDate : null,
          value: item.currentHeat ? item.currentHeat : "", //todo
          yearReport:
            item.report && item.report.isYearReport ? <IconCheck /> : <IconX />,
          isActive: true,
        },
      };
    });
  }

  let tableBodyDeviceStatuses = [];
  if (state.deviceStatusesLoaded) {
    tableBodyDeviceStatuses = state.device_statuses.map((item) => {
      return {
        id: item.id,
        data: {
          from_date: formatDateLocale(item.fromDate),
          to_date: formatDateLocale(item.toDate),
          condition: item.status
            ? translateConditionEnumValue(item.status)
            : "",
          isActive: item.toDate === null,
        },
      };
    });
  }

  const handleAutoCompleteChangeHeaterType = (event, item) => {

    const item4 = state.nomHeaterTypes?.find((obj) => {
      return Number(obj.heaterType && obj.heaterType.id) === Number(item.id);
    });


    setState((state) => ({
      ...state,

        heaterType: item4.heaterType,
       document: {
        ...state.document,
         heaterId: item4.heaterId,
      },
      nomHeaterType: item.type,
      nomHeaterTypeName: item.name,
    }));
  };

  const item4 = state.nomHeaterTypes?.find((obj) => {
    return Number(obj.heaterType && obj.heaterType.id) === Number(state.heaterType && state.heaterType.id);
  });

  const nomHeaterType = item4 && item4.type;

  const nomHeaterTypeName =
      state.heaterType && state.heaterType.name;
  item4 && translateHeaterTypeEnumValue(item4.type);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl="12">
              <BackButton className="mb-4" />
            </Col>

            <Col xl="12">
              <Card>
                <CardBody className="mb-0 pb-0">
                  <Row>
                    <Col>
                      <CardTitle className="mb-4">{state.pageTitle}</CardTitle>
                    </Col>

                    <Col sm="3" className="text-right">
                      <Row>
                        <Col
                          xs="12"
                          className="d-flex align-items-center justify-content-end"
                        >
                          <ListActions
                            link={`/${state.document.id}`}
                            data={state.document}
                            mode={state.mode}
                            toggleSave={handleSave}
                            toggleEdit={handleEdit}
                            toggle={toggleActive}
                            delete={handleDelete}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col md="12">
              <AvForm className="needs-validation">
                <Row className="d-flex">
                  <Col xl="6" className="d-flex">
                    <Card className="w-100">
                      <CardBody>
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <Label
                                htmlFor="heat_meter_serial_number"
                                className=""
                              >
                                Сериен номер
                              </Label>
                              <AvField
                                name="document.device.serialNumber"
                                placeholder="Сериен номер"
                                type="text"
                                className="form-control"
                                id="heat_meter_serial_number"
                                value={state.document.device.serialNumber}
                                onChange={(e) =>
                                  setState((state) => ({
                                    ...state,
                                    document: {
                                      ...state.document,
                                      device: {
                                        ...state.document.device,
                                        serialNumber: e.target.value,
                                      },
                                    },
                                  }))
                                }
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                  maxLength: {
                                    value: 30,
                                  },
                                }}
                                {...(state.mode === "view" && {
                                  disabled: true,
                                })}
                              />
                            </FormGroup>
                          </Col>

                          <Col md="6">
                            <FormGroup>
                              <Label htmlFor="heat_cost_allocator_zeroing_date">
                                Месец на зануляване
                              </Label>

                              <AvField
                                type="number"
                                min={1}
                                max={12}
                                name="document.zeroingMonth"
                                id="heat_cost_allocator_zeroing_date"
                                onChange={(e) =>
                                  setState((state) => ({
                                    ...state,
                                    document: {
                                      ...state.document,
                                      zeroingMonth: e.target.value,
                                    },
                                  }))
                                }
                                value={state.document.zeroingMonth}
                                className="w-100"
                                {...(state.mode === "view" && {
                                  disabled: true,
                                })}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <Label htmlFor="heat_cost_allocator_type">
                                Вид разпределител
                              </Label>
                              <AvField
                                type="select"
                                value={state.document.heatCostAllocatorTypeId}
                                onChange={(e) =>
                                  setState((state) => ({
                                    ...state,
                                    document: {
                                      ...state.document,
                                      heatCostAllocatorTypeId: e.target.value,
                                    },
                                  }))
                                }
                                name="document.heatCostAllocatorTypeId"
                                id="heat_cost_allocator_type"
                                errorMessage="Select Role"
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                }}
                                {...(state.mode === "view" && {
                                  disabled: true,
                                })}
                              >
                                <option
                                  key={`heat-cost-allocator-type-missing`}
                                  value="0"
                                  selected={
                                    state.document.heatCostAllocatorTypeId ===
                                    null
                                  }
                                >
                                  -- Изберете вид разпределител --
                                </option>
                                {state.nomHCATypes.map((option) => (
                                  <option
                                    key={`heat-cost-allocator-type-${option.id}`}
                                    value={option.id}
                                    selected={
                                      state.document.heatCostAllocatorTypeId ===
                                      option.id
                                    }
                                  >
                                    {option.name}
                                  </option>
                                ))}
                              </AvField>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label htmlFor="heat_cost_allocator_installation_type">
                                Монтаж
                              </Label>
                              <AvField
                                type="select"
                                name="document.installationType"
                                id="heat_cost_allocator_installation_type"
                                onChange={(e) =>
                                  setState((state) => ({
                                    ...state,
                                    document: {
                                      ...state.document,
                                      installationType: e.target.value,
                                    },
                                  }))
                                }
                                value={state.document.installationType}
                                className="w-100"
                                {...(state.mode === "view" && {
                                  disabled: true,
                                })}
                              >
                                <option
                                  value=""
                                  selected={
                                    state.document.installationType === ""
                                  }
                                >
                                  -- Изберете монтаж --
                                </option>

                                {heat_cost_allocator_installation_types &&
                                  heat_cost_allocator_installation_types.length >
                                    0 &&
                                  heat_cost_allocator_installation_types.map(
                                    (option) => (
                                      <option
                                        value={option.key}
                                        selected={
                                          state.document.installationType ===
                                          option.key
                                        }
                                      >
                                        {option.value}
                                      </option>
                                    )
                                  )}
                              </AvField>
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xl="6" className="d-flex">
                    <Card className="w-100">
                      <CardBody>
                        <Row>
                          <Col md="12">
                            <FormGroup>
                              <Label htmlFor="heat_meter_comment">
                                Коментар
                              </Label>
                              <AvField
                                type="textarea"
                                name="document.device.comment"
                                placeholder="Въведете коментар"
                                id="heat_meter_comment"
                                maxLength="200"
                                onChange={(e) =>
                                  setState((state) => ({
                                    ...state,
                                    document: {
                                      ...state.document,
                                      device: {
                                        ...state.document.device,
                                        comment: e.target.value,
                                      },
                                    },
                                  }))
                                }
                                value={state.document.device.comment}
                                className="w-100"
                                {...(state.mode === "view" && {
                                  disabled: true,
                                })}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6">
                            <div className="form_controls">
                              <label>Принадлежи към радиатор</label>

                              <Autocomplete
                                options={state.nomHeaterTypes.map(item => item.heaterType)}
                                renderOption={(option) => <>{option.name}</>}
                                getOptionLabel={(option) => option.name}
                                label={nomHeaterTypeName}
                                renderInput={(params) => {
                                  return (
                                      <TextField
                                          {...params}
                                          label={
                                            nomHeaterTypeName
                                                ? nomHeaterTypeName
                                                : "Изберете тип"
                                          }
                                          variant="outlined"
                                          fullWidth
                                      />
                                  );
                                }}
                                onChange={(e, option) =>
                                    handleAutoCompleteChangeHeaterType(e, option)
                                }
                                {...(state.mode === "view" && {
                                  disabled: true,
                                })}
                              />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </AvForm>
            </Col>

            <Col xl="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <Row>
                        <Col md="6">
                          <Nav pills className="navtab-bg nav-justified">
                            {state.tabsNav.map((item) => (
                              <NavItem key={item.id}>
                                <NavLink
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  className={classnames({
                                    active: state.activeTab === item.index,
                                  })}
                                  onClick={() => {
                                    toggleTabs(item.index);
                                  }}
                                >
                                  {item.label}
                                </NavLink>
                              </NavItem>
                            ))}
                          </Nav>
                        </Col>
                      </Row>
                      <TabContent activeTab={state.activeTab}>
                        <TabPane tabId="1" className="p-3">
                          {/* Отчети */}
                          <Row className="mb-2">
                            <Col>
                              <CardTitle className="d-none mb-4">
                                Таблица с отчетните стойности
                              </CardTitle>
                            </Col>
                          </Row>
                          {tableBodyDeviceReports && (
                            <TableReports
                              dataHead={[
                                i18next.t("report-column-report-date"),
                                i18next.t("report-column-visitation-date"),
                                i18next.t("report-column-value"),
                                i18next.t("report-column-yearly"),
                                i18next.t("actions"),
                              ]}
                              dataBody={tableBodyDeviceReports}
                              isEditable={true}
                              editableCell={"value"}
                              url_path={""}
                              category={""}
                              emitRowSave={emitRowSave}
                              enableLinkDelete={true}
                              onDelete={onModalDeviceReportDelete}
                              delete_tooltip={i18next.t(
                                  "tooltip-delete-report"
                              )}
                              isLoaded={true}
                            />
                          )}
                        </TabPane>
                        <TabPane tabId="2" className="p-3">
                          {/* Състояния */}
                          <Row className="mb-2">
                            <Col>
                              <CardTitle className="d-none mb-4">
                                Таблица със състоянията
                              </CardTitle>
                            </Col>

                            <Col>
                              <div className="text-right">
                                <Tooltip
                                  title="Добави ново състояние"
                                  placement="right-start"
                                >
                                  <button
                                    className="btn btn-primary waves-effect waves-light"
                                    onClick={(id) => openModal("condition")}
                                  >
                                    <i
                                      className="bx bx-plus-circle"
                                      style={{
                                        fontSize: "18px",
                                      }}
                                    />
                                  </button>
                                </Tooltip>
                              </div>
                            </Col>
                          </Row>
                          {tableBodyDeviceStatuses && (
                            <TableComponent
                              dataHead={[
                                "От дата",
                                "До дата",
                                "Състояние",
                                "Действия",
                              ]}
                              dataBody={tableBodyDeviceStatuses}
                              url_path={""}
                              category={""}
                              enableLinkDelete={true}
                              onDelete={onModalDeviceStatusDelete}
                              delete_tooltip={i18next.t(
                                "tooltip-delete-condition"
                              )}
                              isLoaded={true}
                            />
                          )}
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <ModalCondition
            conditions={heat_cost_allocator_conditions}
            isModalOpen={state.showModal === "condition"}
            closeModal={closeModal}
            update={() => closeAndUpdate(getAllDeviceStatuses)}
            device_id={state.document.deviceId}
            emitErrors={emitErrors}
            emitSuccess={emitSuccess}
          />
        </Container>
      </div>
    </>
  );
};

HeatCostAllocatorSingle.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HeatCostAllocatorSingle));

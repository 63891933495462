import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import i18next from "i18next";

const ConfirmBox = (props) => {
  const confirmFunction = () => {
    // if (this.props.actionType === 'delete') {
    //   this.props.closeAfterConfirmDelete();
    // } else if (this.props.actionType === 'toggle') {
    //   this.props.closeAfterConfirmToggle();
    // } else {
    //   this.props.closeAfterConfirmSave();
    // }
  };

  const confirmFirstFunction = () => {
    if (props.actionType === "delete") {
      props.confirmDelete();
    } else if (props.actionType === "toggle") {
      props.confirmToggle();
    } else {
      props.confirmSave();
    }

    if (props.actionType === "delete") {
      props.closeAfterConfirmDelete();
    } else if (props.actionType === "toggle") {
      props.closeAfterConfirmToggle();
    } else {
      props.closeAfterConfirmSave();
    }
  };

  let title;
  let dynamic_title;
  let dynamic_description;
  let toggleState;
  let toggle_msg;

  if (props.isActive) {
    toggle_msg = i18next.t("deactivate");
    toggleState = i18next.t("deactivated");
  } else {
    toggle_msg = i18next.t("activate");
    toggleState = i18next.t("activated");
  }

  switch (props.actionType) {
    case "delete":
      title = i18next.t("delete_confirmation");
      dynamic_title = i18next.t("deleted");
      break;
    case "toggle":
      title = i18next
        .t("action_confirmation")
        .replace("{toggle_msg}", toggle_msg);
      dynamic_title = `${toggleState}`;
      break;
    case "save":
      title = i18next.t("save_confirmation");
      dynamic_title = i18next.t("saved");
      break;
    default:
      null;
  }

  return (
    <>
      {props.confirm_delete || props.confirm_toggle || props.confirm_save ? (
        <SweetAlert
          title={title}
          type="warning"
          showCancel
          confirmButtonText={i18next.t("yes")}
          cancelButtonText="cancelcancel"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => confirmFirstFunction()}
          onCancel={() => props.cancelConfirmBox()}
        ></SweetAlert>
      ) : null}

      {props.success_dlg ? (
        <SweetAlert
          success
          title={dynamic_title}
          onConfirm={() => confirmFunction()}
        ></SweetAlert>
      ) : null}
    </>
  );
};

ConfirmBox.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
};

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUsers: () => dispatch(getUsers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ConfirmBox));

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardBody, Col, Row, FormGroup, Label } from "reactstrap";
import { Modal, Button } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { getUsers } from "store/contacts/actions";
import * as endpoints from "constants/endpoints";
import * as jsonpatch from "fast-json-patch";
import { GetData, CreateItem } from "../../helpers/CRUD.js";
import i18next from "i18next";

const ModalBuildingContact = (props) => {
  const old_state = {
    nomProfessionalHouseManagementCompany: [],
  };

  const [state, setState] = useState({
    ...old_state,
    document: {
      name: "",
      email: "",
      phoneNumber: "",
      position: "",
      professionalHouseManagementCompanyId: null,
      isActive: false,
    },
  });

  const getInitialState = (old_state) => ({
    ...old_state,
    document: {
      name: "",
      email: "",
      phoneNumber: "",
      position: "",
      professionalHouseManagementCompanyId: null,
      isActive: false,
    },
  });

  const resetData = () => {
    setState(getInitialState(state));
  };

  const handleSubmit = (event, errors, values) => {
    setState((state) => ({ ...state, errors, values }));
  };

  const handleValidSubmit = (event, values) => {
    let patch = jsonpatch.compare(getInitialState().document, state.document);

    if (props.buildingId) {
      patch.push({
        op: "replace",
        path: "/buildingId",
        value: props.buildingId,
      });
    }

    const url = `${endpoints.BUILDING_UNITS_ADDITIONAL_ENDPOINT}/BuildingContact/Add`;

    CreateItem(
      url,
      patch,
      (data) => {
        update();
        props.emitSuccess(i18next.t("entry_added_successfully"));
      },
      (error) => {
        props.emitErrors(error);
      }
    );
  };

  const closeModal = () => {
    props.closeModal();
    resetData();
  };

  const update = () => {
    props.update();
    resetData();
  };

  const getAllProfessionalHouseManagementCompany = () => {
    const url = `${endpoints.NOMENCLATURE_ENDPOINT}/ProfessionalHouseManagementCompany/GetAll`;

    GetData(
      url,
      (data) => {
        setState((state) => ({
          ...state,
          nomProfessionalHouseManagementCompany: data.items,
        }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    getAllProfessionalHouseManagementCompany();
  }, []);

  return (
    <>
      <Modal isOpen={props.isModalOpen} scrollable={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            {i18next.t("modal_building_contact_title")} {props.building_top}
          </h5>

          <button
            type="button"
            onClick={() => closeModal()}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Card className="mb-0">
            <CardBody>
              <AvForm
                onSubmit={handleSubmit}
                onValidSubmit={handleValidSubmit}
                className="needs-validation"
              >
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="building_contact_address">
                        {i18next.t("name")}
                      </Label>
                      <AvField
                        type="text"
                        name="document.name"
                        placeholder={i18next.t("placeholder_enter_name")}
                        errorMessage={i18next.t("wrong-name")}
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                          },
                          maxLength: { value: 15 },
                        }}
                        value={state.document.name}
                        onChange={(e) =>
                          setState((state) => ({
                            ...state,
                            document: {
                              ...state.document,
                              name: e.target.value,
                            },
                          }))
                        }
                        id="building_contact_address"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="building_contact_phone">
                        {i18next.t("phone")}
                      </Label>
                      <AvField
                        name="document.phoneContact"
                        type="text"
                        placeholder={i18next.t("placeholder_enter_phone")}
                        errorMessage={i18next.t("wrong-phone")}
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                          },
                          maxLength: { value: 40 },
                        }}
                        value={state.document.phoneContact}
                        onChange={(e) =>
                          setState((state) => ({
                            ...state,
                            document: {
                              ...state.document,
                              phoneContact: e.target.value,
                            },
                          }))
                        }
                        id="building_contact_phone"
                      />
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="building_contact_email">
                        {i18next.t("email")}
                      </Label>
                      <AvField
                        name="document.email"
                        type="text"
                        placeholder={i18next.t("placeholder_enter_email")}
                        errorMessage={i18next.t("wrong-email")}
                        className="form-control"
                        validate={{
                          required: {
                            value: false,
                          },
                          maxLength: { value: 25 },
                        }}
                        value={state.document.email}
                        onChange={(e) =>
                          setState((state) => ({
                            ...state,
                            document: {
                              ...state.document,
                              email: e.target.value,
                            },
                          }))
                        }
                        id="building_contact_email"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="building_contact_location">
                        {i18next.t("location")}
                      </Label>
                      <AvField
                        name="document.location"
                        type="text"
                        placeholder={i18next.t("placeholder_enter_location")}
                        errorMessage={i18next.t("wrong-location")}
                        className="form-control"
                        id="building_contact_location"
                        validate={{
                          required: {
                            value: true,
                          },
                          maxLength: { value: 30 },
                        }}
                        value={state.document.location}
                        onChange={(e) =>
                          setState((state) => ({
                            ...state,
                            document: {
                              ...state.document,
                              location: e.target.value,
                            },
                          }))
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="building_contact_position">
                        {i18next.t("position")}
                      </Label>
                      <AvField
                        name="document.position"
                        placeholder={i18next.t("placeholder_enter_position")}
                        errorMessage={i18next.t("wrong-position")}
                        type="text"
                        className="form-control"
                        id="building_contact_position"
                        validate={{
                          required: {
                            value: true,
                          },
                          maxLength: { value: 30 },
                        }}
                        value={state.document.position}
                        onChange={(e) =>
                          setState((state) => ({
                            ...state,
                            document: {
                              ...state.document,
                              position: e.target.value,
                            },
                          }))
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="building_contact_professional_management_company">
                        {i18next.t("professional-management-company")}
                      </Label>

                      <AvField
                        type="select"
                        name="document.professionalHouseManagementCompanyId"
                        id="building_contact_professional_management_company"
                        errorMessage="Select Role"
                        validate={{
                          required: {
                            value: true,
                          },
                        }}
                        onChange={(e) =>
                          setState((state) => ({
                            ...state,
                            document: {
                              ...state.document,
                              professionalHouseManagementCompanyId:
                                e.target.value,
                            },
                          }))
                        }
                        value={
                          state.document.professionalHouseManagementCompanyId
                        }
                      >
                        <option
                          key={`apartment-type-missing`}
                          value={null}
                          selected={
                            state.document
                              .professionalHouseManagementCompanyId === null
                              ? true
                              : false
                          }
                        >
                          {i18next.t("select_company")}
                        </option>
                        {state.nomProfessionalHouseManagementCompany.map(
                          (option) => (
                            <option
                              key={`HouseManagerCompanies-${option.id}`}
                              value={option.id}
                              selected={
                                state.document
                                  .professionalHouseManagementCompanyId ===
                                option.id
                                  ? true
                                  : false
                              }
                            >
                              {option.name}
                            </option>
                          )
                        )}
                      </AvField>
                    </FormGroup>
                  </Col>
                </Row>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => closeModal()}
                  >
                    <i className="fa fa-times mr-1" />
                    {i18next.t("button_cancel")}
                  </button>

                  <Button color="primary" type="submit">
                    <i className="fa fa-plus-circle mr-1" />
                    {i18next.t("button_add")}
                  </Button>
                </div>
              </AvForm>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </>
  );
};

ModalBuildingContact.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUsers: () => dispatch(getUsers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ModalBuildingContact));

import React from "react";

import {
  sendRequest,
  sendRequestFile,
  sendRequestFileDownload,
} from "../helpers/fetch_helper.js";

export function DeleteItem(url, successCallback, errorCallback) {
  sendRequest({
    url,
    method: "DELETE",
  })
    .then((resp) => {
      if (resp.status === 200) {
        successCallback();
      }
    })
    .then((data) => {})
    .catch((error) => {
      errorCallback(error);
      console.log(error);
    });
}

export function SaveItem(url, patch, successCallback, errorCallback) {
  sendRequest({
    url,
    method: "PATCH",
    body: JSON.stringify(patch),
  })
    .then((resp) =>
      resp.json().then((data) => {
        successCallback(data);
      })
    )
    .catch((error) => {
      errorCallback(error);
    });
}

export function CreateItem(url, patch, successCallback, errorCallback) {
  sendRequest({
    url,
    method: "POST",
    body: JSON.stringify(patch),
  })
    .then((resp) =>
      resp.json().then((data) => {
        if (resp.status === 200) {
          successCallback(data, resp);
        }

        if (resp.status === 401) {
          console.log("unauthorized");
        }
      })
    )
    .catch((error) => {
      errorCallback(error);
    });
}

export function ToggleItem(url, successCallback, errorCallback) {
  sendRequest({
    url,
    method: "PUT",
  })
    .then((response) => {
      if (response.status === 200) {
        successCallback();
      }
    })
    .catch((error) => {
      errorCallback(error);
    });
}

export function GetData(url, successCallback, errorCallback) {
  sendRequest({
    url,
    method: "GET",
  })
    .then((resp) => resp != null && resp.json())
    .then((data) => {
      successCallback(data);
    })
    .catch((error) => {
      if (error) {
        errorCallback(error);
      }
    });
}

export function PostData(url, params, successCallback, errorCallback) {
  sendRequest({
    url,
    method: "POST",
    body: JSON.stringify(params),
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      }
    })
    .then((data) => successCallback(data))
    .catch((error) => {
      errorCallback(error);
    });
}

export function GetFile(url, successCallback, errorCallback) {
  sendRequest({
    url,
    method: "GET",
  })
    .then((res) => res.blob())
    .then((blob) => {
      successCallback(blob);
    })
    .catch((error) => {
      console.log(error);
      if (error) {
        errorCallback(error);
      }
    });
}

export function FileUpload(url, file, callBack, errorCallback) {
  sendRequestFile({
    url,
    method: "POST",
    body: file,
  })
    .then((resp) =>
      resp.json().then((data) => {
        callBack(resp, data);
      })
    )
    .catch((error) => {
      errorCallback(error);
    });
}

export function FileDownload(url, successCallback, errorCallback) {
  sendRequestFileDownload({
    url,
    method: "GET",
  })
    .then((res) => res.blob())
    .then((blob) => {
      successCallback(blob);
    })
    .catch((error) => {
      console.log(error);
      if (error) {
        errorCallback(error);
      }
    });
}

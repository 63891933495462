import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  withRouter,
  useParams,
  useLocation,
  useHistory,
} from "react-router-dom";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { FormGroup, Label } from "reactstrap";
import { cloneDeep } from "lodash";
import * as jsonpatch from "fast-json-patch";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import classnames from "classnames";
import ModalHeater from "../../components/Modals/ModalHeater";
import BackButton from "../../components/Common/BackButton.js";
import TableComponent from "../../components/Common/TableComponent";
import ListActions from "../../components/Common/ListActions.js";
import LinkAdd from "components/Common/LinkAdd";
import * as endpoints from "constants/endpoints";
import {
  translateConditionEnumValue,
  translateTypeEnumValue,
  translateDeviceLocationEnumValue,
  translateStatusEnumValue,
} from "helpers/translateHardcodedData";
import ModalWaterMeter from "../../components/Modals/ModalWaterMeter";
import { Tooltip } from "@material-ui/core";
import {
  GetData,
  SaveItem,
  DeleteItem,
  ToggleItem,
} from "../../helpers/CRUD.js";
import i18next from "i18next";
import ModalHeatCostAllocator from "../../components/Modals/ModalHeatCostAllocator";
import TableReports from "../../components/Common/TableReports";

const SharedPartSingle = (props) => {
  const old_state = {
    pageTitle: "Обща част",
    mode: "view",
    watermeters: [],
    heaters: [],
    activeTab: "1",
    tabsNav: [
      { index: "1", id: "tab-nav-1", label: i18next.t("tab-watermeters") },
      { index: "2", id: "tab-nav-2", label: i18next.t("tab-heatmeters") },
      { index: "3", id: "tab-nav-11", label: i18next.t("tab-reports") },
    ],
  };
  const date = new Date();
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams();

  const [state, setState] = useState({
    ...old_state,
    document: {
      comment: "",
      isActive: false,
    },
  });

  const [year, setYear] = useState(date.getFullYear());

  const emitErrors = (error) => {
    props.onErrorsShow(error.body);
  };

  const emitSuccess = (success) => {
    props.onSuccessShow(success);
  };

  const onModalDeviceReportDelete = (row) => {
    const url = `${endpoints.REPORT_DEVICE_MAP}/Delete?reportId=${row.reportId}&deviceId=${row.deviceId}`;

    DeleteItem(
        url,
        () => {
          props.onSuccessShow(i18next.t("entry_deleted_successfully"));
          getReportsByYear();
        },
        (error) => {
          props.onErrorsShow(error.body);
        }
    );
  };

  const openModal = (id) => {
    setState((state) => ({ ...state, showModal: id }));
  };

  const onModalHCAOpen = (id) => {
    setState((state) => ({ ...state, showModal: "hca", heaterId: id }));
  };

  const closeModal = () => {
    setState((state) => ({ ...state, showModal: null }));
    removeBodyCss();
  };

  const closeAndUpdate = (modalCallback) => {
    setState((state) => ({ ...state, showModal: null }));
    modalCallback();
    removeBodyCss();
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };

  const handleEdit = () => {
    setState((state) => ({ ...state, mode: "edit" }));
  };

  const handleSave = () => {
    let patch = jsonpatch.compare(state.initialDocument, state.document);

    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/SharedPart/Update?id=${id}`;

    SaveItem(
      url,
      patch,
      (data) => {
        setState((state) => ({ ...state, isModalOpen: false }));
        props.onSuccessShow(i18next.t("entry_updated_successfully"));
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  const handleDelete = () => {
    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/SharedPart/Delete?id=${id}`;

    DeleteItem(url, history.goBack, (error) => {
      props.onErrorsShow(error.body);
    });
  };

  const toggleActive = () => {
    setState((state) => ({
      document: {
        ...state.document,
        isActive: !state.document.isActive,
      },
    }));
    handleToggleStatusPost();
  };

  const toggleTabs = (tab) => {
    if (state.activeTab !== tab) {
      setState((state) => ({ ...state, activeTab: tab }));
    }
  };

  const handleToggleStatusPost = () => {
    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/SharedPart/ToggleActive?id=${id}&isActive=${state.document.isActive}`;

    ToggleItem(
      url,
      () => {
        getSharedPartById(state.document.id);
        state.document.isActive
          ? props.onSuccessShow(i18next.t("entry_activated_successfully"))
          : props.onSuccessShow(i18next.t("entry_deactivated_successfully"));
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  const getSharedPartById = (id) => {
    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/SharedPart/GetById?id=${id}`;

    GetData(
      url,
      (data) => {
        setState((state) => ({
          ...state,
          document: { ...data },
          initialDocument: cloneDeep(data),
        }));
        fetchCallback();
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  const fetchCallback = () => {
    getAllWaterMeters();
    getAllHeaters();
    getReportsByYear();
  };

  const getAllWaterMeters = () => {
    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/reportEntities/WaterMeter/GetAllBySharedPartId?id=${id}`;

    GetData(
      url,
      (data) => {
        setState((state) => ({
          ...state,
          watermeters: data,
          watermeterLoaded: true,
        }));
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  const getAllWaterMetersInactive = () => {
    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/reportEntities/WaterMeter/GetAllBySharedPartId?id=${id}&isActive=false`;

    GetData(
      url,
      (data) => {
        let all_water_meters = state.watermeters;
        all_water_meters.push(...data);

        setState((state) => ({ ...state, watermeters: all_water_meters }));
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  const getReportsByYear = () => {
    const url = `${endpoints.REPORTS_ENDPOINT}/ReportDeviceMap/GetAllBySharedPartId?id=${id}&year=${year}`;
    GetData(
        url,
        (data) => {
          setState((state) => ({ ...state, reports: data, reportsLoaded: true }));
        },
        (error) => console.log(error)
    );
  };
  const incrementYear = () => {
    setYear(year + 1);
  };

  const decrementYear = () => {
    setYear(year - 1);
  };

  useEffect(() => {
    state.watermeterLoaded && getAllWaterMetersInactive();
  }, [state.watermeterLoaded]);

  const getAllHeaters = () => {
    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/Heater/GetAllBySharedPartId?id=${id}`;

    GetData(
      url,
      (data) => {
        setState((state) => ({ ...state, heaters: data, heatersLoaded: true }));
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  const getAllHeatersInactive = () => {
    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/Heater/GetAllBySharedPartId?id=${id}&isActive=false`;

    GetData(
      url,
      (data) => {
        let all_heaters = state.heaters;
        all_heaters.push(...data);

        setState((state) => ({ ...state, heaters: all_heaters }));
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  useEffect(() => {
    state.heatersLoaded && getAllHeatersInactive();
  }, [state.heatersLoaded]);

  useEffect(() => {
    getSharedPartById(id);
  }, []);

  useEffect(() => {
    getReportsByYear();
  }, [year]);

  const emitRowSave = (row, deviceType) => {
     const url = `${endpoints.REPORTS_ENDPOINT}/ReportDeviceMap/Update?deviceId=${row.deviceId}&reportId=${row.reportId}`;

     let patch = [];
     if(deviceType === 3) {
       patch = [
         {op: "replace", path: "/currentHeat", value: `${row.data.value}`},
       ];
     }else if (deviceType === 4) {
       patch = [
         {op: "replace", path: "/waterMeterValue", value: `${row.data.value}`},
       ];
     }

     SaveItem(
         url,
         patch,
         (data) => {
           props.onSuccessShow(i18next.t("entry_updated_successfully"));
           getReportsByYear();
         },
         (error) => {
           props.onErrorsShow(error.body);
         }
     );
  };

  const radioStyle = {
    listStyle: "none",
    paddingLeft: "0",
  };

  const labelStyle = {
    paddingLeft: "20px",
  };

  const tableStyle = {
    tableLayout: "fixed",
  };

  let currentLocation = location.pathname;

  if (currentLocation.charAt(0) === "/") {
    currentLocation = currentLocation.substring(1);
  }

  let tableBodyDataWaterMeter = [];
  if (state.watermeterLoaded) {
    tableBodyDataWaterMeter = state.watermeters.map((item) => {
      return {
        id: item.id,
        data: {
          room: translateDeviceLocationEnumValue(item.deviceLocation),
          number: item.device ? item.device.serialNumber : "",
          radio_number: item.radioModuleNumber,
          type: item.type ? translateTypeEnumValue(item.type) : "",
          condition:
            item.device &&
            item.device.deviceStatuses &&
            item.device.deviceStatuses.length > 0
              ? translateConditionEnumValue(
                  item.device.deviceStatuses[0].status
                )
              : "",
          comment:
            item.device && item.device.comment ? item.device.comment : "",
          isActive: item.isActive,
        },
      };
    });
  }

  let tableBodyDataHeaters = [];
  if (state.heatersLoaded) {
    tableBodyDataHeaters = state.heaters.map((item) => {
      return {
        id: item.id,
        data: {
          room: item.roomType ? item.roomType.name : "",
          number:
            item.heatCostAllocators && item.heatCostAllocators.length
              ? item.heatCostAllocators[0].number
              : "",
          radio_module:
            item.heatCostAllocators &&
            item.heatCostAllocators.length &&
            item.heatCostAllocators[0].device
              ? item.heatCostAllocators[0].device.serialNumber
              : "",
          condition:
            item.heatCostAllocators &&
            item.heatCostAllocators.length &&
            item.heatCostAllocators[0].device &&
            item.heatCostAllocators[0].device.deviceStatuses &&
            item.heatCostAllocators[0].device.deviceStatuses.length
              ? translateConditionEnumValue(
                  item.heatCostAllocators[0].device.deviceStatuses[0].status
                )
              : "",
          type: item.heaterType.name,
          status: translateStatusEnumValue(item.status),
          isActive: item.isActive,
        },
        enableLinkAdd: item.status === "WITHOUT_DEVICE" || item.status === "DEMOUNTED" ? false : true
      };
    });
  }

  let tableBodyDataReportsWaterMeters = [];
  if (state.reportsLoaded) {
    tableBodyDataReportsWaterMeters = state.reports
        .filter((item) => item.device.deviceTypeId === 4)
        .map((item) => {
          return {
            id: item.reportId,
            deviceId: item.deviceId,
            reportId: item.reportId,
            editMode: true,
            data: {
              number: item.device.serialNumber,
              value: item.waterMeterValue,
              date: item.report.reportDate,
              dateVisitation: item.report.visitationDate,
              isActive: true,
            },
          };
        });
  }

  let tableBodyDataReportsHCA = [];
  if (state.reportsLoaded) {
    tableBodyDataReportsHCA = state.reports
        .filter((item) => item.device.deviceTypeId === 3)
        .map((item) => {
          return {
            id: item.reportId,
            deviceId: item.deviceId,
            reportId: item.reportId,
            editMode: true,
            data: {
              number: item.device.serialNumber,
              value: item.currentHeat,
              date: item.report.reportDate,
              dateVisitation: item.report.visitationDate,
              isActive: true,
            },
          };
        });
  }


  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl="12">
              <BackButton className="mb-4" />
            </Col>

            <Col xl="12">
              <Card>
                <CardBody className="mb-0 pb-0">
                  <Row>
                    <Col>
                      <CardTitle className="mb-4">{state.pageTitle}</CardTitle>
                    </Col>

                    <Col sm="3" className="text-right">
                      <Row>
                        <Col
                          xs="12"
                          className="d-flex align-items-center justify-content-end"
                        >
                          <div className="mr-2">
                            <LinkAdd
                              link={`${currentLocation}/report/add`}
                              id={"link-to" + id}
                              tooltip="Добави отчет"
                            ></LinkAdd>
                          </div>

                          <ListActions
                            link={`/${id}`}
                            data={state.document}
                            mode={state.mode}
                            toggleSave={handleSave}
                            toggleEdit={handleEdit}
                            toggle={toggleActive}
                            delete={handleDelete}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col md="12">
              <AvForm className="needs-validation">
                <Row className="d-flex">
                  <Col xl="6" className="d-flex">
                    <Card className="w-100">
                      <CardBody>
                        <Row>
                          <Col md="12">
                            <FormGroup>
                              <Label htmlFor="shared_part_comment" className="">
                                Коментар
                              </Label>
                              <AvField
                                name="document.comment"
                                placeholder="Коментар"
                                type="textarea"
                                className="form-control"
                                id="shared_part_comment"
                                maxLength="200"
                                value={state.document.comment}
                                onChange={(e) =>
                                  setState((state) => ({
                                    ...state,
                                    document: {
                                      ...state.document,
                                      comment: e.target.value,
                                    },
                                  }))
                                }
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                  maxLength: {
                                    value: 200,
                                  },
                                }}
                                {...(state.mode === "view" && {
                                  readOnly: true,
                                })}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col xl="6" className="d-flex">
                    <Card className="w-100">
                      <CardBody>
                        <Row></Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </AvForm>
            </Col>

            <Col xl="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <Row>
                        <Col md="6">
                          <Nav pills className="navtab-bg nav-justified">
                            {state.tabsNav.map((item) => (
                              <NavItem key={item.id}>
                                <NavLink
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  className={classnames({
                                    active: state.activeTab === item.index,
                                  })}
                                  onClick={() => {
                                    toggleTabs(item.index);
                                  }}
                                >
                                  {item.label}
                                </NavLink>
                              </NavItem>
                            ))}
                          </Nav>
                        </Col>
                      </Row>

                      <TabContent activeTab={state.activeTab}>
                        <TabPane tabId="1" className="p-3">
                          {/* Водомери */}

                          <Row className="mb-2">
                            <Col>
                              <CardTitle className="d-none mb-4">
                                Таблица с водомери
                              </CardTitle>
                            </Col>

                            <Col>
                              <div className="text-right">
                                <Tooltip
                                  title="Добави нов водомер"
                                  placement="right-start"
                                >
                                  <button
                                    className="btn btn-primary waves-effect waves-light"
                                    onClick={(id) => openModal("water-meter")}
                                  >
                                    <i
                                      className="bx bx-plus-circle"
                                      style={{
                                        fontSize: "18px",
                                      }}
                                    />
                                  </button>
                                </Tooltip>
                              </div>
                            </Col>
                          </Row>

                          {tableBodyDataWaterMeter && (
                            <TableComponent
                              dataHead={[
                                "Помещение",
                                "Номер",
                                "Радио модул",
                                "Тип",
                                "Състояние",
                                "Коментар",
                                "Действия",
                              ]}
                              dataBody={tableBodyDataWaterMeter}
                              url_path={`${currentLocation}/water-meter`}
                              category={""}
                              enableLinkView={true}
                              isLoaded={true}
                            />
                          )}
                        </TabPane>
                        <TabPane tabId="2" classNatableBodyDataReportsHCAme="p-3">
                          {/* Отоплителни тела */}

                          <Row className="mb-2">
                            <Col>
                              <CardTitle className="d-none mb-4">
                                Таблица с отоплителни тела
                              </CardTitle>
                            </Col>

                            <Col>
                              <div className="text-right">
                                <Tooltip
                                  title="Добави ново отоплително тяло"
                                  placement="right-start"
                                >
                                  <button
                                    className="btn btn-primary waves-effect waves-light"
                                    onClick={(id) => openModal("heater")}
                                  >
                                    <i
                                      className="bx bx-plus-circle"
                                      style={{
                                        fontSize: "18px",
                                      }}
                                    />
                                  </button>
                                </Tooltip>
                              </div>
                            </Col>
                          </Row>

                          {tableBodyDataHeaters ? (
                            <TableComponent
                              dataHead={[
                                "Помещение",
                                "Номер",
                                "Радио модул",
                                "Състояние HCA",
                                "Тип",
                                "Състояние отопл. тяло",
                                "Действия",
                              ]}
                              dataBody={tableBodyDataHeaters}
                              url_path={`${currentLocation}/heater`}
                              category={""}
                              enableLinkView={true}
                              linkAddTooltip={i18next.t("tooltip-add-hca")}
                              onModalHCAOpen={onModalHCAOpen}
                              isLoaded={true}
                            />
                          ) : null}
                        </TabPane>
                        <TabPane tabId="3" className="p-3">
                          {/* Отчети */}
                          <Row className="mb-2">
                            <Col>
                              <div className="year-select d-flex align-items-center justify-content-center mb-4">
                                <button
                                    onClick={decrementYear}
                                    style={{
                                      background: "none",
                                      fontSize: "0",
                                      border: "none",
                                    }}
                                >
                                  <i
                                      className="bx bx-left-arrow-alt"
                                      style={{
                                        fontSize: "18px",
                                      }}
                                  />
                                </button>
                                <h6 className="mb-0 mx-2">{year}</h6>
                                <button
                                    onClick={incrementYear}
                                    style={{
                                      background: "none",
                                      fontSize: "0",
                                      border: "none",
                                    }}
                                >
                                  <i
                                      className="bx bx-right-arrow-alt"
                                      style={{
                                        fontSize: "18px",
                                      }}
                                  />
                                </button>
                              </div>
                            </Col>
                          </Row>

                          {tableBodyDataReportsWaterMeters &&
                          tableBodyDataReportsWaterMeters.length > 0 ? (
                              <Row className="mb-2">
                                <Col>
                                  <CardTitle className="text-center mb-4">
                                    Отчети Водомери
                                  </CardTitle>
                                </Col>
                              </Row>
                          ) : null}

                          {tableBodyDataReportsWaterMeters && (
                              <TableReports
                                  dataHead={[
                                    "Номер",
                                    "Показание",
                                    "Дата отчет",
                                    "Дата посещение",
                                    "Действия",
                                  ]}
                                  dataBody={tableBodyDataReportsWaterMeters}
                                  isEditable={true}
                                  editableCell={"value"}
                                  url_path={""}
                                  category={""}
                                  emitRowSave={emitRowSave}
                                  deviceType={4}
                                  disableLink={true}
                                  enableLinkDelete={true}
                                  onDelete={onModalDeviceReportDelete}
                                  delete_tooltip={i18next.t(
                                      "tooltip-delete-report"
                                  )}
                                  isLoaded={true}
                              />
                          ) }

                          {tableBodyDataReportsHCA &&
                          tableBodyDataReportsHCA.length > 0 ? (
                              <Row className="mb-2">
                                <Col>
                                  <CardTitle className="text-center mb-4">
                                    Отчети Разпределители
                                  </CardTitle>
                                </Col>
                              </Row>
                          ) : null}

                          {tableBodyDataReportsHCA && (
                              <TableReports
                                  dataHead={[
                                    "Номер",
                                    "Показание",
                                    "Дата отчет",
                                    "Дата посещение",
                                    "Действия",
                                  ]}
                                  dataBody={tableBodyDataReportsHCA}
                                  isEditable={true}
                                  editableCell={"value"}
                                  url_path={""}
                                  category={""}
                                  emitRowSave={emitRowSave}
                                  deviceType={3}
                                  disableLink={true}
                                  enableLinkDelete={true}
                                  onDelete={onModalDeviceReportDelete}
                                  delete_tooltip={i18next.t(
                                      "tooltip-delete-report"
                                  )}
                                  isLoaded={true}
                              />
                          )}
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <ModalWaterMeter
            isModalOpen={state.showModal === "water-meter"}
            closeModal={closeModal}
            update={() => closeAndUpdate(getAllWaterMeters)}
            shared_part_id={id}
            emitErrors={emitErrors}
            emitSuccess={emitSuccess}
          />

          <ModalHeater
            isModalOpen={state.showModal === "heater"}
            closeModal={closeModal}
            update={() => closeAndUpdate(getAllHeaters)}
            shared_part_id={id}
            emitErrors={emitErrors}
            emitSuccess={emitSuccess}
          />

          <ModalHeatCostAllocator
            isModalOpen={state.showModal === "hca"}
            closeModal={closeModal}
            update={() => closeAndUpdate(getAllHeaters)}
            heater_id={state.heaterId}
            shared_part_id={id}
            emitErrors={emitErrors}
            emitSuccess={emitSuccess}
          />
        </Container>
      </div>
    </>
  );
};

SharedPartSingle.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SharedPartSingle));

import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Table } from "reactstrap";
import {
  translateTypeEnumValue,
  translateInstallationTypeEnumValue,
  translateHeatMeterKindEnumValue,
} from "helpers/translateHardcodedData";
import {
  meter_types,
  heat_meter_kinds,
  installation_types,
} from "helpers/listsHardcodedData";
import * as endpoints from "constants/endpoints";
import { GetData } from "../../helpers/CRUD.js";
import { str2bool } from "../../helpers/utils.js";
import i18next from "i18next";

const TableHeatMeterTypes = (props) => {
  const [state, setState] = useState({
    all_meter_types: [],
    all_installation_types: [],
    equipmentManufacturers: [],
  });

  const handleDataChange = (event) => {
    const value = event.target.value;

    setState((state) => ({
      ...state,
      [event.target.name]: value,
    }));

    props.data[event.target.name] = value;
  };

  const handleEdit = () => {
    setState((state) => ({ ...state, mode: "edit" }));
  };

  const handleSave = () => {
    setState((state) => ({ ...state, mode: "view" }));
  };

  const toggleRadioModule = (e) => {
    setState((state) => ({ ...state, radioModule: str2bool(e.target.value) }));

    props.data.hasRadioModule = str2bool(e.target.value);
  };

  const toggleActive = (e) => {
    setState((state) => ({ ...state, isActive: str2bool(e.target.value) }));

    props.data.isActive = str2bool(e.target.value);
  };

  const getEquipmentManufacturers = () => {
    const url = `${endpoints.NOMENCLATURE_ENDPOINT}/EquipmentManufacturer/GetAllByDeviceType?deviceType=Топломери`;

    GetData(
      url,
      (data) => {
        setState((state) => ({ ...state, equipmentManufacturers: data }));
      },
      (error) => console.log(error)
    );
  };

  useEffect(() => {
    setState((state) => ({ ...state, ...props.data }));
    getEquipmentManufacturers();
  }, []);

  const radioStyle = {
    listStyle: "none",
    paddingLeft: "0",
  };
  const labelStyle = {
    paddingLeft: "20px",
  };

  const tableStyle = {
    tableLayout: "fixed",
  };

  const { nomEquipmentManufacturers } = props.data;
  const {
    id,
    name,
    equipmentManufacturerId,
    equipmentManufacturer,
    model,
    type,
    kind,
    range,
    installation,
    hasRadioModule,
  } = props.data;

  const meter_types_list = meter_types();
  const heat_meter_kinds_list = heat_meter_kinds();
  const installation_types_list = installation_types();

  state.all_installation_types = installation_types_list.filter((item) =>
    item.categories.includes("heat-meter-types")
  );
  const installationValue = translateInstallationTypeEnumValue(installation);

  state.all_meter_types = meter_types_list.filter((item) =>
    item.categories.includes("heat-meter-types")
  );

  const typeValue = translateTypeEnumValue(type);
  const kindValue = translateHeatMeterKindEnumValue(kind);

  return (
    <>
      <div className="table-responsive">
        <Table className="table-nowrap mb-0" style={tableStyle}>
          <tbody>
            <tr>
              <th>{i18next.t("name")}</th>

              <td>
                {props.mode === "edit" ? (
                  <input
                    name="name"
                    placeholder={i18next.t("placeholder_enter_name")}
                    id="heat_meter_type_name"
                    onChange={(e) => handleDataChange(e)}
                    defaultValue={name}
                    className="w-100 input-styled"
                  />
                ) : (
                  name
                )}
              </td>
            </tr>

            <tr>
              <th>{i18next.t("manufacture")}</th>

              <td>
                {props.mode === "edit" ? (
                  <select
                    name="equipmentManufacturerId"
                    id="heat_meter_type_equipment_manufacturer"
                    onChange={(e) => handleDataChange(e)}
                    value={equipmentManufacturerId}
                    className="w-100 input-styled"
                  >
                    <option
                      value="0"
                      selected={equipmentManufacturerId === "" ? true : false}
                    >
                      {i18next.t("select_equipment_manufacturer")}
                    </option>
                    {state.equipmentManufacturers.length > 0 &&
                      state.equipmentManufacturers.map(
                        (еquipmentManufacturer) => (
                          <option
                            key={`option-${еquipmentManufacturer.id}`}
                            value={еquipmentManufacturer.id}
                            selected={
                              equipmentManufacturerId ===
                              еquipmentManufacturer.id
                                ? true
                                : false
                            }
                          >
                            {еquipmentManufacturer.name}
                          </option>
                        )
                      )}
                  </select>
                ) : (
                  props.data.equipmentManufacturer &&
                  props.data.equipmentManufacturer.name
                )}
              </td>
            </tr>

            <tr>
              <th>{i18next.t("model")}</th>

              <td>
                {props.mode === "edit" ? (
                  <input
                    name="model"
                    placeholder={i18next.t("placeholder_enter_model")}
                    id="heat_meter_type_model"
                    onChange={(e) => handleDataChange(e)}
                    defaultValue={model}
                    className="w-100 input-styled"
                  />
                ) : (
                  model
                )}
              </td>
            </tr>

            <tr>
              <th>{i18next.t("type")}</th>

              <td>
                {props.mode === "edit" ? (
                  <select
                    name="type"
                    id="heat_meter_type_type"
                    onChange={(e) => handleDataChange(e)}
                    value={type}
                    className="w-100 input-styled"
                  >
                    <option value="0" selected={type === "" ? true : false}>
                      {i18next.t("select_type")}
                    </option>
                    {state.all_meter_types &&
                      state.all_meter_types.length > 0 &&
                      state.all_meter_types.map((type) => (
                        <option
                          value={type.key}
                          selected={type === type.key ? true : false}
                        >
                          {type.value}
                        </option>
                      ))}
                  </select>
                ) : (
                  typeValue
                )}
              </td>
            </tr>

            <tr>
              <th>{i18next.t("kind")}</th>

              <td>
                {props.mode === "edit" ? (
                  <select
                    name="kind"
                    id="heat_meter_type_kind"
                    onChange={(e) => handleDataChange(e)}
                    value={kind}
                    className="w-100 input-styled"
                  >
                    <option value="0" selected={kind === "" ? true : false}>
                      {i18next.t("select_kind")}
                    </option>
                    {heat_meter_kinds_list &&
                      heat_meter_kinds_list.length > 0 &&
                      heat_meter_kinds_list.map((kind) => (
                        <option
                          value={kind.key}
                          selected={kind === kind.key ? true : false}
                        >
                          {kind.value}
                        </option>
                      ))}
                  </select>
                ) : (
                  kindValue
                )}
              </td>
            </tr>

            <tr>
              <th>{i18next.t("range")}</th>

              <td>
                {props.mode === "edit" ? (
                  <input
                    name="range"
                    type="number"
                    step={0.1}
                    min={0}
                    placeholder={i18next.t("placeholder_enter_range")}
                    id="heat_meter_type_range"
                    onChange={(e) => handleDataChange(e)}
                    defaultValue={range}
                    className="w-100 input-styled"
                  />
                ) : (
                  range
                )}
              </td>
            </tr>

            <tr>
              <th>{i18next.t("installation")}</th>

              <td>
                {props.mode === "edit" ? (
                  <select
                    name="installation"
                    id="heat_meter_type_installation"
                    onChange={(e) => handleDataChange(e)}
                    value={installation}
                    className="w-100 input-styled"
                  >
                    <option
                      value="0"
                      selected={installation === "" ? true : false}
                    >
                      {i18next.t("select_installation_type")}
                    </option>
                    {state.all_installation_types &&
                      state.all_installation_types.length > 0 &&
                      state.all_installation_types.map((type) => (
                        <option
                          value={type.key}
                          selected={type === type.key ? true : false}
                        >
                          {type.value}
                        </option>
                      ))}
                  </select>
                ) : (
                  installationValue
                )}
              </td>
            </tr>

            <tr>
              <th>{i18next.t("radioModuleNumber")}</th>

              <td>
                {props.mode === "edit" ? (
                  <div>
                    <div className="radio">
                      <label>
                        <input
                          type="radio"
                          value="true"
                          checked={hasRadioModule === true}
                          onChange={(e) => toggleRadioModule(e)}
                        />
                        {i18next.t("yes")}
                      </label>
                    </div>

                    <div className="radio">
                      <label>
                        <input
                          type="radio"
                          value="false"
                          checked={hasRadioModule === false}
                          onChange={(e) => toggleRadioModule(e)}
                        />
                        {i18next.t("no")}
                      </label>
                    </div>
                  </div>
                ) : (
                  [
                    hasRadioModule === true ? (
                      <span key={`${id}-0`}>{i18next.t("yes")}</span>
                    ) : (
                      <span key={`${id}-1`}>{i18next.t("no")}</span>
                    ),
                  ]
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
};

TableHeatMeterTypes.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TableHeatMeterTypes));

import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  withRouter,
  useParams,
  useHistory,
  useLocation,
} from "react-router-dom";
import { AvField, AvForm } from "availity-reactstrap-validation";
import {
  FormGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { cloneDeep } from "lodash";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import BackButton from "../../../components/Common/BackButton.js";
import ListActions from "../../../components/Common/ListActions.js";
import * as endpoints from "constants/endpoints";
import * as jsonpatch from "fast-json-patch";
import { statuses } from "../../../helpers/listsHardcodedData";
import classnames from "classnames";
import { Tooltip } from "@material-ui/core";
import TableComponent from "../../../components/Common/TableComponent";
import {
  translateConditionEnumValue,
  translateHeaterTypeEnumValue,
  translateInstallationTypeEnumValue,
} from "../../../helpers/translateHardcodedData";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import ModalHeatCostAllocator from "../../../components/Modals/ModalHeatCostAllocator";
import {
  GetData,
  SaveItem,
  DeleteItem,
  ToggleItem,
} from "../../../helpers/CRUD.js";
import i18next from "i18next";

const HeaterSingle = (props) => {
  const old_state = {
    pageTitle: i18next.t("heater_single"),
    mode: "view",
    nomRoomTypes: [],
    nomHeaterTypes: [],
    all_heat_cost_allocators: [],
    activeTab: "1",
    tabsNav: [{ index: "1", id: "tab-nav-1", label: i18next.t("allocators") }],
    heaterHCAs: [],
    nomHCATypes: [],
  };
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [state, setState] = useState({
    ...old_state,
    document: {
      name: "",
      roomTypeId: null,
      heaterTypeId: null,
      valueOne: 0,
      valueTwo: 0,
      heatCostAllocatorId: null,
      status: "",
      demountingDate: "",
      comment: "",
      isActive: false,
    },
  });

  const emitErrors = (error) => {
    props.onErrorsShow(error.body);
  };

  const emitSuccess = (success) => {
    props.onSuccessShow(success);
  };

  const handleAutoCompleteChangeHeaterType = (event, item) => {
    setState((state) => ({
      ...state,
      document: {
        ...state.document,
        heaterTypeId: item.id,
      },
      nomHeaterType: item.type,
      nomHeaterTypeName: item.name,
    }));
  };

  const deleteCondition = (id) => {
    fetch(
      `${endpoints.BUILDING_UNITS_ADDITIONAL_ENDPOINT}/Condition/Delete?id=${id}`,
      {
        method: "DELETE",
      }
    ).then(() =>
      setState((state) => ({ ...state, status: "Delete successful" }))
    );
    const currentState = state.conditions;
    const index = currentState.findIndex((item) => item.id === id);
    currentState.splice(index, 1);
    setState((state) => ({ ...state, conditions: currentState }));
  };

  const openModal = (id) => {
    setState((state) => ({ ...state, showModal: id }));
  };

  const closeModal = () => {
    setState((state) => ({ ...state, showModal: null }));
    removeBodyCss();
  };

  const closeAndUpdate = (modalCallback) => {
    setState((state) => ({ ...state, showModal: null }));
    modalCallback();
    removeBodyCss();
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };

  const getNomRoomTypes = () => {
    const url = `${endpoints.NOMENCLATURE_ENDPOINT}/RoomType/GetAll`;

    GetData(
      url,
      (data) => {
        setState((state) => ({ ...state, nomRoomTypes: data.items }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getNomHeaterTypes = () => {
    const url = `${endpoints.NOMENCLATURE_ENDPOINT}/HeaterType/GetAll`;

    GetData(
      url,
      (data) => {
        setState((state) => ({ ...state, nomHeaterTypes: data.items }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getNomHCATypes = () => {
    const url = `${endpoints.NOMENCLATURE_ENDPOINT}/HeatCostAllocatorType/GetAll`;

    GetData(
      url,
      (data) => {
        setState((state) => ({ ...state, nomHCATypes: data.items }));
      },
      (error) => {
        console.log(error);
      }
    );
  };
  const getAllActiveAndInactiveHeatCostAllocators = () => {
    getAllHeatCostAllocators(getAllHeatCostAllocatorsInactive);
  };

  const getAllHeatCostAllocators = (callback) => {
    const url = `${endpoints.BUILDING_UNITS_REPORT_ENTITIES_ENDPOINT}/HeatCostAllocator/GetAllByHeaterId?id=${id}&orderBy=id_desc`;

    GetData(
      url,
      (data) => {
        callback(data);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getAllHeatCostAllocatorsInactive = (old_data) => {
    const url = `${endpoints.BUILDING_UNITS_REPORT_ENTITIES_ENDPOINT}/HeatCostAllocator/GetAllByHeaterId?id=${id}&isActive=false&orderBy=id_desc`;

    GetData(
      url,
      (data) => {
        old_data.push(...data);

        setState((state) => ({ ...state, heaterHCAs: old_data }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const addCurrentHCAToTheList = () => {
    const activeHeatCostAllocator =
      state.heatCostAllocatorsList &&
      state.heatCostAllocatorsList.find((item) => item.isActive);

    if (activeHeatCostAllocator) {
      state.bureHeatCostAllocatorId = activeHeatCostAllocator.id;

      state.all_heat_cost_allocators.push(activeHeatCostAllocator);
    }
  };

  const getHeaterById = () => {
    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/Heater/GetById?id=${id}`;

    GetData(
      url,
      (data) => {
        setState((state) => ({
          ...state,
          document: { ...data },
          initialDocument: cloneDeep(data),
        }));
        getAllActiveAndInactiveHeatCostAllocators();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const handleEdit = () => {
    setState((state) => ({ ...state, mode: "edit" }));
  };

  const handleSave = () => {
    let patch = jsonpatch.compare(state.initialDocument, state.document);

    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/Heater/Update?id=${id}`;

    SaveItem(
      url,
      patch,
      (data) => {
        props.onSuccessShow(i18next.t("entry_updated_successfully"));
        setState((state) => ({ ...state, mode: "view" }));
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  const handleDelete = () => {
    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/Heater/Delete?id=${id}`;

    DeleteItem(url, history.goBack, (error) => {
      props.onErrorsShow(error.body);
    });
  };

  const toggleTabs = (tab) => {
    if (state.activeTab !== tab) {
      setState((state) => ({ ...state, activeTab: tab }));
    }
  };

  const toggleActive = () => {
    let old_state = state.document;
    old_state.isActive = !state.document.isActive;

    setState((state) => ({ ...state, document: old_state }));
    handleToggleStatusPost();
  };

  const handleToggleStatusPost = () => {
    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/Heater/ToggleActive?id=${id}&isActive=${state.document.isActive}`;

    ToggleItem(
      url,
      () => {
        getHeaterById();
        state.document.isActive
          ? props.onSuccessShow(i18next.t("entry_activated_successfully"))
          : props.onSuccessShow(i18next.t("entry_deactivated_successfully"));
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  const cancelConfirmBox = () => {
    setState((state) => ({
      ...state,
      confirm_delete: false,
      confirm_toggle: false,
      confirm_save: false,
    }));
  };

  const confirmDelete = () => {
    setState((state) => ({
      ...state,
      confirm_delete: false,
      success_dlg: true,
    }));
  };

  const confirmToggle = () => {
    setState((state) => ({
      ...state,
      confirm_toggle: false,
      success_dlg: true,
    }));
  };

  const confirmSave = () => {
    setState((state) => ({
      ...state,
      confirm_save: false,
      success_dlg: true,
      mode: "view",
    }));
  };

  const closeAfterConfirmDelete = () => {
    setState((state) => ({ ...state, success_dlg: false }));
    handleDelete();
  };

  const closeAfterConfirmToggle = () => {
    setState((state) => ({ ...state, success_dlg: false }));
    toggleActive();
  };

  const closeAfterConfirmSave = () => {
    setState((state) => ({ ...state, success_dlg: false }));
    handleSave();
  };

  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem("authUser"));

    if (location.state) {
      setState((state) => ({
        ...state,
        loggedUserType: obj.role,
        mode: "edit",
      }));
    } else {
      setState((state) => ({ ...state, loggedUserType: obj.role }));
    }

    getHeaterById();
    getNomRoomTypes();
    getNomHeaterTypes();
    getNomHCATypes();
  }, []);

  const radioStyle = {
    listStyle: "none",
    paddingLeft: "0",
  };
  const labelStyle = {
    paddingLeft: "20px",
  };

  const tableStyle = {
    tableLayout: "fixed",
  };

  const statuses_list = statuses();

  const item4 = state.nomHeaterTypes?.find((obj) => {
    return Number(obj.id) === Number(state.nomHeaterTypeId);
  });

  const nomHeaterType = item4 && item4.type;

  const nomHeaterTypeName =
    state.document.heaterType && state.document.heaterType.name;
  item4 && translateHeaterTypeEnumValue(item4.type);

  let currentLocation = location.pathname;

  if (currentLocation.charAt(0) === "/") {
    currentLocation = currentLocation.substring(1);
  }

  let tableBodyDataHCAs = [];
  if (state.heaterHCAs) {
    tableBodyDataHCAs = state.heaterHCAs.map((item) => {
      return {
        id: item.id,
        data: {
          serialNumber:
            item.device && item.device.serialNumber
              ? item.device.serialNumber
              : "",
          type: item.heatCostAllocatorType
            ? item.heatCostAllocatorType.name
            : "",
          installation: translateInstallationTypeEnumValue(
            item.installationType
          ),
          condition:
            item.device &&
            item.device.deviceStatuses &&
            item.device.deviceStatuses.length > 0
              ? translateConditionEnumValue(
                  item.device.deviceStatuses[0].status
                )
              : "",
          isActive: item.isActive,
        },
      };
    });
  }

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl="12">
              <BackButton className="mb-4" />
            </Col>

            <Col xl="12">
              <Card>
                <CardBody className="mb-0 pb-0">
                  <Row>
                    <Col>
                      <CardTitle className="mb-4">{state.pageTitle}</CardTitle>
                    </Col>

                    <Col sm="3" className="text-right">
                      <Row>
                        <Col
                          xs="12"
                          className="d-flex align-items-center justify-content-end"
                        >
                          <ListActions
                            link={`/${state.document?.id}`}
                            data={state.document}
                            mode={state.mode}
                            toggleSave={handleSave}
                            toggleEdit={handleEdit}
                            toggle={toggleActive}
                            delete={handleDelete}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col md="12">
              <AvForm className="needs-validation">
                <Row className="d-flex">
                  <Col xl="6" className="d-flex">
                    <Card className="w-100">
                      <CardBody>
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <Label htmlFor="heater_room_type" className="">
                                {i18next.t("room")}
                              </Label>

                              <AvField
                                type="select"
                                value={state.document?.roomTypeId}
                                onChange={(e) => {
                                  setState((state) => ({
                                    ...state,
                                    document: {
                                      ...state.document,
                                      roomTypeId: e.target.value,
                                    },
                                  }));
                                }}
                                name="document.roomTypeId"
                                id="heater_room_type"
                                errorMessage="Select Role"
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                }}
                                {...(state.mode === "view" && {
                                  disabled: true,
                                })}
                              >
                                <option
                                  key={`room-type-missing`}
                                  value="0"
                                  selected={state.document?.roomTypeId === null}
                                >
                                  {i18next.t("select_room")}
                                </option>
                                {state.nomRoomTypes?.map((option) => (
                                  <option
                                    key={`room-type-${option.id}`}
                                    value={option.id}
                                    selected={
                                      state.document.roomTypeId === option.id
                                    }
                                  >
                                    {option.name}
                                  </option>
                                ))}
                              </AvField>
                            </FormGroup>
                          </Col>

                          <Col md="6">
                            <div className="form_controls">
                              <label>{i18next.t("type")}</label>

                              <Autocomplete
                                options={state.nomHeaterTypes}
                                renderOption={(option) => <>{option.name}</>}
                                getOptionLabel={(option) => option.name}
                                label={nomHeaterTypeName}
                                renderInput={(params) => {
                                  return (
                                    <TextField
                                      {...params}
                                      label={
                                        nomHeaterTypeName
                                          ? nomHeaterTypeName
                                          : i18next.t("choose-type")
                                      }
                                      variant="outlined"
                                      fullWidth
                                    />
                                  );
                                }}
                                onChange={(e, option) =>
                                  handleAutoCompleteChangeHeaterType(e, option)
                                }
                                {...(state.mode === "view" && {
                                  disabled: true,
                                })}
                              />
                            </div>
                          </Col>
                        </Row>

                        {(state.nomHeaterType ===
                          i18next.t("heater_type_cast_iron") ||
                          state.nomHeaterType ===
                            i18next.t("heater_type_aluminum")) && (
                          <Row className="mb-4">
                            <Col md="12">
                              <FormGroup>
                                <Label htmlFor="heater_gliders_count">
                                  {i18next.t("heater_gliders_count")}
                                </Label>

                                <AvField
                                  type="text"
                                  name="document.valueOne"
                                  placeholder={i18next.t(
                                    "placeholder_enter_gliders_count"
                                  )}
                                  id="heater_gliders_count"
                                  onChange={(e) => {
                                    setState((state) => ({
                                      ...state,
                                      document: {
                                        ...state.document,
                                        valueOne: e.target.value,
                                      },
                                    }));
                                  }}
                                  value={state.document.valueOne}
                                  className="w-100"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        )}
                        {(state.nomHeaterType ===
                          i18next.t("heater_type_lyre_tube") ||
                          state.nomHeaterType ===
                            i18next.t("heater_type_lyre_pipe")) && (
                          <Row className="mb-4">
                            <Col md="12">
                              <FormGroup>
                                <Label htmlFor="heater_linear_meter">
                                  {i18next.t("heater_linear_meter")}
                                </Label>

                                <AvField
                                  type="text"
                                  name="document.valueOne"
                                  placeholder={i18next.t(
                                    "placeholder_enter_linear_meter"
                                  )}
                                  id="heater_linear_meter"
                                  onChange={(e) => {
                                    setState((state) => ({
                                      ...state,
                                      document: {
                                        ...state.document,
                                        valueOne: e.target.value,
                                      },
                                    }));
                                  }}
                                  value={state.document.valueOne}
                                  className="w-100"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        )}
                        {state.nomHeaterType ===
                          i18next.t("heater_type_lyre_tube") && (
                          <Row className="mb-4">
                            <Col md="12">
                              <Label htmlFor="heater_sections_count">
                                {i18next.t("heater_sections_count")}
                              </Label>
                              <AvField
                                type="text"
                                name="document.valueTwo"
                                placeholder={i18next.t(
                                  "placeholder_enter_sections_count"
                                )}
                                id="heater_sections_count"
                                onChange={(e) => {
                                  setState((state) => ({
                                    ...state,
                                    document: {
                                      ...state.document,
                                      valueTwo: e.target.value,
                                    },
                                  }));
                                }}
                                value={state.document.valueTwo}
                                className="w-100"
                              />
                            </Col>
                          </Row>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xl="6" className="d-flex">
                    <Card className="w-100">
                      <CardBody>
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <Label htmlFor="heater_status" className="">
                                {i18next.t("status")}
                              </Label>

                              <AvField
                                type="select"
                                name="document.status"
                                id="heater_status"
                                errorMessage="Select Device type"
                                value={state.document?.status}
                                onChange={(e) => {
                                  setState((state) => ({
                                    ...state,
                                    document: {
                                      ...state.document,
                                      status: e.target.value,
                                    },
                                  }));
                                }}
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                }}
                                {...(state.mode === "view" && {
                                  disabled: true,
                                })}
                              >
                                <option>{i18next.t("select_status")}</option>
                                {statuses_list &&
                                  statuses_list.length > 0 &&
                                  statuses_list.map((option) => (
                                    <option
                                      value={option.key}
                                      selected={
                                        state.document?.status === option.key
                                      }
                                    >
                                      {option.value}
                                    </option>
                                  ))}
                              </AvField>
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="heater_demounting_date">
                                {i18next.t("demounting_date")}
                              </Label>
                              <AvField
                                type="date"
                                name="document.demountingDate"
                                id="heater_demounting_date"
                                onChange={(e) => {
                                  setState((state) => ({
                                    ...state,
                                    document: {
                                      ...state.document,
                                      demountingDate: e.target.value,
                                    },
                                  }));
                                }}
                                value={state.document?.demountingDate}
                                className="w-100"
                                validate={{
                                  date: {
                                    format: "YYYY-MM-DD",
                                  },
                                }}
                                title="Use YYYY/MM/DD"
                                {...(state.mode === "view" && {
                                  disabled: true,
                                })}
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="12">
                            <FormGroup>
                              <Label htmlFor="heat_meter_comment">
                                {i18next.t("field_comment")}
                              </Label>
                              <AvField
                                type="textarea"
                                name="document.comment"
                                placeholder={i18next.t(
                                  "placeholder_enter_comment"
                                )}
                                id="heat_meter_comment"
                                maxLength="200"
                                onChange={(e) => {
                                  setState((state) => ({
                                    ...state,
                                    document: {
                                      ...state.document,
                                      comment: e.target.value,
                                    },
                                  }));
                                }}
                                value={state.document?.comment}
                                className="w-100"
                                {...(state.mode === "view" && {
                                  disabled: true,
                                })}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </AvForm>
            </Col>

            <Col xl="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <Row>
                        <Col md="3">
                          <Nav pills className="navtab-bg nav-justified">
                            {state.tabsNav?.map((item) => (
                              <NavItem key={item.id}>
                                <NavLink
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  className={classnames({
                                    active: state.activeTab === item.index,
                                  })}
                                  onClick={() => {
                                    toggleTabs(item.index);
                                  }}
                                >
                                  {item.label}
                                </NavLink>
                              </NavItem>
                            ))}
                          </Nav>
                        </Col>
                      </Row>

                      <TabContent activeTab={state.activeTab}>
                        <TabPane tabId="1" className="p-3">
                          {/* Разпределители */}
                          <Row className="mb-2">
                            <Col>
                              <CardTitle className="d-none mb-4">
                                {i18next.t("allocators_table")}
                              </CardTitle>
                            </Col>

                            <Col>
                              <div className="text-right">
                                <Tooltip
                                  title={i18next.t("tooltip-add-new-hca")}
                                  placement="right-start"
                                >
                                  <button
                                    className="btn btn-primary waves-effect waves-light"
                                    onClick={(id) => openModal("hca")}
                                  >
                                    <i
                                      className="bx bx-plus-circle"
                                      style={{
                                        fontSize: "18px",
                                      }}
                                    />
                                  </button>
                                </Tooltip>
                              </div>
                            </Col>
                          </Row>
                          {tableBodyDataHCAs && (
                            <TableComponent
                              dataHead={[
                                i18next.t("field_serial_number"),
                                i18next.t("kind"),
                                i18next.t("installation"),
                                i18next.t("condition"),
                                i18next.t("actions"),
                              ]}
                              dataBody={tableBodyDataHCAs}
                              url_path={`${currentLocation}/heat-cost-allocator`}
                              category={""}
                              enableLinkView={true}
                              isLoaded={true}
                            />
                          )}
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <ModalHeatCostAllocator
            isModalOpen={state.showModal === "hca"}
            closeModal={closeModal}
            update={() =>
              closeAndUpdate(getAllActiveAndInactiveHeatCostAllocators)
            }
            heater_id={id}
            estate_id={state.document.estateId}
            shared_part_id={state.document.sharedPartId}
            emitErrors={emitErrors}
            emitSuccess={emitSuccess}
          />
        </Container>
      </div>
    </>
  );
};

HeaterSingle.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HeaterSingle));

import PropTypes from "prop-types";

const Navigation = (props) => {
  return <></>;
};

Navigation.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
};

export default Navigation;

import PropTypes from "prop-types";
import { useState } from "react";
import { FormGroup } from "reactstrap";
import { connect } from "react-redux";
import {
  hideRightSidebar,
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changePreloader,
  changeTopbarTheme,
} from "../../store/actions";
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import "./rightbar.scss";
import layout4 from "../../assets/images/layouts/hori-light.png";
import layout5 from "../../assets/images/layouts/hori-dark.png";
import layout6 from "../../assets/images/layouts/hori-rtl.png";

const RightSidebar = (props) => {
  const [state, setState] = useState({
    layoutType: props.layoutType,
    sidebarType: props.leftSideBarType,
    layoutWidth: props.layoutWidth,
    sidebarTheme: props.leftSideBarTheme,
    topbarTheme: props.topbarTheme,
  });

  const hideRightbar = (e) => {
    e.preventDefault();
    props.hideRightSidebar();
  };

  // componentDidUpdate(prevProps) {
  //   if (prevProps !== this.props) {
  //     this.setState({
  //       layoutType: this.props.layoutType,
  //       sidebarType: this.props.leftSideBarType,
  //       layoutWidth: this.props.layoutWidth,
  //       sidebarTheme: this.props.leftSideBarTheme,
  //       topbarTheme: this.props.topbarTheme,
  //     });
  //   }
  // }

  const changeThemePreloader = () => {
    props.changePreloader(!props.isPreloader);
  };

  const changeLayout = (e) => {
    if (e.target.checked) {
      props.changeLayout(e.target.value);
    }
  };

  const changeLayoutWidth = (e) => {
    if (e.target.checked) {
      props.changeLayoutWidth(e.target.value);
    }
  };

  const changeLeftSidebarType = (e) => {
    if (e.target.checked) {
      props.changeSidebarType(e.target.value);
    }
  };

  const changeLeftSidebarTheme = (e) => {
    if (e.target.checked) {
      props.changeSidebarTheme(e.target.value);
    }
  };

  const changeTopbarTheme = (e) => {
    if (e.target.checked) {
      props.changeTopbarTheme(e.target.value);
    }
  };

  return (
    <>
      <div className="right-bar">
        <SimpleBar style={{ height: "900px" }}>
          <div data-simplebar className="h-100">
            <div className="rightbar-title px-3 py-4">
              <Link
                to="#"
                onClick={hideRightbar}
                className="right-bar-toggle float-right"
              >
                <i className="mdi mdi-close noti-icon" />
              </Link>
              <h5 className="m-0">Settings</h5>
            </div>

            <hr className="my-0" />

            <div className="p-4">
              <div className="radio-toolbar">
                <span className="mb-2 d-block">Layouts</span>
                <input
                  type="radio"
                  id="radioVertical"
                  name="radioFruit"
                  value="vertical"
                  checked={state.layoutType === "vertical"}
                  onChange={changeLayout}
                />
                <label htmlFor="radioVertical">Vertical</label>
                {"   "}
                <input
                  type="radio"
                  id="radioHorizontal"
                  name="radioFruit"
                  value="horizontal"
                  checked={state.layoutType === "horizontal"}
                  onChange={changeLayout}
                />
                <label htmlFor="radioHorizontal">Horizontal</label>
              </div>

              <hr className="mt-1" />

              <div className="radio-toolbar">
                <span className="mb-2 d-block" id="radio-title">
                  Layout Width
                </span>
                <input
                  type="radio"
                  id="radioFluid"
                  name="radioWidth"
                  value="fluid"
                  checked={state.layoutWidth === "fluid"}
                  onChange={changeLayoutWidth}
                />
                <label htmlFor="radioFluid">Fluid</label>
                {"   "}
                <input
                  type="radio"
                  id="radioBoxed"
                  name="radioWidth"
                  value="boxed"
                  checked={state.layoutWidth === "boxed"}
                  onChange={changeLayoutWidth}
                />
                <label htmlFor="radioBoxed">Boxed</label>
                <input
                  type="radio"
                  id="radioscrollable"
                  name="radioscrollable"
                  value="scrollable"
                  checked={state.layoutWidth === "scrollable"}
                  onChange={changeLayoutWidth}
                />
                <label htmlFor="radioscrollable">Scrollable</label>
              </div>
              <hr className="mt-1" />

              <div className="radio-toolbar">
                <span className="mb-2 d-block" id="radio-title">
                  Topbar Theme
                </span>
                <input
                  type="radio"
                  id="radioThemeLight"
                  name="radioTheme"
                  value="light"
                  checked={state.topbarTheme === "light"}
                  onChange={changeTopbarTheme}
                />

                <label htmlFor="radioThemeLight">Light</label>
                {"   "}
                <input
                  type="radio"
                  id="radioThemeDark"
                  name="radioTheme"
                  value="dark"
                  checked={state.topbarTheme === "dark"}
                  onChange={changeTopbarTheme}
                />
                <label htmlFor="radioThemeDark">Dark</label>
                {"   "}
                {state.layoutType === "vertical" ? null : (
                  <>
                    {" "}
                    <input
                      type="radio"
                      id="radioThemeColored"
                      name="radioTheme"
                      value="colored"
                      checked={state.topbarTheme === "colored"}
                      onChange={changeTopbarTheme}
                    />
                    <label htmlFor="radioThemeColored">Colored</label>{" "}
                  </>
                )}
              </div>

              {state.layoutType === "vertical" ? (
                <>
                  <hr className="mt-1" />
                  <div className="radio-toolbar">
                    <span className="mb-2 d-block" id="radio-title">
                      Left Sidebar Type
                    </span>
                    <input
                      type="radio"
                      id="sidebarDefault"
                      name="sidebarType"
                      value="light"
                      checked={
                        state.sidebarType === "default" ||
                        state.sidebarType === "light"
                      }
                      onChange={changeLeftSidebarType}
                    />

                    <label htmlFor="sidebarDefault">Default</label>
                    {"   "}
                    <input
                      type="radio"
                      id="sidebarCompact"
                      name="sidebarType"
                      value="compact"
                      checked={state.sidebarType === "compact"}
                      onChange={changeLeftSidebarType}
                    />
                    <label htmlFor="sidebarCompact">Compact</label>
                    {"   "}
                    <input
                      type="radio"
                      id="sidebarIcon"
                      name="sidebarType"
                      value="icon"
                      checked={state.sidebarType === "icon"}
                      onChange={changeLeftSidebarType}
                    />
                    <label htmlFor="sidebarIcon">Icon</label>
                  </div>

                  <hr className="mt-1" />

                  <div className="radio-toolbar">
                    <span className="mb-2 d-block" id="radio-title">
                      Left Sidebar Type
                    </span>
                    <input
                      type="radio"
                      id="leftsidebarThemelight"
                      name="leftsidebarTheme"
                      value="light"
                      checked={state.sidebarTheme === "light"}
                      onChange={changeLeftSidebarTheme}
                    />

                    <label htmlFor="leftsidebarThemelight">Light</label>
                    {"   "}
                    <input
                      type="radio"
                      id="leftsidebarThemedark"
                      name="leftsidebarTheme"
                      value="dark"
                      checked={state.sidebarTheme === "dark"}
                      onChange={changeLeftSidebarTheme}
                    />
                    <label htmlFor="leftsidebarThemedark">Dark</label>
                    {"   "}
                    <input
                      type="radio"
                      id="leftsidebarThemecolored"
                      name="leftsidebarTheme"
                      value="colored"
                      checked={state.sidebarTheme === "colored"}
                      onChange={changeLeftSidebarTheme}
                    />
                    <label htmlFor="leftsidebarThemecolored">Colored</label>
                  </div>
                  <hr className="mt-1" />
                </>
              ) : null}

              <FormGroup>
                <span className="mb-2 d-block" id="radio-title">
                  Preloader
                </span>

                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    className="custom-control-input checkbox"
                    id="checkbox_1"
                    checked={props.isPreloader}
                    onChange={changeThemePreloader}
                  />
                  <label className="custom-control-label" htmlFor="checkbox_1">
                    Preloader
                  </label>
                </div>
              </FormGroup>

              <h6 className="text-center">Choose Layouts</h6>

              <div className="mb-2">
                <Link
                  to="//skote-h-light.react.themesbrand.com"
                  target="_blank"
                >
                  <img
                    src={layout4}
                    className="img-fluid img-thumbnail"
                    alt=""
                  />
                </Link>
              </div>

              <div className="mb-2">
                <Link to="//skote-h-dark.react.themesbrand.com" target="_blank">
                  <img
                    src={layout5}
                    className="img-fluid img-thumbnail"
                    alt=""
                  />
                </Link>
              </div>

              <div className="mb-2">
                <Link to="//skote-h-rtl.react.themesbrand.com" target="_blank">
                  <img
                    src={layout6}
                    className="img-fluid img-thumbnail"
                    alt=""
                  />
                </Link>
              </div>

              <Link
                to="#"
                className="btn btn-primary btn-block mt-3"
                target="_blank"
              >
                <i className="mdi mdi-cart mr-1" /> Purchase Now
              </Link>
            </div>
          </div>
        </SimpleBar>
      </div>
      <div className="rightbar-overlay" />
    </>
  );
};

RightSidebar.propTypes = {
  changeLayout: PropTypes.func,
  changeLayoutWidth: PropTypes.func,
  changePreloader: PropTypes.func,
  changeSidebarTheme: PropTypes.func,
  changeSidebarType: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  hideRightSidebar: PropTypes.func,
  isPreloader: PropTypes.bool,
  layoutType: PropTypes.any,
  layoutWidth: PropTypes.any,
  leftSideBarTheme: PropTypes.any,
  leftSideBarType: PropTypes.any,
  topbarTheme: PropTypes.any,
};

const mapStatetoProps = (state) => {
  return { ...state.Layout };
};

export default connect(mapStatetoProps, {
  hideRightSidebar,
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeLayoutWidth,
  changeTopbarTheme,
  changePreloader,
})(RightSidebar);

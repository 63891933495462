import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, useParams, useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import { cloneDeep } from "lodash";
import { getUserProfile } from "store/actions";
import ListActions from "../../components/Common/ListActions.js";
import BackButton from "../../components/Common/BackButton.js";
import ConfirmBox from "../../components/Common/ConfirmBox.js";
import * as jsonpatch from "fast-json-patch";
import * as endpoints from "constants/endpoints";
import i18next from "i18next";
import {
  GetData,
  SaveItem,
  DeleteItem,
  ToggleItem,
} from "../../helpers/CRUD.js";
import { roles } from "../../helpers/listsHardcodedData";

const ContactsProfile = (props) => {
  const { id } = useParams();
  const history = useHistory();

  const [state, setState] = useState({
    mode: "view",
    document: {},
    roles: roles(),
    confirm_delete: false,
    confirm_toggle: false,
    confirm_save: false,
    success_dlg: false,
    error_dlg: false,
    actionType: "",
  });

  const getUser = () => {
    const url = `${endpoints.USER}/GetById?id=${id}`;

    GetData(
      url,
      (data) => {
        const initialDocument = cloneDeep(data);
        setState((state) => ({ ...state, document: data, initialDocument }));
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  const toggleActive = () => {
    let old_state = state.document;
    old_state.isActive = !state.document.isActive;

    setState((state) => ({ ...state, document: old_state }));
    handleToggleStatusPost();
  };

  const handleToggleStatusPost = () => {
    const url = `${endpoints.USER}/ToggleActive?id=${state.document.id}&isActive=${state.document.isActive}`;

    ToggleItem(
      url,
      () => {
        getUser(state.document.id);
        state.document.isActive
          ? props.onSuccessShow(i18next.t("entry_activated_successfully"))
          : props.onSuccessShow(i18next.t("entry_deactivated_successfully"));
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  const handleDelete = () => {
    const url = `${endpoints.USER}/Delete?id=${state.document.id}`;

    DeleteItem(url, history.goBack, (error) => {
      props.onErrorsShow(error.body);
    });
  };

  const handleEdit = () => {
    setState((state) => ({ ...state, mode: "edit" }));
  };

  const handleSave = () => {
    setState((state) => ({ ...state, mode: "view" }));

    let patch = jsonpatch.compare(state.initialDocument, state.document);

    let url = `${endpoints.USER}/Update?id=${state.document.id}`;

    SaveItem(
      url,
      patch,
      (data) => {
        props.onSuccessShow(i18next.t("entry_updated_successfully"));
        setState((state) => ({ ...state, isModalOpen: false }));
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  useEffect(() => {
    getUser();
    const loggedUser = JSON.parse(localStorage.getItem("role"));

    setState((state) => ({ ...state, loggedUser }));
  }, []);

  const cancelConfirmBox = () => {
    setState((state) => ({
      ...state,
      confirm_delete: false,
      confirm_toggle: false,
      confirm_save: false,
    }));
  };

  const confirmDelete = () => {
    setState({ confirm_delete: false, success_dlg: true });
  };

  const confirmToggle = () => {
    setState((state) => ({
      ...state,
      confirm_toggle: false,
      success_dlg: true,
    }));
  };

  const confirmSave = () => {
    setState((state) => ({
      ...state,
      confirm_save: false,
      success_dlg: true,
      mode: "view",
    }));
  };

  const closeAfterConfirmDelete = () => {
    setState((state) => ({ ...state, success_dlg: false }));
    handleDelete();
  };

  const closeAfterConfirmToggle = () => {
    setState((state) => ({ ...state, success_dlg: false }));
    toggleActive();
  };

  const closeAfterConfirmSave = () => {
    setState((state) => ({ ...state, success_dlg: false }));
    handleSave();
  };

  const { userProfile } = props;

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl="12">
              <BackButton className="mb-4" />

              <Card className="overflow-hidden">
                <CardBody className="pt-4">
                  <Row className="justify-content-between">
                    <Col sm="4">
                      <h5 className="font-size-15 text-truncate">
                        {state.document.name}
                      </h5>

                      {state.document.role && state.document.role.roleName && (
                        <p className="text-muted mb-0 text-truncate">
                          {i18next.t(state.document.role.roleName)}
                        </p>
                      )}
                    </Col>

                    <Col sm="3" className="text-right">
                      <Row>
                        <Col xs="12">
                          <ListActions
                            link={`/${id}`}
                            data={state.document}
                            mode={state.mode}
                            toggleSave={() => {
                              setState((state) => ({
                                ...state,
                                confirm_save: true,
                                actionType: "save",
                              }));
                            }}
                            toggleEdit={handleEdit}
                            toggle={() =>
                              setState((state) => ({
                                ...state,
                                confirm_toggle: true,
                                actionType: "toggle",
                              }))
                            }
                            delete={() =>
                              setState((state) => ({
                                ...state,
                                confirm_delete: true,
                                actionType: "delete",
                              }))
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    {i18next.t("user-info")}
                  </CardTitle>

                  <div className="table-responsive">
                    <Table className="table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <th scope="row">{i18next.t("fullname")} :</th>
                          <td>{state.document.name}</td>
                        </tr>

                        <tr>
                          <th scope="row">{i18next.t("username")} :</th>

                          <td>
                            {state.mode === "edit" &&
                            state.loggedUser.length &&
                            state.loggedUser === "Admin" ? (
                              <div>
                                <input
                                  name="document.username"
                                  placeholder="Username"
                                  type="text"
                                  onChange={(e) => {
                                    setState((state) => ({
                                      ...state,
                                      document: {
                                        ...state.document,
                                        username: e.target.value,
                                      },
                                    }));
                                  }}
                                  defaultValue={state.document.username}
                                />
                              </div>
                            ) : (
                              state.document.username
                            )}
                          </td>
                        </tr>

                        {state.document.role && (
                          <tr>
                            <th scope="row">
                              {i18next.t("role")} :{state.roleId}
                            </th>

                            <td>
                              {state.mode === "edit" &&
                              state.loggedUser.length &&
                              state.loggedUser === "Admin" ? (
                                <div>
                                  <select
                                    type="roleId"
                                    name="document.role.roleId"
                                    id="validationCustomUserRole12"
                                    onChange={(e) => {
                                      setState((state) => ({
                                        ...state,
                                        document: {
                                          ...state.document,
                                          role: {
                                            ...state.document.role,
                                            roleId: e.target.value,
                                          },
                                        },
                                      }));
                                    }}
                                  >
                                    {state.roles.map((option) => (
                                      <option
                                        key={`role-${option.value}`}
                                        value={option.value}
                                        selected={
                                          state.document.roleId === option.value
                                            ? true
                                            : false
                                        }
                                      >
                                        {i18next.t(option.name)}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              ) : (
                                i18next.t(state.document.role.roleName)
                              )}
                            </td>
                          </tr>
                        )}

                        <tr>
                          <th scope="row">{i18next.t("phone")} :</th>

                          <td>
                            {state.mode === "edit" ? (
                              <div>
                                <input
                                  name="document.phoneNumber"
                                  placeholder={`Въведете ${i18next
                                    .t("phone")
                                    .toLowerCase()}`}
                                  type="text"
                                  onChange={(e) => {
                                    setState((state) => ({
                                      ...state,
                                      document: {
                                        ...state.document,
                                        phoneNumber: e.target.value,
                                      },
                                    }));
                                  }}
                                  defaultValue={state.document.phoneNumber}
                                />
                              </div>
                            ) : (
                              state.document.phoneNumber
                            )}
                          </td>
                        </tr>

                        <tr>
                          <th scope="row">{i18next.t("email")} :</th>

                          <td>
                            {state.mode === "edit" ? (
                              <div>
                                <input
                                  name="document.email"
                                  placeholder={`Въведете ${i18next
                                    .t("email")
                                    .toLowerCase()}`}
                                  type="text"
                                  onChange={(e) => {
                                    setState((state) => ({
                                      ...state,
                                      document: {
                                        ...state.document,
                                        email: e.target.value,
                                      },
                                    }));
                                  }}
                                  defaultValue={state.document.email}
                                />
                              </div>
                            ) : (
                              state.document.email
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <ConfirmBox
            confirm_delete={state.confirm_delete}
            confirm_toggle={state.confirm_toggle}
            confirm_save={state.confirm_save}
            success_dlg={state.success_dlg}
            cancelConfirmBox={cancelConfirmBox}
            closeAfterConfirmDelete={closeAfterConfirmDelete}
            closeAfterConfirmToggle={closeAfterConfirmToggle}
            closeAfterConfirmSave={closeAfterConfirmSave}
            confirmDelete={confirmDelete}
            confirmToggle={confirmToggle}
            confirmSave={confirmSave}
            isActive={state.document.isActive}
            actionType={state.actionType}
          />
        </Container>
      </div>
    </>
  );
};

ContactsProfile.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ContactsProfile));

import { openModal, closeModal, selectOption } from "../routines";

const initialState = {
  open: false,
  options: [
    "Module",
    "Connection",
    "Global Connection",
    "NLU - Bot training",
    "Channels",
  ],
  step: 0,
  selectedOption: 0,
};

export const modal = (state = initialState, { type, payload }) => {
  switch (type) {
    case openModal.TRIGGER:
      return {
        ...state,
        open: true,
        errors: payload,
      };
    case closeModal.TRIGGER:
      return {
        ...state,
        open: false,
      };
    case selectOption.TRIGGER:
      return {
        ...state,
        selectedOption: payload,
      };
    default:
      return state;
  }
};

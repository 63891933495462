import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  withRouter,
  useParams,
  useHistory,
  useLocation,
} from "react-router-dom";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import TableCondition from "../../../components/TableDetailed/TableCondition.js";
import BackButton from "../BackButton.js";
import ConfirmBox from "../../../components/Common/ConfirmBox.js";
import ListActions from "../../../components/Common/ListActions.js";
import * as endpoints from "constants/endpoints";

const ConditionSingle = (props) => {
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();

  const [state, setState] = useState({
    page_title: "Състояние",
    mode: "view",
  });

  const handleEdit = () => {
    setState((state) => ({ ...state, mode: "edit" }));
  };

  const handleSave = () => {
    setState((state) => ({ ...state, mode: "view" }));

    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        fromDate: state.fromDate,
        toDate: state.toDate,
        heaterCondition: state.heaterCondition,
        bureHeatCostAllocatorId: state.bureHeatCostAllocatorId,
        bureWaterMeterId: state.bureWaterMeterId,
        buHeaterId: state.buHeaterId,
      }),
    };

    fetch(
      `${endpoints.BUILDING_UNITS_ADDITIONAL_ENDPOINT}/Condition/Update?entityUpdateId=${id}`, //nomHCATypeId
      requestOptions
    )
      .then((resp) => resp.json())
      .then((data) => {
        setState((state) => ({ ...state, isModalOpen: false }));
      })
      .catch((error) => {
        // handle error
        console.log(error);
      });
  };

  const handleDelete = () => {
    const requestOptions = {
      method: "DELETE",
    };

    fetch(
      `${endpoints.BUILDING_UNITS_ADDITIONAL_ENDPOINT}/Condition/Delete?id=${id}`,
      requestOptions
    )
      .then((resp) => {
        if (resp.status === 200) {
          history.goBack();
        }

        if (resp.status === 401) {
        }
      })
      .then((data) => {})
      .catch((error) => {
        // handle error
        console.log(error);
      });
  };

  const getCondition = (id) => {
    fetch(
      `${endpoints.BUILDING_UNITS_ADDITIONAL_ENDPOINT}/Condition/GetById?id=${id}`
    )
      .then((resp) => resp.json())
      .then((data) => {
        setState((state) => ({ ...state, ...data }));
      })
      .catch((error) => {
        // handle error
        console.log(error);
      });
  };

  // Important for confirm Box
  const cancelConfirmBox = () => {
    setState((state) => ({
      ...state,
      confirm_delete: false,
      confirm_toggle: false,
      confirm_save: false,
    }));
  };

  const confirmDelete = () => {
    setState((state) => ({
      ...state,
      confirm_delete: false,
      success_dlg: true,
    }));
  };

  const confirmToggle = () => {
    setState((state) => ({
      ...state,
      confirm_toggle: false,
      success_dlg: true,
    }));
  };

  const confirmSave = () => {
    setState((state) => ({
      ...state,
      confirm_save: false,
      success_dlg: true,
      mode: "view",
    }));
  };

  const closeAfterConfirmDelete = () => {
    setState((state) => ({ ...state, success_dlg: false }));
    handleDelete();
  };

  const closeAfterConfirmToggle = () => {
    setState((state) => ({ ...state, success_dlg: false }));
    toggleActive();
  };

  const closeAfterConfirmSave = () => {
    setState((state) => ({ ...state, success_dlg: false }));
    handleSave();
  };

  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem("authUser"));

    if (location.state) {
      setState((state) => ({
        ...state,
        mode: "edit",
        loggedUserType: obj.role,
      }));
    } else {
      setState((state) => ({ ...state, loggedUserType: obj.role }));
    }

    getCondition(id);
  }, []);

  const radioStyle = {
    listStyle: "none",
    paddingLeft: "0",
  };
  const labelStyle = {
    paddingLeft: "20px",
  };

  const tableStyle = {
    tableLayout: "fixed",
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl="12">
              <BackButton className="mb-4" />

              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <CardTitle className="mb-4">{state.page_title}</CardTitle>
                    </Col>

                    <Col xs={3} className="text-right">
                      <Row>
                        <Col xs="12">
                          <ListActions
                            link={`/${id}`}
                            data={state}
                            mode={state.mode}
                            hideToggle={true}
                            toggleSave={() => {
                              setState((state) => ({
                                ...state,
                                confirm_save: true,
                                actionType: "save",
                              }));
                            }}
                            toggleEdit={handleEdit}
                            toggle={() =>
                              setState((state) => ({
                                ...state,
                                confirm_toggle: true,
                                actionType: "toggle",
                              }))
                            }
                            delete={() =>
                              setState((state) => ({
                                ...state,
                                confirm_delete: true,
                                actionType: "delete",
                              }))
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <TableCondition
                    data={state}
                    mode={state.mode}
                  ></TableCondition>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ConfirmBox
            confirm_delete={state.confirm_delete}
            confirm_toggle={state.confirm_toggle}
            confirm_save={state.confirm_save}
            success_dlg={state.success_dlg}
            cancelConfirmBox={cancelConfirmBox}
            closeAfterConfirmDelete={closeAfterConfirmDelete}
            closeAfterConfirmToggle={closeAfterConfirmToggle}
            closeAfterConfirmSave={closeAfterConfirmSave}
            confirmDelete={confirmDelete}
            confirmToggle={confirmToggle}
            confirmSave={confirmSave}
            isActive={state.isActive}
            actionType={state.actionType}
          />
        </Container>
      </div>
    </>
  );
};

ConditionSingle.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ConditionSingle));

import { useEffect } from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { createBrowserHistory } from "history";

// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";

// layouts
import VerticalLayout from "./components/VerticalLayout/";
import LayoutNomenclature from "./components/LayoutNomenclature/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";

// Import fackbackend Configuration file
import fakeBackend from "./helpers/AuthType/fakeBackend";

// Activating fake backend
fakeBackend();

const history = createBrowserHistory();

const App = (props) => {
  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const lastLoggedDate = localStorage.getItem("lastLoggedDate");

    const date = new Date();
    const today =
      date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate();

    if (lastLoggedDate && lastLoggedDate !== today) {
      localStorage.removeItem("authUser");
      localStorage.removeItem("loginExpire");
      localStorage.removeItem("lastLoggedDate");
      window.location.href = window.location.origin + "/login";
    }
  }, []);

  return (
    <>
      <Router history={history}>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <AppRoute
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
            />
          ))}

          {authProtectedRoutes.map((route, idx) => {
            return route.layout === "layout-nomenclature" ? (
              <AppRoute
                path={route.path}
                layout={LayoutNomenclature}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            ) : (
              <AppRoute
                path={route.path}
                layout={VerticalLayout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            );
          })}
        </Switch>
      </Router>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

App.propTypes = {
  layout: PropTypes.object,
};

export default connect(mapStateToProps, null)(App);

import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useParams } from "react-router";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { AvField, AvForm } from "availity-reactstrap-validation";
import BarPageTitle from "../../components/Common/BarPageTitle";
import BackButton from "../../components/Common/BackButton.js";
import Pagination from "components/Common/Pagination";
import ModalEstateReportsDetails from "components/Modals/ModalEstateReportsDetails";
import { Tooltip } from "@material-ui/core";
import * as endpoints from "constants/endpoints";
import * as jsonpatch from "fast-json-patch";
import { GetData, PostData } from "../../helpers/CRUD.js";
import i18next from "i18next";
import Loader from "../../components/Common/Loader";

const MonthlyDetailedCalculations = (props) => {
  const { id } = useParams();

  const [state, setState] = useState({
    buildingDetails: {},
    tableData: [],
    page: 1,
    totalCount: null,
    pageSize: 100,
    showModal: false,
    modalEstateId: "",
    isLoading: false,
    isLoaded: false,
    buildingDetailsLoaded: false,
    year: 0,
    month: 0,
    heatingMonth: {},
  });

  const emitErrors = (error) => {
    setState((state) => ({ ...state, isLoaded: false, isLoading: false }));
    props.onErrorsShow(error.body);
  };

  const emitSuccess = (success) => {
    setState((state) => ({ ...state, isLoaded: true, isLoading: false }));
    props.onSuccessShow(success);
  };

  const updatePage = (page) => {
    setState((state) => ({ ...state, page }));
  };

  const openModal = (id) => {
    setState((state) => ({ ...state, showModal: true, modalEstateId: id }));
  };

  const closeModal = () => {
    setState((state) => ({ ...state, showModal: false }));
  };
  const getHeatingMonth = (year, month) => {
    const url = `${endpoints.NOMENCLATURE_ENDPOINT}/HeatingMonth/GetByYearAndMonth/${year}/${month}`;

    GetData(
      url,
      (data) => {
        setState((state) => ({ ...state, heatingMonth: data }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getBuildingHistoryDetails = () => {
    const url = `${endpoints.CALCULATIONS}/GetBuildingHistoryDetails/${id}`;

    GetData(
      url,
      (data) => {
        setState((state) => ({
          ...state,
          buildingDetails: data,
          buildingDetailsLoaded: true,
          year: data.year,
          month: data.month,
        }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const recalculate = () => {
    setState((state) => ({ ...state, isLoaded: false, isLoading: true }));
    const url = `${endpoints.CALCULATIONS}/Recalculate`;

    const params = {
      buildingHistoryId: Number(id),
      quantityHotWater: (state.buildingDetails?.quantityHotWater / 1).toFixed(
        2
      ),
    };

    PostData(
      url,
      params,
      (data) => {
        emitSuccess(i18next.t("calculation_done_successfully_single_building"));
        window.location.replace(`/monthly-detailed-calculations/${data}`);
      },
      (error) => {
        emitErrors(error);
      }
    );
  };

  const confirm = () => {
    setState((state) => ({ ...state, isLoaded: false, isLoading: true }));
    const url = `${endpoints.CALCULATIONS}/Confirm`;

    PostData(
      url,
      [id],
      (data) => {
        emitSuccess(
          i18next.t("calculation_confirmed_successfully_single_building")
        );
      },
      (error) => {
        emitErrors(error);
      }
    );
  };

  const getTableData = () => {
    const url = `${endpoints.CALCULATIONS}/GetAllEstatesHistoryPerMonth/${id}`;

    GetData(
      url,
      (data) => {
        setState((state) => ({
          ...state,
          tableData: data.items,
          totalCount: data.totalCount,
        }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    getTableData();
    getBuildingHistoryDetails();
  }, [state.isLoaded]);

  useEffect(() => {
    if (state.year !== 0 && state.month !== 0) {
      getHeatingMonth(state.year, state.month);
    }
  }, [state.buildingDetailsLoaded]);

  const tableHead = {
    backgroundColor: "#F0F0F0",
    borderColor: "#C8C8C8",
    verticalAlign: "middle",
  };

  const tableBody = {
    borderColor: "#C8C8C8",
  };

  const btnCommonStyle = {
    display: "inline-block",
    marginLeft: 20,
    fontWeight: 600,
    border: "0.5px solid #C8C8C8",
  };

  const tableData = {
    color: "black",
    size: "30px",
  };

  const tableDataInactive = {
    color: "darkgray",
    size: "30px",
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          {state.isLoading && !state.isLoaded && <Loader showLoader={true} />}

          <AvForm>
            <Row>
              <Col md="3">
                <BackButton />
              </Col>
              <Col md="9">
                <div className="d-flex justify-content-end">
                  {state.heatingMonth && state.heatingMonth.isLocked ? (
                    <button
                      className="btn"
                      style={{ ...btnCommonStyle, background: "#ECB800" }}
                      type="button"
                      onClick={recalculate}
                    >
                      <i className="fas fa-edit mr-1" />
                      {i18next.t("correction")}
                    </button>
                  ) : (
                    <button
                      className="btn"
                      style={{
                        ...btnCommonStyle,
                        background: "#D9EC00",
                      }}
                      type="button"
                      onClick={recalculate}
                    >
                      <i className="fas fa-calculator mr-1" />
                      {i18next.t("btn-recalculate")}
                    </button>
                  )}
                  <button
                    className="btn"
                    style={{ ...btnCommonStyle, background: "#EBFEE5" }}
                    onClick={confirm}
                    type="button"
                    disabled={
                      state.buildingDetails?.calculationHistoryStatus === 4
                    }
                  >
                    <i className="fa fa-times mr-1" />
                    {i18next.t("placeholder_end")}
                  </button>
                </div>
              </Col>
            </Row>
            <Row className="mb-5">
              <Col className="d-flex align-items-center">
                {state.buildingDetails?.calculationHistoryStatus === 1 && (
                  <div
                    style={{
                      width: 23,
                      height: 23,
                      background: "#B80000",
                      display: "inline-block",
                    }}
                  ></div>
                )}
                {state.buildingDetails?.calculationHistoryStatus === 2 && (
                  <div
                    style={{
                      width: 23,
                      height: 23,
                      background: "#D07E03",
                      display: "inline-block",
                    }}
                  ></div>
                )}
                {state.buildingDetails?.calculationHistoryStatus === 3 && (
                  <div
                    style={{
                      width: 23,
                      height: 23,
                      background: "#EDD60A",
                      display: "inline-block",
                    }}
                  ></div>
                )}
                {state.buildingDetails?.calculationHistoryStatus === 4 && (
                  <div
                    style={{
                      width: 23,
                      height: 23,
                      background: "#04B800",
                      display: "inline-block",
                    }}
                  ></div>
                )}
                <h3 style={{ marginBottom: 0 }} className="ml-1">
                  {state.buildingDetails?.buildingExternalId &&
                    `${state.buildingDetails.buildingExternalId} - ${state.buildingDetails.address}`}
                </h3>
                <p style={{ marginBottom: 0 }} className="ml-4">
                  {state.heatingMonth.isLocked === true ? (
                    <span style={tableDataInactive}>
                      <i className="fa fa-lock" />
                      &nbsp;
                      {state.buildingDetails.month}/{state.buildingDetails.year}
                    </span>
                  ) : (
                    <span style={tableData}>
                      <i className="fa fa-lock-open" />
                      &nbsp;
                      {state.buildingDetails.month}/{state.buildingDetails.year}
                    </span>
                  )}
                </p>
              </Col>
            </Row>

            <Row className="mb-2">
              <Col md="3">
                <h4 className="calculation-title">
                  {i18next.t("hotWaterKwh")}
                </h4>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md="3">
                <label className="calculation-value">
                  {i18next.t("summer")}
                </label>
                <div className="calculation-value-box">
                  <p className="calculation-value">
                    {state.buildingDetails?.quantityHotWaterNonHeatingSeason &&
                      state.buildingDetails?.quantityHotWaterNonHeatingSeason.toFixed(
                        6
                      )}
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="mb-5">
              <Col md="3">
                <label className="calculation-value">
                  {i18next.t("winter")}
                </label>
                <div className="calculation-value-box">
                  <p className="calculation-value">
                    {state.buildingDetails?.quantityHotWaterHeatingSeason &&
                      state.buildingDetails?.quantityHotWaterHeatingSeason.toFixed(
                        6
                      )}
                  </p>
                </div>
              </Col>
              <Col md="9">
                <div className="d-flex">
                  <label className="calculation-value" style={{ marginTop: 5 }}>
                    {i18next.t("hotWaterCoefficient")}
                  </label>
                  <AvField
                    name="hotWaterCoefficient"
                    placeholder=""
                    type="text"
                    className="form-control calculation-editable-box"
                    value={state.buildingDetails?.quantityHotWater}
                    onChange={(e) => {
                      setState((state) => ({
                        ...state,
                        buildingDetails: {
                          quantityHotWater: e.target.value,
                        },
                      }));
                    }}
                    validate={{
                      required: {
                        value: true,
                      },
                    }}
                  />
                </div>
              </Col>
            </Row>
            <div style={{ maxWidth: 960 }}>
              <Row className="mb-2">
                <Col md="3">
                  <h4 className="calculation-title">
                    {i18next.t("kwhEnergy")}
                  </h4>
                </Col>
                <Col md="1">
                  <h4 className="calculation-title">{i18next.t("days")}</h4>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col
                  md="3"
                  className="d-flex justify-content-between align-items-center"
                >
                  <label className="calculation-value">
                    {i18next.t("hotWater")}
                  </label>
                  <div className="calculation-value-box">
                    <p className="calculation-value">
                      {state.buildingDetails?.hotWaterEnergy &&
                        state.buildingDetails?.hotWaterEnergy.toFixed(6)}
                    </p>
                  </div>
                </Col>
                <Col md="1">
                  <div className="calculation-days-box">
                    <Tooltip
                      title={i18next.t("workDays_hotWater")}
                      placement="right-start"
                    >
                      <p className="calculation-value">
                        {state.buildingDetails?.workDaysHotWater}
                      </p>
                    </Tooltip>
                  </div>
                </Col>
                <Col md="4" className="text-right">
                  <label className="calculation-value">
                    {i18next.t("commonWaterMeter")}
                  </label>
                  <div className="calculation-value-box">
                    <p className="calculation-value">
                      {state.buildingDetails?.globalWaterReadingDifference}
                    </p>
                  </div>
                </Col>
                <Col md="3" className="text-right">
                  <label className="calculation-value">
                    {i18next.t("MSRS")}
                  </label>
                  <div className="calculation-value-box">
                    <Tooltip
                      title={i18next
                        .t("MSRS_estateExternalId")
                        .replace(
                          "{0}",
                          state.buildingDetails
                            ?.maximumSpecificBuildingConsumptionEstateExternalId
                        )}
                      placement="right-start"
                    >
                      <p className="calculation-value">
                        {state.buildingDetails
                          ?.maximumSpecificBuildingConsumption &&
                          state.buildingDetails?.maximumSpecificBuildingConsumption.toFixed(
                            6
                          )}
                      </p>
                    </Tooltip>
                  </div>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col
                  md="3"
                  className="d-flex justify-content-between align-items-center"
                >
                  <label className="calculation-value">
                    {i18next.t("heating")}
                  </label>
                  <div className="calculation-value-box">
                    <p className="calculation-value">
                      {state.buildingDetails?.heatingEnergy &&
                        state.buildingDetails?.heatingEnergy.toFixed(6)}
                    </p>
                  </div>
                </Col>
                <Col md="1">
                  <div className="calculation-days-box">
                    <Tooltip
                      title={i18next.t("workDays_heating")}
                      placement="right-start"
                    >
                      <p className="calculation-value">
                        {state.buildingDetails?.workDaysHeating}
                      </p>
                    </Tooltip>
                  </div>
                </Col>
                <Col md="4" className="text-right">
                  <label className="calculation-value">
                    {i18next.t("individualWatermetersSum")}
                  </label>
                  <div className="calculation-value-box">
                    <p className="calculation-value">
                      {state.buildingDetails?.sumWaterMetersConsumption}
                    </p>
                  </div>
                </Col>
                <Col md="3" className="text-right">
                  <label className="calculation-value">
                    {i18next.t("unitKwh")}
                  </label>
                  <div className="calculation-value-box">
                    <p className="calculation-value">
                      {state.buildingDetails?.heatingEnergyPerUnit}
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col
                  md="3"
                  className="d-flex justify-content-between align-items-center"
                >
                  <label className="calculation-value">
                    {i18next.t("commonParts")}
                  </label>
                  <div className="calculation-value-box">
                    <p className="calculation-value">
                      {state.buildingDetails?.sharedPartsEnergy &&
                        state.buildingDetails?.sharedPartsEnergy.toFixed(6)}
                    </p>
                  </div>
                </Col>
                <Col md="1"></Col>
                <Col md={{ size: 4 }} className="text-right">
                  <label className="calculation-value">
                    {i18next.t("correctionCommonWatermeter")}
                  </label>
                  <div className="calculation-value-box">
                    <p className="calculation-value">
                      {state.buildingDetails?.correctionCoefficient &&
                        state.buildingDetails?.correctionCoefficient.toFixed(6)}
                    </p>
                  </div>
                </Col>
                <Col md="3" className="text-right">
                  <label className="calculation-value">
                    {i18next.t("commonReportingUnits")}
                  </label>
                  <div className="calculation-value-box">
                    <p className="calculation-value">
                      {state.buildingDetails?.sumUnits}
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="mb-5">
                <Col
                  md="3"
                  className="d-flex justify-content-between align-items-center"
                >
                  <label className="calculation-value">
                    {i18next.t("buildingInstallation")}
                  </label>
                  <div className="calculation-value-box">
                    <p className="calculation-value">
                      {state.buildingDetails?.buildingInstallationEnergy &&
                        state.buildingDetails?.buildingInstallationEnergy.toFixed(
                          6
                        )}
                    </p>
                  </div>
                </Col>
                <Col md={{ size: 3, offset: 2 }} className="text-right">
                  <label className="calculation-value">
                    {i18next.t("percentageBuildingInstallation")}
                  </label>
                  <div className="calculation-value-box">
                    <p className="calculation-value">
                      {state.buildingDetails?.buildingInstallationPercent &&
                        state.buildingDetails?.buildingInstallationPercent.toFixed(
                          6
                        )}
                    </p>
                  </div>
                </Col>
                <Col md="3" className="text-right">
                  <label className="calculation-value">
                    {i18next.t("dayDegree")}
                  </label>
                  <div className="calculation-value-box">
                    <p className="calculation-value">
                      {state.buildingDetails?.degreeDay}
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col
                  md="3"
                  className="d-flex justify-content-between align-items-center"
                >
                  <label className="calculation-value">
                    {i18next.t("toploficationAbbrv")}
                  </label>
                  <div className="calculation-value-box">
                    <p className="calculation-value">
                      {state.buildingDetails?.totalEnergy}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
            <Row>
              <Table
                className="table scrollable table-bordered"
                style={{ tableLayout: "fixed" }}
              >
                <Thead>
                  <Tr>
                    <Th style={tableHead}>{i18next.t("subscriber")}</Th>
                    <Th style={tableHead}>
                      {i18next.t("label-apartment-type")}
                    </Th>
                    <Th style={tableHead}>{i18next.t("apartmentNumber")}</Th>
                    <Th style={tableHead}>{i18next.t("heatingEnergy")}</Th>
                    <Th style={tableHead}>{i18next.t("hotWaterEnergy")}</Th>
                    <Th style={tableHead}>
                      {i18next.t("buildingInstallationEnergy")}
                    </Th>
                    <Th style={tableHead}>{i18next.t("zone_volume_total")}</Th>
                    <Th style={tableHead}>{i18next.t("actions")}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {state.tableData && state.tableData.length === 0 ? (
                    <Tr>
                      <Td
                        colSpan={8}
                        className="align-items-center text-center"
                        style={tableBody}
                      >
                        {i18next.t("table_missing_data")}
                      </Td>
                    </Tr>
                  ) : (
                    state.tableData.map((row, i) => (
                      <Tr key={`period-${row.id}`}>
                        <Td style={tableBody}>{row.estateExternalId}</Td>
                        <Td style={tableBody}>{row.apartmentType?.name}</Td>
                        <Td style={tableBody}>{row.apartmentNumber}</Td>
                        <Td style={tableBody}>
                          {row.heatingEnergy.toFixed(6)}
                        </Td>
                        <Td style={tableBody}>
                          {row.hotWaterEnergy.toFixed(6)}
                        </Td>
                        <Td style={tableBody}>
                          {row.buildingInstallation.toFixed(6)}
                        </Td>
                        <Td style={tableBody}>{row.estateVolume}</Td>
                        <Td style={tableBody}>
                          <button
                            style={{
                              border: "none",
                              background: "transparent",
                            }}
                            onClick={() => openModal(row.id)}
                          >
                            <i>
                              <svg
                                width="24"
                                height="16"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M15 8c0 1.654-1.346 3-3 3S9 9.654 9 8s1.346-3 3-3 3 1.346 3 3zm9-.449S19.748 16 12.015 16C4.835 16 0 7.551 0 7.551S4.446 0 12.015 0C19.709 0 24 7.551 24 7.551zM17 8c0-2.757-2.243-5-5-5S7 5.243 7 8s2.243 5 5 5 5-2.243 5-5z"
                                  fill="#4A4D46"
                                  fillRule="nonzero"
                                />
                              </svg>
                            </i>
                          </button>
                        </Td>
                      </Tr>
                    ))
                  )}
                </Tbody>
              </Table>
            </Row>
            <Row className="justify-content-center">
              <Pagination
                updatePage={updatePage}
                page={state.page}
                totalCount={state.totalCount}
                pageSize={state.pageSize}
              />
            </Row>
          </AvForm>
          <ModalEstateReportsDetails
            isModalOpen={state.showModal}
            closeModal={closeModal}
            estateHistoryId={state.modalEstateId}
          />
        </Container>
      </div>
    </>
  );
};

MonthlyDetailedCalculations.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MonthlyDetailedCalculations));

const dotenv = require("dotenv");

const BASE_API_URL = "https://api.heatcontrol.io/";

export const NOMENCLATURE_ENDPOINT = BASE_API_URL + "api/nomenclature";

// PDF
export const PDF_USED_ENERGY =
  BASE_API_URL + "api/template/ReportUsedHeatingEnergy";

// api

export const API = BASE_API_URL + "api";

// Building
export const BUILDING = BASE_API_URL + "api/buildingUnits/Building";

export const BUILDING_UNITS_ENDPOINT = BASE_API_URL + "api/buildingUnits";
export const BUILDING_UNITS_REPORT_ENTITIES_ENDPOINT =
  BASE_API_URL + "api/buildingUnits/reportEntities";
export const BUILDING_UNITS_ADDITIONAL_ENDPOINT =
  BASE_API_URL + "api/buildingUnits/additional";
export const REPORTS_ENDPOINT = BASE_API_URL + "api/reports";
export const REPORT_DEVICE_MAP = BASE_API_URL + "api/reports/ReportDeviceMap";
//// Zone
export const ZONE = BASE_API_URL + "api/buildingUnits/Zone";

//// SharedPart
export const SHAREDPART = BASE_API_URL + "api/buildingUnits/SharedPart";

// Login
export const LOGIN = BASE_API_URL + "api/Login/LoginUser";

//User
export const USER = BASE_API_URL + "api/users/User";

// Heating Area

export const HEATING_AREA_TOGGLE =
  BASE_API_URL + "api/nomenclature/HeatingArea/ToggleActive";
export const HEATING_AREA_GET_ALL =
  BASE_API_URL + "api/nomenclature/HeatingArea/GetAll";
export const HEATING_AREA_GET_BY_ID =
  BASE_API_URL + "api/nomenclature/HeatingArea/GetById";

/// TPF
export const TPF = BASE_API_URL + "api/TPF";
/// TEC
export const TEC = BASE_API_URL + "api/TEC";

/// Export
export const EXPORT = BASE_API_URL + "api/Export";
export const EXPORT_TEST = BASE_API_URL + "api/ExportTest";

//Walk-By
export const WALKBY = BASE_API_URL + "api/WalkBy";

//Calculation
export const CALCULATION_CONFIGURATION =
  BASE_API_URL + "api/CalculationConfiguration";

export const CALCULATIONS = BASE_API_URL + "api/Calculations";

//HistoryUploads
export const HISTORY_UPLOADS = BASE_API_URL + "api/HistoryUploadedFiles";

//Version
export const VERSION = BASE_API_URL + "api/Version";

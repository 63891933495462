import { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import FilterBar from "components/Common/FilteringBar";
import BackButton from "../../components/Common/BackButton.js";
import TableListComponent from "../../components/Common/TableListComponent";
import ModalZones from "components/Modals/ModalZone";

const Zone = (props) => {
  const [state, setState] = useState({
    data: [
      {
        name: "Вход А",
        id: "zone-a",
        isActive: true,
      },
      {
        name: "Вход Б",
        id: "zone-b",
        isActive: true,
      },
      {
        name: "Вход В",
        id: "zone-c",
        isActive: true,
      },
    ],
    heaters: [],
    isModalOpen: false,
    isLoading: false,
    isLoaded: false,
    isActive: true,
  });

  const emitErrors = (error) => {
    props.onErrorsShow(error.body);
  };

  const toggleActive = (id) => {
    const state = state.heaters;

    const index = state.findIndex((item) => item.id === id);
    state[index].isActive = !state[index].isActive;

    setState((state) => ({ ...state, heaters: state }));
  };

  const deleteItem = (id) => {
    if (window.confirm("Delete selected?")) {
      const state = state.heaters;
      const index = state.findIndex((item) => item.id === id);

      state.splice(index, 1);
      setState((state) => ({ ...state, heaters: state }));
    }
  };

  const openModal = () => {
    setState((state) => ({ ...state, isModalOpen: !state.isModalOpen }));
    removeBodyCss();
  };

  const closeModal = () => {
    setState((state) => ({ ...state, isModalOpen: false }));
    removeBodyCss();
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };

  const handleDataChange = (event) => {
    const value = event.target.value;
    setState((state) => ({
      ...state,
      [event.target.name]: value,
    }));
  };

  const handleNewItemAdd = (item) => {
    const state = state.heaters;
    state.push(item);

    setState((state) => ({ ...state, heaters: state, isModalOpen: false }));
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl="12">
              <BackButton className="mb-4" />

              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <CardTitle className="mb-4">{state.pageTitle}</CardTitle>
                    </Col>
                  </Row>

                  <div className="d-flex align-items-start mb-4">
                    <div className="flex-grow-1">
                      <FilterBar
                        tableData={state.data}
                        isActive
                        state={state}
                        setState={setState}
                      ></FilterBar>
                    </div>

                    <button
                      type="button"
                      onClick={(e) => openModal(e)}
                      data-toggle="modal"
                      className="btn btn-primary waves-effect waves-light"
                    >
                      Добави нова зона
                    </button>
                  </div>

                  <TableListComponent
                    tableData={state.data}
                    tableColumns={["Име"]}
                    toggleActive={toggleActive}
                    deleteItem={deleteItem}
                    category={"zone"}
                    isLoading={state.isLoading}
                    isLoaded={state.isLoaded}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <ModalZones
            isModalOpen={state.isModalOpen}
            closeModal={closeModal}
            addNewItem={handleNewItemAdd}
            emitErrors={emitErrors}
          />
        </Container>
      </div>
    </>
  );
};

Zone.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Zone));

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {Card, CardBody, Table, UncontrolledTooltip} from "reactstrap";
import { str2bool } from "../../helpers/utils.js";
import i18next from "i18next";
import {Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table";
import Loader from "../Common/Loader";
import ListActions from "../Common/ListActions";
import * as endpoints from "../../constants/endpoints";
import {GetData, SaveItem, ToggleItem} from "../../helpers/CRUD";
import IconCheck from "../Common/IconCheck";
import IconX from "../Common/IconX";
import {sendRequest} from "../../helpers/fetch_helper";
import {Tooltip} from "@material-ui/core";
import TableReports from "../Common/TableReports";
import TableHeatingMonths from "../Common/TableHeatingMonths";

const TableHeatingSeasons = (props) => {
  const [state, setState] = useState({editMode: []});
  const [heatingSeasonMonths, setHeatingSeasonMonths] = useState([]);

  const handleDataChange = (event) => {
    const value = event.target.value;

    setState((state) => ({
      ...state,
      [event.target.name]: value,
    }));

    props.data[event.target.name] = value;
  };

  const handleEdit = () => {
    setState((state) => ({ ...state, mode: "edit" }));
  };

  const handleSave = () => {
    setState((state) => ({ ...state, mode: "view" }));
  };

  const toggleActive = (e) => {
    setState((state) => ({ ...state, isActive: str2bool(e.target.value) }));

    props.data.isActive = str2bool(e.target.value);
  };

  const toggleIsLocked = (e) => {
    setState((state) => ({ ...state, isLocked: str2bool(e.target.value) }));

    props.data.isLocked = str2bool(e.target.value);
  };

  useEffect(() => {
    setState((state) => ({ ...state, ...props.data }));
  }, []);

  useEffect(() => {
    if(props.data.id !== undefined) {
      getMonths(props.data.id);
    }
  }, [props.data.id]);

  const radioStyle = {
    listStyle: "none",
    paddingLeft: "0",
  };
  const labelStyle = {
    paddingLeft: "20px",
  };

  const tableStyle = {
    tableLayout: "fixed",
  };

  const tableHead = {
    backgroundColor: "#f8f9fa",
    borderColor: "#eff2f7",
  };

  const tableData = {
    color: "black",
  };

  const tableDataInactive = {
    color: "darkgray",
  };

  const iconSize = {
    fontSize: "22px",
  };

  const emitErrors = (error) => {
    props.emitErrors(error);
  };

  const emitSuccess = (success) => {
    props.emitSuccess(success);
  };


  const emitRowSave = (row, heatingSeasonId) => {
    const url = `${endpoints.NOMENCLATURE_ENDPOINT}/HeatingMonth/Update?id=${row.data.id}`;

    let patch = [
      {op: "replace", path: "/priceManual", value: `${row.data.priceManual}`},
      {op: "replace", path: "/heatingSeasonId", value: `${heatingSeasonId}`},
    ];

    SaveItem(
        url,
        patch,
        (data) => {
          emitSuccess(i18next.t("entry_updated_successfully"));
          getMonths(props.data.id);
        },
        (error) => {
          emitErrors(error.body);
        }
    );
  }

  const getMonths = (heatingSeasonId) => {
    const url = `${endpoints.NOMENCLATURE_ENDPOINT}/HeatingMonth/GetAllByHeatingSeasonId?id=${heatingSeasonId}`;

    GetData(
        url,
        (data) => {
          setState((state) => ({
            ...state,
            heatingSeasonsMonths: data,
            heatingSeasonsMonthsLoaded: true
          }));
        },
        (error) => {
          props.onErrorsShow(error.body);
        }
    );
  };

  const editHeatingMonthItem = (id, isLocked) => {

    const url = `${endpoints.NOMENCLATURE_ENDPOINT}/HeatingMonth/ToggleLocked?id=${id}&isLocked=${isLocked}`;

    ToggleItem(
        url,
        () => {
          emitSuccess(i18next.t("entry_updated_successfully"));
          getMonths(props.data.id);
        },
        (error) => {
          //todo modal error
        }
    );

  };

  const { id, name, fromYear, toYear, isLocked } = props.data;

  let tableBodyDataHeatingSeasonsMonths = [];
  if (state.heatingSeasonsMonthsLoaded) {
    tableBodyDataHeatingSeasonsMonths = state.heatingSeasonsMonths
        .map((item) => {
          return {
            id: item.id,
            editMode: true,
            data: {
              id: item.id,
              name: i18next.t(`${item.name.toLowerCase()}`),
              year: item.year,
              price: item.price,
              priceManual: item.priceManual,
              isLocked: item.isLocked ? <IconCheck/> : <IconX/>,
              shouldLock: !item.isLocked,
              isActive: !item.isLocked
            }
          }
        });
  }

  return (
    <>
      <div className="table-responsive">
        <Table className="table-nowrap mb-0" style={tableStyle}>
          <tbody>
            <tr>
              <th>{i18next.t("name")}</th>

              <td>
                {props.mode === "edit" ? (
                  <input
                    name="name"
                    placeholder={i18next.t("placeholder_enter_name")}
                    id="heating_season_name"
                    onChange={(e) => handleDataChange(e)}
                    defaultValue={name}
                    className="w-100 input-styled"
                  />
                ) : (
                  name
                )}
              </td>
            </tr>

            <tr>
              <th>{i18next.t("fromYear")}</th>

              <td>
                {props.mode === "edit" ? (
                  <input
                    type="number"
                    min={1990}
                    max={2099}
                    name="fromYear"
                    placeholder={i18next.t("placeholder_enter_year")}
                    id="heating_season_from_year"
                    onChange={(e) => handleDataChange(e)}
                    defaultValue={fromYear}
                    className="w-100 input-styled"
                  />
                ) : (
                  fromYear
                )}
              </td>
            </tr>

            <tr>
              <th>{i18next.t("toYear")}</th>

              <td>
                {props.mode === "edit" ? (
                  <input
                    type="number"
                    name="toYear"
                    min={1990}
                    max={2099}
                    placeholder={i18next.t("placeholder_enter_year")}
                    id="heating_season_to_year"
                    onChange={(e) => handleDataChange(e)}
                    defaultValue={toYear}
                    className="w-100 input-styled"
                  />
                ) : (
                  toYear
                )}
              </td>
            </tr>

            <tr>
              <th>{i18next.t("locked")}</th>

              <td>
                {props.mode === "edit" ? (
                  <div>
                    <div className="radio">
                      <label>
                        <input
                          type="radio"
                          value="true"
                          checked={isLocked === true}
                          onChange={(e) => toggleIsLocked(e)}
                        />
                        {i18next.t("yes")}
                      </label>
                    </div>

                    <div className="radio">
                      <label>
                        <input
                          type="radio"
                          value="false"
                          checked={isLocked === false}
                          onChange={(e) => toggleIsLocked(e)}
                        />
                        {i18next.t("no")}
                      </label>
                    </div>
                  </div>
                ) : (
                  [
                    isLocked === true ? (
                      <span key={`${id}-0`}>{i18next.t("yes")}</span>
                    ) : (
                      <span key={`${id}-1`}>{i18next.t("no")}</span>
                    ),
                  ]
                )}
              </td>
            </tr>
          </tbody>
        </Table>
        <br />

        {tableBodyDataHeatingSeasonsMonths && (
            <TableHeatingMonths
                dataHead={[
                  i18next.t("month"),
                  i18next.t("year"),
                  i18next.t("price"),
                  i18next.t("price_manual"),
                  i18next.t("locked"),
                  i18next.t("actions")
                ]}

                dataBody={tableBodyDataHeatingSeasonsMonths}
                isEditable={true}
                editableCell={"priceManual"}
                url_path={""}
                category={""}
                emitRowSave={emitRowSave}
                heatingSeasonId={id}
                enableLinkLock={true}
                editItem={editHeatingMonthItem}
                isLoaded={true}
            />
        )}
      </div>
    </>
  );
};

TableHeatingSeasons.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
};

const mapStateToProps = (state) => ({
  //userProfile: contacts.userProfile
});

const mapDispatchToProps = (dispatch) => ({
  // onGetUserProfile: () => dispatch(getUserProfile())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TableHeatingSeasons));

import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, useParams } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import TableHeatingSeasons from "../../components/TableDetailed/TableHeatingSeasons.js";
import BackButton from "../../components/Common/BackButton.js";
import NomenclatureSingleWrapper from "../../components/Common/NomenclatureSingleWrapper.js";
import * as endpoints from "constants/endpoints";
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table";
import i18next from "i18next";
import Loader from "../../components/Common/Loader";

const HeatingSeasonSingle = (props) => {
  const [pageTitle, setPageTitle] = useState("Отоплителен сезон");
  const [mode, setMode] = useState("view");
  const [document, setDocument] = useState({});
  const [loggedUserType, setLoggedUserType] = useState("");

  const { id } = useParams();

  const emitErrors = (error) => {
    props.onErrorsShow(error.body);
  };

  const emitSuccess = (success) => {
    props.onSuccessShow(success);
  };

  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    setLoggedUserType(obj.role);
  }, []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl="12">
              <BackButton className="mb-4" />

              <Card>
                <CardBody>
                  <NomenclatureSingleWrapper
                      disableToggleActive={true}
                    childComponent={
                      <TableHeatingSeasons
                        data={document}
                        mode={mode}
                        emitErrors={emitErrors}
                        emitSuccess={emitSuccess}
                      ></TableHeatingSeasons>
                    }
                    getUrl={`${endpoints.NOMENCLATURE_ENDPOINT}/HeatingSeason/GetById?id=${id}`}
                    saveUrl={`${endpoints.NOMENCLATURE_ENDPOINT}/HeatingSeason/Update?id=${id}`}
                    // toggleUrl={`${endpoints.NOMENCLATURE_ENDPOINT}/HeatingSeason/ToggleActive?id=${id}`}
                    deleteUrl={`${endpoints.NOMENCLATURE_ENDPOINT}/HeatingSeason/Delete?id=${id}`}
                    page_title={pageTitle}
                    emitErrors={emitErrors}
                    emitSuccess={emitSuccess}
                  ></NomenclatureSingleWrapper>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

HeatingSeasonSingle.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HeatingSeasonSingle));

import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  withRouter,
  useHistory,
  useParams,
  useLocation,
} from "react-router-dom";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { FormGroup, Label } from "reactstrap";
import { cloneDeep } from "lodash";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import classnames from "classnames";
import BackButton from "../../components/Common/BackButton.js";
import TableComponent from "../../components/Common/TableComponent";
import ModalEstate from "components/Modals/ModalEstate";
import ModalBuildingContact from "components/Modals/ModalBuildingContact";
import ModalBuildingComment from "components/Modals/ModalBuildingComment";
import ModalZone from "components/Modals/ModalZone";
import ModalReport from "components/Modals/ModalReport";
import ConfirmBox from "../../components/Common/ConfirmBox.js";
import ListActions from "../../components/Common/ListActions.js";
import IconCheck from "components/Common/IconCheck";
import IconX from "components/Common/IconX";
import * as endpoints from "constants/endpoints";
import { currentYear, currentMonth  } from "../../helpers/utils";
import {
  building_device_types,
  water_report_frequency,
} from "helpers/listsHardcodedData";
import { Tooltip } from "@material-ui/core";
import * as jsonpatch from "fast-json-patch";
import {
  GetData,
  SaveItem,
  DeleteItem,
  ToggleItem,
  CreateItem,
} from "../../helpers/CRUD.js";
import { str2bool } from "../../helpers/utils.js";
import i18next from "i18next";

const BuildingSingle = (props) => {
  const old_state = {
    pageTitle: "Сграда",
    mode: "view",
    contacts: [],
    building: null,
    heating_areas: null,
    heating_area: null,
    zones: null,
    estates: null,
    id: "",
    activeTab: "1",
    tabsNav: [
      { index: "1", id: "tab-nav-1", label: i18next.t("tab-estates") },
      { index: "2", id: "tab-nav-2", label: i18next.t("tab-zones") },
      { index: "3", id: "tab-nav-3", label: i18next.t("tab-contacts") },
      { index: "4", id: "tab-nav-4", label: i18next.t("tab-documents") },
      {
        index: "5",
        id: "tab-nav-5",
        label: i18next.t("tab-all-reconciliation-bills"),
      },
      { index: "6", id: "tab-nav-6", label: i18next.t("tab-comments") },
    ],
    comments: "",
    documents: [],
    files_new: [],
    files: [],
    isActive: true,
    report_period: "",
    report_periods: null,
    isModalReportOpen: false,
    heating_season: null,
    heating_month: null,
    heatingMonthLoaded: false,
    contracts: [],
    isLoading: false,
    isLoaded: false,
  };
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();

  const [state, setState] = useState({
    ...old_state,
    document: {
      coldMeter: null,
      address: {
        city: "",
        streetName: "",
        streetCode: "",
        buildingNumber: "",
        buildingSubNumber: "",
      },
      sofiaWaterReport: {
        id: "",
        installation: "",
        reportFrequency: null,
        isActive: false,
      },
      toploficationReport: {
        reportDeviceType: "",
        //toploficationReportType: "",
        reportPeriodSofiaWaterId: null,
        percentBuildingInstallation: "",
        hotWaterSubmitMonthPeriod: null,
      },
      gasMeter: {
        isActive: false,
      },
      externalId: null,
      heatingAreaId: null,
      number: "",
      signedBy: "",
      totalHeatingVolume: 0,
      buAddElectricityId: "",
    },
  });

  const emitErrors = (error) => {
    props.onErrorsShow(error.body);
  };

  const emitSuccess = (success) => {
    props.onSuccessShow(success);
  };

  const onBuildingCommentDelete = (id) => {
    const url = `${endpoints.BUILDING_UNITS_ADDITIONAL_ENDPOINT}/BuildingComment/Delete?id=${id}`;

    DeleteItem(
      url,
      () => {
        props.onSuccessShow(i18next.t("entry_deleted_successfully"));
        getAllComments();
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  const onFileChange = (event) => {
    const files = event.target.files;
    setState((state) => ({ ...state, files_new: files }));
  };

  const uploadFiles = () => {
    const files = state.files_new;
    const filesArray = Array.from(files);

    for (let i = 0; i < filesArray.length; i++) {
      uploadFile(filesArray[i]);
    }
  };

  const uploadFile = (file) => {
    let patch = [];

    if (id) {
      patch.push({
        op: "add",
        path: "/buildingId",
        value: id,
      });
    }

    if (file) {
      console.log(file);

      patch.push(
        {
          op: "add",
          path: "/path",
          value: "path",
        },
        {
          op: "add",
          path: "/name",
          value: file.name,
        },
        {
          op: "add",
          path: "/estateId",
          value: null,
        }
      );
    }

    const url = `${endpoints.BUILDING_UNITS_ADDITIONAL_ENDPOINT}/Document/Add`;

    CreateItem(
      url,
      patch,
      (data) => {
        getAllDocuments();
        emitSuccess(i18next.t("file_uploaded_successfully"));
      },
      (error) => {
        emitErrors(error);
      }
    );
  };

  const openModal = (id) => {
    setState((state) => ({ ...state, showModal: id }));
  };

  const closeModal = () => {
    setState((state) => ({ ...state, showModal: null }));
    removeBodyCss();
  };

  const closeAndUpdate = (modalCallback) => {
    setState((state) => ({ ...state, showModal: null }));
    modalCallback();
    removeBodyCss();
  };

  const handleEdit = () => {
    setState((state) => ({ ...state, mode: "edit" }));
  };

  const handleSave = () => {
    if(state.document.toploficationReport.percentBuildingInstallation === "")
    {
      state.document.toploficationReport.percentBuildingInstallation = null;
    }

    let patch = jsonpatch.compare(state.initialDocument, state.document);

    console.log(patch);

    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/Building/Update?id=${id}`;

    SaveItem(
      url,
      patch,
      (data) => {
        emitSuccess(i18next.t("entry_updated_successfully"));
        setState((state) => ({ ...state, mode: "view" }));
      },
      (error) => {
        emitErrors(error);
      }
    );
  };

  const handleDelete = () => {
    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/Building/Delete?id=${id}`;

    DeleteItem(url, history.goBack, (error) => {
      props.onErrorsShow(error.body);
    });
  };

  const toggleActive = () => {
    let old_state = state.document;
    old_state.isActive = !state.document.isActive;

    setState((state) => ({ ...state, document: old_state }));
    handleToggleStatusPost();
  };

  const handleToggleStatusPost = () => {
    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/Building/ToggleActive?id=${id}&isActive=${state.document.isActive}`;

    ToggleItem(
      url,
      () => {
        state.document.isActive
          ? props.onSuccessShow(i18next.t("entry_activated_successfully"))
          : props.onSuccessShow(i18next.t("entry_deactivated_successfully"));
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  const cancelConfirmBox = () => {
    setState((state) => ({
      ...state,
      confirm_delete: false,
      confirm_toggle: false,
      confirm_save: false,
    }));
  };

  const confirmDelete = () => {
    setState((state) => ({
      ...state,
      confirm_delete: false,
      success_dlg: true,
    }));
  };

  const confirmToggle = () => {
    setState((state) => ({
      ...state,
      confirm_toggle: false,
      success_dlg: true,
    }));
  };

  const confirmSave = () => {
    setState((state) => ({
      ...state,
      confirm_save: false,
      success_dlg: true,
      mode: "view",
    }));
  };

  const closeAfterConfirmDelete = () => {
    setState((state) => ({ ...state, success_dlg: false }));
    handleDelete();
  };

  const closeAfterConfirmToggle = () => {
    setState((state) => ({ ...state, success_dlg: false }));
    toggleActive();
  };

  const closeAfterConfirmSave = () => {
    setState((state) => ({ ...state, success_dlg: false }));
    handleSave();
  };

  const openModalReport = () => {
    setState((state) => ({
      ...state,
      isModalReportOpen: !state.isModalReportOpen,
    }));
    removeBodyCss();
  };

  const closeModalReport = () => {
    setState((state) => ({ ...state, isModalReportOpen: false }));
    removeBodyCss();
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };

  const getBuildingById = (id) => {
    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/Building/GetById?id=${id}`;

    GetData(
      url,
      (data) => {
        setState((state) => ({
          ...state,
          document: { ...data },
          initialDocument: cloneDeep(data),
          buildingIsLoaded: true,
        }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const fetchCallback = () => {
    getAllHeatingAreas();
    getAllEstates();
    getAllZones();
    getAllContacts();
    getAllComments();
    getAllDocuments();
    //getSWReportPeriods();
    getAllComments();
  };

  const getAllHeatingAreas = () => {
    const url = `${endpoints.HEATING_AREA_GET_ALL}`;

    GetData(
      url,
      (data) => {
        setState((state) => ({ ...state, heating_areas: data.items }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getAllZones = () => {
    const url = `${endpoints.ZONE}/GetAllByBuildingId?id=${id}`;

    GetData(
      url,
      (data) => {
        setState((state) => ({ ...state, zones: data, zonesLoaded: true }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getAllZonesInactive = () => {
    const url = `${endpoints.ZONE}/GetAllByBuildingId?id=${id}&isActive=false`;

    GetData(
      url,
      (data) => {
        let old_data = state.zones;
        old_data.push(...data);

        setState((state) => ({ ...state, zones: old_data }));
      },
      (error) => {
        console.log(error);
      }
    );
  };


  const getWorkingHeatingSeason = () => {
    const url = `${endpoints.NOMENCLATURE_ENDPOINT}/HeatingSeason/GetWorkingHeatingSeason`;

    GetData(
        url,
        (data) => {
          setState((state) => ({ ...state, heating_season: data}));
        },
        (error) => console.log(error)
    );
  };

  const getWorkingHeatingMonth = () => {
    const url = `${endpoints.NOMENCLATURE_ENDPOINT}/HeatingMonth/GetWorkingHeatingMonth`;

    GetData(
        url,
        (data) => {
          setState((state) => ({ ...state, heating_month: data, heatingMonthLoaded: true}));
        },
        (error) => console.log(error)
    );
  };

  useEffect(() => {
    state.zonesLoaded && getAllZonesInactive();
  }, [state.zonesLoaded]);

  useEffect(() => {
    fetchCallback();
  }, [state.heatingMonthLoaded, state.buildingIsLoaded]);


  const getAllEstates = () => {
    if(state.heatingMonthLoaded) {
      const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/Estate/GetAllByBuildingId?id=${id}&isYearReport=${state.document.toploficationReport && state.document.toploficationReport.toploficationReportType === 'PER_YEAR'}&year=${state.heating_month && state.heating_month.year}&month=${state.heating_month && state.heating_month.month}`;

      GetData(
          url,
          (data) => {
            setState((state) => ({
              ...state,
              estates: data,
              estatesLoaded: true,
              isLoaded: true,
            }));
          },
          (error) => {
            emitErrors(error);
          }
      );
    }
  };

  const getAllEstatesInactive = () => {
    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/Estate/GetAllByBuildingId?id=${id}&isYearReport=${state.document.toploficationReport && state.document.toploficationReport.toploficationReportType === 'PER_YEAR'}&year=${state.heating_month && state.heating_month.year}&month=${state.heating_month && state.heating_month.month}&isActive=false`;

    GetData(
      url,
      (data) => {
        let old_data = state.estates;
        old_data.push(...data);

        setState((state) => ({ ...state, estates: old_data }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    state.estatesLoaded && getAllEstatesInactive();
  }, [state.estatesLoaded]);

  const getAllDocuments = () => {
    const url = `${endpoints.BUILDING_UNITS_ADDITIONAL_ENDPOINT}/Document/GetAllByBuildingId?id=${id}`;

    GetData(
      url,
      (data) => {
        setState((state) => ({
          ...state,
          documents: data,
          documentsLoaded: true,
        }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getAllDocumentsInactive = () => {
    const url = `${endpoints.BUILDING_UNITS_ADDITIONAL_ENDPOINT}/Document/GetAllByBuildingId?id=${id}&isActive=false`;

    GetData(
      url,
      (data) => {
        let old_data = state.documents;
        old_data.push(...data);

        setState((state) => ({ ...state, documents: old_data }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    state.documentsLoaded && getAllDocumentsInactive();
  }, [state.documentsLoaded]);

  const getAllContacts = () => {
    const url = `${endpoints.BUILDING_UNITS_ADDITIONAL_ENDPOINT}/BuildingContact/GetAllByBuildingId?id=${id}`;

    GetData(
      url,
      (data) => {
        setState((state) => ({
          ...state,
          contacts: data,
          contactsLoaded: true,
        }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getAllContactsInactive = () => {
    const url = `${endpoints.BUILDING_UNITS_ADDITIONAL_ENDPOINT}/BuildingContact/GetAllByBuildingId?id=${id}&isActive=false`;

    GetData(
      url,
      (data) => {
        let old_data = state.contacts;
        old_data.push(...data);

        setState((state) => ({ ...state, contacts: old_data }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    state.contactsLoaded && getAllContactsInactive();
  }, [state.contactsLoaded]);

  const getAllComments = () => {
    const url = `${endpoints.BUILDING_UNITS_ADDITIONAL_ENDPOINT}/BuildingComment/GetAllByBuildingId?id=${id}`;

    GetData(
      url,
      (data) => {
        setState((state) => ({
          ...state,
          comments: data,
          commentsLoaded: true,
        }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getAllCommentsInactive = () => {
    const url = `${endpoints.BUILDING_UNITS_ADDITIONAL_ENDPOINT}/BuildingComment/GetAllByBuildingId?id=${id}&isActive=false`;

    GetData(
      url,
      (data) => {
        let old_data = state.comments;
        old_data.push(...data);

        setState((state) => ({ ...state, comments: old_data }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    state.commentsLoaded && getAllCommentsInactive();
  }, [state.commentsLoaded]);

  const toggleTabs = (tab) => {
    if (state.activeTab !== tab) {
      setState((state) => ({ ...state, activeTab: tab }));
    }
  };

  useEffect(() => {
    setState((state) => ({ ...state, isLoading: true }));
    getWorkingHeatingSeason();
    getWorkingHeatingMonth();
    getBuildingById(id);
  }, []);

  const radioStyle = {
    listStyle: "none",
    paddingLeft: "0",
  };
  const labelStyle = {
    paddingLeft: "20px",
  };

  const tableStyle = {
    tableLayout: "fixed",
  };

  let currentLocation = location.pathname;

  if (currentLocation.charAt(0) === "/") {
    currentLocation = currentLocation.substring(1);
  }

  let tableBodyDataEstates = [];
  if (state.estatesLoaded) {
    tableBodyDataEstates = state.estates.map((item) => {
      return {
        id: item.id,
        data: {
          name: item.name,
          tec_number: item.externalId ? item.externalId : "",
          sw_number: item.sofiaWaterData
            ? item.sofiaWaterData.subscriberNumber &&
              item.sofiaWaterData.subscriberNumber === "empty" &&
              "-"
            : "",
          number_tk: item.number ? item.number : "",
          volume:
            item.toploficationData && item.toploficationData.length > 0
              ? item.toploficationData[item.toploficationData.length - 1]
                  .estateVolume
              : "",
          expected_hot_water:
            item.toploficationData && item.toploficationData.length > 0
              ? item.toploficationData[item.toploficationData.length - 1]
                  .estimatedHotWaterPerMonth
              : "",
          expected_heating:
            item.toploficationData && item.toploficationData.length > 0
              ? item.toploficationData[item.toploficationData.length - 1]
                  .estimatedHeatingPerDay
              : "",
          hasAllReports: item.isReported ? (
              <IconCheck />
          ) : (
              <IconX />
          ),
          isActive: item.isActive,
        },
      };
    });
  }

  let tableBodyDataZones = [];
  if (state.zonesLoaded) {
    tableBodyDataZones = state.zones.map((item) => {
      return {
        id: item.id,
        data: {
          name: item.name ? item.name : "",
          subscriber_station: item.subscriberStation
            ? item.subscriberStation.number
            : "",
          volume: item.volume ? item.volume : "",
          isActive: item.isActive,
        },
      };
    });
  }

  let tableBodyDataContacts = [];
  if (state.contactsLoaded) {
    tableBodyDataContacts = state.contacts.map((item) => {
      return {
        id: item.id,
        data: {
          name: item.name ? item.name : "",
          phoneNumber: item.phoneContact ? item.phoneContact : "",
          email: item.email ? item.email : "",
          position: item.position ? item.position : "-",
          professional_company: item.professionalHouseManagementCompany
            ? item.professionalHouseManagementCompany.name
            : "-",
          isActive: item.isActive,
        },
      };
    });
  }

  let tableBodyDataContract = [];
  tableBodyDataContract = state.contracts.map((item) => {
    return {
      id: item.id,
      data: {
        number: item.number,
        date_from: item.date_from,
        date_to: item.date_to,
        isActive: item.isActive,
      },
    };
  });

  let tableBodyDataDocuments = [];
  if (state.documentsLoaded) {
    tableBodyDataDocuments = state.documents.map((item) => {
      return {
        id: item.id,
        data: {
          name: item.name,
          date_updated: item.dateUpdated,
          isActive: item.isActive,
        },
      };
    });
  }

  let tableBodyDataComments = [];
  if (state.commentsLoaded) {
    tableBodyDataComments = state.comments.map((item) => {
      return {
        id: item.id,
        data: {
          comment: item.comment ? item.comment : "",
          date_updated: item.dateUpdated ? item.dateUpdated : "",
          isActive: item.isActive,
        },
      };
    });
  }

  let tableBodyDataReconciliationBills = [];

  const water_report_frequency_list = water_report_frequency();
  const building_device_types_list = building_device_types();

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl="12">
              <BackButton className="mb-4" />
            </Col>

            <Col xl="12">
              <Card>
                <CardBody className="mb-0 pb-0">
                  <Row>
                    <Col>
                      <CardTitle className="mb-4">{state.pageTitle}</CardTitle>
                    </Col>

                    <Col sm="3" className="text-right">
                      <Row>
                        <Col
                          xs="12"
                          className="d-flex align-items-center justify-content-end"
                        >
                          <div className="mr-2">
                            <Tooltip
                              title={i18next.t("tootltip-report-add")}
                              placement="right-start"
                            >
                              <button
                                className="btn btn-primary waves-effect waves-light"
                                onClick={(id) => openModal("report")}
                              >
                                <i
                                  className="bx bx-plus-circle"
                                  style={{
                                    fontSize: "18px",
                                  }}
                                />
                              </button>
                            </Tooltip>
                          </div>

                          <ListActions
                            link={`/${id}`}
                            data={state.document}
                            mode={state.mode}
                            toggleSave={() => {
                              setState((state) => ({
                                ...state,
                                confirm_save: true,
                                actionType: "save",
                              }));
                            }}
                            toggleEdit={handleEdit}
                            toggle={() =>
                              setState((state) => ({
                                ...state,
                                confirm_toggle: true,
                                actionType: "toggle",
                              }))
                            }
                            delete={() =>
                              setState((state) => ({
                                ...state,
                                confirm_delete: true,
                                actionType: "delete",
                              }))
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col md="12">
              <AvForm className="needs-validation">
                <Row className="d-flex">
                  <Col xl="6" className="d-flex">
                    <Card className="w-100">
                      <CardBody>
                        <Row>
                          <Col md="12">
                            <h5>{i18next.t("address")}</h5>
                          </Col>

                          <Col md="6">
                            <FormGroup>
                              <Label htmlFor="building_address_street_name">
                                {i18next.t("label-street")}
                              </Label>
                              <Row>
                                <Col md="12">
                                  <AvField
                                    name="document.address.streetName"
                                    placeholder={i18next.t(
                                      "label-single-street"
                                    )}
                                    type="text"
                                    className="form-control"
                                    id="building_address_street_name"
                                    value={
                                      state.document.address &&
                                      state.document.address.streetName
                                    }
                                    onChange={(e) => {
                                      setState((state) => ({
                                        ...state,
                                        document: {
                                          ...state.document,
                                          address: {
                                            ...state.document.address,
                                            streetName: e.target.value,
                                          },
                                        },
                                      }));
                                    }}
                                    validate={{
                                      required: {
                                        value: true,
                                      },
                                      maxLength: {
                                        value: 30,
                                      },
                                    }}
                                    {...(state.mode === "view" && {
                                      readOnly: true,
                                    })}
                                  />
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>

                          <Col md="6">
                            <FormGroup>
                              <Label htmlFor="building_address_building_number">
                                {i18next.t("building_number")}
                              </Label>
                              <Row>
                                <Col md="6">
                                  <AvField
                                    name="document.address.buildingNumber"
                                    placeholder={i18next.t("label-number")}
                                    type="text"
                                    className="form-control"
                                    id="building_address_building_number"
                                    value={
                                      state.document.address &&
                                      state.document.address.buildingNumber
                                    }
                                    onChange={(e) => {
                                      setState((state) => ({
                                        ...state,
                                        document: {
                                          ...state.document,
                                          address: {
                                            ...state.document.address,
                                            buildingNumber: e.target.value,
                                          },
                                        },
                                      }));
                                    }}
                                    validate={{
                                      required: {
                                        value: true,
                                      },
                                      maxLength: {
                                        value: 30,
                                      },
                                    }}
                                    {...(state.mode === "view" && {
                                      readOnly: true,
                                    })}
                                  />
                                </Col>
                                <Col md="6">
                                  <AvField
                                    name="document.address.buildingSubNumber"
                                    placeholder={i18next.t("label-subnumber")}
                                    type="text"
                                    className="form-control"
                                    id="building_address_building_sub_number"
                                    value={
                                      state.document.address &&
                                      state.document.address.buildingSubNumber
                                    }
                                    onChange={(e) => {
                                      setState((state) => ({
                                        ...state,
                                        document: {
                                          ...state.document,
                                          address: {
                                            ...state.document.address,
                                            buildingSubNumber: e.target.value,
                                          },
                                        },
                                      }));
                                    }}
                                    validate={{
                                      required: {
                                        value: true,
                                      },
                                      maxLength: {
                                        value: 30,
                                      },
                                    }}
                                    {...(state.mode === "view" && {
                                      readOnly: true,
                                    })}
                                  />
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          {state.heating_areas !== null && (
                            <Col md="6">
                              <FormGroup>
                                <Label
                                  htmlFor="building_heating_area"
                                  className=""
                                >
                                  {i18next.t("heating-area")}
                                </Label>

                                <AvField
                                  type="select"
                                  value={state.document.heatingAreaId}
                                  onChange={(e) => {
                                    setState((state) => ({
                                      ...state,
                                      document: {
                                        ...state.document,
                                        heatingAreaId: e.target.value,
                                      },
                                    }));
                                  }}
                                  name="document.heatingAreaId"
                                  id="building_heating_area"
                                  errorMessage="Select Heating Area"
                                  validate={{
                                    required: {
                                      value: true,
                                    },
                                  }}
                                  {...(state.mode === "view" && {
                                    disabled: true,
                                  })}
                                >
                                  <option
                                    key={`heating-area-default`}
                                    value={null}
                                    selected={
                                      state.document.heatingAreaId === null
                                    }
                                  >
                                    {i18next.t("select_heating_area")}
                                  </option>
                                  {state.heating_areas.map((option) => (
                                    <option
                                      key={`heating-area-${option.id}`}
                                      value={option.id}
                                      selected={
                                        state.document.heatingAreaId ===
                                        option.id
                                      }
                                    >
                                      {option.name}
                                    </option>
                                  ))}
                                </AvField>
                              </FormGroup>
                            </Col>
                          )}

                          <Col md="6">
                            <FormGroup>
                              <Label
                                htmlFor="building_toplofication_report_device_type"
                                className=""
                              >
                                {i18next.t("devicesType")}
                              </Label>

                              <AvField
                                type="select"
                                name="document.toploficationReport.reportDeviceType"
                                id="building_toplofication_report_device_type"
                                errorMessage="Select Device type"
                                value={
                                  state.document.toploficationReport &&
                                  state.document.toploficationReport
                                    .reportDeviceType
                                }
                                onChange={(e) => {
                                  setState((state) => ({
                                    ...state,
                                    document: {
                                      ...state.document,
                                      toploficationReport: {
                                        ...state.document.toploficationReport,
                                        reportDeviceType: e.target.value,
                                      },
                                    },
                                  }));
                                }}
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                }}
                                {...(state.mode === "view" && {
                                  disabled: true,
                                })}
                              >
                                <option
                                  key={`device-type-default`}
                                  value={null}
                                  selected={
                                    state.document.toploficationReport &&
                                    state.document.toploficationReport
                                      .reportDeviceType === null
                                  }
                                >
                                  {i18next.t("select_devices_type")}
                                </option>
                                {building_device_types_list &&
                                  building_device_types_list.length > 0 &&
                                  building_device_types_list.map((option) => (
                                    <option
                                      key={`device-type-${option.key}`}
                                      value={option.key}
                                      selected={
                                        state.document.toploficationReport &&
                                        state.document.toploficationReport
                                          .reportDeviceType === option.key
                                      }
                                    >
                                      {option.value}
                                    </option>
                                  ))}
                              </AvField>
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="building_toplofication_report_percent_building_installation">
                                {i18next.t("building-installation")}
                              </Label>
                              <AvField
                                name="document.toploficationReport.percentBuildingInstallation"
                                placeholder={i18next.t(
                                  "placeholder_enter_percentages"
                                )}
                                type="number"
                                min={20}
                                max={40}
                                errorMessage="Enter "
                                className="form-control"
                                value={
                                  state.document.toploficationReport &&
                                  state.document.toploficationReport
                                    .percentBuildingInstallation
                                }
                                onChange={(e) => {
                                  setState((state) => ({
                                    ...state,
                                    document: {
                                      ...state.document,
                                      toploficationReport: {
                                        ...state.document.toploficationReport,
                                        percentBuildingInstallation:
                                          e.target.value,
                                      },
                                    },
                                  }));
                                }}
                                validate={{
                                  required: {
                                    value: false,
                                  },
                                }}
                                id="building_toplofication_report_percent_building_installation"
                                {...((state.mode === "view" || (state.mode === "edit" && (state.document.toploficationReport
                                    .reportDeviceType === 'MIXED_HEAT_COST_ALLOCATORS' || state.document.toploficationReport
                                    .reportDeviceType === 'HEAT_METERS'))) && {
                                  readOnly: true,
                                })}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label htmlFor="building_total_heating_volume">
                                {i18next.t("zone_volume_total")}
                              </Label>
                              <AvField
                                disabled
                                name="document.totalHeatingVolume"
                                placeholder={i18next.t(
                                  "placeholder_enter_estate_volume"
                                )}
                                type="number"
                                min={0}
                                errorMessage="Enter "
                                className="form-control"
                                value={state.document.totalHeatingVolume}
                                onChange={(e) => {
                                  setState((state) => ({
                                    ...state,
                                    document: {
                                      ...state.document,
                                      totalHeatingVolume: e.target.value,
                                    },
                                  }));
                                }}
                                validate={{
                                  required: {
                                    value: false,
                                  },
                                }}
                                id="building_total_heating_volume"
                                {...(state.mode === "view" && {
                                  readOnly: true,
                                })}
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <h5 className="text-left">
                              {i18next.t("toplofication")}
                            </h5>
                          </Col>
                        </Row>

                        <Row className="mb-0">
                          <Col md="6">
                            <FormGroup className="mb-0">
                              <AvField
                                type="checkbox"
                                name="document.toploficationReport.isActive"
                                label={i18next.t("active")}
                                checked={
                                  state.document.toploficationReport &&
                                  state.document.toploficationReport.isActive
                                }
                                onChange={(e) => {
                                  setState((state) => ({
                                    ...state,
                                    document: {
                                      ...state.document,
                                      toploficationReport: {
                                        ...state.document.toploficationReport,
                                        isActive: !str2bool(e.target.value),
                                      },
                                      isActive: !str2bool(e.target.value),
                                    },
                                  }));
                                }}
                                id="building_toploficationReport_isActive"
                                {...(state.mode === "view" && {
                                  disabled: true,
                                })}
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row className="mb-2">
                          <Col md="6">
                            <FormGroup>
                              <AvField
                                type="checkbox"
                                name="document.toploficationReport.toploficationReportType"
                                label={i18next.t("yearly_frequency")}
                                checked={
                                  state.document.toploficationReport &&
                                  state.document.toploficationReport
                                      .toploficationReportType ===
                                    'PER_YEAR'
                                }
                                onChange={(e) => {
                                  setState((state) => ({
                                    ...state,
                                    document: {
                                      ...state.document,
                                      toploficationReport: {
                                        ...state.document.toploficationReport,
                                        toploficationReportType: !str2bool(
                                          e.target.value
                                        )
                                          ? 'PER_YEAR'
                                          : 'PER_MONTH',
                                      },
                                    },
                                  }));
                                }}
                                id="building_toploficationReportType"
                                {...(state.mode === "view" && {
                                  disabled: true,
                                })}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col xl="6" className="d-flex">
                    <Card className="w-100">
                      <CardBody>
                        <Row>
                          <Col md="6">
                            <h5 className="text-left">
                              {i18next.t("toplofication")}
                            </h5>
                            <Row>
                              <Col md="6">
                                <Label
                                  className="text-center d-block"
                                  htmlFor="validationCustombuAddToploFicationId"
                                >
                                  {i18next.t("label-subscriber-number")}
                                </Label>
                                <AvField
                                  name="document.externalId"
                                  disabled
                                  type="text"
                                  value={state.document.externalId}
                                  className="form-control"
                                  id="validationCustombuAddToploFicationId"
                                  {...(state.mode === "view" && {
                                    readOnly: true,
                                  })}
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col md="6">
                            <h5 className="text-left">
                              {i18next.t("water-supply")}
                            </h5>
                            <Row className="mb-2">
                              <Col md="12">
                                <FormGroup>
                                  <AvField
                                    type="checkbox"
                                    name="document.sofiaWaterReport.isActive"
                                    label={i18next.t("active")}
                                    checked={
                                      state.document.sofiaWaterReport &&
                                      state.document.sofiaWaterReport.isActive
                                    }
                                    onChange={(e) => {
                                      setState((state) => ({
                                        ...state,
                                        document: {
                                          ...state.document,
                                          sofiaWaterReport: {
                                            ...state.document.sofiaWaterReport,
                                            isActive: !str2bool(e.target.value),
                                          },
                                        },
                                      }));
                                    }}
                                    id="building_sofiaWaterReport_isActive"
                                    {...(state.mode === "view" && {
                                      disabled: true,
                                    })}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row className="mb-2">
                              <Col md="12">
                                <FormGroup>
                                  <AvField
                                      type="checkbox"
                                      name="document.toploficationReport.hotWaterSubmitMonthPeriod"
                                      label={i18next.t("yearly_hot_water")}
                                      checked={
                                        state.document.toploficationReport &&
                                        state.document.toploficationReport.isFullYearHotWater === true
                                      }
                                      onChange={(e) => {
                                        e.target.value == "true"
                                            ? setState((state) => ({
                                              ...state,
                                              document: {
                                                ...state.document,
                                                toploficationReport: {
                                                  ...state.document
                                                      .toploficationReport,
                                                  isFullYearHotWater: false,
                                                },
                                              },
                                            }))
                                            : setState((state) => ({
                                              ...state,
                                              document: {
                                                ...state.document,
                                                toploficationReport: {
                                                  ...state.document
                                                      .toploficationReport,
                                                  isFullYearHotWater: true,
                                                },
                                              },
                                            }));
                                      }}
                                      id="building_toplofication_yearly"
                                      {...(state.mode === "view" && {
                                        disabled: true,
                                      })}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row className="mb-2">
                              {water_report_frequency_list !== null && (
                                  <Col md="12">
                                    <FormGroup>
                                      <Label
                                          htmlFor="building_sofia_water_report_frequency"
                                          className=""
                                      >
                                        {i18next.t("report_frequency")}
                                      </Label>

                                      <AvField
                                          type="select"
                                          value={
                                            state.document.sofiaWaterReport &&
                                            state.document.sofiaWaterReport
                                                .reportFrequency
                                          }
                                          onChange={(e) =>
                                              setState((state) => ({
                                                ...state,
                                                document: {
                                                  ...state.document,
                                                  sofiaWaterReport: {
                                                    ...state.document.sofiaWaterReport,
                                                    reportFrequency: e.target.value,
                                                  },
                                                },
                                              }))
                                          }
                                          name="report_period"
                                          id="building_sofia_water_report_frequency"
                                          errorMessage="Select Report period"
                                          validate={{
                                            required: {
                                              value: true,
                                            },
                                          }}
                                          {...(state.mode === "view" && {
                                            disabled: true,
                                          })}
                                      >
                                        <option
                                            key={`report-frequency-default`}
                                            value={null}
                                            selected={
                                              state.document.sofiaWaterReport &&
                                              state.document.sofiaWaterReport
                                                  .reportFrequency === null
                                            }
                                        >
                                          {i18next.t("select_report_frequency")}
                                        </option>

                                        {water_report_frequency_list &&
                                        water_report_frequency_list.length > 0 &&
                                        water_report_frequency_list.map(
                                            (option) => (
                                                <option
                                                    key={`sw-report-frequency-${option.key}`}
                                                    value={option.key}
                                                    selected={
                                                      state.document.sofiaWaterReport &&
                                                      state.document.sofiaWaterReport
                                                          .reportFrequency === option.key
                                                    }
                                                >
                                                  {option.value}
                                                </option>
                                            )
                                        )}
                                      </AvField>
                                    </FormGroup>
                                  </Col>
                              )}
                            </Row>
                          </Col>
                        </Row>

                        <Row>

                        </Row>
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <AvField
                                type="checkbox"
                                name="document.gasMeter.isActive"
                                label={i18next.t("label-gas")}
                                checked={
                                  state.document.gasMeter &&
                                  state.document.gasMeter.isActive
                                }
                                onChange={(e) => {
                                  setState((state) => ({
                                    ...state,
                                    document: {
                                      ...state.document,
                                      gasMeter: {
                                        ...state.document.gasMeter,
                                        isActive: !str2bool(e.target.value),
                                      },
                                    },
                                  }));
                                }}
                                id="building_gazmeter_isActive"
                                {...(state.mode === "view" && {
                                  disabled: true,
                                })}
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <AvField
                                type="checkbox"
                                name="document.coldMeter"
                                label={i18next.t("coldmeter")}
                                checked={
                                  state.document.coldMeter &&
                                  state.document.coldMeter === true
                                }
                                onChange={(e) => {
                                  setState((state) => ({
                                    ...state,
                                    document: {
                                      ...state.document,
                                      coldMeter: !str2bool(e.target.value),
                                    },
                                  }));
                                }}
                                id="building_hasWaterCooling"
                                {...(state.mode === "view" && {
                                  disabled: true,
                                })}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>

                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </AvForm>
            </Col>

            <Col xl="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <Nav pills className="navtab-bg nav-justified">
                        {state.tabsNav.map((item) => (
                          <NavItem key={item.id}>
                            <NavLink
                              style={{
                                cursor: "pointer",
                              }}
                              className={classnames({
                                active: state.activeTab === item.index,
                              })}
                              onClick={() => {
                                toggleTabs(item.index);
                              }}
                            >
                              {item.label}
                            </NavLink>
                          </NavItem>
                        ))}
                      </Nav>

                      <TabContent activeTab={state.activeTab}>
                        <TabPane tabId="1" className="p-3">
                          {/* Имоти */}

                          <Row className="mb-2">
                            <Col>
                              <div className="text-right">
                                <Tooltip
                                  title={i18next.t("toolptip-add-new-estate")}
                                  placement="right-start"
                                >
                                  <button
                                    className="btn btn-primary waves-effect waves-light"
                                    onClick={(id) => openModal("estate")}
                                  >
                                    <i
                                      className="bx bx-plus-circle"
                                      style={{
                                        fontSize: "18px",
                                      }}
                                    />
                                  </button>
                                </Tooltip>
                              </div>
                            </Col>
                          </Row>

                          {tableBodyDataEstates && (
                            <TableComponent
                              dataHead={[
                                i18next.t("estate"),
                                i18next.t("subscriber-number-toplofication"),
                                i18next.t("subscriber-number-water"),
                                i18next.t("subscriber-number-internal"),
                                i18next.t("zone_volume"),
                                i18next.t("water-forecast"),
                                i18next.t("heating-forecast"),
                                  !state.buildingIsLoaded
                                    ? i18next.t("has-all-devices-report")
                                    : state.document.toploficationReport && state.document.toploficationReport.toploficationReportType === 'PER_YEAR'
                                      ? i18next.t("has-all-devices-report-yearly").replace("{season}", state.heating_season && state.heating_season.name)
                                      : i18next.t("has-all-devices-report-monthly").replace("{month}", state.heating_month && state.heating_month.month).replace("{year}", state.heating_month && state.heating_month.year),
                                i18next.t("actions"),
                              ]}
                              dataBody={tableBodyDataEstates}
                              url_path={`${currentLocation}/estate`}
                              category={""}
                              enableLinkView={true}
                              isLoading={state.isLoading}
                              isLoaded={state.isLoaded}
                            />
                          )}
                        </TabPane>
                        <TabPane tabId="2" className="p-3">
                          {/* Зони */}

                          <Row className="mb-2">
                            <Col>
                              <div className="text-right">
                                <Tooltip
                                  title={i18next.t("toolptip-add-new-zone")}
                                  placement="right-start"
                                >
                                  <button
                                    className="btn btn-primary waves-effect waves-light"
                                    onClick={(id) => openModal("zone")}
                                  >
                                    <i
                                      className="bx bx-plus-circle"
                                      style={{
                                        fontSize: "18px",
                                      }}
                                    />
                                  </button>
                                </Tooltip>
                              </div>
                            </Col>
                          </Row>

                          {tableBodyDataZones && (
                            <TableComponent
                              dataHead={[
                                i18next.t("name"),
                                i18next.t("numberAS"),
                                i18next.t("zone_volume"),
                                i18next.t("actions"),
                              ]}
                              dataBody={tableBodyDataZones}
                              url_path={`${currentLocation}/zone`}
                              category={""}
                              enableLinkView={true}
                              isLoaded={true}
                            />
                          )}
                        </TabPane>
                        <TabPane tabId="3" className="p-3">
                          {/* Контакти */}

                          <Row className="mb-2">
                            <Col>
                              <CardTitle className="d-none mb-4">
                                {i18next.t("contacts_table")}
                              </CardTitle>
                            </Col>

                            <Col>
                              <div className="text-right">
                                <Tooltip
                                  title={i18next.t("toolptip-add-new-contact")}
                                  placement="right-start"
                                >
                                  <button
                                    className="btn btn-primary waves-effect waves-light"
                                    onClick={(id) =>
                                      openModal("building-contact")
                                    }
                                  >
                                    <i
                                      className="bx bx-plus-circle"
                                      style={{
                                        fontSize: "18px",
                                      }}
                                    />
                                  </button>
                                </Tooltip>
                              </div>
                            </Col>
                          </Row>

                          {tableBodyDataContacts && (
                            <TableComponent
                              dataHead={[
                                i18next.t("name"),
                                i18next.t("phone"),
                                i18next.t("email"),
                                i18next.t("position"),
                                i18next.t(
                                  "link-professional-house-manager-companies"
                                ),
                                i18next.t("actions"),
                              ]}
                              dataBody={tableBodyDataContacts}
                              url_path={`${currentLocation}/contacts`}
                              category={""}
                              enableLinkView={true}
                              isLoaded={true}
                            />
                          )}
                        </TabPane>

                        <TabPane tabId="4" className="p-3">
                          {/* Документи */}

                          <Row className="mb-2">
                            <Col>
                              <CardTitle className="d-none mb-4">
                                {i18next.t("tab-documents")}
                              </CardTitle>
                            </Col>

                            <Col>
                              <div className="text-right">
                                <input
                                  name="files_new"
                                  placeholder=""
                                  id="field-AS"
                                  type="file"
                                  multiple
                                  accept="
                                    image/jpeg,
                                    image/png,
                                    .pdf,
                                    .doc,
                                    .docx,
                                    "
                                  onChange={onFileChange}
                                  className="w-100"
                                />

                                {state.files_new.length > 0 && (
                                  <button
                                    type="button"
                                    className="btn btn-primary waves-effect waves-light"
                                    onClick={uploadFiles}
                                  >
                                    {i18next.t("upload_files_add_new_file")}
                                  </button>
                                )}
                              </div>
                            </Col>
                          </Row>

                          {tableBodyDataDocuments && (
                            <TableComponent
                              dataHead={[
                                i18next.t("name"),
                                i18next.t("processing_date"),
                                i18next.t("show_document_new_window"),
                              ]}
                              dataBody={tableBodyDataDocuments}
                              url_path={`/building/${id}/documents`}
                              category={""}
                              disableLink={true}
                              actionDownload={true}
                              isLoaded={true}
                            />
                          )}
                        </TabPane>
                        <TabPane tabId="5" className="p-3">
                          {/* Общи Изравнителни сметки */}

                          {tableBodyDataReconciliationBills ? (
                            <TableComponent
                              dataHead={[
                                i18next.t("name"),
                                i18next.t("season"),
                                i18next.t("correction"),
                                i18next.t("actions"),
                              ]}
                              dataBody={tableBodyDataReconciliationBills}
                              /*url_path={`${currentLocation}/heater`}
                                  category={""}
                                  enableLinkView={true}
                                  enableLinkAdd={true}
                                  onModalHCAOpen={onModalHCAOpen}*/
                              isLoaded={true}
                            />
                          ) : null}
                        </TabPane>
                        <TabPane tabId="6" className="p-3">
                          {/* Коментари */}

                          <Row className="mb-2">
                            <Col>
                              <CardTitle className="d-none mb-4">
                                {i18next.t("comment_table")}
                              </CardTitle>
                            </Col>

                            <Col>
                              <div className="text-right">
                                <Tooltip
                                  title={i18next.t("toolptip-add-new-comment")}
                                  placement="right-start"
                                >
                                  <button
                                    className="btn btn-primary waves-effect waves-light"
                                    onClick={(id) =>
                                      openModal("building-comment")
                                    }
                                  >
                                    <i
                                      className="bx bx-plus-circle"
                                      style={{
                                        fontSize: "18px",
                                      }}
                                    />
                                  </button>
                                </Tooltip>
                              </div>
                            </Col>
                          </Row>

                          {tableBodyDataComments && (
                            <TableComponent
                              dataHead={[
                                i18next.t("field_comment"),
                                i18next.t("data"),
                                i18next.t("actions"),
                              ]}
                              dataBody={tableBodyDataComments}
                              url_path={""}
                              category={""}
                              enableLinkDelete={true}
                              onDelete={onBuildingCommentDelete}
                              delete_tooltip={i18next.t(
                                "tooltip-delete-comment"
                              )}
                              isLoaded={true}
                            />
                          )}
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {state.buildingIsLoaded && id && (
            <ModalReport
              isModalOpen={state.showModal === "report"}
              closeModal={closeModal}
              building_id={id}
              building_top={state.document.externalId}
              emitErrors={emitErrors}
            ></ModalReport>
          )}

          {state.buildingIsLoaded && (
            <ModalBuildingContact
              isModalOpen={state.showModal === "building-contact"}
              closeModal={closeModal}
              update={() => closeAndUpdate(getAllContacts)}
              buildingId={id ? id : ""}
              building_top={state.document.externalId}
              emitErrors={emitErrors}
              emitSuccess={emitSuccess}
            ></ModalBuildingContact>
          )}

          {state.buildingIsLoaded && (
            <ModalBuildingComment
              isModalOpen={state.showModal === "building-comment"}
              closeModal={closeModal}
              update={() => closeAndUpdate(getAllComments)}
              buildingId={id ? id : ""}
              building_top={state.document.externalId}
              emitErrors={emitErrors}
              emitSuccess={emitSuccess}
            ></ModalBuildingComment>
          )}
          {state.buildingIsLoaded && (
            <ModalEstate
              isModalOpen={state.showModal === "estate"}
              closeModal={closeModal}
              update={() => closeAndUpdate(getAllEstates)}
              buildingId={id ? id : ""}
              building_top={state.document.externalId}
              addressId={
                state.document.address && state.document.address.id ? state.document.address.id : ""
              }
              emitErrors={emitErrors}
              emitSuccess={emitSuccess}
            ></ModalEstate>
          )}

          {state.buildingIsLoaded && (
            <ModalZone
              isModalOpen={state.showModal === "zone"}
              closeModal={closeModal}
              update={() => closeAndUpdate(getAllZones)}
              buildingId={id ? id : ""}
              building_top={state.document.externalId}
              emitErrors={emitErrors}
              emitSuccess={emitSuccess}
            ></ModalZone>
          )}

          <ConfirmBox
            confirm_delete={state.confirm_delete}
            confirm_toggle={state.confirm_toggle}
            confirm_save={state.confirm_save}
            success_dlg={state.success_dlg}
            cancelConfirmBox={cancelConfirmBox}
            closeAfterConfirmDelete={closeAfterConfirmDelete}
            closeAfterConfirmToggle={closeAfterConfirmToggle}
            closeAfterConfirmSave={closeAfterConfirmSave}
            confirmDelete={confirmDelete}
            confirmToggle={confirmToggle}
            confirmSave={confirmSave}
            isActive={state.document.isActive}
            actionType={state.actionType}
          />
        </Container>
      </div>
    </>
  );
};

BuildingSingle.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUsers: () => dispatch(getUsers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BuildingSingle));

import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { apiError, loginUser } from "../../store/actions";
import * as endpoints from "constants/endpoints";
import jwt_decode from "jwt-decode";
import logoLightPng from "../../assets/images/logo.png";
import logoNordPng from "../../assets/images/Logotipi.png";

const Login = (props) => {
  const history = useHistory();

  const [state, setState] = useState({
    username: "",
    password: "",
    error_msg:
      "Username and password are invalid. Please enter correct username and password",
    isValid: null,
  });

  const getUsers = (username) => {
    fetch(`${endpoints.USER}/GetAll`)
      .then((resp) => resp.json())
      .then((data) => {
        const loggedUser = data.filter((user) => user.username === username);

        localStorage.setItem("authUser", JSON.stringify(...loggedUser));

        history.push("/buildings");
      })
      .catch((error) => {
        // handle error
        console.log(error);
      });
  };

  const handleValidSubmit = (event, values) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        username: values.username,
        password: values.password,
      }),
    };

    fetch(`${endpoints.LOGIN}`, requestOptions)
      .then((resp) =>
        resp.json().then((data) => {
          if (resp.status === 200) {
            var decoded = jwt_decode(data.token);
            const date = new Date();
            const lastLoggedDate =
              date.getFullYear() +
              "/" +
              (date.getMonth() + 1) +
              "/" +
              date.getDate();

            localStorage.setItem("authUser", JSON.stringify(data));
            localStorage.setItem("lastLoggedDate", lastLoggedDate);
            localStorage.setItem("role", JSON.stringify(decoded.role));
            localStorage.setItem("nameid", JSON.stringify(decoded.nameid));

            setState((state) => ({ ...state, isValid: true }));
            history.push("/buildings");
          }

          if (resp.status === 401) {
            setState((state) => ({ ...state, isValid: false }));
          }
        })
      )
      .catch((error) => {
        console.log(error);
        setState((state) => ({ ...state, isValid: false }));
      });
  };

  useEffect(() => {
    props.apiError("");
  }, []);

  return (
    <>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <div className="pb-2">
                <img src={logoLightPng} alt="" height="25" />
              </div>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Sign in</h5>
                      </div>
                    </Col>
                  </Row>
                </div>

                <CardBody className="pt-0">
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={handleValidSubmit}
                    >
                      {state.isValid === false && (
                        <Alert color="danger">{state.error_msg}</Alert>
                      )}

                      <div className="form-group">
                        <AvField
                          name="username"
                          label="Username"
                          value=""
                          className="form-control"
                          placeholder="Enter username"
                          type="text"
                          required
                        />
                      </div>

                      <div className="form-group">
                        <AvField
                          name="password"
                          label="Password"
                          value=""
                          type="password"
                          placeholder="Enter Password"
                          required
                        />
                      </div>

                      <div className="mt-3">
                        <button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        {/* <Link to="/forgot-password" className="text-muted"> */}
                        {/*   <i className="mdi mdi-lock mr-1" /> Forgot your */}
                        {/*   password? */}
                        {/* </Link> */}

                        <Link to="/page-recoverpw" className="text-muted">
                          <i className="mdi mdi-lock mr-1" />
                          Forgot your password?
                        </Link>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="pb-2 text-right">
                <img src={logoNordPng} alt="" width="120" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

Login.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { error } = state.Login;
  return { error };
};

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError })(Login)
);

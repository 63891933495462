import { useState, useEffect, Fragment } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  FormGroup,
  Label,
} from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as endpoints from "constants/endpoints";
import { GetData } from "../../helpers/CRUD.js";
import { throttle } from "lodash";
import i18next from "i18next";

const AnnualCalculationBill = (props) => {
  const [state, setState] = useState({
    buildings_all: [],
    heatingSeasons: [],
    document: {
      buildingId: "",
      heatingSeasonId: "",
    },
  });

  const getAllHeatingSeasons = () => {
    const url = `${endpoints.NOMENCLATURE_ENDPOINT}/HeatingSeason/GetAll`;

    GetData(
      url,
      (data) => {
        setState((state) => ({ ...state, heatingSeasons: data.items }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getAllBuildings = () => {
    const url = `${endpoints.BUILDING}/GetAll`;

    GetData(
      url,
      (data) => {
        setState((state) => ({ ...state, buildings_all: data.items }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const filterBuildings = (value) => {
    let endpointQueryParam = "";

    if (value) {
      endpointQueryParam = `?filterText=${value}`;
    }

    const url = `${endpoints.BUILDING}/GetAll${endpointQueryParam}`;

    GetData(
      url,
      (data) => {
        setState((state) => ({ ...state, buildings_all: data.items }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const filterBuildingsThrottled = throttle(filterBuildings, 800);

  const handleAutoCompleteChangeBuilding = (event, item) => {
    if (item !== null) {
      setState((state) => ({
        ...state,
        document: {
          ...state.document,
          buildingId: item.id,
        },
      }));
    } else {
      setState((state) => ({
        ...state,
        document: {
          ...state.document,
          buildingId: "",
        },
      }));
    }
  };

  const showReport = (e, preview) => {
    e.preventDefault();

    if (
      state.document.heatingSeasonId !== "" &&
      state.document.buildingId !== ""
    ) {
      window.open(
        `${endpoints.PDF_USED_ENERGY}?heatingSeason=${state.document.heatingSeasonId}&buildingId=${state.document.buildingId}&isPreview=${preview}`
      );
    }
  };

  useEffect(() => {
    getAllHeatingSeasons();
    getAllBuildings();
  }, []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Card className="overflow-hidden">
            <CardBody className="pt-3">
              <AvForm className="form-horizontal">
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="heating_season">
                        {i18next.t("heating-season")}
                      </Label>

                      <AvField
                        type="select"
                        name="document.heatingSeasonId"
                        id="heating_season"
                        onChange={(e) =>
                          setState((state) => ({
                            ...state,
                            document: {
                              ...state.document,
                              heatingSeasonId: e.target.value,
                            },
                          }))
                        }
                        className="w-100"
                      >
                        <option
                          value=""
                          selected={
                            state.document.heatingSeasonId === "" ? true : false
                          }
                        >
                          {i18next.t("choose-season")}
                        </option>
                        {state.heatingSeasons.length > 0 &&
                          state.heatingSeasons.map((heatingSeason) => (
                            <option value={heatingSeason.id}>
                              {heatingSeason.name}
                            </option>
                          ))}
                      </AvField>
                    </FormGroup>
                  </Col>
                </Row>

                <Row className="mb-4">
                  <Col md="6">
                    <div className="form_controls">
                      <label>{i18next.t("building")}</label>

                      <Autocomplete
                        options={state.buildings_all}
                        renderOption={(option) => (
                          <Fragment>{option.externalId}</Fragment>
                        )}
                        getOptionLabel={(option) => option.externalId}
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              label={i18next.t("choose-building")}
                              variant="outlined"
                              fullWidth
                              onChange={(e) =>
                                filterBuildingsThrottled(e.target.value)
                              }
                            />
                          );
                        }}
                        onChange={handleAutoCompleteChangeBuilding}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <button
                      className="btn btn-primary btn-block waves-effect waves-light"
                      onClick={(e) => showReport(e, true)}
                      type="button"
                    >
                      <i className="fa fa-eye mr-1" />
                      {i18next.t("btn-watch")}
                    </button>
                  </Col>

                  <Col md={2}>
                    <button
                      className="btn btn-primary btn-block waves-effect waves-light"
                      onClick={(e) => showReport(e, false)}
                      type="button"
                    >
                      <i className="fa fa-download mr-1" />
                      {i18next.t("btn-download")}
                    </button>
                  </Col>
                </Row>
              </AvForm>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
};

AnnualCalculationBill.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AnnualCalculationBill));

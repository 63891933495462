import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, useLocation } from "react-router-dom";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import FilterBar from "components/Common/FilteringBar";
import TableComponent from "../../components/Common/TableComponent";
import ModalApartmentTypes from "components/Modals/ModalApartmentTypes";
import ButtonAdd from "components/Common/ButtonAdd.js";
import { GetData } from "../../helpers/CRUD.js";
import * as endpoints from "constants/endpoints";
import i18next from "i18next";
import Pagination from "components/Common/Pagination";
import PaginationSelect from "components/Common/PaginationSelect";

const ApartmentTypes = (props) => {
  const [state, setState] = useState({
    pageTitle: i18next.t("link-apartment-types-tooltip"),
    apartment_types: [],
    isLoading: false,
    isLoaded: false,
    page: 1,
    totalCount: null,
    pageSize: 100,
    sorterLabel: "",
    sorterDirection: "",
    isModalOpen: false,
    isActive: true,
  });

  const location = useLocation();

  const emitErrors = (error) => {
    props.onErrorsShow(error.body);
  };

  const emitSuccess = (success) => {
    props.onSuccessShow(success);
  };

  const openModal = () => {
    setState((state) => ({ ...state, isModalOpen: !state.isModalOpen }));
    removeBodyCss();
  };

  const closeModal = () => {
    setState((state) => ({ ...state, isModalOpen: false }));
    removeBodyCss();
  };

  const closeAndUpdate = (modalCallback) => {
    setState((state) => ({ ...state, isModalOpen: false }));
    modalCallback();
    removeBodyCss();
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };

  const getAllApartmentTypes = () => {
    setState((state) => ({
      ...state,
      isLoaded: false,
      apartment_types: [],
    }));

    let endpointSortAdd = "";

    if (state.sorterLabel !== "") {
      endpointSortAdd = `&orderBy=${state.sorterLabel}_${state.sorterDirection}`;
    }

    const url = `${endpoints.NOMENCLATURE_ENDPOINT}/ApartmentType/GetAll?filterText=&page=${state.page}&size=${state.pageSize}${endpointSortAdd}&isActive=${state.isActive}`;

    GetData(
      url,
      (data) => {
        let tableBodyData;

        if (data) {
          tableBodyData = data.items.map((item) => {
            return {
              id: item.id,
              data: {
                name: item.name,
                isActive: item.isActive,
              },
            };
          });
        }

        setState((state) => ({
          ...state,
          apartment_types: tableBodyData,
          isLoaded: true,
          totalCount: data.totalCount,
        }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const updatePage = (page) => {
    setState((state) => ({ ...state, page }));
  };

  const onPageSizeUpdate = (pageSize) => {
    setState((state) => ({ ...state, pageSize, page: 1 }));
  };

  const onSorterClick = (label, direction) => {
    setState((state) => ({
      ...state,
      page: 1,
      sorterLabel: label,
      sorterDirection: direction,
    }));
  };

  useEffect(() => {
    getAllApartmentTypes();
  }, [state.page, state.pageSize, state.isActive]);

  useEffect(() => {
    onPageSizeUpdate(state.pageSize);
  }, [state.isActive]);

  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    setState((state) => ({
      ...state,
      loggedUserType: obj.role,
      isLoading: true,
    }));

    if (location.state) {
      setState((state) => ({ ...state, mode: "edit" }));
    }
  }, []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <CardTitle className="mb-4">{state.pageTitle}</CardTitle>
                </Col>

                <Col md="2" className="ml-auto mr-0">
                  <PaginationSelect
                    pageSize={state.pageSize}
                    index={1}
                    onPageSizeUpdate={onPageSizeUpdate}
                  />
                </Col>

                <Col className="text-right col-md-1">
                  <ButtonAdd
                    onBtnClick={openModal}
                    data-toggle="modal"
                    tooltip={i18next.t("toolptip-add-new-estate")}
                  />
                </Col>
              </Row>
              <FilterBar
                tableData={state.apartment_types}
                isActive
                state={state}
                setState={setState}
              ></FilterBar>
              <TableComponent
                dataHead={[i18next.t("estate"), i18next.t("actions")]}
                dataBody={state.apartment_types}
                sorterData={["name"]}
                onSorterClick={onSorterClick}
                url_path={"apartment-types"}
                category={"apartment-types"}
                enableLinkView={true}
                isLoading={state.isLoading}
                isLoaded={state.isLoaded}
              />

              <Pagination
                updatePage={updatePage}
                page={state.page}
                totalCount={state.totalCount}
                pageSize={state.pageSize}
              />

              <ModalApartmentTypes
                isModalOpen={state.isModalOpen}
                closeModal={closeModal}
                update={() => closeAndUpdate(getAllApartmentTypes)}
                emitErrors={emitErrors}
                emitSuccess={emitSuccess}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
};

ApartmentTypes.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ApartmentTypes));

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import i18next from "i18next";

const IconCheck = (props) => {
  const iconSize = {
    fontSize: "18px",
    color: "green",
  };

  const randomNumb = Math.floor(Math.random() * 10);
  const id = `icon-check-with-tooltip${Date.now()}-${randomNumb}`;

  return (
    <>
      <i className="bx bx-check" style={iconSize} id={id} />
      <UncontrolledTooltip placement="top" target={id}>
        {i18next.t("yes")}
      </UncontrolledTooltip>
    </>
  );
};

IconCheck.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUsers: () => dispatch(getUsers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(IconCheck));

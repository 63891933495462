import * as endpoints from "../constants/endpoints";
import {GetData} from "./CRUD";

const formatDate = (date) => {
  const dateObj = new Date(date);
  if (dateObj) {
    return (
      dateObj.getDate() +
      "." +
      (dateObj.getMonth() + 1) +
      "." +
      dateObj.getFullYear()
    );
  }
  return "";
};

const formatDateLocale = (date) => {
  if (date) {
    return new Date(date).toISOString().slice(0, 10);
  }

  return null;
};

const str2bool = (value) => {
  if (value && typeof value === "string") {
    if (value.toLowerCase() === "true") return true;
    if (value.toLowerCase() === "false") return false;
  }
  return value;
};

const currentYear = () => {
  return  new Date().getFullYear()
}

const currentMonth = () => {
  return new Date().getMonth() + 1;
}

export { formatDate, formatDateLocale, str2bool, currentYear, currentMonth };

import { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, useHistory, useLocation } from "react-router-dom";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import TableSubscriberStation from "../../../components/TableDetailed/TableSubscriberStation.js";
import BackButton from "../../../components/Common/BackButton.js";
import ConfirmBox from "../../../components/Common/ConfirmBox.js";
import ListActions from "../../../components/Common/ListActions.js";
import { SaveItem } from "../../../helpers/CRUD.js";
import i18next from "i18next";

const SubscriberStationSingle = (props) => {
  const history = useHistory();
  const location = useLocation();

  const [state, setState] = useState({
    page_title: "Абонатна станция",
    mode: "view",
    id: "subscriber-staiton1",
    name: "Името на Абонатна станция",
    number: "12A24C",
    isActive: true,
    isModalOpen: false,
  });

  const handleEdit = () => {
    setState((state) => ({ ...state, mode: "edit" }));
  };

  const handleSave = () => {
    setState((state) => ({ ...state, mode: "view" }));

    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        fromData: state.fromData,
        toData: state.toData,
        price: state.price,
        isActive: state.isActive,
      }),
    };

    fetch(
      `${endpoints.NOMENCLATURE_ENDPOINT}/HeatingPricePeriod/Update?entityUpdateId=${state.id}`,
      requestOptions
    )
      .then((resp) => resp.json())
      .then((data) => {
        setState((state) => ({ ...state, isModalOpen: false }));
      })
      .catch((error) => {
        console.log(error);
      });

    const url = `${endpoints.NOMENCLATURE_ENDPOINT}/HeatingPricePeriod/Update?entityUpdateId=${state.id}`;

    SaveItem(
      url,
      patch,
      (data) => {
        props.onSuccessShow(i18next.t("entry_updated_successfully"));
        setState((state) => ({ ...state, isModalOpen: false }));
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  const handleDelete = () => {
    const requestOptions = {
      method: "DELETE",
    };

    fetch(
      `${endpoints.NOMENCLATURE_ENDPOINT}/HeatingPricePeriod/Delete?id=${state.id}`,
      requestOptions
    )
      .then((resp) => {
        if (resp.status === 200) {
          history.goBack();
        }

        if (resp.status === 401) {
        }
      })
      .then((data) => {})
      .catch((error) => {
        console.log(error);
      });
  };

  const toggleActive = () => {
    setState((state) => ({ ...state, isActive: !state.isActive }));
    handleToggleStatusPost();
  };

  const handleToggleStatusPost = () => {
    const requestOptions = {
      method: "PUT",
    };

    const toggleAction = state.isActive ? "Activate" : "Deactivate";

    fetch(
      `${endpoints.NOMENCLATURE_ENDPOINT}/HeatingPricePeriod/${toggleAction}?id=${state.id}`,
      requestOptions
    )
      .then((resp) => {
        if (resp.status === 200) {
          console.log("success");
          //history.goBack();
        }

        if (resp.status === 401) {
          console.log("unauthorized");
        }
      })
      .then((data) => {})
      .catch((error) => {
        console.log(error);
      });
  };

  const cancelConfirmBox = () => {
    setState((state) => ({
      ...state,
      confirm_delete: false,
      confirm_toggle: false,
      confirm_save: false,
    }));
  };

  const confirmDelete = () => {
    setState((state) => ({
      ...state,
      confirm_delete: false,
      success_dlg: true,
    }));
  };

  const confirmToggle = () => {
    setState((state) => ({
      ...state,
      confirm_toggle: false,
      success_dlg: true,
    }));
  };

  const confirmSave = () => {
    setState((state) => ({
      ...state,
      confirm_save: false,
      success_dlg: true,
      mode: "view",
    }));
  };

  const closeAfterConfirmDelete = () => {
    setState((state) => ({ ...state, success_dlg: false }));
    handleDelete();
  };

  const closeAfterConfirmToggle = () => {
    setState((state) => ({ ...state, success_dlg: false }));
    toggleActive();
  };

  const closeAfterConfirmSave = () => {
    setState((state) => ({ ...state, success_dlg: false }));
    handleSave();
  };

  const radioStyle = {
    listStyle: "none",
    paddingLeft: "0",
  };
  const labelStyle = {
    paddingLeft: "20px",
  };

  const tableStyle = {
    tableLayout: "fixed",
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl="12">
              <BackButton className="mb-4" />

              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <CardTitle className="mb-4">{state.page_title}</CardTitle>
                    </Col>

                    <Col sm="3" className="text-right">
                      <Row>
                        <Col xs="12">
                          <ListActions
                            link={`/${state.id}`}
                            data={state}
                            mode={state.mode}
                            toggleSave={
                              // handleSave
                              () => {
                                setState((state) => ({
                                  ...state,
                                  confirm_save: true,
                                  actionType: "save",
                                }));
                              }
                            }
                            toggleEdit={handleEdit}
                            toggle={() =>
                              setState((state) => ({
                                ...state,
                                confirm_toggle: true,
                                actionType: "toggle",
                              }))
                            }
                            delete={() =>
                              setState((state) => ({
                                ...state,
                                confirm_delete: true,
                                actionType: "delete",
                              }))
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <TableSubscriberStation
                    data={state}
                    mode={state.mode}
                  ></TableSubscriberStation>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <ConfirmBox
            confirm_delete={state.confirm_delete}
            confirm_toggle={state.confirm_toggle}
            confirm_save={state.confirm_save}
            success_dlg={state.success_dlg}
            cancelConfirmBox={cancelConfirmBox}
            closeAfterConfirmDelete={closeAfterConfirmDelete}
            closeAfterConfirmToggle={closeAfterConfirmToggle}
            closeAfterConfirmSave={closeAfterConfirmSave}
            confirmDelete={confirmDelete}
            confirmToggle={confirmToggle}
            confirmSave={confirmSave}
            isActive={state.isActive}
            actionType={state.actionType}
          />
        </Container>
      </div>
    </>
  );
};

SubscriberStationSingle.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SubscriberStationSingle));

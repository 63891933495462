import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, useLocation } from "react-router-dom";
import {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  toggleRightSidebar,
  changeTopbarTheme,
  changeLayoutWidth,
} from "../../store/actions";

// Layout Related Components
import Header from "../Common/Header";
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import Rightbar from "../CommonForBoth/Rightbar";
import Navigation from "../Common/Navigation";
import ModalErrors from "components/Modals/ModalErrors";
import ModalWarnings from "components/Modals/ModalWarnings";
import ModalSuccess from "../Modals/ModalSuccess";

const Layout = (props) => {
  const [isMobile, setIsMobile] = useState(
    /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  );
  const [errors, setErrors] = useState([]);
  const [warnings, setWarnings] = useState([]);
  const [message, setMessage] = useState("");
  const [showModalErrors, setShowModalErrors] = useState(false);
  const [showModalWarnings, setShowModalWarnings] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);

  const toggleRightSidebar = () => {
    props.toggleRightSidebar();
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(1).toUpperCase() + string.slice(2);
  };

  const showErrorsModal = (errors) => {
    setErrors(errors);
    setShowModalErrors(true);
  };

  const showWarningsModal = (warnings) => {
    setWarnings(warnings);
    setShowModalWarnings(true);
  };

  const showSuccessModal = (message) => {
    setMessage(message);
    setShowModalSuccess(true);
  };

  const closeModalErrors = () => {
    setShowModalErrors(false);
  };

  const closeModalWarnings = () => {
    setShowModalWarnings(false);
  };

  const closeModalSuccess = () => {
    setShowModalSuccess(false);
  };

  const location = useLocation();

  useEffect(() => {
    if (props.isPreloader === true) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";

      setTimeout(function () {
        document.getElementById("preloader").style.display = "none";
        document.getElementById("status").style.display = "none";
      }, 2500);
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }

    window.scrollTo(0, 0);
    let currentage = capitalizeFirstLetter(location.pathname);

    document.title =
      currentage + " | Skote - Heat Hub";

    if (props.leftSideBarTheme) {
      props.changeSidebarTheme(props.leftSideBarTheme);
    }

    if (props.layoutWidth) {
      props.changeLayoutWidth(props.layoutWidth);
    }

    if (props.leftSideBarType) {
      props.changeSidebarType(props.leftSideBarType);
    }

    if (props.topbarTheme) {
      props.changeTopbarTheme(props.topbarTheme);
    }

    if (props.showRightSidebar) {
      toggleRightSidebar();
    }
  }, []);

  const toggleMenuCallback = () => {
    if (props.leftSideBarType === "default") {
      props.changeSidebarType("condensed", isMobile);
    } else if (props.leftSideBarType === "condensed") {
      props.changeSidebarType("default", isMobile);
    }
  };

  let childrenComponent = React.cloneElement(props.children, {
    className: "active",
    onErrorsShow: showErrorsModal,
    onWarningsShow: showWarningsModal,
    onSuccessShow: showSuccessModal,
  });

  return (
    <>
      <div id="preloader">
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
          </div>
        </div>
      </div>

      <div id="layout-wrapper">
        <Header
          toggleMenuCallback={toggleMenuCallback}
          toggleRightSidebar={toggleRightSidebar}
        />

        <Navigation />

        <Sidebar
          theme={props.leftSideBarTheme}
          type={props.leftSideBarType}
          isMobile={isMobile}
        />

        <div className="main-content">{childrenComponent}</div>
        <Footer />
      </div>
      <Rightbar />

      {showModalErrors === true && (
        <ModalErrors
          isModalOpen={true}
          closeModal={closeModalErrors}
          errors={errors}
        />
      )}

      {showModalWarnings === true && (
        <ModalWarnings
          isModalOpen={true}
          closeModal={closeModalWarnings}
          warnings={warnings}
        />
      )}

      {showModalSuccess === true && (
        <ModalSuccess
          isModalOpen={true}
          closeModal={closeModalSuccess}
          message={message}
        />
      )}
    </>
  );
};

Layout.propTypes = {
  changeLayoutWidth: PropTypes.func,
  changeSidebarTheme: PropTypes.func,
  changeSidebarType: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  children: PropTypes.any,
  isPreloader: PropTypes.bool,
  layoutWidth: PropTypes.any,
  leftSideBarTheme: PropTypes.any,
  leftSideBarType: PropTypes.any,
  location: PropTypes.object,
  showRightSidebar: PropTypes.any,
  toggleRightSidebar: PropTypes.any,
  topbarTheme: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    ...state.Layout,
  };
};

export default connect(mapStateToProps, {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  toggleRightSidebar,
  changeTopbarTheme,
  changeLayoutWidth,
})(withRouter(Layout));

import { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Card, CardBody, Col, Container, Form, Row, Label } from "reactstrap";
import * as endpoints from "constants/endpoints";
import { walkby_file_categories } from "../../helpers/listsHardcodedData";
import { GetData, FileUpload } from "../../helpers/CRUD.js";
import Loader from "../../components/Common/Loader";
import { AvField, AvForm } from "availity-reactstrap-validation";
import i18next from "i18next";

const WalkBy = (props) => {
  const [state, setState] = useState({
    heatingSeasons: [],
    years: [],
    months: [
      { value: 1, label: "january" },
      { value: 2, label: "february" },
      { value: 3, label: "march" },
      { value: 4, label: "april" },
      { value: 5, label: "may" },
      { value: 6, label: "june" },
      { value: 7, label: "july" },
      { value: 8, label: "august" },
      { value: 9, label: "september" },
      { value: 10, label: "october" },
      { value: 11, label: "november" },
      { value: 12, label: "december" },
    ],
    selectedMonth: "",
    selectedYear: "",
    files: [],
    showData: false,
    file_categories: walkby_file_categories(),
    choosen_file_type: "Apator-TXT",
    choosen_file_extension: "text/plain",
    isYearReport: false,
    countDataForTheEndOfThePeriod: false,
    fieldDisabled: false,
    isLoading: false,
    isLoaded: false,
  });

  const onFileChange = (event) => {
    const files = event.target.files;
    setState((state) => ({ ...state, files: Array.from(files) }));
  };

  const uploadFile = () => {
    setState((state) => ({ ...state, isLoading: true }));

    const files = state.files;
    const data = new FormData();
    const choosen_type = state.choosen_file_type;

    let endpoint_path;

    if (choosen_type === "Apator-TXT") {
      endpoint_path = "UploadApatorTXTFile";
    } else if (choosen_type === "Apator-CSV") {
      endpoint_path = "UploadApatorCSVFile";
    } else if (choosen_type === "Engelman") {
      endpoint_path = "UploadEngelmannCSVFile";
    } else if (choosen_type === "Siemens") {
      endpoint_path = "UploadSiemensRepFile";
    } else if (choosen_type === "Remote file") {
      endpoint_path = "UploadRemoteFile";
    } else {
      //please choose a file type
    }
    const nameid = JSON.parse(localStorage.getItem("nameid"));

    const url = `${endpoints.WALKBY}/${endpoint_path}?month=${state.selectedMonth}&year=${state.selectedYear}&isYearReport=${state.isYearReport}&countDataForTheEndOfThePeriod=${state.countDataForTheEndOfThePeriod}&userId=${nameid}`;

    for (var i = 0; i < files.length; i++) {
      data.append("files", files[i]);
    }

    FileUpload(
      url,
      data,
      (resp, data) => {
        if (resp.status === 200) {
          setState((state) => ({ ...state, isLoaded: true, isLoading: false }));

          if (data.uploadMessage !== null && data.uploadMessage !== "") {
            props.onSuccessShow(data.uploadMessage);
          } else if (data.warningResponse !== null) {
            props.onWarningsShow(data.warningResponse);
          }
        }
      },
      (error) => {
        setState((state) => ({ ...state, isLoaded: false, isLoading: false }));
        props.onErrorsShow(error.body);
      }
    );
  };

  const handleDataChange = (event) => {
    const value = event.target.value;
    setState((state) => ({
      ...state,
      [event.target.name]: value,
    }));
  };

  const updateFileType = (event) => {
    const value = event.target.value;

    if (value === "Apator-TXT") {
      setState((state) => ({ ...state, choosen_file_extension: "text/plain" }));
    } else if (value === "Apator-CSV") {
      setState((state) => ({ ...state, choosen_file_extension: ".csv" }));
    } else if (value === "Engelman") {
      setState((state) => ({ ...state, choosen_file_extension: ".csv" }));
    } else if (value === "Siemens") {
      setState((state) => ({ ...state, choosen_file_extension: ".rep" }));
    } else if (value === "Remote file") {
      setState((state) => ({ ...state, choosen_file_extension: ".csv" }));
    }
  };

  const getAllHeatingSeasons = () => {
    const url = `${endpoints.NOMENCLATURE_ENDPOINT}/HeatingSeason/GetAll?isActive=true`;

    GetData(
      url,
      (data) => {
        setState((state) => ({
          ...state,
          heatingSeasons: data.items,
          years: [
            ...data.items.map((item) => item.fromYear),
            ...data.items.map((item) => item.toYear),
          ],
        }));
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  const toggleIsYearReport = (e) => {
    var currentHeatingSeason = state.heatingSeasons.find(
      (a) => a.toYear === new Date().getFullYear()
    );

    setState((state) => ({
      ...state,
      isYearReport: !state.isYearReport,
      selectedMonth: e.target.checked ? 4 : state.selectedMonth,
      selectedYear: e.target.checked
        ? currentHeatingSeason && currentHeatingSeason.toYear
        : state.selectedYear,
      fieldDisabled: e.target.checked,
    }));
  };

  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    setState((state) => ({ ...state, loggedUserType: obj.role }));

    getAllHeatingSeasons();
  }, []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              <AvForm>
                <Row>
                  <Col>
                    <Form>
                      <div className="d-flex align-items-center mb-3">
                        <input
                          name="files"
                          placeholder=""
                          id="field-AS"
                          type="file"
                          multiple
                          accept={state.choosen_file_extension}
                          onChange={(e) => onFileChange(e)}
                          className="flex-grow-1"
                        />

                        {state.files && state.files.length > 0 ? (
                          <button
                            type="button"
                            className="btn btn-primary waves-effect waves-light flex-shrink-1"
                            onClick={uploadFile}
                          >
                            {i18next.t("upload_files_add_new_file")}
                          </button>
                        ) : null}
                      </div>

                      <Row className="mb-3">
                        <Col md={4}>
                          <div className="form_controls">
                            <label>Тип на файл</label>

                            <select
                              name="choosen_file_type"
                              id="choosen_file_type"
                              onChange={(event) => {
                                handleDataChange(event);
                                updateFileType(event);
                              }}
                              value={state.choosen_file_type}
                              className="w-100"
                            >
                              {state.file_categories.map((item) => (
                                <option
                                  key={`option-${item.id}`}
                                  value={item.label}
                                >
                                  {item.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="3">
                          <div className="form_controls">
                            <Label htmlFor="building_heating_area" className="">
                              Период на отчет
                            </Label>

                            <AvField
                              type="select"
                              value={state.selectedMonth}
                              onChange={(e) => handleDataChange(e)}
                              name="selectedMonth"
                              id="building_heating_area"
                              disabled={state.fieldDisabled}
                              {...(state.mode === "view" && {
                                disabled: "disabled",
                              })}
                            >
                              <option key={`month-missing`} value={null}>
                                Изберете Месец
                              </option>
                              {state.months.map((option, i) => (
                                <option key={`month-${i}`} value={option.value}>
                                  {i18next.t(option.label)}
                                </option>
                              ))}
                            </AvField>
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="form_controls">
                            <Label htmlFor="building_heating_area" className="">
                              &nbsp;
                            </Label>

                            <AvField
                              type="select"
                              value={state.selectedYear}
                              onChange={(e) => handleDataChange(e)}
                              name="selectedYear"
                              id="building_heating_area2"
                              disabled={state.fieldDisabled}
                              {...(state.mode === "view" && {
                                disabled: "disabled",
                              })}
                            >
                              <option key={`year-missing`} value={null}>
                                Изберете Година
                              </option>
                              {[...new Set(state.years)].map((option, i) => (
                                <option key={`year-${i}`} value={option}>
                                  {option}
                                </option>
                              ))}
                            </AvField>
                          </div>
                        </Col>
                      </Row>

                      <div
                        className="custom-control custom-switch mb-0 mt-3"
                        dir="ltr"
                      >
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customSwitchsizesmIsYearReport"
                          checked={state.isYearReport}
                          onClick={(e) => toggleIsYearReport(e)}
                        />
                        <label
                          className="custom-control-label mb-0"
                          htmlFor="customSwitchsizesmIsYearReport"
                        >
                          {i18next.t("report_yearly")}
                        </label>
                      </div>

                      <div
                        className="custom-control custom-switch mb-0 mt-3"
                        dir="ltr"
                      >
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customSwitchsizesmCountDataForTheEndOfThePeriod"
                          onClick={() => {
                            setState((state) => ({
                              ...state,
                              countDataForTheEndOfThePeriod:
                                !state.countDataForTheEndOfThePeriod,
                            }));
                          }}
                        />
                        <label
                          className="custom-control-label mb-0"
                          htmlFor="customSwitchsizesmCountDataForTheEndOfThePeriod"
                        >
                          Считай данните за края на отчетния период
                        </label>
                      </div>
                    </Form>
                  </Col>
                </Row>
              </AvForm>
            </CardBody>
          </Card>

          {state.isLoading && !state.isLoaded && <Loader showLoader={true} />}
        </Container>
      </div>
    </>
  );
};

WalkBy.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WalkBy));

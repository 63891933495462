import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Modal, FormGroup, Label, Button } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import * as endpoints from "constants/endpoints";
import * as jsonpatch from "fast-json-patch";
import { CreateItem } from "../../helpers/CRUD.js";
import i18next from "i18next";

const ModalCondition = (props) => {
  const old_state = {
    nomRadioModuleTypes: [],
    nomEquipmentManufacturers: [],
    nomRoomTypes: [],
    all_meter_types: [],
  };

  const [state, setState] = useState({
    ...old_state,
    document: {
      fromDate: "",
      toDate: "",
      status: "",
    },
  });

  const getInitialState = (old_state) => ({
    ...old_state,
    document: {
      fromDate: "",
      toDate: "",
      status: "",
    },
  });

  const resetData = () => {
    setState(getInitialState(state));
  };

  const handleValidSubmit = (event, values) => {
    let patch = jsonpatch.compare(getInitialState().document, state.document);

    if (props.device_id) {
      patch.push({
        op: "replace",
        path: "/deviceId",
        value: props.device_id,
      });
    }

    const url = `${endpoints.BUILDING_UNITS_ADDITIONAL_ENDPOINT}/DeviceStatus/Add`;

    CreateItem(
      url,
      patch,
      (data) => {
        update();
        props.emitSuccess(i18next.t("entry_added_successfully"));
      },
      (error) => {
        props.emitErrors(error);
      }
    );
  };

  const closeModal = () => {
    props.closeModal();
    resetData();
  };

  const update = () => {
    props.update();
    resetData();
  };

  const getConditionsEnum = () => {
    //todo fetch conditions enumeration
  };

  useEffect(() => {
    getConditionsEnum();
  }, []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Modal isOpen={props.isModalOpen} scrollable={true}>
            <div className="modal-header">
              <h5 className="modal-title mt-0">
                {i18next.t("modal_condition_title")}
              </h5>

              <button
                type="button"
                onClick={() => closeModal()}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <Card className="mb-0">
                <CardBody>
                  <AvForm
                    onValidSubmit={handleValidSubmit}
                    className="needs-validation"
                  >
                    <Row className="mb-4">
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="device_status_from_date">
                            {i18next.t("date-from")}
                          </Label>
                          <AvField
                            type="date"
                            name="document.fromDate"
                            id="device_status_from_date"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  fromDate: e.target.value,
                                },
                              }))
                            }
                            defaultValue={state.document.fromDate}
                            className="w-100"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="device_status_to_date">
                            {i18next.t("date-to")}
                          </Label>
                          <AvField
                            type="date"
                            name="document.toDate"
                            id="device_status_to_date"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  toDate: e.target.value,
                                },
                              }))
                            }
                            defaultValue={state.document.toDate}
                            className="w-100"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="device_status">
                            {i18next.t("condition")}
                          </Label>

                          <AvField
                            type="select"
                            name="document.status"
                            id="device_status"
                            onChange={(e) =>
                              setState((state) => ({
                                ...state,
                                document: {
                                  ...state.document,
                                  status: e.target.value,
                                },
                              }))
                            }
                            value={state.document.status}
                            className="w-100"
                          >
                            <option
                              value=""
                              selected={
                                state.document.status === "" ? true : false
                              }
                            >
                              {i18next.t("select_condition")}
                            </option>
                            {props.conditions &&
                              props.conditions.length > 0 &&
                              props.conditions.map((option) => (
                                <option
                                  value={option.key}
                                  selected={
                                    state.document.status === option.key
                                      ? true
                                      : false
                                  }
                                >
                                  {option.value}
                                </option>
                              ))}
                          </AvField>
                        </FormGroup>
                      </Col>
                    </Row>

                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => closeModal()}
                      >
                        <i className="fa fa-times mr-1" />
                        {i18next.t("button_cancel")}
                      </button>

                      <Button color="primary" type="submit">
                        <i className="fa fa-plus-circle mr-1" />
                        {i18next.t("button_add")}
                      </Button>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </div>
          </Modal>
        </Container>
      </div>
    </>
  );
};

ModalCondition.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUsers: () => dispatch(getUsers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ModalCondition));

import { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Modal, Button } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import i18next from "i18next";

const ModalSubscriberStation = (props) => {
  const [state, setState] = useState({
    id: "",
    name: "",
    number: "",
    isActive: true,
  });

  const handleDataChange = (event) => {
    const value = event.target.value;

    setState((state) => ({
      ...state,
      [event.target.name]: value,
    }));
  };

  const handleAdd = () => {
    const currentState = state;
    currentState.id = `subscriber-station-${Date.now()}`;

    props.addNewItem(currentState);
  };

  const closeModal = () => {
    setState((state) => ({
      ...state,
      id: "",
      name: "",
      number: "",
      isActive: true,
    }));

    props.closeModal();
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Modal isOpen={props.isModalOpen} scrollable={true}>
            <div className="modal-header">
              <h5 className="modal-title mt-0">
                {i18next.t("modal_subscriber_station_title")}
              </h5>

              <button
                type="button"
                onClick={() => closeModal()}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <Card className="mb-0">
                <CardBody>
                  <AvForm className="needs-validation">
                    <Row className="mb-4">
                      <Col md="12">
                        <div className="form_controls">
                          <label>{i18next.t("name")}</label>

                          <input
                            name="name"
                            placeholder={i18next.t("placeholder_enter_name")}
                            id="field-name"
                            onChange={(e) => handleDataChange(e)}
                            defaultValue={state.name}
                            className="w-100"
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col md="12">
                        <div className="form_controls">
                          <label>{i18next.t("label-number")}</label>

                          <input
                            name="number"
                            type="text"
                            placeholder={i18next.t("placeholder_enter_number")}
                            id="field-number"
                            onChange={(e) => handleDataChange(e)}
                            defaultValue={state.number}
                            className="w-100"
                          />
                        </div>
                      </Col>
                    </Row>

                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => closeModal()}
                      >
                        <i className="fa fa-times mr-1" />
                        {i18next.t("button_cancel")}
                      </button>

                      <Button color="primary" type="submit" onClick={handleAdd}>
                        <i className="fa fa-plus-circle mr-1" />
                        {i18next.t("button_add")}
                      </Button>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </div>
          </Modal>
        </Container>
      </div>
    </>
  );
};

ModalSubscriberStation.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUsers: () => dispatch(getUsers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ModalSubscriberStation));

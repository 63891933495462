import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import LinkView from "components/Common/LinkView";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { Tooltip } from "@material-ui/core";
import Loader from "../../components/Common/Loader.js";
import i18next from "i18next";

const TableComponent = (props) => {
  const [state, setState] = useState({
    tableHead: [],
    tableBody: [],
    sortingDirection: "",
    sortingLabel: "",
    url_path: "",
    category: "",
    tempValue: "",
  });

  const loadInitalData = () => {
    setState((state) => ({
      ...state,
      tableHead: props.dataHead,
      tableBody: props.dataBody,
      url_path: props.url_path,
      category: props.category,
      editMode: [],
    }));
  };

  useEffect(() => {
    setState((state) => ({ ...state, tableBody: props.dataBody }));

    const lengthNumber = props.dataBody.length;
    const collection = [];

    for (var i = 0; i < lengthNumber; i++) {
      collection.push(false);
    }

    setState((state) => ({ ...state, editMode: collection }));
  }, [props.dataBody]);

  const handleTableSorting = (label) => {
    if (label === "") {
      return false;
    }

    if (state.sortingLabel === label) {
      setState((state) => ({
        ...state,
        sortingDirection: state.sortingDirection === "asc" ? "desc" : "asc",
      }));
      props.onSorterClick(state.sortingLabel, state.sortingDirection);
    } else {
      setState((state) => ({
        ...state,
        sortingLabel: label,
        sortingDirection: "asc",
      }));
      props.onSorterClick(state.sortingLabel, state.sortingDirection);
    }
  };

  const toggleEditMode = (row, i) => {
    const editMode = state.editMode;
    editMode[i] = true;

    setState((state) => ({
      ...state,
      editMode: editMode,
    }));
  };

  const toggleSaveMode = (row, i) => {
    props.emitRowSave(row, props.heatingSeasonId);

    const editMode = state.editMode;
    editMode[i] = false;

    setState((state) => ({
      ...state,
      editMode: editMode,
    }));
  };

  const handleFieldUpdate = (e, row) => {
    const tbody = state.tableBody;

    const indexOfReport = tbody.findIndex((item) => item.id === row.id);

    tbody[indexOfReport].data.priceManual = e.target.value;

    setState((state) => ({
      ...state,
      tableBody: tbody,
    }));

    //props.dataBody[indexOfReport].data.value = e.target.value;
  };

  useEffect(() => {
    loadInitalData();
  }, []);

  const tableStyle = {
    tableLayout: "fixed",
  };

  const tableHead = {
    backgroundColor: "#f8f9fa",
    borderColor: "#eff2f7",
  };

  const tableData = {
    color: "black",
  };

  const tableDataInactive = {
    color: "darkgray",
  };

  const iconSize = {
    fontSize: "22px",
  };

  return (
    <Table className="table scrollable table-bordered" style={tableStyle}>
      <Thead>
        <Tr>
          {props.dataHead && props.dataHead.length && (
            <React.Fragment>
              {props.dataHead.map((title, i) => (
                <Th
                  key={`${title}-${i}`}
                  style={tableHead}
                  onClick={() => handleTableSorting(props.sorterData[i])}
                >
                  <div className="d-flex align-items-center">
                    <span className="flex-grow-1">{title}</span>

                    {props.sorterData &&
                      props.sorterData[i] !== "" &&
                      props.sorterData[i] !== undefined && (
                        <svg
                          width="8"
                          height="12"
                          xmlns="http://www.w3.org/2000/svg"
                          className="flex-shrink-0"
                          viewBox="0 0 8 12"
                        >
                          <g fill="#495057" fillRule="evenodd">
                            <path d="M4 0l4 5H0zM4 12L0 7h8z" />
                          </g>
                        </svg>
                      )}
                  </div>
                </Th>
              ))}
            </React.Fragment>
          )}
        </Tr>
      </Thead>

      <Tbody>
        {props.isLoading && !props.isLoaded ? (
          <Tr>
            <Td
              colSpan={props.dataHead.length}
              className="align-items-center text-center"
            >
              <Loader showLoader={true} />
            </Td>
          </Tr>
        ) : null}

        {props.isLoaded && props.dataBody.length === 0 ? (
          <Tr>
            <Td
              colSpan={props.dataHead.length}
              className="align-items-center text-center"
            >
              {i18next.t("table_missing_data")}
            </Td>
          </Tr>
        ) : (
        /*  props.dataBody &&
          props.dataBody !== null &&
          props.dataBody !== undefined &&*/
          props.dataBody.length > 0 && (
              <>
                {props.dataBody.map((row, rowIndex) => {return (
                    <Tr
                        key={row.id}
                        style={row.data.isActive ? tableData : tableDataInactive}
                    >
                      {Object.keys(row.data).map((cell, i) => {

                        return (
                            <React.Fragment key={`${row.id}-${i}`}>
                              {cell !== "isActive" && cell !== "id" && cell !== "shouldLock" && (
                                  <Td>
                                    {cell !== props.editableCell && row.data[cell]}

                                    {cell === props.editableCell &&
                                    state.editMode[rowIndex] === true && (
                                        <React.Fragment>
                                          <div className="d-flex align-items-center justify-content-between">

                                            <input
                                                type="number"
                                                min={0}
                                                step={0.01}
                                                value={row.data[cell]}
                                                onChange={(e) => handleFieldUpdate(e, row)}
                                                style={{
                                                  marginBottom: "0"
                                                }}
                                            />
                                            <Tooltip
                                                title={i18next.t(
                                                    "tooltip-save"
                                                )}
                                                placement="right-start"
                                            >
                                              <button
                                                  className="ml-2"
                                                  style={{
                                                    border: "none",
                                                    background: "none",
                                                    fontSize: "0",
                                                  }}
                                                  onClick={() =>
                                                      toggleSaveMode(row, rowIndex)
                                                  }
                                              >
                                                <i
                                                    style={{
                                                      fontSize: 0,
                                                    }}
                                                >
                                                  <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
                                                    <g fill="#000" fillRule="evenodd">
                                                      <path d="M5.633 3.321h6.714c.2 0 .364-.163.364-.363V0H5.269v2.958c0 .2.163.363.364.363z" />
                                                      <path
                                                          d="M17.699 4.523L13.909.718v2.24c0 .861-.7 1.562-1.562 1.562H5.633a1.564 1.564 0 01-1.562-1.562V0H1.65A1.65 1.65 0 000 1.65v14.68c0 .91.739 1.65 1.65 1.65h14.68c.91 0 1.65-.74 1.65-1.65V5.202c0-.255-.102-.5-.281-.68zm-2.516 9.673c0 .532-.431.963-.964.963H3.76a.963.963 0 01-.962-.963v-5.02c0-.53.43-.962.963-.962h10.46c.532 0 .963.432.963.963v5.02z"
                                                          fillRule="nonzero"
                                                      />
                                                      <path d="M13.123 9.76H4.857a.599.599 0 100 1.198h8.266a.599.599 0 100-1.199zM11.924 12.157H6.056a.599.599 0 100 1.198h5.868a.6.6 0 100-1.198z" />
                                                    </g>
                                                  </svg>
                                                </i>
                                              </button>
                                            </Tooltip>
                                          </div>
                                        </React.Fragment>
                                    )}

                                    {cell === props.editableCell &&
                                    state.editMode[rowIndex] === false && (
                                        <React.Fragment>
                                          <div className="d-flex align-items-center justify-content-between">
                                            <p>{row.data[cell]}</p>
                                            <Tooltip
                                                title={i18next.t(
                                                    "tooltip-edit"
                                                )}
                                                placement="right-start"
                                            >
                                              <button
                                                  onClick={() =>
                                                      toggleEditMode(row, rowIndex)
                                                  }
                                                  style={{
                                                    background: "none",
                                                    border: "none",
                                                  }}
                                              >
                                                <i
                                                    className="bx bx-pencil"
                                                    style={iconSize}
                                                />
                                              </button>
                                            </Tooltip>
                                          </div>
                                        </React.Fragment>
                                    )}

                                  </Td>
                              )}

                              {cell === "shouldLock" && (
                                  row.data && row.data.shouldLock
                                      ? (<Tooltip
                                              title={i18next.t("tootltip-lock")}
                                              placement="right-start"
                                          >
                                        <button
                                            className="ml-2"
                                            style={{
                                              border: "none",
                                              background: "none",
                                              fontSize: "22px",
                                            }}
                                            onClick={() => props.editItem(row.id, row.data.shouldLock)}
                                        >
                                            <i className="fa fa-lock" />
                                        </button>
                                         </Tooltip>)
                                      : (<Tooltip
                                              title={i18next.t("tootltip-unlock")}
                                              placement="right-start"
                                          >
                                        <button
                                            className="ml-2"
                                            style={{
                                              border: "none",
                                              background: "none",
                                              fontSize: "22px",
                                            }}
                                            onClick={() => props.editItem(row.id, row.data.shouldLock)}
                                        >
                                            <i className="fa fa-lock-open" />
                                        </button>
                                      </Tooltip>)
                              )}


                            </React.Fragment>
                        )})}


                      </Tr>
                )})}
              </>
          )
        )}
      </Tbody>
    </Table>
  );
};

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TableComponent));

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, useLocation } from "react-router-dom";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import FilterBar from "components/Common/FilteringBar";
import TableListComponent from "../../../components/Common/TableListComponent";
import ModalHeater from "components/Modals/ModalHeater";
import ButtonAdd from "components/Common/ButtonAdd.js";
import * as endpoints from "constants/endpoints";
import { translateStatusEnumValue } from "helpers/translateHardcodedData";
import i18next from "i18next";

const Heater = (props) => {
  const location = useLocation();

  const [state, setState] = useState({
    pageTitle: i18next.t("heater"),
    heaters: [],
    heatersLoaded: false,
    nomRoomTypes: [],
    nomHeaterTypes: [],
    isLoading: false,
    isLoaded: false,
    isModalOpen: false,
    isActive: true,
  });

  const emitErrors = (error) => {
    props.onErrorsShow(error.body);
  };

  const toggleActive = (id) => {
    const state = state.heaters;

    const item = state.find((obj) => {
      return obj.id === id;
    });

    if (item) {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      };

      if (!item.isActive) {
        fetch(
          `${endpoints.BUILDING_UNITS_ENDPOINT}/Heater/Activate?id=${id}`,
          requestOptions
        ).then(() => {
          setState((state) => ({ ...state, status: "Activation successful" }));

          const currentState = state.heaters;

          const index = currentState.findIndex((item) => item.id === id);
          currentState[index].isActive = true;

          setState((state) => ({ ...state, heaters: currentState }));
        });
      } else {
        fetch(
          `${endpoints.BUILDING_UNITS_ENDPOINT}/Heater/Deactivate?id=${id}`,
          requestOptions
        ).then(() => {
          setState((state) => ({ ...state, status: "Dectivation successful" }));

          const currentState = state.heaters;

          const index = currentState.findIndex((item) => item.id === id);
          currentState[index].isActive = false;

          setState((state) => ({ ...state, heaters: currentState }));
        });
      }
    }
  };

  const deleteItem = (id) => {
    fetch(`${endpoints.BUILDING_UNITS_ENDPOINT}/Heater/Delete?id=${id}`, {
      method: "DELETE",
    }).then(() =>
      setState((state) => ({ ...state, status: "Delete successful" }))
    );

    const currentState = state.heaters;
    const index = currentState.findIndex((item) => item.id === id);

    currentState.splice(index, 1);
    setState((state) => ({ ...state, heaters: currentState }));
  };

  const openModal = () => {
    setState((state) => ({ ...state, isModalOpen: !state.isModalOpen }));
    removeBodyCss();
  };

  const closeModal = () => {
    setState((state) => ({ ...state, isModalOpen: false }));
    removeBodyCss();
  };

  const showData = (e) => {
    var checkBox = document.getElementById("myCheck");
    setState((state) => ({ ...state, showActive: checkBox.checked }));
    getAllHeaters();
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };

  const handleDataChange = (event) => {
    const value = event.target.value;
    setState((state) => ({
      ...state,
      [event.target.name]: value,
    }));
  };

  const handleNewItemAdd = (item) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name: item.name,
        nomRoomTypeId: item.nomRoomTypeId,
        nomHeaterTypeId: item.nomHeaterTypeId,
        valueOne: item.valueOne,
        valueTwo: item.valueTwo,
        bureHeatCostAllocatorId: item.bureHeatCostAllocatorId,
        status: item.status,
        comment: item.comment,
        demountingDate: item.demountingDate,
        isActive: true,
      }),
    };

    fetch(`${endpoints.BUILDING_UNITS_ENDPOINT}/Heater/Add`, requestOptions)
      .then((resp) => resp.json())
      .then((data) => {
        const currentState = state.heaters;
        currentState.push(data);

        setState((state) => ({
          ...state,
          heaters: currentState,
          isModalOpen: false,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAllHeaters = () => {
    const url = `${endpoints.BUILDING_UNITS_ENDPOINT}/Heater/GetAll?isActive=${state.isActive}`;

    const data = fetch(url)
      .then((resp) => resp.json())
      .then((data) => {
        setState((state) => ({
          ...state,
          heaters: data.items,
          heatersLoaded: true,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getNomRoomTypes = () => {
    const data = fetch(`${endpoints.NOMENCLATURE_ENDPOINT}/RoomType/GetAll`)
      .then((resp) => resp.json())
      .then((data) => {
        setState((state) => ({ ...state, nomRoomTypes: data.items }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getNomHeaterTypes = () => {
    const data = fetch(`${endpoints.NOMENCLATURE_ENDPOINT}/HeaterType/GetAll`)
      .then((resp) => resp.json())
      .then((data) => {
        setState((state) => ({ ...state, nomHeaterTypes: data.items }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem("authUser"));

    if (location.state) {
      setState((state) => ({
        ...state,
        mode: "edit",
        loggedUserType: obj.role,
        isLoading: true,
      }));
    } else {
      setState((state) => ({
        ...state,
        loggedUserType: obj.role,
        isLoading: true,
      }));
    }

    getNomRoomTypes();
    getNomHeaterTypes();
    getAllHeaters();
  }, []);

  useEffect(() => {
    getAllHeaters();
  }, [state.isActive]);

  state.heaters.length &&
    state.heaters.map((element) => {
      const item = state.nomRoomTypes.find((obj) => {
        return obj.id === Number(element.nomRoomTypeId);
      });

      element.roomType = item && item.name;
    });

  state.heaters.length &&
    state.heaters.map((element) => {
      const item = this.state.nomHeaterTypes.find((obj) => {
        return obj.id === Number(element.nomHeaterTypeId);
      });

      element.heaterType = item && item.name;
    });

  state.heaters.length &&
    state.heaters.map((element) => {
      element.statusValue = translateStatusEnumValue(element.status);
    });

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <CardTitle className="mb-4">{state.pageTitle}</CardTitle>
                </Col>

                <Col className="text-right">
                  <ButtonAdd
                    onBtnClick={openModal}
                    data-toggle="modal"
                    tooltip={i18next.t("tootltip-heating-area-add")}
                  />
                </Col>
              </Row>

              <FilterBar
                tableData={state.heaters}
                isActive
                state={state}
                setState={setState}
              ></FilterBar>

              <TableListComponent
                tableData={state.heaters}
                tableColumns={[
                  i18next.t("room"),
                  i18next.t("type"),
                  i18next.t("status"),
                ]}
                toggleActive={toggleActive}
                deleteItem={deleteItem}
                category={"heater"}
                isLoading={state.isLoading}
                isLoaded={state.isLoaded}
              />

              <ModalHeater
                isModalOpen={state.isModalOpen}
                closeModal={closeModal}
                addNewItem={handleNewItemAdd}
                emitErrors={emitErrors}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
};

Heater.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Heater));

import PropTypes from "prop-types";
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { userForgetPassword } from "../../store/actions";
import i18next from "i18next";

const ForgetPasswordPage = (props) => {
  const handleValidSubmit = (event, values) => {
    console.log(values);
    //this.props.userForgetPassword(values, this.props.history);
  };

  return (
    <>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Reset password</h5>
                      </div>
                    </Col>
                  </Row>
                </div>

                <CardBody className="pt-0">
                  <div className="p-2">
                    <Alert
                      color="danger"
                      style={{
                        marginTop: "13px",
                      }}
                    >
                      {i18next.t("placeholder_enter_username")}
                    </Alert>
                    {props.forgetError && props.forgetError ? (
                      <Alert
                        color="danger"
                        style={{
                          marginTop: "13px",
                        }}
                      >
                        {/* {this.props.forgetError} */}
                        {i18next.t("placeholder_enter_username")}
                      </Alert>
                    ) : null}
                    {props.forgetSuccessMsg ? (
                      <Alert
                        color="success"
                        style={{
                          marginTop: "13px",
                        }}
                      >
                        {/* { */}
                        {/* 	this.props */}
                        {/* 		.forgetSuccessMsg */}
                        {/* } */}
                        {i18next.t("placeholder_enter_username")}
                      </Alert>
                    ) : null}

                    <AvForm
                      className="form-horizontal mt-4"
                      onValidSubmit={handleValidSubmit}
                    >
                      <div className="form-group">
                        <AvField
                          name="username"
                          label="Username"
                          className="form-control"
                          placeholder={i18next.t("placeholder_enter_username")}
                          type="text"
                          required
                        />
                      </div>
                      <Row className="form-group">
                        <Col className="text-right">
                          <button
                            className="btn btn-primary btn-block waves-effect waves-light"
                            type="submit"
                          >
                            Reset
                          </button>
                        </Col>
                      </Row>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>

              <div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link to="login" className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

ForgetPasswordPage.propTypes = {
  forgetError: PropTypes.func,
  forgetSuccessMsg: PropTypes.func,
  history: PropTypes.object,
  userForgetPassword: PropTypes.any,
};

const mapStateToProps = (state) => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword;
  return { forgetError, forgetSuccessMsg };
};

export default withRouter(
  connect(mapStateToProps, { userForgetPassword })(ForgetPasswordPage)
);

import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import * as endpoints from "constants/endpoints";
import i18next from "i18next";

const Recoverpw = (props) => {
  const history = useHistory();

  const [state, setState] = useState({
    isResetSend: false,
    error: "",
  });

  const handleValidSubmit = (event, values) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "text/plain" },
      // body: JSON.stringify({
      // 	username: values.username
      // })
    };

    fetch(
      `${endpoints.USER}/EmployeeForgotPassword?username=${values.username}`,
      requestOptions
    )
      .then((resp) => {
        console.log(resp);
        // Delete after BE fix

        if (resp.status === 200) {
          console.log("success");
          setState((state) => ({ ...state, isResetSend: true }));
          // this.getUsers(values.username);
          // this.setState({ isValid: true });
          // this.props.history.push('/dashboard');
        } else {
          setState((state) => ({
            ...state,
            error: `User with username: ${values.username} not found!`,
          }));
        }

        if (resp.status === 401) {
          // console.log('unauthorized');
          // localStorage.removeItem('authUser');
          // this.setState({ isValid: false });
        }
      })
      .then((data) => {})
      .catch((error) => {
        // handle error
        console.log(error);
      });
  };

  const handleValidSubmitWithCode = (event, values) => {
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json-patch+json" },
      body: JSON.stringify({
        username: values.username,
        resetPasswordCode: values.reset_code,
        newPassword: values.new_password,
      }),
    };

    fetch(`${endpoints.USER}/EmployeeChangePassword`, requestOptions)
      .then((resp) => {
        console.log(resp);
        // Delete after BE fix

        if (resp.status === 200) {
          console.log("success");

          history.push("/login");
        } else {
          setState((state) => ({
            ...state,
            error: i18next.t("error-occurred-contact-admin"),
          }));
        }

        if (resp.status === 401) {
          console.log("unauthorized");
          localStorage.removeItem("authUser");
          setState((state) => ({ ...state, isValid: false }));
        }
      })
      .then((data) => {})
      .catch((error) => {
        // handle error
        console.log(error);
      });
  };

  return (
    <>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              {!state.isResetSend ? (
                <Card className="overflow-hidden">
                  <div className="bg-soft-primary">
                    <Row>
                      <Col xs={7}>
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Reset Password</h5>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div className="p-2">
                      <div
                        className="alert alert-success text-center mb-4 mt-4"
                        role="alert"
                      >
                        {i18next.t("placeholder_enter_username")}
                      </div>

                      {state.error.length > 0 && (
                        <div
                          className="alert alert-danger text-center mb-4 mt-4"
                          role="alert"
                        >
                          {state.error}
                        </div>
                      )}

                      <AvForm
                        className="form-horizontal mt-4"
                        onValidSubmit={handleValidSubmit}
                      >
                        <div className="form-group">
                          <AvField
                            name="username"
                            label="Username"
                            className="form-control"
                            placeholder={i18next.t(
                              "placeholder_enter_username"
                            )}
                            type="text"
                            required
                          />
                        </div>
                        <Row className="form-group">
                          <Col className="text-right">
                            <button
                              className="btn btn-primary btn-block waves-effect waves-light"
                              type="submit"
                            >
                              Reset
                            </button>
                          </Col>
                        </Row>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
              ) : (
                <Card className="overflow-hidden">
                  <div className="bg-soft-primary">
                    <Row>
                      <Col xs={7}>
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Reset Password</h5>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div className="p-2">
                      <div
                        className="alert alert-success text-center mb-4 mt-4"
                        role="alert"
                      >
                        {i18next.t("placeholder_enter_username")}
                      </div>

                      <AvForm
                        className="form-horizontal mt-4"
                        onValidSubmit={handleValidSubmitWithCode}
                      >
                        <div className="form-group">
                          <AvField
                            name="username"
                            label="Username"
                            className="form-control"
                            placeholder={i18next.t(
                              "placeholder_enter_username"
                            )}
                            type="text"
                            required
                          />
                        </div>

                        <div className="form-group">
                          <AvField
                            name="reset_code"
                            label={i18next.t("code")}
                            className="form-control"
                            placeholder={i18next.t("placeholder_enter_code")}
                            type="text"
                            required
                          />
                        </div>

                        <div className="form-group">
                          <AvField
                            name="new_password"
                            label={i18next.t("new-password")}
                            className="form-control"
                            placeholder={i18next.t(
                              "placeholder_enter_password"
                            )}
                            type="text"
                            required
                          />
                        </div>

                        <Row className="form-group">
                          <Col className="text-right">
                            <button
                              className="btn btn-primary btn-block waves-effect waves-light"
                              type="submit"
                            >
                              Reset
                            </button>
                          </Col>
                        </Row>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
              )}

              <div className="mt-5 text-center">
                <p>
                  Remember It ?{" "}
                  <Link to="/" className="font-weight-medium text-primary">
                    {" "}
                    Sign In here
                  </Link>{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Recoverpw;

import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Card, CardBody, Container } from "reactstrap";
import { Modal } from "reactstrap";
import i18next from "i18next";

const ModalSuccess = (props) => {
  const closeModal = () => {
    props.closeModal();
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Modal isOpen={props.isModalOpen} scrollable={true}>
            <div className="modal-header">
              <h5 className="modal-title mt-0">
                {i18next.t("modal_success_title")}
              </h5>

              <button
                type="button"
                onClick={() => closeModal()}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <Card className="mb-0">
                <CardBody>{i18next.t(props.message)}</CardBody>
              </Card>
            </div>
          </Modal>
        </Container>
      </div>
    </>
  );
};

ModalSuccess.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
  closeModal: PropTypes.func,
  openModal: PropTypes.func,
};

const mapStateToProps = ({ modal }) => ({
  // modal
});

const mapDispatchToProps = (dispatch) => ({
  //...bindActionCreators({ closeModal, openModal }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ModalSuccess));

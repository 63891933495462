import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import i18next from "i18next";

const LinkView = (props) => {
  return (
    <>
      <Tooltip title={i18next.t("open")} placement="right-start">
        <Link to={props.link} id={"link-to" + props.id}>
          <i>
            <svg width="24" height="16" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15 8c0 1.654-1.346 3-3 3S9 9.654 9 8s1.346-3 3-3 3 1.346 3 3zm9-.449S19.748 16 12.015 16C4.835 16 0 7.551 0 7.551S4.446 0 12.015 0C19.709 0 24 7.551 24 7.551zM17 8c0-2.757-2.243-5-5-5S7 5.243 7 8s2.243 5 5 5 5-2.243 5-5z"
                fill="#4A4D46"
                fillRule="nonzero"
              />
            </svg>
          </i>
        </Link>
      </Tooltip>
    </>
  );
};

LinkView.propTypes = {
  title: PropTypes.string,
};

export default LinkView;

import { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Row,
} from "reactstrap";
import TableWithAccordeon from "../../components/Common/TableWithAccordeon";
import * as endpoints from "constants/endpoints";
import {
  GetData,
  SaveItem,
  ToggleItem,
  FileUpload,
} from "../../helpers/CRUD.js";
import Loader from "../../components/Common/Loader";
import i18next from "i18next";

const FileUpload_F = (props) => {
  const [state, setState] = useState({
    file_new: null,
    showData: false,
    dataToShow: [],
    toggleOldStructure: false,
    estates: [],
    buildings: [],
    showMsg: false,
    message: "",
    hideUploadPanel: false,
    diffId: null,
    isFileUploaded: false,
    isLoading: false,
    isLoaded: false,
    groupData: [],
    areEstatesSelected: false,
    areBuildingsSelected: false
  });

  const onFileChange = (event) => {
    const file = event.target.files[0];
    setState((state) => ({ ...state, file_new: file }));
  };

  const uploadFile = () => {
    setState((state) => ({ ...state, isLoading: true }));
    const nameid = JSON.parse(localStorage.getItem("nameid"));

    const file = state.file_new;
    const data = new FormData();
    data.append("file", file);

    const url = `${endpoints.TPF}/UploadFFile?userId=${nameid}&isLegacyFile=${state.toggleOldStructure}`;

    FileUpload(
      url,
      data,
      (resp, data) => {
        if (resp.status === 200) {
          console.log("upload f file result", data);

          setState((state) => ({ ...state, isLoaded: true, isLoading: false }));

          if (
            data.diffMessage !== null &&
            data.diffMessage !== "F_NO_COMPARISON_DATA"
          ) {
            setState((state) => ({
              ...state,
              hideUploadPanel: true,
              diffId: data.mergeDiffId,
            }));
            getMergeData();
          } else if (data.uploadMessage !== null && data.uploadMessage !== "") {
            setState((state) => ({
              ...state,
              hideUploadPanel: false,
            }));
            props.onSuccessShow(data.uploadMessage);
          } else if (data.warningResponse !== null) {
            setState((state) => ({
              ...state,
              hideUploadPanel: false,
            }));
            props.onWarningsShow(data.warningResponse);
          }
        }
      },
      (error) => {
        setState((state) => ({ ...state, isLoaded: false, isLoading: false }));
        props.onErrorsShow(error.body);
      }
    );
  };


  const getMergeData = () => {
    const url = `${endpoints.TPF}/GetLastUnmerged`;

    GetData(
      url,
      (data) => {
        const estatesCount = data.estatesMergeDiffDetails && data.estatesMergeDiffDetails.length;
        const buildingsCount = data.buildingsMergeDiffDetails && data.buildingsMergeDiffDetails.length;

        const estates = [];
        const buildings = [];

        if (buildingsCount) {
          for (var i = 0; i < buildingsCount; i++) {
            const newItem = {
              id: `building-${i}`,
              isCollapsed: true,
              isSelected: false,
              data: data.buildingsMergeDiffDetails[i].allEntities,
              category_diff:
                data.buildingsMergeDiffDetails[i].propertiesValueChanged,
              selectedRow: [
                { isSelected: false },
                { isSelected: false },
                { isSelected: false },
                { isSelected: true },
              ],
            };

            buildings.push(newItem);
          }
        }

        if(data.mergeDiffId > 0) {
          console.log("mergeDiffId", data.mergeDiffId);
          console.log("data.isMerged", data.isMerged)
          if (data.isMerged) {
            setState((state) => ({
              ...state,
              hideUploadPanel: false,
              diffId: data.mergeDiffId,
            }));
          } else {
            setState((state) => ({
              ...state,
              hideUploadPanel: true,
              diffId: data.mergeDiffId,
            }));
          }
        }
        else {
          setState((state) => ({
            ...state,
            hideUploadPanel: false,
          }));
        }

        if (estatesCount) {
          for (var i = 0; i < estatesCount; i++) {
            const newItem = {
              id: `estate-${i}`,
              isCollapsed: true,
              isSelected: false,
              data: data.estatesMergeDiffDetails[i].allEntities,
              category_diff:
                data.estatesMergeDiffDetails[i].propertiesValueChanged,
              selectedRow: [
                { isSelected: false },
                { isSelected: false },
                { isSelected: false },
                { isSelected: true },
              ],
            };

            estates.push(newItem);
          }
        }

        if (estates.length === 0 && buildings.length === 0) {
          setState((state) => ({
            ...state,
            estates,
            buildings,
            mergeData: data,
            showData: true,
            isFileUploaded: false,
           // hideUploadPanel: false,
          }));
        } else {
          setState((state) => ({
            ...state,
            estates,
            buildings,
            mergeData: data,
            showData: true,
            isFileUploaded: true,
            //hideUploadPanel: true,
            diffId: data.mergeDiffId
          }));
        }
      },
      (error) => {
        console.log(error);
        props.onErrorsShow(error.body);
      }
    );
  };

  const toggleAcordion = (category, obj) => {
    const data = state[category];
    const index = data.findIndex((item) => item.id === obj.id);
    data[index].isCollapsed = !data[index].isCollapsed;
    setState((state) => ({ ...state, data }));
  };

  const selectReport = (category, parentId, objIndex) => {
    const data = state[category];
    const indexParent = data.findIndex((item) => item.id === parentId);

    if (data[indexParent].selectedRow[objIndex].isSelected) {
      data[indexParent].selectedRow[objIndex].isSelected = false;
    } else {
      data[indexParent].selectedRow.map((item) => {
        item.isSelected = false;
      });

      data[indexParent].selectedRow[objIndex].isSelected = true;
    }

    setState((state) => ({ ...state, data }));
  };

  const selectRow = (category, obj, isSelected) => {
    const data = state[category];

    console.log("selectRow", obj);
    console.log("isSelected", isSelected);
    const index = data.findIndex((item) => item.id === obj.id);

    data[index].isSelected = isSelected;
    setState((state) => ({...state, data }));




    if(category === "estates") {
      const selectedEstates = state["estates"].filter(x => x.isSelected === true);
      setState((state) => ({...state, data, areEstatesSelected: selectedEstates.length}));
    }
    if(category === "buildings") {
      const selectedBuildings = state["buildings"].filter(x => x.isSelected === true);
      setState((state) => ({...state, data, areBuildingsSelected: selectedBuildings.length}));
    }
  };

  const sendSingleRowData = (id, category) => {
    const selectedGroupIndex = state[category].findIndex(
      (item) => item.id === id
    );
    const selectedGroup = state[category][selectedGroupIndex];
    const indexOfRow = selectedGroup.selectedRow.findIndex(
      (item) => item.isSelected === true
    );

    exportDataToApi(category, [
      {
        groupIndex: selectedGroupIndex,
        rowIndex: indexOfRow,
      },
    ]);
  };

  /*const items = state["estate"];
  const groupData = [];

  const selectedItems = items && items.map((item, itemIndex) => {
    if (
        item.isSelected &&
        item.selectedRow.some((element) => element.isSelected === true)
    ) {
      const indexOfSelectedOption = item.selectedRow.findIndex(
          (element) => element.isSelected === true
      );

      const selectedItem = item.data[indexOfSelectedOption];

      groupData.push({
        groupIndex: itemIndex,
        rowIndex: indexOfSelectedOption,
      });

      return selectedItem;
    } else {
      return false;
    }
  });
  setState((state) => ({ ...state, groupData }));*/


  const items = state["estates"].filter(x => x.isSelected === true);

  console.log("items selected", items);

  const sendData = (category) => {
    const items = state[category];
    const groupData = [];

    console.log(items);

    const selectedItems = items.map((item, itemIndex) => {
      if (
        item.isSelected &&
        item.selectedRow.some((element) => element.isSelected === true)
      ) {
        const indexOfSelectedOption = item.selectedRow.findIndex(
          (element) => element.isSelected === true
        );

        const selectedItem = item.data[indexOfSelectedOption];

        groupData.push({
          groupIndex: itemIndex,
          rowIndex: indexOfSelectedOption,
        });

        return selectedItem;
      } else {
        return false;
      }
    });

    exportDataToApi(category, groupData);
  };

  const exportDataToApi = (category, groupData) => {
    const url = `${endpoints.TPF}/UpdateData`;
    const mergeData = state.mergeData;
    const diffPerRow = [];
    const computedCategory =
      category === "estates"
        ? "estatesMergeDiffDetails"
        : "buildingsMergeDiffDetails";

    groupData.map((group) => {
      const diffDetailsId = Object.keys(
        mergeData[computedCategory][`${group.groupIndex}`].mergeDiffDetailsById
      );

      mergeData[computedCategory][`${group.groupIndex}`].mergeDiffDetailsById[
        `${diffDetailsId[0]}`
      ].map((diff) => {
        const rowData = {
          key: group.rowIndex + 1,
          value: diff.id,
        };

        diffPerRow.push(rowData);
      });
    });

    console.log(diffPerRow);

    SaveItem(
      url,
      diffPerRow,
      (data) => {
        const items = state[`${category}`];

        const indexes = groupData.map((item) => {
          return item.groupIndex;
        });

        var pulled = _.pullAt(items, indexes);

        if (state.estates.length === 0 && state.buildings.length === 0) {
          setState((state) => ({
            ...state,
            [`${category}`]: items,
            isFileUploaded: false,
           // hideUploadPanel: false,
          }));
        } else {
          setState((state) => ({ ...state, [`${category}`]: items }));
        }
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  const resolveAll = () => {
    const url = `${endpoints.TPF}/ToggleIsMerged?id=${state.diffId}&isMerged=true`;

    ToggleItem(
      url,
      (resp) => {
        setState((state) => ({ ...state, estates: [], buildings: [], hideUploadPanel: false }));
        props.onSuccessShow("Сравнението беше финализирано.");
      },
      (error) => {
        props.onErrorsShow(error.body);
      }
    );
  };

  useEffect(() => {
    getMergeData();
    const obj = JSON.parse(localStorage.getItem("authUser"));
    setState((state) => ({ ...state, loggedUserType: obj.role }));
  }, []);

  console.log(state.hideUploadPanel);
  return (
    <>
      <div className="page-content">
        <Container fluid>
         {/* {state.isFileUploaded === false &&
            (state.buildings.length === 0 || state.estates.length === 0) && (*/}
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <div>
                        <div
                            //style={{display: `${state.hideUploadPanel ? 'none' : 'block'}`}}
                            className='d-flex align-items-center'>
                          <input
                              style={{display: `${state.hideUploadPanel ? 'none' : 'block'}`}}
                            name="file_new"
                            placeholder=""
                            id="field-AS"
                            type="file"
                            accept=".dbf"
                            onChange={(e) => onFileChange(e)}
                            className="flex-grow-1"
                          />


                          {state.file_new !== null ? (
                            <button
                              type="button"
                              style={{display: `${state.hideUploadPanel ? 'none' : 'block'}`}}
                              className="btn btn-primary waves-effect waves-light flex-shrink-1"
                              onClick={uploadFile}
                            >
                              {i18next.t("upload_files_add_new_file")}
                            </button>
                          ) : null}
                        </div>

                        <div
                            style={{display: `${state.hideUploadPanel ? 'none' : 'block'}`}}
                            className="custom-control custom-switch mb-0 mt-3"
                            dir="ltr"
                        >
                          <input
                              type="checkbox"
                              className="custom-control-input"
                              id="customSwitch-old-structure"
                              onClick={() => {
                                setState((state) => ({
                                  ...state,
                                  toggleOldStructure: !state.toggleOldStructure,
                                }));
                              }}
                          />
                          <label
                              className="custom-control-label mb-0"
                              htmlFor="customSwitch-old-structure"
                          >
                            {i18next.t("upload_files_old_f_structure")}
                          </label>
                        </div>

                        <div
                            style={{display: `${state.hideUploadPanel ? 'block' : 'none'}`}}
                            className='align-items-center'
                            dir="ltr"
                        >

                          <div className="d-flex justify-content-between align-items-center mb-2">
                            {(state.estates && state.estates.length > 0) ||
                            (state.buildings && state.buildings.length > 0) ? (
                            <CardTitle className="mb-0">{i18next.t("merge_diff_data_true")}</CardTitle>)
                                :
                                (<CardTitle className="mb-0">{i18next.t("merge_diff_data_false")}</CardTitle>)}
                            <button
                                className="btn btn-primary waves-effect waves-light"
                                onClick={() => resolveAll()}
                            >
                              {i18next.t("upload_files_mark_as_resolved")}
                            </button>
                          </div>

                        </div>

                        </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
          {/*  )}*/}

          {state.showMsg ? (
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <div className="text-center mb-2">
                      <h1>{state.message}</h1>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          ) : null}

          {state.estates && state.estates.length > 0 && (
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <CardTitle className="mb-0">{i18next.t("menu-estates")}</CardTitle>

                      <button
                        className="btn btn-primary waves-effect waves-light"
                        onClick={() => sendData("estates")}
                        disabled={!state.areEstatesSelected}
                      >
                        {i18next.t("upload_files_save_changes")}
                      </button>
                    </div>

                    <TableWithAccordeon
                      data={state.estates}
                      category={"estates"}
                      toggleAcordion={toggleAcordion}
                      selectReport={selectReport}
                      selectRow={selectRow}
                      sendSingleRowData={sendSingleRowData}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          )}

          {state.buildings && state.buildings.length > 0 && (
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <CardTitle className="mb-0">{i18next.t("menu-buildings")}</CardTitle>

                      <button
                        className="btn btn-primary waves-effect waves-light"
                        onClick={() => sendData("buildings")}
                        disabled={!state.areBuildingsSelected}
                      >
                        {i18next.t("upload_files_save_changes")}
                      </button>
                    </div>

                    <TableWithAccordeon
                      data={state.buildings}
                      category={"buildings"}
                      toggleAcordion={toggleAcordion}
                      selectReport={selectReport}
                      selectRow={selectRow}
                      sendSingleRowData={sendSingleRowData}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          )}



          {state.isLoading && !state.isLoaded && <Loader showLoader={true} />}
        </Container>
      </div>
    </>
  );
};

FileUpload_F.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FileUpload_F));

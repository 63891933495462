import { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Card, CardBody, Col, Container, Form, Row } from "reactstrap";
import * as endpoints from "constants/endpoints";
import { FileUpload } from "../../helpers/CRUD.js";
import Loader from "../../components/Common/Loader";
import i18next from "i18next";

const FileUpload_FRD = (props) => {
  const [state, setState] = useState({
    file_new: null,
    showData: false,
    dataToShow: [],
    toggleSwitch: false,
    dataFromPreviuosFile: null,
    dataFromTK: null,
    isLoading: false,
    isLoaded: false,
  });

  const onFileChange = (event) => {
    const file = event.target.files[0];
    setState((state) => ({ ...state, file_new: file }));
  };

  const uploadFile = () => {
    setState((state) => ({ ...state, isLoading: true }));

    const nameid = JSON.parse(localStorage.getItem("nameid"));
    const file = state.file_new;
    const data = new FormData();
    data.append("file", file);

    const url = `${endpoints.TPF}/UploadFRDFile?userId=${nameid}`;

    FileUpload(
      url,
      data,
      (resp, data) => {
        if (resp.status === 200) {
          setState((state) => ({ ...state, isLoaded: true, isLoading: false }));

          if (data.uploadMessage !== "") {
            props.onSuccessShow(data.uploadMessage);
          }
        }
      },
      (error) => {
        setState((state) => ({ ...state, isLoaded: false, isLoading: false }));
        props.onErrorsShow(error.body);
      }
    );
  };

  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    setState((state) => ({ ...state, loggedUserType: obj.role }));
  }, []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <Form>
                    <div className="d-flex align-items-center">
                      <input
                        name="file_new"
                        placeholder=""
                        id="field-AS"
                        type="file"
                        accept=".dbf"
                        onChange={(e) => onFileChange(e)}
                        className="flex-grow-1"
                      />

                      {state.file_new !== null ? (
                        <button
                          type="button"
                          className="btn btn-primary waves-effect waves-light flex-shrink-1"
                          onClick={uploadFile}
                        >
                          {i18next.t("upload_files_add_new_file")}
                        </button>
                      ) : null}
                    </div>

                    <div
                      className="custom-control custom-switch mb-0 mt-3"
                      dir="ltr"
                    >
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customSwitchsizesm"
                        onClick={() => {
                          setState((state) => ({
                            ...state,
                            toggleSwitch: !state.toggleSwitch,
                          }));
                        }}
                      />
                      <label
                        className="custom-control-label mb-0"
                        htmlFor="customSwitchsizesm"
                      >
                        {i18next.t("upload_files_yearly")}
                      </label>
                    </div>
                  </Form>
                </Col>
              </Row>
            </CardBody>
          </Card>

          {state.isLoading && !state.isLoaded && <Loader showLoader={true} />}
        </Container>
      </div>
    </>
  );
};

FileUpload_FRD.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FileUpload_FRD));

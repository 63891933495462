import PropTypes from "prop-types";
import loaderSrc from "../../assets/images/loader.gif";

const Loader = (props) => {
  return (
    <>
      {props.showLoader && !props.loaded && (
        <div className="loader text-center ">
          <img src={loaderSrc} alt="" height="50" width="50" />
        </div>
      )}
    </>
  );
};

Loader.propTypes = {
  title: PropTypes.string,
};

export default Loader;
